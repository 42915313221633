import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import ErrorPage from './pages/ErrorPage';
import AdminRoute, { EthRoute } from './components/Common/AdminRoute';
import CollectionWidget from './pages/CollectionWidget';
import CollectionMint from './pages/CollectionMint';
import StudioHome from './pages/Studio/Home/index';
import StudioPageWrapper from './components/Studio/PageWrapper/index';
import Collection from './pages/Collection';
import StudioCollection from './pages/Studio/Manage/Collection';
import Home from './pages/Home';
import Admin from './pages/Admin';
import Auction from './pages/Auction';
import Auctions from './pages/Auctions';
import Create from './pages/Studio/Create';
import Manage from './pages/Studio/Manage';
import GettingStarted from './pages/Studio/GettingStarted';
import MintRequest from './pages/Studio/MintRequest';
import Personal from './pages/Studio/Create/Personal';
import Public from './pages/Studio/Create/Public';
import Editions from './pages/Studio/Create/Editions';
import Privacy from './pages/Studio/Terms/privacy';
import Terms from './pages/Studio/Terms/terms';
import TermsCreator from './pages/Studio/Terms/terms-creator';
import { globalCss } from '@stitches/react';
import ColourModeProvider from './theme/ColorModeProvider';
import { Helmet } from 'react-helmet';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import '@rainbow-me/rainbowkit/dist/index.css';
import { chain, configureChains, createClient, WagmiConfig } from 'wagmi';
import { alchemyProvider } from 'wagmi/providers/alchemy';
import { publicProvider } from 'wagmi/providers/public';
import PublicEth from './pages/Studio/Create/PublicEth';
import EthDrop from './pages/EthDrop';
import EditionsEth from './pages/Studio/Create/EditionsEth';
import MegapontAlphaJacket from './pages/MegapontAlphaJacket';
import EthFeeManager from './pages/EthFeeManager';
import { MetaMaskConnector } from 'wagmi/connectors/metaMask';

const { provider } = configureChains(
  [chain.mainnet],
  [alchemyProvider({ apiKey: process.env.REACT_APP_ALCHEMY_KEY }), publicProvider()],
);

const wagmiClient = createClient({
  autoConnect: true,
  connectors: [new MetaMaskConnector()],
  provider,
});

const globalStyles = globalCss({
  body: {
    color: '$text',
    backgroundColor: '$background',
  },
  a: {
    textDecoration: 'none',
    color: 'inherit',
  },
});

const queryClient = new QueryClient();

export default function App() {
  globalStyles();
  return (
    <WagmiConfig client={wagmiClient}>
      <QueryClientProvider client={queryClient}>
        <ColourModeProvider>
          <Helmet>
            <title>Gamma.io | Find & Buy Bitcoin-Secured Stacks NFTs</title>
            <meta
              name="description"
              content="The best place to find, collect, and sell NFTs secured by Bitcoin. Gamma is the open marketplace for Stacks NFTs, with thousands of NFTs to find & trade."
            />
          </Helmet>
          <Router>
            <Switch>
              <Route
                path="/privacy"
                render={(props) => (
                  <StudioPageWrapper {...props}>
                    <Privacy />
                  </StudioPageWrapper>
                )}
              />

              <Route
                path="/terms/creator-terms"
                render={(props) => (
                  <StudioPageWrapper {...props}>
                    <TermsCreator />
                  </StudioPageWrapper>
                )}
              />

              <Route
                path="/terms"
                render={(props) => (
                  <StudioPageWrapper {...props}>
                    <Terms />
                  </StudioPageWrapper>
                )}
              />

              <Route
                path="/getting-started"
                render={(props) => (
                  <StudioPageWrapper {...props}>
                    <GettingStarted />
                  </StudioPageWrapper>
                )}
              />

              <Route
                path="/mint-request"
                render={(props) => (
                  <StudioPageWrapper {...props}>
                    <MintRequest />
                  </StudioPageWrapper>
                )}
              />

              <Route
                path="/create/continuous"
                render={(props) => (
                  <StudioPageWrapper {...props}>
                    <Personal />
                  </StudioPageWrapper>
                )}
              />

              <Route
                path="/create/public"
                render={(props) => (
                  <StudioPageWrapper {...props}>
                    <Public />
                  </StudioPageWrapper>
                )}
              />

              <EthRoute
                path="/create/public-eth"
                render={(props: any) => (
                  <StudioPageWrapper {...props}>
                    <PublicEth />
                  </StudioPageWrapper>
                )}
              />

              <EthRoute
                path="/create/editions-eth"
                render={(props: any) => (
                  <StudioPageWrapper {...props}>
                    <EditionsEth />
                  </StudioPageWrapper>
                )}
              />

              <Route
                path="/create/editions"
                render={(props) => (
                  <StudioPageWrapper {...props}>
                    <Editions />
                  </StudioPageWrapper>
                )}
              />

              <Route
                path="/create"
                render={(props) => (
                  <StudioPageWrapper {...props}>
                    <Create />
                  </StudioPageWrapper>
                )}
              />

              <Route
                path="/drop/:hash"
                render={(props) => (
                  <StudioPageWrapper {...props}>
                    <EthDrop hash={props.match.params.hash} />
                  </StudioPageWrapper>
                )}
              />

              <Route
                path="/feemanager"
                render={(props) => (
                  <StudioPageWrapper {...props}>
                    <EthFeeManager />
                  </StudioPageWrapper>
                )}
              />

              <Route
                path="/manage/:contractId"
                render={(props) => (
                  <StudioPageWrapper {...props}>
                    <StudioCollection contractId={props.match.params.contractId} />
                  </StudioPageWrapper>
                )}
              />

              <Route
                path="/manage"
                render={(props) => (
                  <StudioPageWrapper {...props}>
                    <Manage />
                  </StudioPageWrapper>
                )}
              />

              <Route path="/studio">
                <StudioPageWrapper>
                  <StudioHome />
                </StudioPageWrapper>
              </Route>

              <AdminRoute
                exact
                path="/admin"
                render={(props: any) => (
                  <StudioPageWrapper {...props}>
                    <Admin />
                  </StudioPageWrapper>
                )}
              />

              <Route
                exact
                path="/minting-now"
                render={(props: any) => (
                  <StudioPageWrapper {...props}>
                    <Home />
                  </StudioPageWrapper>
                )}
              />

              <Route
                exact
                path="/recently-deployed"
                render={(props: any) => (
                  <StudioPageWrapper {...props}>
                    <Home />
                  </StudioPageWrapper>
                )}
              />

              <Route
                exact
                path="/"
                render={(props: any) => (
                  <StudioPageWrapper {...props}>
                    <StudioHome />
                  </StudioPageWrapper>
                )}
              />

              <Route
                path="/collection/together-with-gamma"
                component={() => {
                  window.location.href = 'https://stacks.gamma.io/collections/together-with-gamma';
                  return null;
                }}
              />

              <Route
                path="/collection/megapont-alpha-jacket"
                render={(props) => (
                  <StudioPageWrapper {...props}>
                    <MegapontAlphaJacket name={'megapont-alpha-jacket'} />
                  </StudioPageWrapper>
                )}
              />

              <Route
                path="/mint-widget/:name"
                render={(props) => <CollectionWidget name={props.match.params.name} />}
              />

              <Route
                path="/collection/:name/:id"
                render={(props) => (
                  <StudioPageWrapper {...props}>
                    <Auction name={props.match.params.name} id={props.match.params.id} />
                  </StudioPageWrapper>
                )}
              />

              <Route
                path="/mint/:name"
                render={(props) => (
                  <StudioPageWrapper {...props}>
                    <CollectionMint name={props.match.params.name} />
                  </StudioPageWrapper>
                )}
              />

              <Route
                path="/collection/:name"
                render={(props) => (
                  <StudioPageWrapper {...props}>
                    <Collection name={props.match.params.name} />
                  </StudioPageWrapper>
                )}
              />

              <Route
                path="/auctions"
                render={(props) => (
                  <StudioPageWrapper {...props}>
                    <Auctions />
                  </StudioPageWrapper>
                )}
              />

              <Route
                path="*"
                render={(props) => (
                  <StudioPageWrapper {...props}>
                    <ErrorPage />
                  </StudioPageWrapper>
                )}
              />
            </Switch>
          </Router>
        </ColourModeProvider>
      </QueryClientProvider>
    </WagmiConfig>
  );
}
