import React, { Component } from "react";
import styles from "./index.module.scss";

import { darkmodeListener } from "../../theme";

type SelfServeDropdownProps = {
  selected?: number;
  setSelected: (selected: number) => void;
  items: Array<string>;
};

type SelfServeDropdownState = {
  open: boolean;
  selected: number;
};

class SelfServeDropdown extends Component<
  SelfServeDropdownProps,
  SelfServeDropdownState
> {
  state = {
    open: false,
    selected: 0,
  };

  componentDidMount() {
    darkmodeListener(styles["theme-light"], styles["theme-dark"]);
  }

  componentDidUpdate(prevProps: any) {
    if (this.props.selected !== prevProps.selected) {
      if (this.props.selected) {
        this.setState({
          selected: this.props.selected,
        });
      }
    }
  }

  toggleOpen() {
    this.setState({
      open: !this.state.open,
    });
  }

  setSelected(idx: number) {
    this.setState(
      {
        selected: idx,
      },
      () => {
        this.props.setSelected(idx);
      }
    );
  }

  renderDropdown() {
    if (this.state.open) {
      return `${styles.DropdownFiter} ${styles.DropdownFiterOpen}`;
    } else {
      return `${styles.DropdownFiter} ${styles.DropdownFiterClosed}`;
    }
  }

  render() {
    return (
      <div className={styles.Dropdown}>
        <div
          className={this.renderDropdown()}
          onClick={this.toggleOpen.bind(this)}
        >
          <div className={styles.DropdownFiterText}>
            {this.state.open &&
              this.props.items.map((x, idx) => (
                <div
                  key={idx}
                  className={styles.DropdownSelected}
                  onClick={this.setSelected.bind(this, idx)}
                >
                  {x}
                </div>
              ))}
            {!this.state.open && (
              <div className={styles.DropdownDefault}>
                {this.props.items[this.state.selected]}
              </div>
            )}
          </div>
          {!this.state.open && (
            <div className={styles.DropdownFiterCarretContainer}>
              <div className={styles.DropdownFiterCarret}>
                <svg
                  width={"8px"}
                  height={"14px"}
                  viewBox={"0 0 8 14"}
                  version={"1.1"}
                  xmlns={"http://www.w3.org/2000/svg"}
                >
                  <g
                    stroke={"none"}
                    strokeWidth={"1"}
                    fill={"none"}
                    fillRule={"evenodd"}
                    strokeLinecap={"round"}
                    strokeLinejoin={"round"}
                  >
                    <g
                      transform={"translate(-426.000000, -200.000000)"}
                      stroke={"#999999"}
                      strokeWidth={"2"}
                    >
                      <g transform={"translate(20.000000, 165.000000)"}>
                        <g
                          transform={
                            "translate(410.000000, 42.000000) rotate(180.000000) translate(-410.000000, -42.000000) translate(407.000000, 36.000000)"
                          }
                        >
                          <polyline points={"0 12 6 6 0 0"}></polyline>
                        </g>
                      </g>
                    </g>
                  </g>
                </svg>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default SelfServeDropdown;
