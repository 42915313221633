import { MerkleTree } from 'merkletreejs';
import { defaultAbiCoder } from '@ethersproject/abi';
import { hexValue } from '@ethersproject/bytes';
import { getAddress } from '@ethersproject/address';
import keccak256 from 'keccak256';
import { getAllowlist } from './Studio/serverCalls';

export function hashForEntry(entry) {
  return keccak256(
    defaultAbiCoder.encode(
      ['address', 'uint256', 'uint256'],
      [getAddress(entry.minter), entry.maxCount, entry.price],
    ),
  );
}

export function makeTree(entries) {
  entries = entries.map((entry) => {
    entry.hash = hashForEntry(entry);
    return entry;
  });

  const tree = new MerkleTree(
    entries.map((entry) => entry.hash),
    keccak256,
    { sortPairs: true },
  );

  entries = entries.map((entry, idx) => {
    entry.hash = hexValue(entry.hash);
    entry.proof = tree.getHexProof(tree.leaves[idx]);
    // entry.proof = tree.getHexProof(entry.hash);
    return entry;
  });

  return {
    tree,
    root: tree.getHexRoot(),
    entries,
  };
}

export async function getMerkle(user, root) {
  const allowlist = await getAllowlist({
    user,
    root,
  });
  let entry = [];
  if (allowlist && allowlist.entries.length && allowlist.root) {
    entry = allowlist.entries.filter((entry) => {
      return entry.minter === user;
    });
  }
  let verified = false;
  if (entry && entry.length) {
    entry = entry[0];
    const tree = makeTree(allowlist.entries);
    verified = tree.tree.verify(entry.proof, entry.hash, root);
  }
  if (verified) {
    // console.log('verified', entry);
    return entry;
  } else {
    // console.log('not verified');
    return null;
  }
}
