import { standardPrincipalCV, contractPrincipalCV } from '@stacks/transactions';
import { ethers } from 'ethers';
import { dealWithEther } from '../../utils';

const isValidName = (val: string) => {
  const validRegex = /^[A-Za-z0-9- ]*$/;
  const validFirstLetter = /^[a-zA-Z]+$/;
  const validLength = 40;
  const firstChar = val.charAt(0);

  const exceptions = ['RPG(Characters)'];

  let isValid =
    (validRegex.test(val) || exceptions.includes(val)) &&
    val.length <= validLength &&
    val != '' &&
    validFirstLetter.test(firstChar);

  let error = '';
  if (!validRegex.test(val)) {
    error = 'Collection name can only contain letters, numbers, and dashes';
  } else if (val.length > validLength) {
    error = `Collection name can only be up to ${validLength} characters long`;
  } else if (val == '') {
    error = "Collection name can't be empty";
  } else if (!validFirstLetter.test(firstChar)) {
    error = 'First character in collection name must be a letter';
  }

  return {
    isValid,
    error,
  };
};

const isValidEditionName = (val: string) => {
  const validRegex = /^[A-Za-z0-9- ]*$/;
  const validFirstLetter = /^[a-zA-Z]+$/;
  const validLength = 40;
  const firstChar = val.charAt(0);

  const exceptions = ['RPG(Characters)'];

  let isValid =
    (validRegex.test(val) || exceptions.includes(val)) &&
    val.length <= validLength &&
    val != '' &&
    validFirstLetter.test(firstChar);

  let error = '';
  if (!validRegex.test(val)) {
    error = 'Collection name can only contain letters, numbers, and dashes';
  } else if (val.length > validLength) {
    error = `Collection name can only be up to ${validLength} characters long`;
  }

  return {
    isValid,
    error,
  };
};

const isValidFullName = (val: string) => {
  const validRegex = /^[A-Za-z- ]*$/;

  const isValid = validRegex.test(val) || val == '';

  let error = '';
  if (!validRegex.test(val)) {
    error = 'Please enter a valid name';
  }

  return { isValid, error };
};

const isValidTelegram = (val: string) => {
  const validRegex = /^[A-Za-z0-9_]*$/;

  const isValid = validRegex.test(val) || val == '';

  let error = '';
  if (!validRegex.test(val)) {
    error = 'Please include a valid Telegram username';
  }

  return { isValid, error };
};

const isValidMessage = (val: string) => {
  let validLength = 2500;

  let isValid = val.length <= validLength && val != '' && !val.includes('<') && !val.includes('>');

  let error = '';
  if (val.length > validLength) {
    error = `Message can only be up to ${validLength} characters long`;
  } else if (val == '') {
    error = "Message can't be empty";
  } else if (val.includes('<') || val.includes('>')) {
    error = "Message can't include the characters < or >";
  }

  return {
    isValid,
    error,
  };
};

const isValidEthDescription = (val: string) => {
  let validRegex = /^[ A-Za-z0-9_':@.#&+-]*$/;
  // const validRegex = /^[A-Za-z- ]*$/;
  let validLength = 2500;

  console.log(validRegex.test(val));

  let isValid =
    validRegex.test(val) &&
    val.length <= validLength &&
    val != '' &&
    !val.includes('<') &&
    !val.includes('>');

  let error = '';
  if (val.length > validLength) {
    error = `Collection description can only be up to ${validLength} characters long`;
  } else if (val == '') {
    error = "Collection description can't be empty";
  } else if (val.includes('<') || val.includes('>')) {
    error = "Collection description can't include the characters < or >";
  } else if (!validRegex.test(val)) {
    error = 'Invalid characters';
  }

  return {
    isValid,
    error,
  };
};

const isValidDescription2 = (val: string) => {
  let validLength = 2500;

  let isValid = val.length <= validLength && val != '' && !val.includes('<') && !val.includes('>');

  let error = '';
  if (val.length > validLength) {
    error = `Collection description can only be up to ${validLength} characters long`;
  } else if (val == '') {
    error = "Collection description can't be empty";
  } else if (val.includes('<') || val.includes('>')) {
    error = "Collection description can't include the characters < or >";
  }

  return {
    isValid,
    error,
  };
};

const isValidDescription = (val: string) => {
  // let validRegex = /^[ A-Za-z0-9_'":@./#&+-]*$/;
  let validLength = 2500;

  let isValid = val.length <= validLength && val != '' && !val.includes('<') && !val.includes('>');

  let error = '';
  if (val.length > validLength) {
    error = `Collection description can only be up to ${validLength} characters long`;
  } else if (val == '') {
    error = "Collection description can't be empty";
  } else if (!val.includes('<') || !val.includes('>')) {
    error = "Collection description can't include the characters < or >";
  }

  return {
    isValid,
    error,
  };
};

const isValidExplanation = (val: string) => {
  // let validRegex = /^[ A-Za-z0-9_'":@./#&+-]*$/;

  let isValid = val != '' && !val.includes('<') && !val.includes('>');

  let error = '';
  // if (val == '') {
  //   error = "Explanation can't be empty";
  // } else if (val.includes('<') || val.includes('>')) {
  //   error = "Explanation can't include the characters < or >";
  // }

  if (val.includes('<') || val.includes('>')) {
    error = "Explanation can't include the characters < or >";
  }

  return {
    isValid,
    error,
  };
};

const isValidContactInfo = (val: string) => {
  let validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  let validLength = 200;

  let isValid = validRegex.test(val) && val.length <= validLength && val != '';

  let error = '';
  if (!validRegex.test(val)) {
    error = 'Please enter a valid email address';
  } else if (val.length > validLength) {
    error = `Email address can only be up to ${validLength} characters long`;
  } else if (val == '') {
    error = "Email address can't be empty";
  }

  return {
    isValid,
    error,
  };
};

const isValidCollectionSize = (val: string) => {
  let isValid = Number.isInteger(parseInt(val)) && val != '';

  let error = '';
  if (!Number.isInteger(val)) {
    error = 'Collection size must be an integer';
  } else if (val == '') {
    error = "Collection size can't be empty";
  }

  return {
    isValid,
    error,
  };
};

const isValidMintPrice = (val: string) => {
  const validRegex = /^\d+$/;

  let maxMintPrice = 1000;
  let validMintPrice = false;
  if (validRegex.test(val) && parseInt(val) <= maxMintPrice) {
    validMintPrice = true;
  }

  // let isValid = !isNaN(float) && val != "" && validMintPrice;
  let isValid = validRegex.test(val) && val != '' && validMintPrice;

  let error = '';
  if (!validRegex.test(val)) {
    error = 'Price must be an integer';
  } else if (val == '') {
    error = "Price can't be empty";
  } else if (!validMintPrice) {
    error = "Price can't be more than 1000 STX";
  }

  return {
    isValid,
    error,
  };
};

const isValidEthRoyalty = (val: number) => {
  let isValid = true;
  let error = '';

  if (val < 0 || val > 10) {
    console.log('eth royalty error');
    isValid = false;
    error = 'Royalty must be an integer between 0 and 10';
  }

  return {
    isValid,
    error,
  };
};

const isValidMintLimitPerUser = (val: string) => {
  const validRegex = /^\d+$/;

  let isValid = validRegex.test(val) || val == '';

  let error = '';
  if (!isValid) {
    error = 'Mint limit must be an integer';
  }

  return {
    isValid,
    error,
  };
};

const isValidCityCoinPrice = (val: string) => {
  const validRegex = /^\d+$/;

  let maxMintPrice = 125000;
  // let maxMintPrice = 3005567;
  let validMintPrice = false;
  if (validRegex.test(val)) {
    validMintPrice = true;
  }

  // let isValid = !isNaN(float) && val != "" && validMintPrice;
  let isValid = validRegex.test(val) && val != '' && validMintPrice;

  let error = '';
  if (!validRegex.test(val)) {
    error = 'Price must be an integer';
  } else if (val == '') {
    error = "Price can't be empty";
  } else if (!validMintPrice) {
    error = "Price can't be more than 125,000 tokens";
  }

  return {
    isValid,
    error,
  };
};

const isValidRoyalty = (val: string) => {
  const float = parseFloat(val);
  let isValid = !isNaN(float) && val != '';

  let error = '';
  if (!isValid) {
    error = 'Royalty must be a float or integer';
  } else if (val == '') {
    error = "Royalty can't be empty";
  }

  return {
    isValid,
    error,
  };
};

const isValidAddress = (val: string) => {
  let isValid = true;
  let error = '';

  try {
    standardPrincipalCV(val);
  } catch (err) {
    console.log('principal error', err);
    isValid = false;
    error = 'Address must be a valid standard principal';
  }

  return {
    isValid,
    error,
  };
};

const isValidEthAddress = (val: string) => {
  let isValid = true;
  let error = '';

  if (!ethers.utils.isAddress(val)) {
    isValid = false;
    error = 'Please enter a valid Ethereum address';
  }

  return {
    isValid,
    error,
  };
};

const isValidContractPrincipal = (val: string) => {
  let isValid = true;
  let error = '';

  try {
    contractPrincipalCV(val.split('.')[0], val.split('.')[1]);
  } catch (err) {
    console.log('principal error', err);
    isValid = false;
    error = 'Address must be a valid contract principal';
  }

  return {
    isValid,
    error,
  };
};

const isValidTwitter = (val: string) => {
  const validRegex = /^((https?:\/\/)?(www\.)?(twitter\.(com))\/.+[A-Za-z0-9_])*$/;

  const isValid = validRegex.test(val) || val == '';

  // const isValid = val.includes("twitter.com/");

  let error = '';
  if (!isValid) {
    error = 'Please include a valid link to your Twitter profile';
  }

  return { isValid, error };
};

const isValidDomain = (val: string) => {
  // const validRegex =
  //   /^((https):\/\/)(www.)?[a-z0-9]+\.[a-z]+(\/[a-zA-Z0-9#]+\/?)*$/;
  const validRegex = /^(https?:\/\/)[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;

  const isValid = validRegex.test(val) || val == '';

  let error = '';
  if (!validRegex.test(val)) {
    error = 'Please include a valid url';
  }

  return { isValid, error };
};

const isValidDiscordInvite = (val: string) => {
  const validRegex =
    /^((https?:\/\/)?(www\.)?(discord\.(gg|io|me|li|com)|discordapp\.com\/invite)\/.+[0-9a-zA-Z])$/;

  const isValid = validRegex.test(val) || val == '';

  let error = '';
  if (!validRegex.test(val)) {
    error = 'Please include a valid Discord invite link';
  }

  return { isValid, error };
};

const isValidDiscordUsername = (val: string) => {
  const validRegex = /^.{3,32}#[0-9]{4}$/;

  const isValid = validRegex.test(val) || val == '';

  let error = '';
  if (!validRegex.test(val)) {
    error = 'Please include a valid Discord username';
  }

  return { isValid, error };
};

const isValidHash = (val: string) => {
  let validRegex = /^[A-Za-z0-9]*$/;
  let validLength = 46;

  let isValid = validRegex.test(val) && val.length == validLength && val != '';

  let error = '';
  if (!validRegex.test(val)) {
    error = 'Hash can only include numbers and letters';
  } else if (val == '') {
    error = "Hash can't be empty";
  } else if (val.length != validLength) {
    error = 'Hash must be 46 characters long';
  }

  return {
    isValid,
    error,
  };
};

const isValidPlaceholder = (image: any) => {
  if (image == null) {
    return {
      isValid: false,
      error: 'No asset was added',
    };
  }

  const validTypes = [
    'image/jpeg',
    'image/gif',
    'image/png',
    'image/bmp',
    'image/svg+xml',
    'image/webp',
    'image/apng',
  ];

  let validType = true;
  if (!validTypes.includes(image.type)) {
    validType = false;
  }

  let validTotalSize = 524288000; // default max total file size is 500mb
  let validSize = true;
  if (image.size > validTotalSize) {
    validSize = false;
  }

  const isValid = validType && validSize;

  let error = '';
  if (!validType) {
    error = 'File must be type jpeg, gif, png, apng, bmp, svg, or webp';
  } else if (!validSize) {
    error = 'Image exceeds maximum file size';
  }

  return {
    isValid,
    error,
  };
};

const isValidAssets = (assets: any, address: string, exceptions: any) => {
  if (assets == null) {
    return {
      isValid: false,
      error: 'No assets were added',
    };
  }

  let tokenCountException = undefined;
  let tokenSizeException = undefined;
  let addressExceptions = undefined;

  if (exceptions) {
    addressExceptions = exceptions['exceptionsByAddress'][address];
  }

  if (addressExceptions) {
    tokenCountException = exceptions['exceptionsByAddress'][address]['tokenCount'];
    tokenSizeException = exceptions['exceptionsByAddress'][address]['tokenSize'];
  }

  let validLength = 500;
  if (tokenCountException) {
    validLength = tokenCountException;
  }

  let validTotalSize = 524288000; // default max total file size is 500mb
  if (tokenSizeException) {
    validTotalSize = tokenSizeException;
  }

  const validTypes = [
    'image/jpeg',
    'image/gif',
    'image/png',
    'image/bmp',
    'image/svg+xml',
    'image/webp',
    'video/mp4',
    'video/webm',
    // "video/quicktime",
    'image/apng',
    'audio/mpeg',
    'audio/wav',
    'audio/x-wav',
  ];

  let validType = true;
  let validSize = true;
  let validNames = true;
  let currentTotalSize = 0;
  for (let i = 0; i < assets.length; i++) {
    const asset = assets[i];

    console.log(asset.type);

    if (!validTypes.includes(asset.type)) {
      validType = false;
      break;
    }

    if (asset.name.indexOf('"') >= 0) {
      validNames = false;
      break;
    }

    currentTotalSize += asset.size;
    if (currentTotalSize > validTotalSize) {
      validSize = false;
      break;
    }
  }

  const isValid = assets.length <= validLength && validType && validSize && validNames;

  let error = '';
  if (assets.length > validLength) {
    error = `Number of tokens limited to ${validLength} files at this time`;
  } else if (!validType) {
    error = 'Files must be type jpeg, gif, png, apng, bmp, svg, webp, mp4, webm, mp3, wav';
  } else if (!validSize) {
    error = 'Collection exceeds maximum total file size';
  } else if (!validNames) {
    error = 'File names include invalid characters (file names can\'t include ")';
  } else {
    error = '';
  }

  return {
    isValid,
    error,
  };
};

const isValidMintpass = (mintpass: any) => {
  let isValid = true;
  let error = '';

  if (mintpass == null) {
    isValid = false;
    error = 'No file added';
  } else {
    for (let i = 0; i < mintpass.length; i++) {
      const element = mintpass[i];
      const address = element[0];
      const amount = element[1];

      const isValidElement = element.length == 2;
      if (!isValidElement) {
        isValid = false;
        error = `Row ${i + 1} is not formatted properly`;
        break;
      }

      try {
        standardPrincipalCV(address);
      } catch (err) {
        console.log('principal error', err);
        isValid = false;
        error = `Address at row ${i + 1} is not a standard principal`;
        break;
      }

      const isValidAmount = Number.isInteger(amount);
      if (!isValidAmount) {
        isValid = false;
        // error = "Amounts must be integers";
        error = `Row ${i + 1} is not formatted properly`;
        break;
      }
    }
  }

  return {
    isValid,
    error,
  };
};

const isValidMintpassEth = (mintpass: any) => {
  let isValid = true;
  let error = '';

  if (mintpass == null) {
    isValid = false;
    error = 'No file added';
  } else {
    for (let i = 0; i < mintpass.length; i++) {
      const row = mintpass[i];
      if (row.length !== 3) {
        isValid = false;
        error = `Row ${i + 1} has incorrect number of columns`;
        break;
      }
      if (isNaN(row[1])) {
        isValid = false;
        error = `Row ${i + 1}, column 2, isn't a number`;
        break;
      }
      if (isNaN(row[2])) {
        isValid = false;
        error = `Row ${i + 1}, column 3, isn't a number`;
        break;
      }
      const minter = row[0].trim();
      const maxCount = parseInt(row[2]);
      const price = parseInt(dealWithEther(row[1].toString()).toString());
      if (!ethers.utils.isAddress(minter)) {
        isValid = false;
        error = `Row ${i + 1} has an invalid minter address`;
        break;
      }
      if (isNaN(maxCount)) {
        isValid = false;
        error = `Row ${i + 1} has an invalid number of mints`;
        break;
      }
      if (isNaN(price)) {
        isValid = false;
        error = `Row ${i + 1} has an invalid price`;
        break;
      }
    }
  }

  return {
    isValid,
    error,
  };
};

const isValidEthAddresses = (addresses: any) => {
  let isValid = true;
  let error = '';

  console.log(addresses);

  if (addresses == null) {
    isValid = false;
    error = 'No file added';
  } else {
    for (let i = 0; i < addresses.length; i++) {
      if (addresses[i].split(',').length !== 1) {
        isValid = false;
        error = `Row ${i + 1} is not formatted properly (incorrect number of columns)`;
        break;
      }

      const addressCheck = isValidEthAddress(addresses[i]);
      if (!addressCheck.isValid) {
        isValid = false;
        error = `Address at row ${i + 1} is not a valid Ethereum address`;
        break;
      }
    }
  }

  return {
    isValid,
    error,
  };
};

const isValidAirdrop = (airdrop: any) => {
  let isValid = true;
  let error = '';

  if (airdrop == null) {
    isValid = false;
    error = 'No file added';
  } else {
    for (let i = 0; i < airdrop.length; i++) {
      if (airdrop[i].split(',').length !== 1) {
        isValid = false;
        error = `Row ${i + 1} is not formatted properly (incorrect number of columns)`;
        break;
      }

      const address = airdrop[i];
      try {
        standardPrincipalCV(address);
      } catch (err) {
        // console.log("principal error", err);
        isValid = false;
        error = `Address at row ${i + 1} is not a standard principal`;
        break;
      }
    }
  }

  return {
    isValid,
    error,
  };
};

const isValidAttributes = (attributes: any) => {
  let isValid = true;
  let error = '';

  const headers = attributes[0];
  let hasCorrectRowSizes = true;
  let badRowNumber = 0;
  let badRowLength = 0;

  for (let i = 1; i < attributes.length; i++) {
    const token = attributes[i];
    if (token.length != headers.length) {
      hasCorrectRowSizes = false;
      badRowNumber = i;
      badRowLength = token.length;
      break;
    }
  }

  if (attributes == null) {
    isValid = false;
    error = 'Attributes is empty';
  } else if (!hasCorrectRowSizes) {
    isValid = false;
    error = `Header row has ${headers.length} columns; found ${badRowLength} columns in row ${badRowNumber}`;
  }

  return {
    isValid,
    error,
  };
};

const isValidAuction = (collectionSize: string) => {
  let isValid = true;
  let error = '';

  // if (Number(collectionSize) > 10) {
  //   isValid = false;
  //   error = "Maximum collection size for auctions is currently set to 10";
  // }

  return {
    isValid,
    error,
  };
};

const escapeSpecialChars = function (chars: string) {
  return chars
    .replace(/\\n/g, '\\n')
    .replace(/\\'/g, "\\'")
    .replace(/\\"/g, '\\"')
    .replace(/\\&/g, '\\&')
    .replace(/\\r/g, '\\r')
    .replace(/\\t/g, '\\t')
    .replace(/\\b/g, '\\b')
    .replace(/\\f/g, '\\f');
};

export {
  isValidDomain,
  isValidName,
  isValidDescription,
  isValidDescription2,
  isValidCollectionSize,
  isValidMintPrice,
  isValidRoyalty,
  isValidAddress,
  isValidHash,
  isValidAssets,
  isValidContactInfo,
  isValidMintpass,
  isValidAttributes,
  isValidCityCoinPrice,
  isValidAirdrop,
  escapeSpecialChars,
  isValidPlaceholder,
  isValidMintLimitPerUser,
  isValidAuction,
  isValidTwitter,
  isValidContractPrincipal,
  isValidDiscordInvite,
  isValidDiscordUsername,
  isValidTelegram,
  isValidExplanation,
  isValidFullName,
  isValidMessage,
  isValidEditionName,
  isValidMintpassEth,
  isValidEthAddress,
  isValidEthRoyalty,
  isValidEthAddresses,
  isValidEthDescription,
};
