import { styled } from '@stitches/react';
import { useAtom } from 'jotai';
import {
  airdropAtom,
  artistAddressAtom,
  assetsAtom,
  categoryAtom,
  claimOptionsAtom,
  collectionNameAtom,
  currentUserAddressAtom,
  descriptionAtom,
  discordAtom,
  editionAtom,
  emailAddressAtom,
  fungibleSelectedAtom,
  logoAtom,
  mintingEnabledAtom,
  mintpassAtom,
  mintsPerAddressAtom,
  numberOfEditionsAtom,
  paymentOptionsAtom,
  placeholderAtom,
  priceAtom,
  twitterAtom,
  userAtom,
  visibilityEnabledAtom,
  websiteAtom,
} from '../../../../components/atoms';
import { ContinueButton } from '../../../../components/buttons';
import { EditionsReviewCard, ReviewCard } from '../../../../components/cards';
import { CardGrid, Container, ContainerItem, EmptyDiv, Logo } from '../../../../components/common';

const DetailsLeft = styled('div', {
  width: '40%',
  display: 'flex',
  flexDirection: 'column',
  gap: '20px',
  overflowWrap: 'break-word',
});

const CollectionName = styled('div', {
  fontSize: '32px',
});

const AdditionalDetails = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',
  margin: '20px 0',
});

function Review(props: { onNextStep: any }) {
  const [assets, setAssets] = useAtom(assetsAtom);
  const [logo, setLogo] = useAtom(logoAtom);
  const [collectionName, setCollectionName] = useAtom(collectionNameAtom);
  const [description, setDescription] = useAtom(descriptionAtom);
  const [website, setWebsite] = useAtom(websiteAtom);
  const [twitter, setTwitter] = useAtom(twitterAtom);
  const [artistAddress, setArtistAddress] = useAtom(artistAddressAtom);
  const [price, setPrice] = useAtom(priceAtom);
  const [emailAddress, setEmailAddress] = useAtom(emailAddressAtom);
  const [paymentOptions, setPaymentOptions] = useAtom(paymentOptionsAtom);
  const [claimOptions, setClaimOptions] = useAtom(claimOptionsAtom);
  const [mintingEnabled, setMintingEnabled] = useAtom(mintingEnabledAtom);
  const [mintsPerAddress, setMintsPerAddress] = useAtom(mintsPerAddressAtom);
  const [mintpass, setMintpass] = useAtom(mintpassAtom);
  const [airdrop, setAirdrop] = useAtom(airdropAtom);
  const [category, setCategory] = useAtom(categoryAtom);
  const [user, setUser] = useAtom(userAtom);
  const [currentUserAddress, setCurrentUserAddress] = useAtom(currentUserAddressAtom);
  const [edition, setEdition] = useAtom(editionAtom);
  const [discord, setDiscord] = useAtom(discordAtom);
  const [numberOfEditions, setNumberOfEditions] = useAtom(numberOfEditionsAtom);
  const [fungibleSelected, setFungibleSelected] = useAtom(fungibleSelectedAtom);

  function renderPaymentOptions() {
    return fungibleSelected.map((item: any, idx: number) => {
      if (item.selected) {
        return (
          <span>
            {item.symbol.toUpperCase()}
            {idx < fungibleSelected.length - 1 ? ', ' : null}
          </span>
        );
      }
      return null;
    });
  }

  function renderClaimOptions() {
    return claimOptions.map((option: any, idx: number) => {
      return (
        <span>
          {option}
          {idx < claimOptions.length - 1 ? ', ' : null}
        </span>
      );
    });
  }

  function renderCards() {
    let cards = [];
    const limit = parseInt(numberOfEditions) < 25 ? numberOfEditions : 25;
    for (let i = 0; i < limit; i++) {
      cards.push(<EditionsReviewCard />);
    }
    return cards;
  }

  function handleContinue() {
    props.onNextStep();
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  }

  return (
    <>
      <Container>
        <h2>Review your collection</h2>
        {/* <ContainerItem>
          Review the details of your collection one more time before
          deploying your contract.
        </ContainerItem> */}
        <DetailsLeft>
          {logo.length ? <Logo src={logo[0].preview} /> : null}
          <CollectionName>{collectionName}</CollectionName>
        </DetailsLeft>
        <AdditionalDetails>
          {/* <h4>Additional Details</h4> */}
          <div>Description: {description}</div>
          <div>
            Website:{' '}
            {/* {website ? (
              <span>
                <a href={website} target="_blank" rel="noopener noreferrer">
                  {website.split('//')[1]}
                </a>{' '}
              </span>
            ) : (
              'No website provided'
            )} */}
            {website ? website : 'No website provided'}
          </div>
          <div>
            Twitter:{' '}
            {/* {twitter ? (
              <span>
                <a href={'https://' + twitter} target="_blank" rel="noopener noreferrer">
                  {twitter}
                </a>{' '}
              </span>
            ) : (
              'No Twitter account provided'
            )} */}
            {twitter ? twitter : 'No Twitter account provided'}
          </div>
          <div>
            Discord:{' '}
            {/* {discord ? (
              <span>
                <a href={'https://' + discord} target="_blank" rel="noopener noreferrer">
                  {discord}
                </a>{' '}
              </span>
            ) : (
              'No Discord link provided'
            )} */}
            {discord ? discord : 'No Discord link provided'}
          </div>
          <div>Number of editions available to mint: {numberOfEditions}</div>
          <div>Price per edition: {price}</div>
          <div>Email address (will remain private): {emailAddress}</div>
          <div>Artist address: {artistAddress || currentUserAddress}</div>
          <div>Additional payment options: {renderPaymentOptions()}</div>
          <div>Claim options: {renderClaimOptions()}</div>
          <div>Minting status: {mintingEnabled ? 'Enabled on launch' : 'Disabled on launch'}</div>
          <div>
            Mints per address: {parseInt(mintsPerAddress) > 0 ? mintsPerAddress : 'Unlimited'}
          </div>
          <div>Total mintpasses: {mintpass.length}</div>
          <div>Total airdrops: {airdrop.length}</div>
          {/* <div>Category: {category === '' ? 'No category selected' : category}</div> */}
        </AdditionalDetails>

        <ContainerItem>
          <ContinueButton disabled={false} text="Continue" onClick={() => handleContinue()} />
        </ContainerItem>

        <ContainerItem>
          <EmptyDiv css={{ color: 'gray' }}>
            * Gamma.io sets royalties for secondary sales to 5% by default. Contact us directly at
            support@gamma.io after deploying your collection to update the royalty amount.
          </EmptyDiv>
        </ContainerItem>
      </Container>
      <CardGrid>{renderCards()}</CardGrid>
    </>
  );
}

export default Review;
