import React from "react";
import Header from "../Header/index";
import styles from "./index.module.scss";

function StudioPageWrapper({ children }: any) {
  return (
    <div className={styles.pageWrapper}>
      <Header />      
      <div>{children}</div>
    </div>
  );
}

export default StudioPageWrapper;
