import { RadioGroupWithNote } from '../../../../components/radio-group';
import { ToggleGroupClaim, ToggleGroupPayment } from '../../../../components/toggle-group';
import { Label, LabelDatePicker, LabelInput } from '../../../../components/label';
import { CsvDropzone, LogoDropzone } from '../../../../components/dropzone';
import { useAtom } from 'jotai';
import { dealWithEther, handleFileInput } from '../../../../utils';
import { useEffect, useState } from 'react';
import { ContinueButton, DateButton } from '../../../../components/buttons';
import {
  airdropAtom,
  claimOptionsAtom,
  fungibleOptionsAtom,
  fungibleSelectedAtom,
  merkleEntriesAtom,
  merkleRootAtom,
  mintingEnabledAtom,
  mintpassAtom,
  mintpassEndDateAtom,
  mintpassEndUnixAtom,
  mintpassStartDateAtom,
  mintpassStartUnixAtom,
  mintsPerAddressAtom,
  numberOfEditionsAtom,
  paymentOptionsAtom,
  placeholderAtom,
  priceAtom,
  publicStartDateAtom,
  publicStartUnixAtom,
  royaltyAtom,
  stxusdAtom,
  visibilityEnabledAtom,
} from '../../../../components/atoms';
import {
  Container,
  ContainerItem,
  ContainerItemNote,
  ContainerItemTitle,
  ContainerSection,
} from '../../../../components/common';
import makeEndpoint from '../../../../endpoints';
import { hashForEntry, makeTree } from '../../../../utils/merkle';
import DatePicker from 'react-datepicker';
import { isValidEthRoyalty } from '../../../../utils/SelfServe/validation';
import axios from 'axios';
import { hexValue } from '@ethersproject/bytes';
import { addAllowlist } from '../../../../utils/Studio/serverCalls';

function Minting(props: { onNextStep: any }) {
  const [price, setPrice] = useAtom(priceAtom);
  const [paymentOptions, setPaymentOptions] = useAtom(paymentOptionsAtom);
  const [claimOptions, setClaimOptions] = useAtom(claimOptionsAtom);
  const [mintpass, setMintpass] = useAtom(mintpassAtom);
  const [airdrop, setAirdrop] = useAtom(airdropAtom);
  const [mintingEnabled, setMintingEnabled] = useAtom(mintingEnabledAtom);
  const [mintsPerAddress, setMintsPerAddress] = useAtom(mintsPerAddressAtom);
  const [visibilityEnabled, setVisibilityEnabled] = useAtom(visibilityEnabledAtom);
  const [placeholder, setPlaceholder] = useAtom(placeholderAtom);
  const [fungibleOptions, setFungibleOptions] = useAtom(fungibleOptionsAtom);
  const [fungibleSelected, setFungibleSelected] = useAtom(fungibleSelectedAtom);
  const [stxusd, setStxusd] = useAtom(stxusdAtom);
  const [numberOfEditions, setNumberOfEditions] = useAtom(numberOfEditionsAtom);
  const [merkleRoot, setMerkleRoot] = useAtom(merkleRootAtom);
  const [merkleEntries, setMerkleEntries] = useAtom(merkleEntriesAtom);
  const [publicStartUnix, setPublicStartUnix] = useAtom(publicStartUnixAtom);
  const [royalty, setRoyalty] = useAtom(royaltyAtom);
  const [mintpassStartUnix, setMintpassStartUnix] = useAtom(mintpassStartUnixAtom);
  const [mintpassEndUnix, setMintpassEndUnix] = useAtom(mintpassEndUnixAtom);
  const [priceError, setPriceError] = useState('');
  const [mintpassError, setMintpassError] = useState('');
  const [airdropError, setAirdropError] = useState('');
  const [mintsPerAddressError, setMintsPerAddressError] = useState('');
  const [placeholderError, setPlaceholderError] = useState('');
  const [claimStrings, setClaimStrings] = useState(['1']);
  const [megaPrice, setMegaPrice] = useState('');
  const [royaltyError, setRoyaltyError] = useState('');
  const [mintpassStartDate, setMintpassStartDate] = useAtom(mintpassStartDateAtom);
  const [mintpassEndDate, setMintpassEndDate] = useAtom(mintpassEndDateAtom);
  const [publicStartDate, setPublicStartDate] = useAtom(publicStartDateAtom);
  const [isAllowlistError, setIsAllowlistError] = useState(false);

  function handlePriceChange(e: React.ChangeEvent<HTMLInputElement>) {
    setPrice(e.target.value);
  }

  function handleRoyaltyChange(e: React.ChangeEvent<HTMLInputElement>) {
    setRoyaltyError('');
    setRoyalty(parseInt(e.target.value).toString());
  }

  function handleRoyaltyBlur() {
    if (royalty) {
      const royaltyCheck = isValidEthRoyalty(parseInt(royalty));
      setRoyaltyError(royaltyCheck.error);
    }
  }

  function handleNumberOfEditionsChange(e: React.ChangeEvent<HTMLInputElement>) {
    let amount = parseInt(e.target.value);
    setNumberOfEditions(amount.toString());
  }

  async function handleMintpassDrop(acceptedFiles: any[], rejectedFiles: any[]) {
    setMintpassError('');
    setMintpass([]);
    setMerkleRoot('');
    const csv = await handleFileInput(acceptedFiles[0], 'mintpassEth');
    if (csv.mintpassEthError) {
      setMintpass([]);
      setMintpassError(csv.mintpassEthError);
    } else if (rejectedFiles[0]) {
      setMintpass([]);
      setMintpassError(rejectedFiles[0].errors[0].message);
    } else {
      const entries = csv.mintpassEth.map((entry: any[]) => {
        return {
          minter: entry[0].trim(),
          maxCount: parseInt(entry[2]),
          price: parseInt(dealWithEther(entry[1].toString()).toString()),
        };
      });
      try {
        const tree = makeTree(entries);
        setMerkleEntries(entries);
        setMerkleRoot(tree.root);
        console.log('entries', entries);
        console.log('tree', tree);
        const res = await addAllowlist({ entries, root: tree.root });
        if (res === 'error') {
          setIsAllowlistError(true);
          setMintpassError('There was an error processing your presale allowlist');
        } else {
          setIsAllowlistError(false);
          setMintpass(csv.mintpassEth);
          console.log(csv.mintpassEth);
        }
      } catch (e: any) {
        setIsAllowlistError(true);
        setMintpassError('There was an error processing your presale allowlist: ' + e.message);
      }
    }
  }

  async function handleAirdropDrop(acceptedFiles: any[], rejectedFiles: any[]) {
    const csv = await handleFileInput(acceptedFiles[0], 'airdrop');
    if (csv.airdropError) {
      setAirdrop([]);
      setAirdropError(csv.airdropError);
    } else if (rejectedFiles[0]) {
      setAirdrop([]);
      setAirdropError(rejectedFiles[0].errors[0].message);
    } else {
      setAirdrop(csv.airdrop);
      setAirdropError('');
    }
  }

  function handleMintingStatusChange(value: string) {
    setMintingEnabled(value === 'enabled');
  }

  function handleMintsPerAddressChange(e: React.ChangeEvent<HTMLInputElement>) {
    setMintsPerAddress(parseInt(e.target.value).toString());
  }

  function handleVisibilityChange(value: string) {
    setVisibilityEnabled(value === 'revealed');
  }

  function handlePlaceholderDrop(acceptedFiles: any[], rejectedFiles: any[]) {
    if (rejectedFiles[0]) {
      setPlaceholderError(rejectedFiles[0].errors[0].message);
      setPlaceholder([]);
    } else {
      setPlaceholderError('');
      setPlaceholder(acceptedFiles);
    }
  }

  function handleMintpassStartDateChange(date: Date) {
    const unix = Math.floor(new Date(date).getTime() / 1000);
    setMintpassStartDate(date);
    setMintpassStartUnix(unix);
  }

  function handleMintpassEndDateChange(date: Date) {
    const unix = Math.floor(new Date(date).getTime() / 1000);
    setMintpassEndDate(date);
    setMintpassEndUnix(unix);
  }

  function handleContinue() {
    // if (!publicStartUnix) {
    //   const unix = Math.floor(new Date().getTime() / 1000);
    //   setPublicStartUnix(unix);
    // }
    props.onNextStep();
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  }

  function handlePublicStartDateChange(date: Date) {
    const unix = Math.floor(new Date(date).getTime() / 1000);
    setPublicStartUnix(unix);
    setPublicStartDate(date);
  }

  function renderAllowlistPreview() {
    return mintpass.map((entry, idx) => {
      if (idx === 3) {
        return <div>...</div>;
      }
      if (idx > 3) {
        return null;
      }
      return (
        <div>
          {entry[0]}, {entry[1]} ETH, {entry[2]} mints
        </div>
      );
    });
  }

  return (
    <Container>
      <h2>Define your mint settings</h2>
      <ContainerItem>
        <LabelInput
          note="Set the number of editions that can be minted. Leave blank for unlimited mints."
          placeholder="0"
          id="numberOfEditions"
          label="Number of editions"
          type="number"
          value={numberOfEditions}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleNumberOfEditionsChange(e)}
        />
      </ContainerItem>

      <ContainerSection>
        <LabelInput
          placeholder="0.001 ETH"
          id="price"
          label="Price"
          type="number"
          value={price}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => handlePriceChange(e)}
        />
      </ContainerSection>

      <ContainerSection>
        <Label>Public sale start date</Label>
        <ContainerItem>
          <DatePicker
            name="Public sale start date"
            title="Public sale start date"
            selected={publicStartDate}
            onChange={(date: Date) => handlePublicStartDateChange(date)}
            showTimeSelect
            dateFormat={'MMM d, yyyy h:mm aa'}
            placeholderText="Start date"
            customInput={
              publicStartDate ? (
                <DateButton>
                  {publicStartDate.toLocaleDateString()} {publicStartDate.toLocaleTimeString()}
                </DateButton>
              ) : (
                <DateButton>Public sale start date</DateButton>
              )
            }
          />
        </ContainerItem>
      </ContainerSection>

      <ContainerItem>
        <CsvDropzone
          onDrop={(acceptedFiles: [], rejectedFiles: []) =>
            handleMintpassDrop(acceptedFiles, rejectedFiles)
          }
          label="Presale (optional)"
          note="Upload a .csv file that includes the numbers of presale mints allowed per wallet address. 
                Each row must include a single wallet address, followed by the price (in ETH) of the mint, then an integer representing the
                number of mints allowed for that address."
          error={mintpassError}
          successText={
            mintpass.length ? `Success! ${mintpass.length} wallets added to presale` : ''
          }
          sample="presale.csv"
        />
      </ContainerItem>

      {merkleRoot && mintpass && mintpass.length && !isAllowlistError ? (
        <ContainerItem>
          <Label>Allowlist preview</Label>
          <ContainerItem>{renderAllowlistPreview()}</ContainerItem>
          <Label>Presale dates</Label>
          <ContainerItem>
            <DatePicker
              name="Presale start date"
              title="Presale start date"
              selected={mintpassStartDate}
              onChange={(date: Date) => handleMintpassStartDateChange(date)}
              showTimeSelect
              selectsStart
              startDate={mintpassStartDate}
              endDate={mintpassEndDate}
              dateFormat={'MMM d, yyyy h:mm aa'}
              placeholderText="Start date"
              customInput={
                mintpassStartDate ? (
                  <DateButton>
                    {mintpassStartDate.toLocaleDateString()}{' '}
                    {mintpassStartDate.toLocaleTimeString()}
                  </DateButton>
                ) : (
                  <DateButton>Presale start date</DateButton>
                )
              }
            />
          </ContainerItem>

          <DatePicker
            selected={mintpassEndDate}
            onChange={(date: Date) => handleMintpassEndDateChange(date)}
            showTimeSelect
            selectsEnd
            startDate={mintpassStartDate}
            endDate={mintpassEndDate}
            minDate={mintpassStartDate}
            dateFormat={'MMM d, yyyy h:mm aa'}
            placeholderText="End date"
            customInput={
              mintpassEndDate ? (
                <DateButton>
                  {mintpassEndDate.toLocaleDateString()} {mintpassEndDate.toLocaleTimeString()}
                </DateButton>
              ) : (
                <DateButton>Presale end date</DateButton>
              )
            }
          />
        </ContainerItem>
      ) : null}

      {/* <ContainerItem>
        <CsvDropzone
          onDrop={(acceptedFiles: [], rejectedFiles: []) =>
            handleAirdropDrop(acceptedFiles, rejectedFiles)
          }
          label="Airdrop (optional)"
          note="Upload a .csv file with a list of wallet addresses eligible for airdrop. 
                Each row must include a single wallet address. If
                an address is to receive multiple airdrops, list their
                address that many times. Airdrops are triggered by the deployer
                using a public function."
          error={airdropError}
          successText={airdrop.length ? `Success! ${airdrop.length} tokens to be airdropped` : ''}
          sample="airdrop.csv"
        />
      </ContainerItem> */}

      {/* <RadioGroupWithNote
        title="Minting Status"
        note="Choose whether you want to launch your collection with minting
            enabled or disabled"
        data={[
          {
            value: 'enabled',
            label: 'Enabled',
            note: 'People will be able to mint as soon as your collection launches',
          },
          {
            value: 'disabled',
            label: 'Disabled',
            note: 'Minting will be disabled by default, until you enable it later',
          },
        ]}
        onChange={(value: string) => handleMintingStatusChange(value)}
      /> */}

      <ContainerItem>
        <LabelInput
          placeholder="1"
          note="Set the maximum number of editions that can be minted per wallet address. Leave blank for unlimited mints."
          id="maximumMints"
          label="Maximum mints per address (optional)"
          type="number"
          value={mintsPerAddress}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleMintsPerAddressChange(e)}
        />
      </ContainerItem>

      <ContainerItem>
        <LabelInput
          placeholder="5%"
          id="royalty"
          label="Royalty (optional)"
          type="number"
          value={royalty}
          error={royaltyError}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleRoyaltyChange(e)}
          onBlur={() => handleRoyaltyBlur()}
          note="Enter the royalty percentage you would like to receive on secondary sales (0-10). Will default to 5% if left blank."
        />
      </ContainerItem>

      <ContainerItem>
        <ContinueButton
          disabled={
            !price ||
            (merkleRoot !== '' && (mintpassStartUnix === 0 || mintpassEndUnix === 0)) ||
            royaltyError !== '' ||
            !publicStartUnix
          }
          text="Continue"
          onClick={() => handleContinue()}
        />
      </ContainerItem>
    </Container>
  );
}

export default Minting;
