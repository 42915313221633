import React from 'react';
import { styled } from '@stitches/react';
import { violet, blackA } from '@radix-ui/colors';
import { CheckIcon } from '@radix-ui/react-icons';
import * as CheckboxPrimitive from '@radix-ui/react-checkbox';
import { Link } from 'react-router-dom';
import { TextLink } from './common';

const StyledCheckbox = styled(CheckboxPrimitive.Root, {
  all: 'unset',
  backgroundColor: 'white',
  minWidth: 25,
  height: 25,
  borderRadius: 4,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  boxShadow: `0 2px 10px ${blackA.blackA7}`,
  '&:hover': { backgroundColor: violet.violet3 },
  // '&:focus': { boxShadow: `0 0 0 2px black` },
});

const StyledIndicator = styled(CheckboxPrimitive.Indicator, {
  color: violet.violet11,
});

// Exports
export const Checkbox = StyledCheckbox;
export const CheckboxIndicator = StyledIndicator;

// Your app...
const Flex = styled('div', { display: 'flex' });
const Label = styled('label', {
  // color: 'white',
  // fontSize: 15,
  // lineHeight: 1,
  userSelect: 'none',
});

export const CheckboxBasic = (props: {
  onChange: any;
  label: string;
  id: string;
  disabled: boolean;
  value?: any;
  checked?: boolean;
}) => (
  <form>
    <Flex>
      <Checkbox
        id={props.id}
        disabled={props.disabled}
        onCheckedChange={(checked: boolean) => props.onChange(checked)}
      >
        <CheckboxIndicator>
          <CheckIcon />
        </CheckboxIndicator>
      </Checkbox>
      <Label css={{ paddingLeft: 15 }} htmlFor={props.id}>
        {props.label}
      </Label>
    </Flex>
  </form>
);

export const CheckboxCreatorTerms3 = (props: {
  onChange: any;
  id: string;
  disabled: boolean;
  value?: any;
  checked?: boolean;
}) => (
  <form>
    <Flex>
      <Checkbox
        id={props.id}
        disabled={props.disabled}
        onCheckedChange={(checked: boolean) => props.onChange(checked)}
      >
        <CheckboxIndicator>
          <CheckIcon />
        </CheckboxIndicator>
      </Checkbox>
      <Label css={{ paddingLeft: 15 }} htmlFor={props.id}>
        I understand that my artwork and intellectual property, though owned by me, is used by Gamma
        and subject to the Terms of Use found{' '}
        <TextLink href="/terms" target="_blank" rel="noopener noreferrer">
          here
        </TextLink>{' '}
        in addition to the Creator Terms above. I have read the Terms of Use and Creator Terms, and
        I understand that they affect rights I have or may have and (among other things) provide a
        framework for submitting disputes to arbitration, contain promises I am making regarding
        intellectual property, and directly relate to my use of the Portal and any other Services.
        My use of this Portal and the Services are specifically conditioned on me agreeing to the
        Terms of Use and Creator Terms.
      </Label>
    </Flex>
  </form>
);

export default CheckboxBasic;
