import React, { Component } from 'react';
import styles from './index.module.scss';

import { darkmodeListener } from '../../theme';
import makeEndpoint from '../../endpoints';

import Drop from '../../components/Drop';
import { Link } from 'react-router-dom';
import { ContainerFull, H1 } from '../../components/common';
import { formatImageUrl } from '../../utils/format-image-url';

const axios = require('axios');

type HomeProps = {};
type HomeState = {
  value: string;
  collections: any;
  loadingText?: string;
};

class Home extends Component<HomeProps, HomeState> {
  state = {
    value: '',
    collections: [],
    loadingText: 'Loading collections...',
  };

  componentDidMount() {
    darkmodeListener(styles['theme-light'], styles['theme-dark']);

    try {
      fetch(makeEndpoint('/api/featured'))
        .then((res) => {
          return res.json();
        })
        .then((json) => {
          this.setState({
            collections: json,
            loadingText: undefined,
          });
        });
    } catch (error) {
      console.error(error);
    }

    this.fetchPrices();
  }

  async fetchPrices() {
    axios
      .get(makeEndpoint('/conversions'), {
        auth: {
          username: process.env.REACT_APP_LOGIN,
          password: process.env.REACT_APP_PASSWORD,
        },
      })
      .then((res: any) => {
        console.log(res.data);
      })
      .catch((err: any) => {
        console.error(err);
      });
  }

  syncCollectionData(data: any) {
    data['featured'] = false;
    const url = makeEndpoint('/update');
    axios
      .post(url, data, {
        auth: {
          username: process.env.REACT_APP_LOGIN,
          password: process.env.REACT_APP_PASSWORD,
        },
      })
      .then(() => {
        console.log('Collection saved');
      })
      .catch((err: any) => {
        console.error(err);
      });
  }

  renderFeatures() {
    let elements: any = [];
    if (this.state.collections != null) {
      this.state.collections.map((collection, i) => {
        const diff = Date.now() - collection['lastModified'];

        // 1300000000 milliseconds = approx 15 days

        if (diff < 1700000000) {
          let to = `collections/${collection['contractName']}`;
          if (collection['mintPage'] && collection['mintPage'] != '') {
            to = `collections/${collection['mintPage']}`;
          }

          const el = (
            <Drop
              key={i}
              image={formatImageUrl({ url: collection['image'], size: 600 })}
              name={collection['name']}
              new={false}
              // description={collection['description']}
              description=""
              to={to}
            />
          );
          elements.push(el);
        } else {
          this.syncCollectionData(collection);
          console.log('expired');
          console.log(collection['lastModified']);
        }
      });
    }

    return elements;
  }

  render() {
    return (
      <ContainerFull>
        <H1>Minting Now</H1>
        <div className={styles.Grid}>
          {this.state.loadingText ? <div>{this.state.loadingText}</div> : this.renderFeatures()}
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </ContainerFull>
    );
  }
}

export default Home;
