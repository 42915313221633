import { useAtom } from 'jotai';
import { useState } from 'react';
import { handleFileInput, natSort } from '../../../../utils';
import { ContinueButton } from '../../../../components/buttons';
import {
  AssetsDropzone,
  AttributesDropzone,
  CsvDropzone,
  LogoDropzone,
} from '../../../../components/dropzone';
import { RadioGroupWithNote } from '../../../../components/radio-group';
import {
  assetsAtom,
  attributesAtom,
  filenamesEnabledAtom,
  logoAtom,
  logoEnlargedAtom,
} from '../../../../components/atoms';
import { Container, ContainerItem } from '../../../../components/common';
import { SwitchEnlargedImage, SwitchFilenames } from '../../../../components/switch';

function Media(props: { onNextStep: any }) {
  const [logo, setLogo] = useAtom(logoAtom);
  const [assets, setAssets] = useAtom(assetsAtom);
  const [attributes, setAttributes] = useAtom(attributesAtom);
  const [filenamesEnabled, setFilenamesEnabled] = useAtom(filenamesEnabledAtom);
  const [logoEnlarged, setLogoEnlarged] = useAtom(logoEnlargedAtom);

  const [logoError, setLogoError] = useState('');
  const [assetsError, setAssetsError] = useState('');
  const [attributesError, setAttributesError] = useState('');

  function handleLogoDrop(acceptedFiles: any[], rejectedFiles: any[]) {
    if (rejectedFiles[0]) {
      setLogoError(rejectedFiles[0].errors[0].message);
      setLogo([]);
    } else {
      setLogoError('');
      setLogo(acceptedFiles);
    }
  }

  function handleAssetsDrop(acceptedFiles: any[], rejectedFiles: any[]) {
    let allNums = true;
    for (let i = 0; i < acceptedFiles.length; i++) {
      const file = acceptedFiles[i];
      const filename = file.file.name;
      let isnum = /^\d+$/.test(filename.split('.')[0]);
      if (!isnum || filename.split('.')[1].includes('.')) {
        allNums = false;
      }
    }

    let sortedFiles: any[];

    // if (allNums) {
    //   const filenames = natSort(acceptedFiles);
    //   sortedFiles = Array(filenames.length).fill('');
    //   for (let i = 0; i < acceptedFiles.length; i++) {
    //     const file = acceptedFiles[i];
    //     const filename = file.file.name;
    //     const index = filenames.indexOf(filename);
    //     sortedFiles[index] = file;
    //   }
    // } else {
    //   sortedFiles = acceptedFiles;
    // }
    sortedFiles = acceptedFiles;

    if (rejectedFiles[0]) {
      setAssetsError(rejectedFiles[0].errors[0].message);
      setAssets([]);
    } else {
      setAssetsError('');
      setAssets(sortedFiles);
    }
  }

  function handleFilenamesEnabledChange(checked: boolean) {
    setFilenamesEnabled(checked);
  }

  function handleLogoEnlargedChange(checked: boolean) {
    console.log(checked);
    setLogoEnlarged(checked);
  }

  async function handleAttributesDrop(acceptedFiles: any[], rejectedFiles: any[]) {
    const file = await handleFileInput(acceptedFiles[0], 'attributes');
    console.log(file);

    if (!assets.length) {
      setAttributesError('Upload your assets first');
    } else if (file.attributesError) {
      setAttributes([]);
      setAttributesError(file.attributesError);
    } else if (file.attributes.length !== assets.length) {
      setAttributes([]);
      setAttributesError("Attributes don't match number of assets");
    } else if (rejectedFiles[0]) {
      setAttributes([]);
      setAttributesError(rejectedFiles[0].errors[0].message);
    } else {
      setAttributesError('');
      setAttributes(file.attributes);
    }
  }

  function handleContinue() {
    console.log(logo);

    props.onNextStep();
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  }

  return (
    <Container>
      <h2>Upload your media files</h2>

      <ContainerItem>
        <LogoDropzone
          onDrop={(acceptedFiles: [], rejectedFiles: []) =>
            handleLogoDrop(acceptedFiles, rejectedFiles)
          }
          label="Logo"
          note="Upload an image to be displayed as the logo for your collection.
          Recommended image size is 400x400px."
          error={logoError}
          successText={logo.length > 0 ? 'Success! Logo added' : ''}
          files={logo}
        />
      </ContainerItem>

      {/* <ContainerItem>
        <SwitchEnlargedImage
          value={logoEnlarged}
          onChange={(checked: boolean) => handleLogoEnlargedChange(checked)}
        />
      </ContainerItem> */}

      <ContainerItem>
        <AssetsDropzone
          onDrop={(acceptedFiles: [], rejectedFiles: []) =>
            handleAssetsDrop(acceptedFiles, rejectedFiles)
          }
          label="Assets"
          note="Upload a folder of your assets. Make sure the number of assets equals
          the number of NFTs in your collection. The order will be the same as
          the order of files in your folder."
          error={assetsError}
          successText={assets.length ? `Success! ${assets.length} assets added` : ''}
          showThumbs={true}
        />
      </ContainerItem>

      <ContainerItem>
        <SwitchFilenames
          value={filenamesEnabled}
          onChange={(checked: boolean) => handleFilenamesEnabledChange(checked)}
        />
      </ContainerItem>

      <ContainerItem>
        <AttributesDropzone
          onDrop={(acceptedFiles: [], rejectedFiles: []) =>
            handleAttributesDrop(acceptedFiles, rejectedFiles)
          }
          label="Attributes (optional)"
          // note="Upload a .csv file of attributes for your assets. Format your file so that each row represents a token id and each column represents an attribute value."
          note="Upload a .csv file of your attributes or a _metadata.json file from Hashlips. If uploading a .csv file, format your file so that each row represents a token id and each column represents an attribute value. If you're using the Hashlips _metadata.json file, your attributes will be sorted by edition/token_id in ascending order. Make sure to view your attributes on the preview cards above to make sure everything is correct."
          error={attributesError}
          successText={
            attributes.length ? `Success! Attributes for ${attributes.length} assets added` : ''
          }
          sample="attributes.csv"
        />
      </ContainerItem>

      <ContainerItem>
        <ContinueButton
          disabled={!logo.length || !assets.length}
          text="Continue"
          onClick={() => handleContinue()}
        />
      </ContainerItem>
    </Container>
  );
}

export default Media;
