import React, { Component } from 'react'
import styles from './index.module.scss'

import { darkmodeListener } from '../../theme';

type TextInputProps = {
  placeholder: string
  error?: string
  value: string
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
}

type TextInputState = {}

class TextInput extends Component<TextInputProps, TextInputState> {
  componentDidMount() {
    darkmodeListener(styles['theme-light'], styles['theme-dark']);
  }

  render() {
    if (!this.props.error) {
      return (
        <div>
          <input
            className={styles.TextInput}
            type={"text"}
            placeholder={this.props.placeholder}
            value={this.props.value}
            onChange={this.props.onChange.bind(this)}/>
        </div>
      )
    } else {
      return (
        <div>
          <div className={styles.TextError}>
            {this.props.error}
          </div>
          <div>
            <input
              className={`${styles.TextInput} ${styles.TextInputError}`}
              type={"text"}
              placeholder={this.props.placeholder}
              value={this.props.value}
              onChange={this.props.onChange.bind(this)}/>
          </div>
        </div>
      )
    }
  }
}

export default TextInput
