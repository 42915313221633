import { useAtom } from 'jotai';
import { ContinueButton } from '../../../../components/buttons';
import {
  airdropAtom,
  artistAddressAtom,
  assetsAtom,
  attributesAtom,
  categoryAtom,
  claimOptionsAtom,
  collectionNameAtom,
  contractIdAtom,
  currentUserAddressAtom,
  descriptionAtom,
  discordAtom,
  editionAtom,
  editionNameAtom,
  emailAddressAtom,
  filenamesEnabledAtom,
  fungibleOptionsAtom,
  fungibleSelectedAtom,
  licenseNameAtom,
  licenseUriAtom,
  logoAtom,
  logoEnlargedAtom,
  mintingEnabledAtom,
  mintpassAtom,
  mintsPerAddressAtom,
  numberOfEditionsAtom,
  paymentOptionsAtom,
  placeholderAtom,
  priceAtom,
  twitterAtom,
  visibilityEnabledAtom,
  websiteAtom,
} from '../../../../components/atoms';
import { Container, ContainerItem, TextLink } from '../../../../components/common';
import { deployEditionsContract } from '../../../../utils/SelfServe/contracts';
import { submitEditionsToIpfs, submitPublicToIpfs } from '../../../../utils/ipfs';
import { useState } from 'react';
import {
  handleEditionsCollection,
  handlePublicCollection,
} from '../../../../utils/Studio/serverCalls';
import { Link } from 'react-router-dom';

function Publish(props: { onNextStep: any }) {
  const [collectionName, setCollectionName] = useAtom(collectionNameAtom);
  const [description, setDescription] = useAtom(descriptionAtom);
  const [emailAddress, setEmailAddress] = useAtom(emailAddressAtom);
  const [website, setWebsite] = useAtom(websiteAtom);
  const [twitter, setTwitter] = useAtom(twitterAtom);
  const [discord, setDiscord] = useAtom(discordAtom);
  const [category, setCategory] = useAtom(categoryAtom);
  const [artistAddress, setArtistAddress] = useAtom(artistAddressAtom);
  const [logo, setLogo] = useAtom(logoAtom);
  const [assets, setAssets] = useAtom(assetsAtom);
  const [paymentOptions, setPaymentOptions] = useAtom(paymentOptionsAtom);
  const [fungibleOptions, setFungibleOptions] = useAtom(fungibleOptionsAtom);
  const [fungibleSelected, setFungibleSelected] = useAtom(fungibleSelectedAtom);
  const [placeholder, setPlaceholder] = useAtom(placeholderAtom);
  const [attributes, setAttributes] = useAtom(attributesAtom);
  const [price, setPrice] = useAtom(priceAtom);
  const [claimOptions, setClaimOptions] = useAtom(claimOptionsAtom);
  const [mintpass, setMintpass] = useAtom(mintpassAtom);
  const [airdrop, setAirdrop] = useAtom(airdropAtom);
  const [mintingEnabled, setMintingEnabled] = useAtom(mintingEnabledAtom);
  const [mintsPerAddress, setMintsPerAddress] = useAtom(mintsPerAddressAtom);
  const [visibilityEnabled, setVisibilityEnabled] = useAtom(visibilityEnabledAtom);
  const [currentUserAddress, setCurrentUserAddress] = useAtom(currentUserAddressAtom);
  const [logoEnlarged, setLogoEnlarged] = useAtom(logoEnlargedAtom);
  const [filenamesEnabled, setFilenamesEnabled] = useAtom(filenamesEnabledAtom);
  const [edition, setEdition] = useAtom(editionAtom);
  const [numberOfEditions, setNumberOfEditions] = useAtom(numberOfEditionsAtom);
  const [contractId, setContractId] = useAtom(contractIdAtom);
  const [editionName, setEditionName] = useAtom(editionNameAtom);
  const [licenseUri, setLicenseUri] = useAtom(licenseUriAtom);
  const [licenseName, setLicenseName] = useAtom(licenseNameAtom);

  const [progress, setProgress] = useState('');
  const [tx, setTx] = useState('');
  const [is_submitted, set_is_submitted] = useState(false);

  async function handlePublish() {
    let contractName = collectionName
      .replaceAll(' ', '-')
      .replaceAll('(', '')
      .replaceAll(')', '')
      .replace(/^-+|-+$/g, '')
      .replaceAll('---', '-')
      .toLowerCase();

    const ipfsData = {
      collectionName,
      logo,
      edition,
      numberOfEditions,
      description,
      price,
      attributes,
      editionName,
    };

    setContractId(currentUserAddress + '.' + contractName);

    // do ipfs stuff
    const result = await submitEditionsToIpfs(ipfsData, handleProgress, handleOnSubmitFinish);

    console.log(result);

    if (!result) {
      return;
    }

    const jsonHash = result?.jsonHash;
    // if (!jsonHash) {
    //   console.log('no json hash');
    //   return;
    // }

    // let metaHash = jsonHash;

    deployEditionsContract(
      contractName,
      artistAddress || currentUserAddress,
      price,
      numberOfEditions,
      jsonHash,
      mintpass,
      airdrop,
      claimOptions,
      fungibleOptions,
      fungibleSelected,
      !visibilityEnabled,
      !mintingEnabled,
      mintsPerAddress,
      licenseUri,
      licenseName,
      handleOnFinish,
      handleOnCancel,
    );

    const data = {
      contractName,
      collectionName,
      description,
      emailAddress,
      website,
      twitter,
      discord,
      category,
      artistAddress,
      logo,
      assets,
      fungibleSelected,
      placeholder,
      attributes,
      price,
      claimOptions,
      mintpass,
      airdrop,
      mintingEnabled,
      mintsPerAddress,
      visibilityEnabled,
      currentUserAddress,
      logoEnlarged,
      filenamesEnabled,
      txId: '',
      total: numberOfEditions,
    };

    console.log(data);

    function handleOnFinish(txUrl: string, txId: string) {
      setTx(txUrl);
      setProgress('Contract deployed.');
      data.txId = txId;

      if (!result) {
        return;
      }

      // handlePublicCollection(data, result, txUrl);
      handleEditionsCollection(data, result, txUrl);

      props.onNextStep(txUrl);
      document.body.scrollTop = document.documentElement.scrollTop = 0;
    }

    function handleOnCancel() {
      setProgress('Deployment submitted. Please contact support@gamma.io for review and approval.');

      if (!result) {
        return;
      }

      set_is_submitted(true);

      handleEditionsCollection(data, result, '');
    }
  }

  function handleProgress(progress: string) {
    setProgress(progress);
  }

  function handleOnSubmitFinish() {
    console.log('finished');
  }

  function renderProgress() {
    if (tx) {
      return (
        <div>
          {progress}{' '}
          <TextLink href={tx} target="_blank" rel="noopener noreferrer">
            View your transaction.
          </TextLink>
        </div>
      );
    } else {
      return <div>{progress}</div>;
    }
  }

  return (
    <Container>
      <h2>Publish your collection</h2>
      <div>
        Hit publish to save your details, upload your files to IPFS, and deploy your contract to
        mainnet.
      </div>
      <ContainerItem>
        <ContinueButton disabled={is_submitted} text="Publish" onClick={() => handlePublish()} />
      </ContainerItem>
      {renderProgress()}
    </Container>
  );
}

export default Publish;
