import { styled } from '../stitches.config';
import { blackA } from '@radix-ui/colors';
import { useEffect, useState } from 'react';
import * as LabelPrimitive from '@radix-ui/react-label';
import { ContinueButton, DateButton } from './buttons';
import {
  call,
  dropAbi,
  etherscanUrlGeorli,
  etherscanUrlMainnet,
} from '../utils/SelfServe/contracts';
import { Container, ContainerItem, ContainerSection, H2, TextLink } from './common';
import { LabelInput, LabelTextArea, Note } from './label';
import { AssetsDropzone, CsvDropzone } from './dropzone';
import { BigNumber, ethers, utils } from 'ethers';
import { useAtom } from 'jotai';
import { mintpassAtom } from './atoms';
import { dealWithEther, handleFileInput } from '../utils';
import { hashForEntry, makeTree } from '../utils/merkle';
import { hexValue } from 'ethers/lib/utils';
import axios from 'axios';
import { useContractWrite, useNetwork } from 'wagmi';
import DatePicker from 'react-datepicker';
import { addAllowlist } from '../utils/Studio/serverCalls';

const Label = styled(LabelPrimitive.Root, {
  // fontSize: 18,
  userSelect: 'none',
  maxWidth: 'fit-content',
  // lineHeight: "32px",
  // color: blackA.blackA9,
});

const Flex = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: 20,
});

const Args = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: 10,
});

const Input = styled('input', {
  all: 'unset',
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: 10,
  padding: 16,
  fontSize: 16,
  border: '1px solid #dcdde2',
  fontFamily: 'inherit',
  '&:focus': { boxShadow: `0 0 0 1px #0055E1` },
});

const CallButton = styled('button', {
  borderRadius: '10px',
  height: '60px',
  width: '100%',
  fontSize: '16px',
  lineHeight: '24px',
  backgroundColor: '$text',
  color: '$background',
  cursor: 'pointer',
  border: '1px solid #dcdde2',
});

const Name = styled('h3', {
  margin: '0',
});

export const ContractCall = (props: {
  contractAddress: string;
  contractName: string;
  access: string;
  name: string;
  args: { name: string; type: any }[];
  onFinish: any;
  onCancel: any;
  onProgress: any;
}) => {
  const [inputValues, setInputValues] = useState<string[]>([]);
  const [progress, setProgress] = useState('');
  const [txUrl, setTxUrl] = useState('');

  useEffect(() => {
    let values = [...inputValues];
    for (let i = 0; i < values.length; i++) {
      values[i] = '';
    }
    setInputValues(values);
    setProgress('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.name]);

  function handleContractCall() {
    const functionArgs = [];
    for (let i = 0; i < props.args.length; i++) {
      let type = '';
      if (typeof props.args[i].type === 'object') {
        type = Object.keys(props.args[i].type)[0];
      } else {
        type = props.args[i].type;
      }
      if (type === 'optional' && typeof props.args[i].type.optional === 'object') {
        type = 'optional (string-ascii ' + props.args[i].type.optional['string-ascii'].length + ')';
      } else if (type === 'optional') {
        type = 'optional ' + props.args[i].type.optional;
      }
      const arg = { value: inputValues[i], type };
      functionArgs.push(arg);
    }

    if (props.access === 'public') {
      setProgress('Confirm your contract call in the dialog window...');
    } else if (props.access === 'read_only') {
      setProgress('Fetching result...');
    }

    call(
      props.contractAddress,
      props.contractName,
      props.name,
      props.access,
      functionArgs,
      handleFinish,
      handleCancel,
    );
  }

  function handleCancel() {
    console.log('cancelled');
    setProgress('Contract call cancelled.');
  }

  function handleFinish(res: any, url?: string) {
    if (res && res['value'] && res['value']['commission'] && res['value']['price']) {
      setProgress(
        `Result: ${res['value']['price']['value']}, ${res['value']['commission']['value']}`,
      );
    } else if (res && res['value'] && res['value']['value']) {
      setProgress('Result: ' + res['value']['value']);
    } else if (res && res['value']) {
      setProgress('Result: ' + res['value']);
    } else if (res && res['value'] === null) {
      setProgress('Result: null');
    } else if (res) {
      setProgress('Result: ' + res);
    } else {
      setProgress('Could not parse result.');
    }

    if (url) {
      setTxUrl(url);
    }
    props.onFinish(url);
  }

  function handleInput(index: number, e: React.ChangeEvent<HTMLInputElement>) {
    let values = [...inputValues];
    values[index] = e.target.value;
    setInputValues(values);
  }

  function renderArgs() {
    const args = [];
    for (let i = 0; i < props.args.length; i++) {
      let arg = `${
        typeof props.args[i].type === 'object'
          ? Object.keys(props.args[i].type)[0]
          : props.args[i].type
      }`;
      if (arg === 'optional' && typeof props.args[i].type.optional === 'object') {
        arg = 'optional string-ascii ' + props.args[i].type.optional['string-ascii'].length;
      } else if (arg === 'optional') {
        arg = 'optional ' + props.args[i].type.optional;
      }
      const argInput = (
        <>
          <Label>{props.args[i].name}</Label>
          <Input
            onChange={(e) => handleInput(i, e)}
            key={i}
            placeholder={arg}
            value={inputValues[i]}
          />
        </>
      );
      args.push(argInput);
    }
    return args;
  }

  function renderDescription() {
    if (props.access === 'public') {
      return (
        <div>
          <ContainerItem>
            {props.name} is a <code>{props.access}</code> function.
          </ContainerItem>
          <ContainerItem>
            Public functions can change the state of your smart contract. Enter any required
            parameters below to make the contract call.
          </ContainerItem>
        </div>
      );
    } else {
      return (
        <div>
          <ContainerItem>
            {props.name} is a <code>{props.access}</code> function.
          </ContainerItem>
          <ContainerItem>
            Read-only functions retrieve information about your contract. Enter any required
            paramaters below to make the contract call.
          </ContainerItem>
        </div>
      );
    }
  }

  function renderProgress() {
    if (txUrl) {
      return (
        <div>
          {progress}{' '}
          <TextLink href={txUrl} target="_blank" rel="noopener noreferrer">
            View your transaction
          </TextLink>
          .
        </div>
      );
    } else {
      return <div>{progress}</div>;
    }
  }

  return (
    <Container>
      <H2>{props.name}</H2>
      <ContainerItem>{renderDescription()}</ContainerItem>
      <Args>{renderArgs()}</Args>
      <ContainerItem>
        <CallButton onClick={() => handleContractCall()}>{props.name}</CallButton>
      </ContainerItem>
      {renderProgress()}
    </Container>
  );
};

export const SetBaseUri = (props: {
  contractAddress: string;
  contractName: string;
  // args: { name: string; type: any }[];
  onFinish: any;
  onCancel: any;
  onProgress: any;
}) => {
  const [inputValues, setInputValues] = useState<string[]>([]);
  const [progress, setProgress] = useState('');
  const [txUrl, setTxUrl] = useState('');
  const [assets, setAssets] = useState([]);

  useEffect(() => {
    setProgress('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleContractCall() {
    const functionArgs: any = [];
    // for (let i = 0; i < props.args.length; i++) {
    //   let type = '';
    //   if (typeof props.args[i].type === 'object') {
    //     type = Object.keys(props.args[i].type)[0];
    //   } else {
    //     type = props.args[i].type;
    //   }
    //   const arg = { value: inputValues[i], type };
    //   functionArgs.push(arg);
    // }

    setProgress('Confirm your contract call in the dialog window...');

    call(
      props.contractAddress,
      props.contractName,
      'set-base-uri',
      'public',
      functionArgs,
      handleFinish,
      handleCancel,
    );
  }

  function handleCancel() {
    console.log('cancelled');
    setProgress('Contract call cancelled.');
  }

  function handleFinish(res: any, url?: string) {
    if (res && res['value'] && res['value']['commission'] && res['value']['price']) {
      setProgress(
        `Result: ${res['value']['price']['value']}, ${res['value']['commission']['value']}`,
      );
    } else if (res && res['value'] && res['value']['value']) {
      setProgress('Result: ' + res['value']['value']);
    } else if (res && res['value']) {
      setProgress('Result: ' + res['value']);
    } else if (res && res['value'] === null) {
      setProgress('Result: null');
    } else if (res) {
      setProgress('Result: ' + res);
    } else {
      setProgress('Could not parse result.');
    }

    if (url) {
      setTxUrl(url);
    }
    props.onFinish(url);
  }

  function handleInput(index: number, e: React.ChangeEvent<HTMLInputElement>) {
    let values = [...inputValues];
    values[index] = e.target.value;
    setInputValues(values);
  }

  function renderProgress() {
    if (txUrl) {
      return (
        <div>
          {progress}{' '}
          <TextLink href={txUrl} target="_blank" rel="noopener noreferrer">
            View your transaction
          </TextLink>
          .
        </div>
      );
    } else {
      return <div>{progress}</div>;
    }
  }

  return (
    <Container>
      <H2>Update token metadata</H2>

      <ContainerItem>
        <LabelInput
          placeholder="1"
          label="Token id"
          note="Enter the id of the token you want to update."
          id="tokenid"
          type="number"
        />
      </ContainerItem>

      <ContainerItem>
        <LabelInput
          placeholder="Token Name"
          label="Name"
          note="Enter the name you want to use for this NFT. If you want the name to stay the same, leave blank."
          id="tokenname"
          type="text"
        />
      </ContainerItem>

      <ContainerItem>
        <LabelTextArea
          placeholder="This is the new description for the token."
          label="Description"
          note="Enter the id of the token you want to update."
          id="tokendescription"
          value=""
        />
      </ContainerItem>

      {/* <AssetsDropzone
        onDrop={(acceptedFiles: [], rejectedFiles: []) =>
          handleAssetsDrop(acceptedFiles, rejectedFiles)
        }
        label="Asset"
        note="Upload the asset for this token."
        error={assetsError}
        showThumbs={true}
        offset={lastTokenId}
      /> */}

      {/* {assets && assets.length ? (
        <ContainerItem>
          <SwitchFilenames
            value={filenamesEnabled}
            onChange={(checked: boolean) => handleFilenamesEnabledChange(checked)}
          />
        </ContainerItem>
      ) : null} */}

      {/* {assets && assets.length ? (
        <ContainerItem>
          <CsvDropzone
            onDrop={(acceptedFiles: [], rejectedFiles: []) =>
              handleAttributesDrop(acceptedFiles, rejectedFiles)
            }
            label="Attributes (optional)"
            note="Upload a .csv file of attributes for your assets. Format your file so that each row represents a token id and each column represents an attribute value."
            error={attributesError}
            successText={
              attributes.length ? `Success! Attributes for ${attributes.length} assets added` : ''
            }
            sample="attributes.csv"
          />
        </ContainerItem>
      ) : null} */}

      <ContainerItem>
        <CallButton onClick={() => handleContractCall()}>set-base-uri</CallButton>
      </ContainerItem>
      {renderProgress()}
    </Container>
  );
};

export const ContractCallEth = (props: {
  contractAddress: string;
  method: string;
  selectedFunction: any;
  abi: any;
  signer: any;
  access?: string;
  args: { name: string; type: any }[];
  onFinish?: any;
  onCancel?: any;
  onProgress?: any;
  description?: string;
}) => {
  const [inputValues, setInputValues] = useState<string[]>([]);
  const [progress, setProgress] = useState('');
  const [txUrl, setTxUrl] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [mintpass, setMintpass] = useAtom(mintpassAtom);
  const [mintpassError, setMintpassError] = useState('');
  const [addresses, setAddresses] = useState([]);
  const [addressesError, setAddressesError] = useState('');
  const [publicStartDate, setPublicStartDate] = useState<any>(null);
  const [mintpassStartDate, setMintpassStartDate] = useState<any>(null);
  const [mintpassEndDate, setMintpassEndDate] = useState<any>(null);
  const [publicStartUnix, setPublicStartUnix] = useState(0);
  const [mintpassStartUnix, setMintpassStartUnix] = useState(0);
  const [mintpassEndUnix, setMintpassEndUnix] = useState(0);
  const [merkleRoot, setMerkleRoot] = useState('');
  const [merkleEntries, setMerkleEntries] = useState<any[]>([]);
  const [isAllowlistError, setIsAllowlistError] = useState(false);
  const network = useNetwork();

  useEffect(() => {
    // clears input values and progress when selected function changes
    let values = [];
    for (let i = 0; i < props.args.length; i++) {
      values.push('');
    }
    setInputValues(values);
    setProgress('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.method]);

  async function handleContractCall() {
    setProgress('');
    setTxUrl('');
    setErrorMessage('');
    const functionArgs = [];
    for (let i = 0; i < props.args.length; i++) {
      const type = props.args[i].type;
      if (!inputValues[i]) {
        continue;
      }
      const value = inputValues[i];
      if (type === 'address') {
        functionArgs.push(value.toString());
      } else if (props.args[i].name === 'publicSalePrice') {
        functionArgs.push(value.toString());
      } else if (type.toString().includes('uint') && !isNaN(parseInt(value))) {
        functionArgs.push(parseInt(value));
      } else if (type.toString().includes('address') && type.toString().length === 9) {
        functionArgs.push(value);
      } else if (type.toString().includes('bytes32') && type.toString().length === 9) {
        const addresses = value.split(',');
        functionArgs.push(addresses);
      } else if (type.toString().includes('bytes')) {
        functionArgs.push(value.toString());
      }
    }

    if (props.selectedFunction.stateMutability === 'view') {
      try {
        const contract = new ethers.Contract(props.contractAddress, props.abi, props.signer);
        const res = await contract[props.method](...functionArgs);
        setProgress(res.toString());
      } catch (e) {
        console.log(e);
      }
      return;
    }

    const MINT_TX_CONFIRMATIONS = 2;
    try {
      const contract = new ethers.Contract(props.contractAddress, props.abi, props.signer);
      let args = functionArgs;

      if (props.method === 'setSaleConfiguration' && !args[0]) {
        setProgress('You must enter a valid public sale price');
        return;
      }

      if (props.method === 'setSaleConfiguration' && !args[2]) {
        setProgress('You must select a public sale start date');
        return;
      }

      if (props.method === 'setSaleConfiguration' && args[4] && (!args[5] || !args[6])) {
        setProgress('You must complete all or none of the presale fields');
        return;
      }
      if (props.method === 'setSaleConfiguration' && args[5] && (!args[4] || !args[6])) {
        setProgress('You must complete all or none of the presale fields');
        return;
      }
      if (props.method === 'setSaleConfiguration' && args[6] && (!args[4] || !args[5])) {
        setProgress('You must complete all or none of the presale fields');
        return;
      }

      if (props.method === 'setSaleConfiguration') {
        args[0] = dealWithEther(args[0].toString()).toString();
        args[3] = 9967325600;
        args[4] = args[4] || 0;
        args[5] = args[5] || 0;
        args[6] = args[6] || '0x0000000000000000000000000000000000000000000000000000000000000000';
      }

      console.log(args);

      // 0xa09420a5ca5922a14592cbf75a2664e536e473069290bf1809a71a6e48160488,0xafae31ffb1cbf9afcf719295162451f029e9100854ddcbac947380e7ad4bce7c,0x066f696aafe88dcf91e9174b60ad31a43ea1ab0f2d05b445cc90f1de606e4a09
      // 0xef45dbf9ebbdc980c04fb0222fcfbab096e83d1bcf874b48908b01d5bbd40a30,0xc340433338c5bb5b7577232c9c89cd37ffe461e3423cbca2904ae1baf0488114,0x9b230c540028281c9d1b7300fa1ea25a28b06ed4cf7224bfe64c30a641aea5ad
      if (props.method === 'purchasePresale') {
        const cost = args[2].toString();
        const qty = parseInt(args[0].toString());
        const value = BigNumber.from(cost).mul(BigNumber.from(qty));
        const gasEstimate = contract
          .connect(props.signer)
          .estimateGas[props.method](...args, { value });
        const gasLimit = (await gasEstimate).mul(120).div(100);
        const tx = await contract.connect(props.signer)[props.method](...args, { gasLimit, value });
        handleProgress('Success!', tx.hash);
        const res = await tx.wait(MINT_TX_CONFIRMATIONS);
        console.log(res);
      } else if (args && args.length) {
        const value = BigNumber.from('0');
        // const gasEstimate = contract
        //   .connect(props.signer)
        //   .estimateGas[props.method](...args, { value });
        // const gasLimit = (await gasEstimate).mul(120).div(100);
        // const tx = await contract.connect(props.signer)[props.method](...args, { gasLimit, value });
        const tx = await contract.connect(props.signer)[props.method](...args);
        handleProgress('Success!', tx.hash);
        const res = await tx.wait(MINT_TX_CONFIRMATIONS);
        console.log(res);
      } else {
        const tx = await contract.connect(props.signer)[props.method]();
        handleProgress('Success!', tx.hash);
        const res = await tx.wait(MINT_TX_CONFIRMATIONS);
        console.log(res);
      }
    } catch (e: any) {
      console.log(e);
      if (e.code === 'ACTION_REJECTED') {
        setProgress('Transaction was cancelled by the user');
      } else if (e.code) {
        setProgress('Transaction failed with error code ' + e.code);
        setErrorMessage(e.message);
      } else {
        setProgress('Transaction failed due to an unkown error');
      }
    }
  }

  function handleInput(index: number, e: React.ChangeEvent<HTMLInputElement>) {
    let values = [...inputValues];
    values[index] = e.target.value;
    setInputValues(values);
  }

  async function handleEthAddressesDrop(acceptedFiles: any[], rejectedFiles: any[], index: number) {
    const csv = await handleFileInput(acceptedFiles[0], 'ethAddresses');
    if (csv.ethAddressesError) {
      setAddresses([]);
      setAddressesError(csv.ethAddressesError);
    } else if (rejectedFiles[0]) {
      setAddresses([]);
      setAddressesError(rejectedFiles[0].errors[0].message);
    } else {
      let values = [...inputValues];
      values[index] = csv.ethAddresses;
      setInputValues(values);
      setAddresses(csv.ethAddresses);
      setAddressesError('');
    }
  }

  function handlePublicStartDateChange(date: Date) {
    const unix = Math.floor(new Date(date).getTime() / 1000);
    setPublicStartDate(date);
    setPublicStartUnix(unix);
    let values = [...inputValues];
    if (!values[1]) {
      values[1] = '4294967295';
    }
    values[2] = unix.toString();
    values[3] = '9967325600';
    setInputValues(values);
  }

  function handleMintpassStartDateChange(date: Date) {
    const unix = Math.floor(new Date(date).getTime() / 1000);
    setMintpassStartDate(date);
    setMintpassStartUnix(unix);
    let values = [...inputValues];
    values[4] = unix.toString();
    setInputValues(values);
  }

  function handleMintpassEndDateChange(date: Date) {
    const unix = Math.floor(new Date(date).getTime() / 1000);
    setMintpassEndDate(date);
    setMintpassEndUnix(unix);
    let values = [...inputValues];
    values[5] = unix.toString();
    setInputValues(values);
  }

  function renderArgs() {
    const args = [];
    for (let i = 0; i < props.args.length; i++) {
      const arg = `${
        typeof props.args[i].type === 'object'
          ? Object.keys(props.args[i].type)[0]
          : props.args[i].type
      }`;

      if (props.args[i].name === 'publicSaleStart') {
        args.push(
          <ContainerItem css={{ marginBottom: 0 }}>
            <Label css={{ fontSize: 18 }}>Public sale start date</Label>
            <ContainerItem>
              <DatePicker
                name="Public sale start date"
                title="Public sale start date"
                selected={publicStartDate}
                onChange={(date: Date) => handlePublicStartDateChange(date)}
                showTimeSelect
                dateFormat={'MMM d, yyyy h:mm aa'}
                placeholderText="Start date"
                customInput={
                  publicStartDate ? (
                    <DateButton>
                      {publicStartDate.toLocaleDateString()} {publicStartDate.toLocaleTimeString()}
                    </DateButton>
                  ) : (
                    <DateButton>Public sale start date</DateButton>
                  )
                }
              />
            </ContainerItem>
          </ContainerItem>,
        );
      } else if (props.args[i].name === 'presaleMerkleRoot') {
        args.push(
          <>
            <CsvDropzone
              onDrop={(acceptedFiles: [], rejectedFiles: []) =>
                handleMintpassDrop(acceptedFiles, rejectedFiles)
              }
              label="Allowlist (optional - required for presale only)"
              note="Upload a .csv file that includes the numbers of presale mints allowed per wallet address. 
                Each row must include a single wallet address, followed by the price (in ETH) of the mint, then an integer representing the
                number of mints allowed for that address."
              error={mintpassError}
              successText={
                mintpass.length ? `Success! ${mintpass.length} wallets added to presale` : ''
              }
              sample="presale.csv"
            />
          </>,
        );
      } else if (props.args[i].name === 'publicSaleEnd') {
      } else if (props.args[i].name === 'presaleStart') {
        args.push(
          <>
            <Label css={{ fontSize: 18 }}>
              Presale start date (optional - required for presale only)
            </Label>
            <ContainerItem>
              <DatePicker
                name="Presale start date"
                title="Presale start date"
                selected={mintpassStartDate}
                onChange={(date: Date) => handleMintpassStartDateChange(date)}
                showTimeSelect
                selectsStart
                startDate={mintpassStartDate}
                endDate={mintpassEndDate}
                dateFormat={'MMM d, yyyy h:mm aa'}
                placeholderText="Start date"
                customInput={
                  mintpassStartDate ? (
                    <DateButton>
                      {mintpassStartDate.toLocaleDateString()}{' '}
                      {mintpassStartDate.toLocaleTimeString()}
                    </DateButton>
                  ) : (
                    <DateButton>Presale start date</DateButton>
                  )
                }
              />
            </ContainerItem>
          </>,
        );
      } else if (props.args[i].name === 'presaleEnd') {
        args.push(
          <>
            <Label css={{ fontSize: 18 }}>
              Presale end date (optional - required for presale only)
            </Label>
            <ContainerItem>
              <DatePicker
                selected={mintpassEndDate}
                onChange={(date: Date) => handleMintpassEndDateChange(date)}
                showTimeSelect
                selectsEnd
                startDate={mintpassStartDate}
                endDate={mintpassEndDate}
                minDate={mintpassStartDate}
                dateFormat={'MMM d, yyyy h:mm aa'}
                placeholderText="End date"
                customInput={
                  mintpassEndDate ? (
                    <DateButton>
                      {mintpassEndDate.toLocaleDateString()} {mintpassEndDate.toLocaleTimeString()}
                    </DateButton>
                  ) : (
                    <DateButton>Presale end date</DateButton>
                  )
                }
              />
            </ContainerItem>
          </>,
        );
      } else if (props.args[i].name === 'publicSalePrice') {
        args.push(
          <>
            <Label css={{ fontSize: 18 }}>Public sale price (ETH)</Label>
            <ContainerItem>
              <Input
                onChange={(e) => handleInput(i, e)}
                key={i}
                placeholder={'0.001'}
                value={inputValues[i]}
              />
            </ContainerItem>
          </>,
        );
      } else if (props.args[i].name === 'maxSalePurchasePerAddress') {
        args.push(
          <>
            <Label css={{ fontSize: 18 }}>
              Max sale purchase per address (leave blank for unlimited)
            </Label>
            <ContainerItem>
              <Input
                onChange={(e) => handleInput(i, e)}
                key={i}
                placeholder={'uint'}
                value={inputValues[i]}
              />
            </ContainerItem>
          </>,
        );
      } else if (props.args[i].type === 'address[]') {
        args.push(
          <>
            <CsvDropzone
              label={props.args[i].name}
              // onDrop={() => console.log('hello')}
              onDrop={(acceptedFiles: [], rejectedFiles: []) =>
                handleEthAddressesDrop(acceptedFiles, rejectedFiles, i)
              }
              note="Upload a .csv file of addresses (one address per row)."
              error={addressesError}
              successText={addresses.length ? `Success! ${addresses.length} addresses added` : ''}
              sample="addresses.csv"
            />
          </>,
        );
      } else {
        args.push(
          <>
            <Label>{props.args[i].name}</Label>
            <Input
              onChange={(e) => handleInput(i, e)}
              key={i}
              placeholder={arg}
              value={inputValues[i]}
            />
          </>,
        );
      }
    }
    return args;
  }

  function handleProgress(progress: string, hash?: string) {
    setProgress(progress);
    if (hash) {
      setTxUrl(hash);
    }
  }

  function renderProgress() {
    if (txUrl) {
      return (
        <div>
          {progress}{' '}
          <TextLink
            href={`${
              network.chain?.id === 5 ? etherscanUrlGeorli : etherscanUrlMainnet
            }/tx/${txUrl}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            View your transaction
          </TextLink>
          .
        </div>
      );
    } else {
      return (
        <>
          <div>{progress}</div>

          {errorMessage ? (
            <ContainerItem css={{ color: 'Red' }}>
              <code>{errorMessage}</code>
            </ContainerItem>
          ) : null}
        </>
      );
    }
  }

  async function handleMintpassDrop(acceptedFiles: any[], rejectedFiles: any[]) {
    setMintpassError('');
    setMintpass([]);
    setMerkleRoot('');
    const csv = await handleFileInput(acceptedFiles[0], 'mintpassEth');
    if (csv.mintpassEthError) {
      setMintpass([]);
      setMintpassError(csv.mintpassEthError);
    } else if (rejectedFiles[0]) {
      setMintpass([]);
      setMintpassError(rejectedFiles[0].errors[0].message);
    } else {
      console.log(csv.mintpassEth);
      const entries = csv.mintpassEth.map((entry: any[]) => {
        return {
          minter: entry[0].trim(),
          price: parseInt(dealWithEther(entry[1].toString()).toString()),
          maxCount: parseInt(entry[2]),
        };
      });
      try {
        const tree = makeTree(entries);
        setMerkleEntries(entries);
        setMerkleRoot(tree.root);
        console.log('entries', entries);
        console.log('tree', tree);
        const res = await addAllowlist({ entries, root: tree.root });
        if (res === 'error') {
          setIsAllowlistError(true);
          setMintpassError('There was an error processing your presale allowlist');
        } else {
          setIsAllowlistError(false);
          setMintpass(csv.mintpassEth);
          let values = [...inputValues];
          values[6] = tree.root;
          setInputValues(values);
          console.log(csv.mintpassEth);
        }
      } catch (e: any) {
        setIsAllowlistError(true);
        setMintpassError('There was an error processing your presale allowlist: ' + e.message);
      }
    }
  }

  return (
    <Container>
      <H2>{props.method}</H2>
      {props.selectedFunction.description ? (
        <ContainerItem>{props.selectedFunction.description}</ContainerItem>
      ) : null}
      <ContainerItem>
        *{props.method} is a <code>{props.selectedFunction.stateMutability}</code> function
      </ContainerItem>
      {/* {props.method === 'setSaleConfiguration' ? (
        <ContainerItem>
          <CsvDropzone
            onDrop={(acceptedFiles: [], rejectedFiles: []) =>
              handleMintpassDrop(acceptedFiles, rejectedFiles)
            }
            label="Presale (optional)"
            note="Upload a .csv file that includes the numbers of presale mints allowed per wallet address. 
                Each row must include a single wallet address, followed by an integer representing the
                number of mints allowed for that address, then the price (in ETH) of the mint."
            error={mintpassError}
            successText={
              mintpass.length ? `Success! ${mintpass.length} wallets added to presale` : ''
            }
            sample="presale.csv"
          />
        </ContainerItem>
      ) : null} */}
      <Args>{renderArgs()}</Args>
      <ContainerItem>
        <CallButton onClick={() => handleContractCall()}>{props.method}</CallButton>
      </ContainerItem>
      <ContainerItem css={{ wordWrap: 'break-word' }}>{renderProgress()}</ContainerItem>
    </Container>
  );
};
