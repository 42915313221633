import { Link, useHistory } from 'react-router-dom';
import {
  CardGrid,
  CollectionFlex,
  CollectionRow,
  ContainerFull,
  ContainerItem,
  ContainerItemNote,
  ContainerSection,
  EmptyDiv,
  FlexColumn,
  H1,
  H2,
  H3,
  H4,
  HeaderRow,
  ManageFlexColumn,
  StepSubtitle,
  StepTitle,
  TextLink,
  Thumbnail,
  Underline,
  Wrapper,
} from '../../../components/common';
import { useEffect, useState } from 'react';
import { useAtom } from 'jotai';
import { currentUserAddressAtom, deployedContractsAtom } from '../../../components/atoms';
import {
  basePath,
  dropAbi,
  ethDeployAddressGoerli,
  ethDeployAddressMainnet,
  etherscanUrlGeorli,
  etherscanUrlMainnet,
  ethNetwork,
} from '../../../utils/SelfServe/contracts';
import { EmptyCard, HoverIcon } from '../../../components/cards';
import { AppConfig, UserSession } from '@stacks/connect';
import makeEndpoint from '../../../endpoints';
import { CreateButton, CreateSmallButton } from '../../../components/buttons';
import { LabelInput } from '../../../components/label';
import { ethers } from 'ethers';
import { adminAddresses, ethAdopters } from '../../../components/Common/AdminRoute';
import { ToggleBlockchain } from '../../../components/toggle-group';
import { convertIPFS } from '../../../utils';
import { formatImageUrl } from '../../../utils/format-image-url';
import { useAccount, useNetwork, useProvider } from 'wagmi';
const appConfig = new AppConfig(['store_write', 'publish_data']);
const userSession = new UserSession({ appConfig });

function Manage() {
  const [currentUserAddress, setCurrentUserAddress] = useAtom(currentUserAddressAtom);
  const [deployedContracts, setDeployedContracts] = useAtom(deployedContractsAtom);
  const [contractsData, setContractsData] = useState<Record<string, any>>({});
  const [lookupContract, setLookupContract] = useState('');
  const [lookupContractError, setLookupContractError] = useState('');
  const [percentLoaded, setPercentLoaded] = useState(0);
  const [ethContracts, setEthContracts] = useState<any[]>([]);

  const history = useHistory();
  const { address, isConnected } = useAccount();
  const provider = useProvider();
  const network = useNetwork();

  useEffect(() => {
    async function fetchDeployedContracts() {
      // get last nonce so we know how many transactions to fetch
      const noncesUrl = `${basePath}/extended/v1/address/${currentUserAddress}/nonces`;
      let res = await fetch(noncesUrl);
      let json = await res.json();
      const lastNonce = json.last_executed_tx_nonce;

      let offset = 0;
      const transactions: any[] = [];
      for (let i = 0; i < lastNonce + 50; i += 50) {
        const transactionsUrl = `${basePath}/extended/v1/address/${currentUserAddress}/transactions?limit=50&offset=${offset}&tx_type=smart_contract`;
        res = await fetch(transactionsUrl);
        json = await res.json();
        transactions.push(...json.results);
        offset += 50;
        let percent = Math.trunc((offset / lastNonce) * 100);
        if (percent >= 100) {
          percent = 100;
        }
        setPercentLoaded(percent);
      }

      const contracts: string[] = [];
      for (let i = 0; i < transactions.length; i++) {
        const tx = transactions[i];
        if (tx.tx_type === 'smart_contract' && tx.tx_status === 'success') {
          const contractName = tx.smart_contract.contract_id.split('.')[1];
          contracts.push(contractName);
        }
      }
      setDeployedContracts(contracts);

      const fetchedData: any = {};
      for (let i = 0; i < contracts.length; i++) {
        const contractName = contracts[i];
        const res = await fetch(makeEndpoint('/api/drop?name=' + contractName.toLowerCase()));
        try {
          const json = await res.json();
          fetchedData[contractName] = json;
        } catch (error) {
          console.log(error);
        }
      }
      setContractsData(fetchedData);
    }

    if (deployedContracts && !deployedContracts.length) {
      fetchDeployedContracts();
    } else if (deployedContracts && deployedContracts.length) {
      for (let i = 0; i < deployedContracts.length; i++) {
        const contractName = deployedContracts[i];
        fetch(makeEndpoint('/api/drop?name=' + contractName.toLowerCase()))
          .then((res) => {
            return res.json();
          })
          .then((data) => {
            let fetchedContractsData = contractsData;
            fetchedContractsData[contractName] = data;
            setContractsData(fetchedContractsData);
          })
          .catch((err) => console.error(err));
      }
    }

    if (
      ethAdopters.includes(currentUserAddress) &&
      typeof window.ethereum !== 'undefined' &&
      network
    ) {
      fetchEthContracts();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUserAddress]);

  async function fetchEthContracts() {
    if (!isConnected || !address) {
      return;
    }
    console.log(isConnected, address, network.chain?.id)
    const etherscanProvider = new ethers.providers.EtherscanProvider(
      network.chain?.id === 5 ? 'goerli' : 'mainnet',
    );
    const history = await etherscanProvider.getHistory(address);
    console.log(history)
    const contracts = [];
    const ethDeployAddress =
      network.chain?.id === 5 ? ethDeployAddressGoerli : ethDeployAddressMainnet;
    for (let i = 0; i < history.length; i++) {
      const tx = history[i];
      const toAddress = tx.to;
      if (toAddress && toAddress === ethDeployAddress) {
        console.log(ethDeployAddress)
        console.log(tx.hash)
        console.log(provider)
        const receipt: any = await provider.getTransactionReceipt(tx.hash);
        console.log(receipt)
        if (receipt && receipt.logs && receipt.logs.length) {
          const address = receipt.logs[0].address;
          const contract = new ethers.Contract(address, dropAbi, provider);
          const collectionData = await fetchCollectionData(contract);
          contracts.push(collectionData);
        }
      }
    }
    setEthContracts(contracts);
  }

  async function fetchCollectionData(contract: ethers.Contract) {
    try {
      const contractUri: string = await contract.contractURI();
      const ipfs = convertIPFS(contractUri);
      const res = await fetch(ipfs);
      let json = await res.json();
      return {
        name: json.name,
        description: json.description,
        image: formatImageUrl({ url: json.image, size: 200 }),
        address: contract.address,
      };
    } catch (e) {
      console.log(e);
    }
  }

  function renderEthContracts() {
    return ethContracts.map((contract) => {
      const baseUri = network.chain?.id === 5 ? etherscanUrlGeorli : etherscanUrlMainnet;
      return (
        <div>
          <TextLink
            css={{ textDecoration: 'none' }}
            href={`/collection/${contract.address}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <CollectionRow>
              <CollectionRow>
                {contract.image ? <Thumbnail src={contract.image} alt="" /> : null}
                <div>{contract.name}</div>
              </CollectionRow>

              <CollectionRow>
                <TextLink
                  href={`${baseUri}/address/${contract.address}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Underline>View on Etherscan</Underline>
                </TextLink>
              </CollectionRow>
            </CollectionRow>
          </TextLink>
          {/* {contract.address} */}
        </div>
      );
    });
  }

  function renderDeployedContracts() {
    return deployedContracts.map((contract, i) => {
      return (
        <Link to={`/manage/${currentUserAddress}.${contract}`}>
          <CollectionRow>
            <CollectionRow>
              {contractsData[contract] && contractsData[contract].image ? (
                <Thumbnail src={contractsData[contract].image} alt="" />
              ) : null}
              <Link to={`/manage/${currentUserAddress}.${contract}`}>
                <div>{contract}</div>
              </Link>
            </CollectionRow>

            <CollectionRow>
              <Link to={`/manage/${currentUserAddress}.${contract}`}>
                <Underline>Marketplace</Underline>
              </Link>
              <Link to={`/collection/${contract}`}>
                <Underline>Mint page</Underline>
              </Link>
            </CollectionRow>
          </CollectionRow>
        </Link>
      );
    });
  }

  function handleLookupContractChange(e: React.ChangeEvent<HTMLInputElement>) {
    // setLookupContractError('');
    setLookupContract(e.target.value);
  }

  function handleLookupContractBlur() {
    if (lookupContract) {
      // const twitterCheck = isValidTwitter(twitter);
      // setTwitterError(twitterCheck.error);
    }
  }

  async function handleLookupContract() {
    // SP3D6PV2ACBPEKYJTCMH7HEN02KP87QSP8KTEH335.megapont-ape-club-nft

    const url = `${basePath}/extended/v1/contract/${lookupContract}`;
    const res = await fetch(url);
    const json = await res.json();

    setLookupContractError('');
    if (json.error) {
      setLookupContractError(json.error);
      return;
    }

    let currentUrlParams = new URLSearchParams(window.location.search);
    currentUrlParams.set('tab', 'AdvancedFunctions');
    // history.push(window.location.pathname + '?' + currentUrlParams.toString());
    history.push(`/manage/${lookupContract}?${currentUrlParams}`);
  }

  function handleBlockchain(value: string) {
    // if (value === 'ethereum') {
    //   // connect wallet
    // }
    // setBlockchain(value);

    console.log(ethContracts);

    if (!ethContracts.length) {
      fetchEthContracts();
    }
  }

  return (
    <ContainerFull>
      <H1 css={{ margin: '0' }}>Smart contract manager</H1>

      <ContainerItem>
        <H3>
          Use this page to manage blockchain-level functions for your collection's smart contract.
          To manage display information for your collections, visit the collection's page as an
          admin and click Manage near the collection's title.
        </H3>
      </ContainerItem>

      {adminAddresses.includes(currentUserAddress) && ethContracts && ethContracts.length ? (
        <ContainerSection>
          <HeaderRow>
            <StepTitle>My Ethereum Contracts</StepTitle>
            {/* <Link to="/create">
              <CreateButton text="Create new collection" />
            </Link> */}
          </HeaderRow>
          <ManageFlexColumn>{renderEthContracts()}</ManageFlexColumn>
        </ContainerSection>
      ) : null}

      <ContainerSection>
        <HeaderRow>
          <StepTitle>My Stacks Contracts</StepTitle>
          <Link to="/create">
            <CreateButton text="Create new collection" />
          </Link>
        </HeaderRow>

        {userSession.isUserSignedIn() ? (
          <ManageFlexColumn>
            {deployedContracts && deployedContracts.length ? (
              renderDeployedContracts()
            ) : (
              <div>Loading contracts ({percentLoaded}%)...</div>
            )}
          </ManageFlexColumn>
        ) : (
          <div>Connect your wallet to continue.</div>
        )}
      </ContainerSection>

      <ContainerSection>
        <ContainerItem>
          <StepTitle>Look up any Stacks contract</StepTitle>
        </ContainerItem>

        <StepSubtitle>
          Enter a collection's contract ID to view its public functions.{' '}
          <HoverIcon text="Sometimes referred to as Contract Name or Contract Key" />
        </StepSubtitle>

        <LabelInput
          placeholder="SP3D6PV2ACBPEKYJTCMH7HEN02KP87QSP8KTEH335.megapont-ape-club-nft"
          label="All functions will be displayed regardless of your permission to call them, so many
          functions may not apply to you."
          id="lookup"
          type="text"
          value={lookupContract}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleLookupContractChange(e)}
          onBlur={() => handleLookupContractBlur()}
          // error={lookupContractError}
        />

        {lookupContractError ? (
          <ContainerItem css={{ color: 'Red' }}>{lookupContractError}</ContainerItem>
        ) : null}

        <ContainerItem>
          <CreateSmallButton text="Find contract" onClick={() => handleLookupContract()} />
        </ContainerItem>
      </ContainerSection>
    </ContainerFull>
  );
}

export default Manage;
