import { useAtom } from 'jotai';
import { ContinueButton } from '../../../../components/buttons';
import {
  artistAddressAtom,
  assetsAtom,
  attributesAtom,
  categoryAtom,
  collectionNameAtom,
  contractIdAtom,
  currentUserAddressAtom,
  descriptionAtom,
  discordAtom,
  emailAddressAtom,
  filenamesEnabledAtom,
  licenseNameAtom,
  licenseUriAtom,
  logoAtom,
  logoEnlargedAtom,
  paymentOptionsAtom,
  tokenDescriptionsAtom,
  tokenNamesAtom,
  twitterAtom,
  websiteAtom,
} from '../../../../components/atoms';
import { deployPersonalContract } from '../../../../utils/SelfServe/contracts';
import { getContractName } from '../../../../utils/helpers';
import { Container, ContainerItem, TextLink } from '../../../../components/common';
import { submitPersonalToIpfs } from '../../../../utils/ipfs';
import { useState } from 'react';
import { handlePersonalCollection } from '../../../../utils/Studio/serverCalls';
import { Link } from 'react-router-dom';

function Publish(props: { onNextStep: any }) {
  const [collectionName, setCollectionName] = useAtom(collectionNameAtom);
  const [description, setDescription] = useAtom(descriptionAtom);
  const [emailAddress, setEmailAddress] = useAtom(emailAddressAtom);
  const [website, setWebsite] = useAtom(websiteAtom);
  const [twitter, setTwitter] = useAtom(twitterAtom);
  const [discord, setDiscord] = useAtom(discordAtom);
  const [logo, setLogo] = useAtom(logoAtom);
  const [currentUserAddress, setCurrentUserAddress] = useAtom(currentUserAddressAtom);
  const [category, setCategory] = useAtom(categoryAtom);
  const [logoEnlarged, setLogoEnlarged] = useAtom(logoEnlargedAtom);
  const [filenamesEnabled, setFilenamesEnabled] = useAtom(filenamesEnabledAtom);
  const [assets, setAssets] = useAtom(assetsAtom);
  const [contractId, setContractId] = useAtom(contractIdAtom);
  const [attributes, setAttributes] = useAtom(attributesAtom);
  const [tokenNames, setTokenNames] = useAtom(tokenNamesAtom);
  const [tokenDescriptions, setTokenDescriptions] = useAtom(tokenDescriptionsAtom);
  const [licenseUri, setLicenseUri] = useAtom(licenseUriAtom);
  const [licenseName, setLicenseName] = useAtom(licenseNameAtom);
  const [artistAddress, setArtistAddress] = useAtom(artistAddressAtom);

  const [progress, setProgress] = useState('');
  const [tx, setTx] = useState('');
  const [is_submitted, set_is_submitted] = useState(false);

  function handleProgress(progress: string) {
    setProgress(progress);
  }

  function handleOnSubmitFinish() {
    console.log('finished');
  }

  async function handlePublish() {
    const contractName = getContractName(collectionName);
    setContractId(currentUserAddress + '.' + contractName);

    let data = {
      collectionName,
      logo,
      filenamesEnabled,
      assets,
      attributes,
      tokenNames,
      tokenDescriptions,
      txId: '',
    };

    console.log(data);

    // do ipfs stuff
    const result = await submitPersonalToIpfs(data, handleProgress, handleOnSubmitFinish);

    if (!result) {
      return;
    }

    // deploy contract
    deployPersonalContract(
      contractName,
      artistAddress || currentUserAddress,
      licenseUri,
      licenseName,
      handleDeployFinish,
      handleDeployCancel,
      result.baseUris,
    );

    function handleDeployFinish(txUrl: string, txId: string) {
      console.log('deployed finished with url ' + txUrl);
      setProgress('Contract deployed.');
      setTx(txUrl);

      if (!result) {
        return;
      }

      const data = {
        contractName,
        collectionName,
        description,
        emailAddress,
        website,
        twitter,
        discord,
        category,
        artistAddress,
        logo,
        assets: [],
        fungibleSelected: [],
        placeholder: [],
        attributes: [],
        price: '',
        claimOptions: [],
        mintpass: [],
        airdrop: [],
        mintingEnabled: false,
        mintsPerAddress: '',
        visibilityEnabled: true,
        currentUserAddress,
        logoEnlarged,
        filenamesEnabled,
        txId,
      };

      handlePersonalCollection(data, result, txUrl);
      props.onNextStep(txUrl);
      document.body.scrollTop = document.documentElement.scrollTop = 0;
    }

    function handleDeployCancel() {
      setProgress('Deployment submitted. Please contact support@gamma.io for review and approval.');

      if (!result) {
        return;
      }

      const data = {
        contractName,
        collectionName,
        description,
        emailAddress,
        website,
        twitter,
        discord,
        category,
        artistAddress,
        logo,
        assets: [],
        fungibleSelected: [],
        placeholder: [],
        attributes: [],
        price: '',
        claimOptions: [],
        mintpass: [],
        airdrop: [],
        mintingEnabled: false,
        mintsPerAddress: '',
        visibilityEnabled: true,
        currentUserAddress,
        logoEnlarged,
        filenamesEnabled,
        txId: '',
      };

      set_is_submitted(true);

      handlePersonalCollection(data, result, '');
    }
  }

  function renderProgress() {
    if (tx) {
      return (
        <div>
          {progress}{' '}
          {/* <TextLink>
            <a href={tx} target="_blank" rel="noopener noreferrer">
              View your transaction.
            </a>
          </TextLink> */}
          <TextLink href={tx} target="_blank" rel="noopener noreferrer">
            View your transaction.
          </TextLink>
        </div>
      );
    } else {
      return <div>{progress}</div>;
    }
  }

  return (
    <Container>
      <h2>Publish your collection</h2>
      <div>
        Hit publish to save your details, upload your files to IPFS, and deploy your contract to
        mainnet.
      </div>
      <ContainerItem>
        <ContinueButton disabled={is_submitted} text="Publish" onClick={() => handlePublish()} />
      </ContainerItem>
      {renderProgress()}
    </Container>
  );
}

export default Publish;
