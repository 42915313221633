import React, { Component } from "react";
import Button from "../Button";
import styles from "./index.module.scss";

type MintNumberButtonsProps = {
  claimOptions: Array<any>;
  currencySelected: string;
  callback: any;
};
type MintNumberButtonsState = {
  numberSelected: number;
};

class MintNumberButtons extends Component<
  MintNumberButtonsProps,
  MintNumberButtonsState
> {
  state = {
    numberSelected: 1,
  };

  setNumberSelected(num: number) {
    this.setState({
      numberSelected: num,
    });

    this.props.callback(num);
  }

  renderNumberButtons() {
    if (
      this.props.claimOptions == null ||
      (this.props.claimOptions != null && this.props.claimOptions.length == 1)
    ) {
      return;
    }

    if (
      this.props.currencySelected == "BTC" ||
      this.props.currencySelected == "xBTC"
    ) {
      return;
    }

    let buttons: any = [];
    for (let i = 0; i < this.props.claimOptions.length; i++) {
      const option: any = this.props.claimOptions[i];
      const el = (
        <div style={{ margin: "10px" }} key={i}>
          <Button
            text={option["amount"].toString()}
            style={
              this.state.numberSelected == option["amount"]
                ? "PRIMARY"
                : "SECONDARY"
            }
            onClick={this.setNumberSelected.bind(this, option["amount"])}
          />
        </div>
      );
      buttons.push(el);
    }
    return <div style={{ display: "flex" }}>{buttons}</div>;
  }

  render() {
    return this.renderNumberButtons() || null;
  }
}

export default MintNumberButtons;
