import React, { Component, useEffect, useState } from 'react';
import styles from './index.module.scss';

import Button from '../../components/Button';
import makeEndpoint from '../../endpoints';
import CheckboxInput from '../../components/CheckboxInput';
import { Link } from 'react-router-dom';
import TextInput from '../../components/TextInput';
import TextAreaInput from '../../components/TextAreaInput';
import Dropdown from '../../components/Dropdown';
import SelfServeDropdown from '../../components/SelfServeDropdown';
import { getUSDInBAN, getUSDInSLIME } from '../../utils/SelfServe/contracts';

const axios = require('axios');
const fs = require('fs');

const testCollections = '../../testCollections/';

type AdminProps = {};
type AdminState = {
  value: string;
  collections: any;
  saveMessage: string;
  inputSaveMessage: string;
  saveIndex: number;
  showEditor: boolean;
  selectedEditor: number;
  fetchMessage: string;
};

function Admin() {
  const [value, setValue] = useState('');
  const [collections, setCollections] = useState([]);
  const [saveMessage, setSaveMessage] = useState('');
  const [inputSaveMessage, setInputSaveMessage] = useState('');
  const [saveIndex, setSaveIndex] = useState(0);
  const [showEditor, setShowEditor] = useState(false);
  const [selectedEditor, setSelectedEditor] = useState(0);
  const [fetchMessage, setFetchMessage] = useState('');

  useEffect(() => {
    try {
      fetch(makeEndpoint('/api/all'))
        .then((res) => {
          return res.json();
        })
        .then((json) => {
          setCollections(json);
        });
    } catch (error) {
      console.error(error);
    }
  }, []);

  async function handleFetchPrices() {
    setFetchMessage('fetching prices...');

    axios
      .get(makeEndpoint('/conversions'), {
        auth: {
          username: process.env.REACT_APP_LOGIN,
          password: process.env.REACT_APP_PASSWORD,
        },
      })
      .then((res: any) => {
        console.log(res.data);
        setFetchMessage('prices saved');
      })
      .catch((err: any) => {
        console.error(err);
        setFetchMessage('fetch failed');
      });
  }

  function changeEditor(e: React.ChangeEvent<HTMLInputElement>) {
    setShowEditor(!showEditor);
    setSelectedEditor(0);
  }

  function changeFeatured(index: number, e: React.ChangeEvent<HTMLInputElement>) {
    if (collections != null) {
      let _collections: any = collections;
      _collections[index]['featured'] = !_collections[index]['featured'];
      _collections[index]['lastModified'] = Date.now();
      setCollections(_collections);
    }
  }

  function changeActive(index: number, e: React.ChangeEvent<HTMLInputElement>) {
    if (collections != null) {
      let _collections: any = collections;
      _collections[index]['active'] = !_collections[index]['active'];
      _collections[index]['lastModified'] = Date.now();
      setCollections(_collections);
    }
  }

  function changeLightningEnabled(index: number, e: React.ChangeEvent<HTMLInputElement>) {
    if (collections != null) {
      let _collections: any = collections;
      _collections[index]['lightningEnabled'] = !_collections[index]['lightningEnabled'];
      _collections[index]['lastModified'] = Date.now();
      let altCurrencies = _collections[index]['altCurrencies'];
      if (_collections[index]['lightningEnabled']) {
        altCurrencies.push({
          symbol: 'BTC',
          price: '',
        });
        console.log(altCurrencies);
      } else {
        const pos = altCurrencies.findIndex((i: any) => i.symbol === 'BTC');
        console.log(pos);

        if (pos > -1) {
          console.log('splicing');
          altCurrencies.splice(pos, 1);
        }
        console.log(altCurrencies);
      }
      _collections[index]['altCurrencies'] = altCurrencies;
      setCollections(_collections);
    }
  }

  function changeName(index: number, e: React.ChangeEvent<HTMLInputElement>) {
    if (collections != null) {
      let _collections: any = collections;
      _collections[index]['name'] = e.target.value;
      setCollections(_collections);
    }
  }

  function changeDescription(index: number, e: React.ChangeEvent<HTMLTextAreaElement>) {
    if (collections != null) {
      let _collections: any = collections;
      _collections[index]['description'] = e.target.value;
      setCollections(_collections);
    }
  }

  function changeImage(index: number, e: React.ChangeEvent<HTMLInputElement>) {
    if (collections != null) {
      let _collections: any = collections;
      _collections[index]['image'] = e.target.value;
      setCollections(_collections);
    }
  }

  function changeWebsiteUrl(index: number, e: React.ChangeEvent<HTMLInputElement>) {
    console.log('change url');

    if (collections != null) {
      let _collections: any = collections;
      _collections[index]['websiteUrl'] = e.target.value;
      setCollections(_collections);
    }
  }

  function changeWebsiteText(index: number, e: React.ChangeEvent<HTMLInputElement>) {
    if (collections != null) {
      let _collections: any = collections;
      _collections[index]['websiteText'] = e.target.value;
      setCollections(_collections);
    }
  }

  function handleSave(index: number) {
    const url = makeEndpoint('/update');

    console.log('save ' + JSON.stringify(collections[index], null, 2));

    setSaveMessage('');

    axios
      .post(url, collections[index], {
        auth: {
          username: process.env.REACT_APP_LOGIN,
          password: process.env.REACT_APP_PASSWORD,
        },
      })
      .then((res: any) => {
        console.log(res.data);
        setSaveIndex(index);
        setSaveMessage(res.data);
      })
      .catch((err: any) => {
        console.error(err);
        setSaveIndex(index);
        setSaveMessage('save failed');
      });
  }

  function handleSaveInput(index: number) {
    const url = makeEndpoint('/update');

    console.log('save ' + JSON.stringify(collections[index], null, 2));
    setInputSaveMessage('');

    axios
      .post(url, collections[index], {
        auth: {
          username: process.env.REACT_APP_LOGIN,
          password: process.env.REACT_APP_PASSWORD,
        },
      })
      .then((res: any) => {
        console.log(res.data);
        setInputSaveMessage(res.data);
      })
      .catch((err: any) => {
        console.error(err);
        setInputSaveMessage('save failed');
      });
  }

  function copyText(principal: string) {
    console.log(principal);

    navigator.clipboard.writeText(principal);

    /* Alert the copied text */
    // alert("Copied the principal: " + principal);
  }

  function renderCollections() {
    let elements: any = [];
    if (collections != null) {
      collections.map((collection, i) => {
        const el = (
          <div key={i} className={styles.CollectionRow}>
            <div
              onClick={() =>
                copyText(collection['contractAddress'] + '.' + collection['contractName'])
              }
              className={styles.CollectionName}
            >
              {collection['name']}
            </div>
            <div className={styles.CollectionName}>
              <a href={collection['deployedTransactionUrl']}>contract</a>
            </div>
            <Link
              to={`/collection/${collection['contractName']}`}
              className={styles.CollectionName}
            >
              mint
            </Link>
            <div className={styles.Checkbox}>
              <CheckboxInput
                label="Featured"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => changeFeatured(i, e)}
                defaultChecked={collection['featured']}
              />
            </div>
            <div className={styles.Checkbox}>
              <CheckboxInput
                label="Active"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => changeActive(i, e)}
                defaultChecked={collection['active']}
              />
            </div>
            <div className={styles.Checkbox}>
              <CheckboxInput
                label="LN"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => changeLightningEnabled(i, e)}
                defaultChecked={collection['lightningEnabled']}
              />
            </div>
            <button className={styles.SaveButton} onClick={() => handleSave(i)}>
              Save
            </button>
            {saveIndex == i ? <div className={styles.SaveMessage}>{saveMessage}</div> : null}
          </div>
        );
        elements.push(el);
      });
    }
    return elements;
  }

  return (
    <div className={styles.HomePage}>
      <h1>Admin</h1>
      <div className={styles.CollectionRow}>
        <button className={styles.FetchPricesButton} onClick={() => handleFetchPrices()}>
          Fetch Prices
        </button>
        {fetchMessage != '' ? <div className={styles.SaveMessage}>{fetchMessage}</div> : null}
      </div>

      <div className={styles.Editor}>
        <div className={styles.EditorCheckbox}>
          <CheckboxInput
            label="Edit Collections"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => changeEditor(e)}
            defaultChecked={showEditor}
          />
        </div>
        {showEditor && (
          <div className={styles.InputsContainer}>
            <SelfServeDropdown
              items={collections.map((a: any) => a['contractName'])}
              setSelected={(e: any) => setSelectedEditor(e)}
              selected={selectedEditor}
            />
            <TextInput
              placeholder={'Collection Name'}
              error=""
              value={collections[selectedEditor]['name']}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => changeName(selectedEditor, e)}
            />
            <TextAreaInput
              placeholder={'Collection Description'}
              error={''}
              value={collections[selectedEditor]['description']}
              onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                changeDescription(selectedEditor, e)
              }
            />
            <TextInput
              placeholder={'Image Url'}
              error=""
              value={collections[selectedEditor]['image']}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => changeImage(selectedEditor, e)}
            />
            <TextInput
              placeholder={'Website Url'}
              error=""
              value={
                collections[selectedEditor]['websiteUrl'] == undefined
                  ? ''
                  : collections[selectedEditor]['websiteUrl']
              }
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                changeWebsiteUrl(selectedEditor, e)
              }
            />
            <TextInput
              placeholder={'Website Text'}
              error=""
              value={
                collections[selectedEditor]['websiteText'] == undefined
                  ? ''
                  : collections[selectedEditor]['websiteText']
              }
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                changeWebsiteText(selectedEditor, e)
              }
            />
            <button className={styles.SaveButton} onClick={() => handleSaveInput(selectedEditor)}>
              Save
            </button>
            <div className={styles.SaveMessage}>{inputSaveMessage}</div>
          </div>
        )}
      </div>
      {collections != null ? renderCollections() : null}
    </div>
  );
}

export default Admin;
