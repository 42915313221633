import React, { Component, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { CreateButton, HelpButton } from '../../../components/buttons';
import {
  ContainerItem,
  H1,
  H2,
  H3,
  QuickTip,
  Step,
  TextLink,
  Underline,
} from '../../../components/common';
import { LabelInput, LabelTextArea } from '../../../components/label';
import Footer from '../../../components/Studio/footer';
import { CheckCircleIcon, ClockIcon, HelpCircleIcon } from '../../../utils/svgs';
import styles from './index.module.scss';

export default function GettingStarted() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <div className={styles.BannerContainer}>
        <div className={styles.Banner}>
          <div className={styles.BannerTitle}>Let's get started</div>
          <div className={styles.BannerSubtitle}>
            These steps have to be done together, so only continue once everything is ready to go.
          </div>
        </div>
      </div>
      <div className={styles.Middle}>
        <Step>
          <div className={styles.StepTitle}>Step 1: Set up your collection</div>
          <div className={styles.StepSubtitle}>
            Collections are managed by smart contracts: blockchain apps that manage NFTs. In Step 1,
            you'll design the features of your contract. Features can't be added or removed later,
            so be sure to select what you need.
          </div>
          <QuickTip>
            <H2>Quick tips for designing your contract</H2>
            <div className={styles.Bullets}>
              <div className={styles.Bullet}>
                <ClockIcon /> This takes about 10 minutes
              </div>
              <div className={styles.Bullet}>
                <CheckCircleIcon /> You'll need your assets and metadata for the NFT ready (e.g.
                images and descriptions)
              </div>
              <div className={styles.Bullet}>
                <HelpCircleIcon />
                <span>
                  If you need help, visit the{' '}
                  <TextLink
                    href="https://support.gamma.io/hc/en-us/categories/5992643779987-Creator-Help-Center"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Creator Help Center
                  </TextLink>{' '}
                  before you begin
                </span>
              </div>
            </div>
          </QuickTip>
        </Step>

        <Step>
          <div className={styles.StepTitle}>Step 2: Deploy your contract to the network</div>
          <div className={styles.StepSubtitle}>
            Your contract must be deployed to the Stacks network, secured by Bitcoin. In Step 2,
            you'll send a transaction to do this. Once confirmed by the blockchain, your contract
            will be public.
          </div>
          <QuickTip>
            <H2>Quick tips for deploying your contract</H2>
            <div className={styles.Bullets}>
              <div className={styles.Bullet}>
                <ClockIcon /> This takes about 10 minutes, though can take longer based on
                blockchain speeds
              </div>
              <div className={styles.Bullet}>
                <CheckCircleIcon /> You'll need a Stacks wallet with enough STX to pay for network
                fees (usually less than 5 STX)
              </div>
              <div className={styles.Bullet}>
                <HelpCircleIcon />
                <span>
                  If you need help, visit{' '}
                  <TextLink href="https://hiro.so/wallet" target="_blank" rel="noopener noreferrer">
                    Hiro
                  </TextLink>{' '}
                  to download a browser wallet extension and buy STX
                </span>
              </div>
            </div>
          </QuickTip>
        </Step>

        <Step>
          <div className={styles.StepTitle}>Step 3: Request your mint page on Gamma</div>
          <div className={styles.StepSubtitle}>
            We need some information to setup your mint page and collection’s marketplace profile on
            Gamma. In Step 3, you’ll provide this information. This step is optional if you don’t
            plan to use a Gamma mint page.
          </div>
          <QuickTip>
            <H2>Quick tips for requesting your mint page</H2>
            <div className={styles.Bullets}>
              <div className={styles.Bullet}>
                <ClockIcon /> This takes about 10 minutes
              </div>
              <div className={styles.Bullet}>
                <CheckCircleIcon /> You'll need information about your collection and how collectors
                can stay in touch
              </div>
              <div className={styles.Bullet}>
                <HelpCircleIcon />
                <span>
                  If you need help, contact us using our{' '}
                  <TextLink
                    href="https://support.gamma.io/hc/en-us/requests/new?ticket_form_id=1500003299381"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Help Center
                  </TextLink>
                </span>
              </div>
            </div>
          </QuickTip>
        </Step>
      </div>
      <div className={styles.Bottom}>
        <div className={styles.Centered}>
          <H1>Ready to go?</H1>
          <div className={styles.StepSubtitle}>
            If you have everything you need from the steps above, connect your wallet and continue.
          </div>
        </div>

        <div className={styles.Centered}>
          <Link to="/create">
            <CreateButton text="Continue" />
          </Link>
        </div>

        <div className={styles.FinePrint}>
          By clicking to continue, you agree to our{' '}
          <Link to="/terms/creator-terms">
            <Underline>Creator Terms</Underline>
          </Link>
        </div>

        <div className={styles.Centered}>
          <H2>Need more help?</H2>
          <div className={styles.InlineBlock}>
            <a
              href="https://support.gamma.io/hc/en-us/categories/5992643779987-Creator-Help-Center"
              target="_blank"
              rel="noopener noreferrer"
            >
              <HelpButton text="Creator Help Center" />
            </a>
            <a
              href="https://support.gamma.io/hc/en-us/requests/new?ticket_form_id=1500003299381"
              target="_blank"
              rel="noopener noreferrer"
            >
              <HelpButton text="Support Request" />
            </a>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}
