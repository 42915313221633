const ipfsPrefix = 'https://images.gamma.io/ipfs/';

const SKIP_CDN_PATHS = ['api.megapont.com/robots', 'api.megapont.com/components'];

function getIpfsPrefix(path: string, _backup?: boolean) {
  return ipfsPrefix;
}

function constructPinataParams(width = 720, quality = 75, useWebp = true) {
  return `?img-width=${width}&img-fit=contain&img-quality=${quality}&img-onerror=redirect&img-fit=pad${
    useWebp ? '&img-format=webp' : ''
  }`;
}

export function _formatImageUrl(path: string, backup?: boolean) {
  if (path.startsWith('/')) return path;
  if (path.includes('stxnft.mypinata.cloud'))
    return path.replace('stxnft.mypinata.cloud', 'images.gamma.io');
  if (path.startsWith('ar://')) return path.replace('ar://', 'https://arweave.net/');
  if (path.startsWith('ipfs://ipfs/'))
    return path.replace('ipfs://ipfs/', getIpfsPrefix(path, backup));
  if (path.startsWith('ipfs://')) return path.replace('ipfs://', getIpfsPrefix(path, backup));
  return path;
}

function getSkipStatus(path: string) {
  let status = false;
  for (const skip of SKIP_CDN_PATHS) {
    if (path.includes(skip)) status = true;
  }
  return status;
}

export function formatImageUrl({
  url,
  isVideo,
  imageIsPixelated,
  contract_id,
  size,
  noParams,
}: {
  url: string;
  isVideo?: boolean;
  imageIsPixelated?: boolean;
  contract_id?: string;
  size?: number;
  noParams?: boolean;
}) {
  const rootUrl = `https://images.gamma.io/cdn-cgi/image/quality=100${
    size ? `,width=${size},height=${size}` : ''
  }/`;
  if (!url || getSkipStatus(url)) return url;
  if (isVideo || url.includes('.svg')) return _formatImageUrl(url);

  return rootUrl + _formatImageUrl(url);
}
