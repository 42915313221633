import React, { Component } from "react";
import styles from "./index.module.scss";
import { darkmodeListener } from "../../theme";
import makeEndpoint from "../../endpoints";
import icon from "../../components/Login/assets/icon.svg";
import { AppConfig, UserSession, showConnect } from "@stacks/connect";
import axios from "axios";

import {
  getAltPrice,
  getMintLimit,
  getMintpassEnabled,
  getMintpasses,
  getPaused,
  getPrice,
  getPublicSaleEnabled,
  getSTXInBTC,
} from "../../utils/SelfServe/contracts";
import MintPriceIcon from "../../components/MintPriceIcon";
import MintButton from "../../components/MintButton";
import MintCurrencyButtons from "../../components/MintCurrencyButtons";
import MintNumberButtons from "../../components/MintNumberButtons";

const appConfig = new AppConfig(["store_write", "publish_data"]);
const userSession = new UserSession({ appConfig });

type CollectionMintProps = {
  name: string;
};

type CollectionMintState = {
  contractAddress: string;
  contractName: string;
  total: number;
  lastTokenID: number;
  prices: number;
  currency?: string;
  active: boolean;
  paused: boolean;
  userData?: any;
  contractType: string;
  mintpassEnabled: boolean;
  saleEnabled: boolean;
  hasMintpasses: boolean;
  passesRemaining: number;
  collectionData: any;
  altCurrencies: Array<any>;
  currencySelected: string;
  numberSelected: number;
  claimOptions: Array<any>;
  fts: Array<any>;
  functionName: string;
  pairs?: any;
  lightningEnabled: boolean;
  btcAmount: number;
  alexCurrencies: Array<any>;
};

class CollectionMint extends Component<
  CollectionMintProps,
  CollectionMintState
> {
  state = {
    contractAddress: "",
    contractName: "",
    total: 0,
    lastTokenID: 0,
    prices: 0,
    currency: undefined,
    active: false,
    paused: false,
    userData: undefined,
    contractType: "",
    mintpassEnabled: false,
    saleEnabled: false,
    hasMintpasses: false,
    passesRemaining: 0,
    collectionData: undefined,
    altCurrencies: [],
    currencySelected: "STX",
    numberSelected: 1,
    claimOptions: [],
    fts: [],
    functionName: "",
    pairs: undefined,
    lightningEnabled: false,
    btcAmount: -1,
    alexCurrencies: [] as any,
  };

  componentDidMount() {
    try {
      const userData = localStorage.getItem("userData");
      if (userData) {
        this.setState({
          userData: JSON.parse(userData),
        });
      }
    } catch {}

    darkmodeListener(styles["theme-light"], styles["theme-dark"]);

    fetch(makeEndpoint("/api/drop?name=" + this.props.name))
      .then((res) => {
        return res.json();
      })
      .then((artistData) => {
        console.log(artistData);
        this.setState(
          {
            contractAddress: artistData.contractAddress,
            contractName: artistData.contractName,
            total: artistData.total,
            prices: artistData.prices,
            active: artistData.active,
            paused: artistData.paused,
            currency: artistData.currency,
            contractType: artistData.contractType,
            collectionData: artistData,
            saleEnabled: artistData.saleEnabled,
            altCurrencies: artistData.altCurrencies,
            claimOptions: artistData.claimOptions,
            lightningEnabled: artistData.lightningEnabled,
            alexCurrencies: artistData.alexCurrencies,
          },
          () => {
            if (artistData.lightningEnabled) {
              fetch("https://api.lnswap.org:9007/getpairs")
                .then((res) => {
                  console.log(res);
                  console.log("response received");
                  return res.json();
                })
                .then((pairs) => {
                  console.log("parsed JSON");
                  console.log(pairs);
                  this.setState({ pairs });
                });
            }

            if (Number(artistData.prices) >= 50000000) {
              const amount = Number(this.amount());
              if (isNaN(amount)) {
                return;
              }

              getSTXInBTC(amount / 1e6).then((res) =>
                this.setState({ btcAmount: res * 1.04 })
              );
            }

            if (
              artistData.contractType == "mintpass" &&
              this.stxAddress() != null &&
              !artistData.saleEnabled
            ) {
              getMintpassEnabled(
                artistData.contractAddress,
                artistData.contractName,
                this.setMintpassEnabled.bind(this)
              );

              getPublicSaleEnabled(
                artistData.contractAddress,
                artistData.contractName,
                this.setPublicSaleEnabled.bind(this)
              );

              getMintpasses(
                artistData.contractAddress,
                artistData.contractName,
                this.stxAddress(),
                this.setMintpasses.bind(this)
              );
            }

            getPrice(
              artistData.contractAddress,
              artistData.contractName,
              this.setPrice.bind(this)
            );

            getMintLimit(
              artistData.contractAddress,
              artistData.contractName,
              this.setTotal.bind(this)
            );

            getPaused(
              artistData.contractAddress,
              artistData.contractName,
              this.setPaused.bind(this)
            );

            if (
              artistData.altCurrencies != null &&
              artistData.altCurrencies.length > 0
            ) {
              let altInputs: Array<any> = [];
              let altErrors: Array<any> = [];

              for (let i = 0; i < artistData.altCurrencies.length; i++) {
                const currency = artistData.altCurrencies[i];
                const input = { symbol: currency["symbol"], input: "" };
                const error = { symbol: currency["symbol"], error: "" };
                altInputs.push(input);
                altErrors.push(error);
                getAltPrice(
                  artistData.contractAddress,
                  artistData.contractName,
                  currency["symbol"].toLowerCase(),
                  this.setAltPrice.bind(this)
                );
              }
            }

            if (
              artistData.altCurrencies != null &&
              artistData.altCurrencies.length > 0 &&
              artistData.claimOptions != null &&
              artistData.claimOptions.length > 0 &&
              !artistData.claimOptions[0]["STX"]
            ) {
              this.setState({
                currencySelected: artistData.altCurrencies[0]["symbol"],
              });
            }

            fetch(
              makeEndpoint(
                "/api/nft/lastTokenID?address=" +
                  artistData.contractAddress +
                  "&name=" +
                  artistData.contractName +
                  "&reload=true"
              )
            );

            fetch(
              makeEndpoint(
                "/api/nft/lastTokenID?address=" +
                  artistData.contractAddress +
                  "&name=" +
                  artistData.contractName
              )
            )
              .then((res) => {
                return res.text();
              })
              .then((lastTokenStr) => {
                if (lastTokenStr === "null") {
                  return;
                }
                const lastToken = parseInt(lastTokenStr, 10);
                this.setState({
                  lastTokenID: lastToken,
                });
              });
          }
        );
      });

    fetch(makeEndpoint("/api/fts"))
      .then((res) => {
        return res.json();
      })
      .then((json) => {
        this.setState({ fts: json.tokens });
      })
      .catch((err) => console.log(err));
  }

  syncCollectionData() {
    let data: any = this.state.collectionData;
    if (data) {
      data["prices"] = this.state.prices;
      data["total"] = this.state.total;
      data["paused"] = this.state.paused;
      data["saleEnabled"] = this.state.saleEnabled;
      data["altCurrencies"] = this.state.altCurrencies;
    }

    const url = makeEndpoint("/update");
    axios
      .post(url, data, {
        auth: {
          username: process.env.REACT_APP_LOGIN
            ? process.env.REACT_APP_LOGIN
            : "",
          password: process.env.REACT_APP_PASSWORD
            ? process.env.REACT_APP_PASSWORD
            : "",
        },
      })
      .then(() => {
        console.log("Collection saved");
      })
      .catch((err: any) => {
        console.error(err);
      });
  }

  setAltPrice(alt: any) {
    let currencies: Array<object> = this.state.altCurrencies;
    const isAlt = (element: any) => element["symbol"] == alt["symbol"];
    const altIndex = currencies.findIndex(isAlt);
    if (altIndex != -1) {
      const currency: any = currencies[altIndex];
      const price = currency["price"];
      if (price != alt["price"]) {
        console.log(alt["symbol"] + " alt price difference");

        currencies[altIndex] = alt;
        this.setState({
          altCurrencies: currencies,
        });
        this.syncCollectionData();
      }
    }
  }

  setTotal(total: number) {
    if (this.state.total != total) {
      console.log("total different");
      this.setState({
        total: total,
      });
      this.syncCollectionData();
    }
  }

  setPrice(price: number) {
    if (this.state.prices != price) {
      console.log("price different");
      this.setState({
        prices: price,
      });
      this.syncCollectionData();
    }
  }

  setPaused(paused: boolean) {
    if (this.state.paused != paused) {
      console.log("paused different");
      this.setState({
        paused: paused,
      });
      this.syncCollectionData();
    }
  }

  setMintpassEnabled(enabled: boolean) {
    this.setState({
      mintpassEnabled: enabled,
    });
  }

  setPublicSaleEnabled(enabled: boolean) {
    if (enabled && !this.state.saleEnabled) {
      this.setState({
        saleEnabled: enabled,
      });
      this.syncCollectionData();
    } else {
      this.setState({
        saleEnabled: enabled,
      });
    }
  }

  setMintpasses(passes: any) {
    console.log(`${this.stxAddress()} has ${passes} mintpasses`);

    this.setState({
      hasMintpasses: passes > 0,
      passesRemaining: passes,
    });
  }

  getRemaining() {
    return this.state.total - this.state.lastTokenID;
  }

  getPriceInStx() {
    return this.state.prices / 1e6;
  }

  amount() {
    let price = this.state.prices;
    if (
      this.state.altCurrencies != null &&
      this.state.altCurrencies.length > 0 &&
      this.state.currencySelected != "STX"
    ) {
      for (let i = 0; i < this.state.altCurrencies.length; i++) {
        const currency = this.state.altCurrencies[i];
        if (currency["symbol"] == this.state.currencySelected) {
          price = currency["price"];
          break;
        }
      }
    }
    return price * this.state.numberSelected;
  }

  stxAddress() {
    if (this.state.userData) {
      const userData: any = this.state.userData;
      return userData.profile.stxAddress.mainnet;
    } else {
      return null;
    }
  }

  authenticate() {
    showConnect({
      appDetails: {
        name: "Stacks NFT",
        icon: icon,
      },
      redirectTo: "/",
      onFinish: () => {
        let userData = userSession.loadUserData();
        this.setState({ userData: userData });
        localStorage.setItem("userData", JSON.stringify(userData));
        window.location.reload();
      },
      userSession: userSession,
    });
  }

  numberSelectedCallback(num: number) {
    this.setState({
      numberSelected: num,
    });
  }

  currencySelectedCallback(currency: string) {
    this.setState({
      currencySelected: currency,
    });

    if (currency == "xBTC") {
      this.setState({
        numberSelected: 1,
      });
    }
  }

  render() {
    return (
      <div className={styles.Collections}>
        <div className={styles.Mint}>
          {this.state.active &&
            this.state.lastTokenID < this.state.total &&
            this.state.passesRemaining > 0 &&
            this.state.mintpassEnabled && (
              <div className={styles.MintpassBalanceContainer}>
                <div className={styles.MintpassBalance}>
                  You have {this.state.passesRemaining} mintpasses remaining
                </div>
              </div>
            )}

          {this.state.active &&
          !this.state.paused &&
          this.state.lastTokenID <= this.state.total &&
          this.state.userData ? (
            <>
              {
                <MintCurrencyButtons
                  altCurrencies={this.state.altCurrencies}
                  prices={this.state.prices}
                  callback={this.currencySelectedCallback.bind(this)}
                />
              }
              {
                <MintNumberButtons
                  claimOptions={this.state.claimOptions}
                  currencySelected={this.state.currencySelected}
                  callback={this.numberSelectedCallback.bind(this)}
                />
              }
              <div style={{ margin: "5px" }}></div>
              {
                <MintButton
                  contractAddress={this.state.contractAddress}
                  contractName={this.state.contractName}
                  userAddress={this.stxAddress()}
                  active={this.state.active}
                  paused={this.state.paused}
                  lastTokenId={this.state.lastTokenID}
                  total={this.state.total}
                  contractType={this.state.contractType}
                  mintpassEnabled={this.state.mintpassEnabled}
                  saleEnabled={this.state.saleEnabled}
                  hasMintpasses={this.state.hasMintpasses}
                  numberSelected={this.state.numberSelected}
                  currencySelected={this.state.currencySelected}
                  amount={this.amount()}
                  claimOptions={this.state.claimOptions}
                  prices={this.state.prices}
                  btcAmount={this.state.btcAmount}
                  fts={this.state.fts}
                  userData={this.state.userData}
                  pairs={this.state.pairs}
                  authenticateCallback={this.authenticate.bind(this)}
                />
              }
            </>
          ) : (
            <div>
              {
                <MintButton
                  contractAddress={this.state.contractAddress}
                  contractName={this.state.contractName}
                  userAddress={this.stxAddress()}
                  active={this.state.active}
                  paused={this.state.paused}
                  lastTokenId={this.state.lastTokenID}
                  total={this.state.total}
                  contractType={this.state.contractType}
                  mintpassEnabled={this.state.mintpassEnabled}
                  saleEnabled={this.state.saleEnabled}
                  hasMintpasses={this.state.hasMintpasses}
                  numberSelected={this.state.numberSelected}
                  currencySelected={this.state.currencySelected}
                  amount={this.amount()}
                  claimOptions={this.state.claimOptions}
                  prices={this.state.prices}
                  btcAmount={this.state.btcAmount}
                  fts={this.state.fts}
                  userData={this.state.userData}
                  pairs={this.state.pairs}
                  authenticateCallback={this.authenticate.bind(this)}
                />
              }
            </div>
          )}

          <div className={styles.MintPrice}>
            <div className={styles.MintPriceContainer}>
              <div>{this.state.total.toLocaleString("us")}</div>
              <div className={styles.MintPriceTitle}>TOTAL</div>
            </div>
            <div className={styles.MintPriceContainer}>
              <div>{this.getRemaining().toLocaleString("us")}</div>
              <div className={styles.MintPriceTitle}>REMAINING</div>
            </div>
            <div className={styles.MintPriceContainer}>
              <div className={styles.MintPriceIconContainer}>
                {!this.state.currency && <MintPriceIcon />}
                <div>{this.getPriceInStx().toLocaleString("us")}</div>
              </div>
              <div className={styles.MintPriceTitle}>MINT PRICE</div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CollectionMint;
