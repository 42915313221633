import React, { Component } from "react";
import styles from "./index.module.scss";

import Button from "../Button";

import { darkmodeListener } from "../../theme";

type ListAuctionModalProps = {
  onClose: () => void;
  buttonText?: string;
  tokenId: number;
  title: string;
};

type ListAuctionModalState = {};

class ListAuctionModal extends Component<
  ListAuctionModalProps,
  ListAuctionModalState
> {
  componentDidMount() {
    darkmodeListener(styles["theme-light"], styles["theme-dark"]);
  }

  render() {
    return (
      <div className={styles.Confirm}>
        <div className={styles.ConfirmTitle}>{this.props.title}</div>
        {this.props.children}
        <div className={styles.ConfirmContainerButton}>
          <Button
            text={
              this.props.buttonText ? this.props.buttonText : "List Auction"
            }
            onClick={this.props.onClose.bind(this, true)}
          />
        </div>
      </div>
    );
  }
}

export default ListAuctionModal;
