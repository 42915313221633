import { createStitches, createTheme } from '@stitches/react';

export const { styled, globalCss, theme } = createStitches({
  theme: {
    colors: {
      text: 'black',
      background: 'white',
      border: '#E1E5E6',
      stepBackground: '#eee',
    },
  },
});

export const darkTheme = createTheme({
  colors: {
    text: 'white',
    background: '#0b0b0b',
    border: '#1c1d1e',
    stepBackground: '#333',
  },
});
