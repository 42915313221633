import { styled } from '@stitches/react';
import { useAtom } from 'jotai';
import {
  airdropAtom,
  artistAddressAtom,
  assetsAtom,
  categoryAtom,
  claimOptionsAtom,
  collectionNameAtom,
  currentUserAddressAtom,
  descriptionAtom,
  emailAddressAtom,
  fungibleSelectedAtom,
  logoAtom,
  mintingEnabledAtom,
  mintpassAtom,
  mintpassEndUnixAtom,
  mintpassStartUnixAtom,
  mintsPerAddressAtom,
  paymentOptionsAtom,
  placeholderAtom,
  priceAtom,
  publicStartUnixAtom,
  royaltyAtom,
  twitterAtom,
  userAtom,
  visibilityEnabledAtom,
  websiteAtom,
} from '../../../../components/atoms';
import { ContinueButton } from '../../../../components/buttons';
import { EthCard, ReviewCard } from '../../../../components/cards';
import { CardGrid, Container, ContainerItem, Logo } from '../../../../components/common';

const Details = styled('div', {
  justifyContent: 'space-between',
  margin: '50px 0',

  variants: {
    display: {
      hasFlex: { display: 'flex' },
      noFlex: { display: '' },
    },
  },
});

const DetailsLeft = styled('div', {
  width: '40%',
  display: 'flex',
  flexDirection: 'column',
  gap: '20px',
  overflowWrap: 'break-word',
});

const DetailsRight = styled('div', {
  textAlign: 'right',
});

const Stats = styled('div', {
  height: 'fit-content',
  display: 'flex',
  overflowWrap: 'break-word',
  border: '1px solid #dcdde2',
  borderRadius: '16px',
  padding: 20,
});

const CollectionName = styled('div', {
  fontSize: '32px',
});

const Links = styled('div', {
  display: 'flex',
  gap: '10px',
});

const Stat = styled('div', {
  padding: '0 30px',
  borderRight: '1px solid #dcdde2',
  textAlign: 'center',
  display: 'flex',
  flexDirection: 'column',
  gap: '6px',
  '&:last-child': {
    borderRight: 'none',
    paddingRight: 'none',
  },
});

const AdditionalDetails = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',
  margin: '20px 0',
});

function Review(props: { onNextStep: any }) {
  const [assets, setAssets] = useAtom(assetsAtom);
  const [logo, setLogo] = useAtom(logoAtom);
  const [collectionName, setCollectionName] = useAtom(collectionNameAtom);
  const [description, setDescription] = useAtom(descriptionAtom);
  const [website, setWebsite] = useAtom(websiteAtom);
  const [twitter, setTwitter] = useAtom(twitterAtom);
  const [artistAddress, setArtistAddress] = useAtom(artistAddressAtom);
  const [price, setPrice] = useAtom(priceAtom);
  const [emailAddress, setEmailAddress] = useAtom(emailAddressAtom);
  const [paymentOptions, setPaymentOptions] = useAtom(paymentOptionsAtom);
  const [claimOptions, setClaimOptions] = useAtom(claimOptionsAtom);
  const [mintingEnabled, setMintingEnabled] = useAtom(mintingEnabledAtom);
  const [mintsPerAddress, setMintsPerAddress] = useAtom(mintsPerAddressAtom);
  const [visibilityEnabled, setVisibilityEnabled] = useAtom(visibilityEnabledAtom);
  const [placeholder, setPlaceholder] = useAtom(placeholderAtom);
  const [mintpass, setMintpass] = useAtom(mintpassAtom);
  const [airdrop, setAirdrop] = useAtom(airdropAtom);
  const [category, setCategory] = useAtom(categoryAtom);
  const [user, setUser] = useAtom(userAtom);
  const [currentUserAddress, setCurrentUserAddress] = useAtom(currentUserAddressAtom);
  const [fungibleSelected, setFungibleSelected] = useAtom(fungibleSelectedAtom);
  const [mintpassStartUnix, setMintpassStartUnix] = useAtom(mintpassStartUnixAtom);
  const [mintpassEndUnix, setMintpassEndUnix] = useAtom(mintpassEndUnixAtom);
  const [publicStartUnix, setPublicStartUnix] = useAtom(publicStartUnixAtom);
  const [royalty, setRoyalty] = useAtom(royaltyAtom);

  function renderCards() {
    // return assets.map((asset: any, idx: number) => {
    //   const token = {
    //     image: asset.preview,
    //     data: { properties: { collection: collectionName }, name: `${collectionName} #${idx + 1}` },
    //   };
    //   return <EthCard token={token} />;
    // });
    return null;
  }

  function renderPaymentOptions() {
    return fungibleSelected.map((item: any, idx: number) => {
      if (item.selected) {
        return (
          <span>
            {item.symbol.toUpperCase()}
            {idx < fungibleSelected.length - 1 ? ', ' : null}
          </span>
        );
      }
      return null;
    });
  }

  function renderClaimOptions() {
    return claimOptions.map((option: any, idx: number) => {
      return (
        <span>
          {option}
          {idx < claimOptions.length - 1 ? ', ' : null}
        </span>
      );
    });
  }

  function handleContinue() {
    props.onNextStep();
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  }

  function getDate(unix: number) {
    const date = new Date(unix * 1000).toLocaleDateString();
    const time = new Date(unix * 1000).toLocaleTimeString();
    return `${date} at ${time}`;
  }

  return (
    <>
      <Container>
        <h2>Review your collection</h2>
        <ContainerItem>
          <AdditionalDetails>
            <div>
              Mints per address: {parseInt(mintsPerAddress) > 0 ? mintsPerAddress : 'Unlimited'}
            </div>
            <div>Total mintpasses: {mintpass.length}</div>
            <div>Resale royalty: {royalty || 5}%</div>
            <div>Email address: {emailAddress}</div>
            {artistAddress ? <div>Artist address: {artistAddress}</div> : null}
            {twitter ? <div>Twitter: {twitter}</div> : null}
            {website ? <div>Website: {website}</div> : null}
            {category ? <div>Category: {category}</div> : null}
            {mintpassStartUnix ? <div>Presale start: {getDate(mintpassStartUnix)}</div> : null}
            {mintpassEndUnix ? <div>Presale end: {getDate(mintpassEndUnix)}</div> : null}
            {publicStartUnix ? <div>Public sale start: {getDate(publicStartUnix)}</div> : null}
          </AdditionalDetails>
        </ContainerItem>

        <ContainerItem>
          <ContinueButton disabled={false} text="Continue" onClick={() => handleContinue()} />
        </ContainerItem>
      </Container>
      <Details
        display={{
          '@initial': 'noFlex',
          '@media (min-width: 620px)': 'hasFlex',
        }}
      >
        <DetailsLeft>
          {logo.length ? <Logo src={logo[0].preview} /> : null}
          <CollectionName>{collectionName}</CollectionName>
          <div>{description}</div>
          <Links>
            {website ? (
              <span>
                <a href={website} target="_blank" rel="noopener noreferrer">
                  {website.split('//')[1]}
                </a>{' '}
              </span>
            ) : null}
            {twitter ? (
              <span>
                <a href={'https://' + twitter} target="_blank" rel="noopener noreferrer">
                  @{twitter.split('/')[1]}
                </a>{' '}
              </span>
            ) : null}
          </Links>
        </DetailsLeft>
        <DetailsRight>
          <Stats>
            <Stat>
              <div>Items</div>
              <div>{assets.length}</div>
            </Stat>
            <Stat>
              <div>Holders</div>
              <div>0</div>
            </Stat>
            <Stat>
              <div>Floor Price</div>
              <div>{price} STX</div>
            </Stat>
            <Stat>
              <div>Sales</div>
              <div>0 STX</div>
            </Stat>
          </Stats>
        </DetailsRight>
      </Details>

      <CardGrid>{renderCards()}</CardGrid>
    </>
  );
}

export default Review;
