import React, { Component } from "react";
import Button from "../Button";
import styles from "./index.module.scss";

type MintCurrencyButtonsProps = {
  altCurrencies: Array<any>;
  prices: number;
  callback: any;
};
type MintCurrencyButtonsState = {
  currencySelected: string;
};

class MintCurrencyButtons extends Component<
  MintCurrencyButtonsProps,
  MintCurrencyButtonsState
> {
  state = {
    currencySelected: "STX",
  };

  setCurrencySelected(currency: string) {
    this.setState({
      currencySelected: currency,
    });

    this.props.callback(currency);
  }

  renderCurrencyButtons() {
    let altButtons: any = [];
    if (
      this.props.altCurrencies != null &&
      this.props.altCurrencies.length > 0
    ) {
      for (let i = 0; i < this.props.altCurrencies.length; i++) {
        const currency = this.props.altCurrencies[i];
        let symbol: string = currency["symbol"];
        if (symbol == "BTC") {
          symbol = "BTC (LN)";
        }
        const el = (
          <div style={{ margin: "10px" }} key={i + 1}>
            <Button
              text={symbol}
              style={
                this.state.currencySelected == currency["symbol"]
                  ? "PRIMARY"
                  : "SECONDARY"
              }
              onClick={this.setCurrencySelected.bind(this, currency["symbol"])}
            />
          </div>
        );
        altButtons.push(el);
      }
    }

    let alexButtons: any = [];

    if (this.props.prices >= 50000000) {
      let currency = "xBTC";
      let symbol = "xBTC (with ALEX)";
      const el = (
        <div style={{ margin: "10px" }}>
          <Button
            text={symbol}
            style={
              this.state.currencySelected == currency ? "PRIMARY" : "SECONDARY"
            }
            onClick={this.setCurrencySelected.bind(this, currency)}
          />
        </div>
      );
      alexButtons.push(el);
    }

    if (altButtons.length > 0 || alexButtons.length > 0) {
      let buttons: any = [];

      let stx = (
        <div style={{ margin: "10px" }} key={0}>
          <Button
            text="STX"
            style={
              this.state.currencySelected == "STX" ? "PRIMARY" : "SECONDARY"
            }
            onClick={this.setCurrencySelected.bind(this, "STX")}
          />
        </div>
      );

      buttons.push(stx);
      buttons.push(altButtons);
      buttons.push(alexButtons);
      return <div style={{ display: "flex" }}>{buttons}</div>;
    } else {
      return null;
    }
  }

  render() {
    return this.renderCurrencyButtons() || null;
  }
}

export default MintCurrencyButtons;
