import React, { Component } from "react";
import styles from "./index.module.scss";

import { darkmodeListener } from "../../theme";

type MintPriceIconProps = {};
type MintPriceIconState = {};

class MintPriceIcon extends Component<MintPriceIconProps, MintPriceIconState> {
  componentDidMount() {
    darkmodeListener(styles["theme-light"], styles["theme-dark"]);
  }

  render() {
    return (
      <div className={styles.MintPriceIcon}>
        <svg
          width={"15px"}
          height={"18px"}
          viewBox={"0 0 15 18"}
          version={"1.1"}
          xmlns={"http://www.w3.org/2000/svg"}
        >
          <g
            stroke={"none"}
            strokeWidth={"1"}
            fill={"none"}
            fillRule={"evenodd"}
          >
            <g
              transform={"translate(-889.000000, -497.000000)"}
              stroke={"#707071"}
            >
              <g transform={"translate(528.000000, 492.000000)"}>
                <g transform={"translate(361.000000, 6.000000)"}>
                  <g>
                    <line
                      x1={"3.84615385"}
                      y1={"6.58823529"}
                      x2={"13.0769231"}
                      y2={"6.58823529"}
                    ></line>
                    <line
                      x1={"-9.60769925e-17"}
                      y1={"6.17647059"}
                      x2={"6.15384615"}
                      y2={"6.17647059"}
                      strokeWidth={"2"}
                    ></line>
                    <line
                      x1={"1.53846154"}
                      y1={"6.17647059"}
                      x2={"6.15384615"}
                      y2={"6.17647059"}
                      strokeWidth={"2"}
                      strokeLinecap={"round"}
                    ></line>
                    <line
                      x1={"8.46153846"}
                      y1={"6.17647059"}
                      x2={"13.0769231"}
                      y2={"6.17647059"}
                      strokeWidth={"2"}
                      strokeLinecap={"round"}
                    ></line>
                    <line
                      x1={"8.46153846"}
                      y1={"6.17647059"}
                      x2={"14.6153846"}
                      y2={"6.17647059"}
                      strokeWidth={"2"}
                    ></line>
                    <line
                      x1={"2.30769231"}
                      y1={"0.411764706"}
                      x2={"6.15384615"}
                      y2={"6.17647059"}
                      strokeWidth={"2"}
                      strokeLinejoin={"round"}
                    ></line>
                    <line
                      x1={"8.46153846"}
                      y1={"0.411764706"}
                      x2={"12.3076923"}
                      y2={"6.17647059"}
                      strokeWidth={"2"}
                      strokeLinejoin={"round"}
                      transform={
                        "translate(10.384615, 3.294118) scale(-1, 1) translate(-10.384615, -3.294118) "
                      }
                    ></line>
                  </g>
                  <g
                    transform={
                      "translate(7.500000, 12.500000) scale(1, -1) translate(-7.500000, -12.500000) translate(0.000000, 9.000000)"
                    }
                  >
                    <line
                      x1={"3.84615385"}
                      y1={"6.58823529"}
                      x2={"13.0769231"}
                      y2={"6.58823529"}
                    ></line>
                    <line
                      x1={"-9.60769925e-17"}
                      y1={"6.17647059"}
                      x2={"6.15384615"}
                      y2={"6.17647059"}
                      strokeWidth={"2"}
                    ></line>
                    <line
                      x1={"1.53846154"}
                      y1={"6.17647059"}
                      x2={"6.15384615"}
                      y2={"6.17647059"}
                      strokeWidth={"2"}
                      strokeLinecap={"round"}
                    ></line>
                    <line
                      x1={"8.46153846"}
                      y1={"6.17647059"}
                      x2={"13.0769231"}
                      y2={"6.17647059"}
                      strokeWidth={"2"}
                      strokeLinecap={"round"}
                    ></line>
                    <line
                      x1={"8.46153846"}
                      y1={"6.17647059"}
                      x2={"14.6153846"}
                      y2={"6.17647059"}
                      strokeWidth={"2"}
                    ></line>
                    <line
                      x1={"2.30769231"}
                      y1={"0.411764706"}
                      x2={"6.15384615"}
                      y2={"6.17647059"}
                      strokeWidth={"2"}
                      strokeLinejoin={"round"}
                    ></line>
                    <line
                      x1={"8.46153846"}
                      y1={"0.411764706"}
                      x2={"12.3076923"}
                      y2={"6.17647059"}
                      strokeWidth={"2"}
                      strokeLinejoin={"round"}
                      transform={
                        "translate(10.384615, 3.294118) scale(-1, 1) translate(-10.384615, -3.294118) "
                      }
                    ></line>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </svg>
      </div>
    );
  }
}

export default MintPriceIcon;
