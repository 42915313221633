import { useAtom } from 'jotai';
import React, { Component, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  artistAddressAtom,
  collectionNameAtom,
  contractIdAtom,
  currentUserAddressAtom,
  discordAtom,
  emailAddressAtom,
  twitterAtom,
  websiteAtom,
} from '../../../components/atoms';
import { ContinueButton, CreateButton } from '../../../components/buttons';
import {
  Container,
  ContainerItem,
  ContainerSection,
  Divider,
  H1,
  H2,
  H3,
  H4,
  TextLink,
  Wrapper,
} from '../../../components/common';
import { LabelInput, LabelTextArea } from '../../../components/label';
import { SwitchEnlargedImage, SwitchGeneric } from '../../../components/switch';
import { getContractParts } from '../../../utils/helpers';
import {
  isValidContactInfo,
  isValidDescription2,
  isValidDiscordInvite,
  isValidDiscordUsername,
  isValidDomain,
  isValidExplanation,
  isValidFullName,
  isValidName,
  isValidTelegram,
  isValidTwitter,
} from '../../../utils/SelfServe/validation';
import { getMintRequest, handleMintRequest } from '../../../utils/Studio/serverCalls';
import styles from './index.module.scss';

const axios = require('axios');

export default function MintRequest(props: {
  isManaging?: boolean;
  txUrl?: string;
  contractId?: string;
}) {
  const [emailAddress, setEmailAddress] = useState('');
  const [website, setWebsite] = useAtom(websiteAtom);
  const [twitter, setTwitter] = useAtom(twitterAtom);
  const [discord, setDiscord] = useAtom(discordAtom);
  const [artistAddress, setArtistAddress] = useAtom(artistAddressAtom);
  const [collectionName, setCollectionName] = useAtom(collectionNameAtom);
  const [contractId, setContractId] = useAtom(contractIdAtom);
  const [currentUser, setCurrentUser] = useAtom(currentUserAddressAtom);

  const [fullName, setFullName] = useState('');
  const [discordUsername, setDiscordUsername] = useState('');
  const [telegram, setTelegram] = useState('');
  const [discordServer, setDiscordServer] = useState('');
  const [twitterCollection, setTwitterCollection] = useState('');
  const [ownerSwitch1, setOwnerSwitch1] = useState(false);
  const [ownerSwitch2, setOwnerSwitch2] = useState(false);
  const [ownerSwitch3, setOwnerSwitch3] = useState(false);
  const [ownerSwitch4, setOwnerSwitch4] = useState(false);
  const [ownerSwitch5, setOwnerSwitch5] = useState(false);
  const [highlight1, setHighlight1] = useState('');
  const [highlight2, setHighlight2] = useState('');
  const [highlight3, setHighlight3] = useState('');
  const [highlight4, setHighlight4] = useState('');
  const [highlight5, setHighlight5] = useState('');
  const [highlight6, setHighlight6] = useState('');
  const [ownerExplain, setOwnerExplain] = useState('');
  const [contractAddress, setContractAddress] = useState('');
  const [contractName, setContractName] = useState('');
  const [submitted, setSubmitted] = useState(false);

  const [fullNameError, setFullNameError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [discordUsernameError, setDiscordUsernameError] = useState('');
  const [telegramError, setTelegramError] = useState('');
  const [twitterError, setTwitterError] = useState('');
  const [discordServerError, setDiscordServerError] = useState('');
  const [websiteError, setWebsiteError] = useState('');
  const [twitterCollectionError, setTwitterCollectionError] = useState('');
  const [highlightError1, setHighlightError1] = useState('');
  const [highlightError2, setHighlightError2] = useState('');
  const [highlightError3, setHighlightError3] = useState('');
  const [highlightError4, setHighlightError4] = useState('');
  const [highlightError5, setHighlightError5] = useState('');
  const [highlightError6, setHighlightError6] = useState('');
  const [ownerExplainError, setOwnerExplainError] = useState('');

  useEffect(() => {
    window.scrollTo(0, 0);

    const parts = getContractParts(props.contractId || contractId || '');
    setContractAddress(parts.contract_address || '');
    setContractName(parts.contract_name || '');

    getMintRequest({
      contractAddress: parts.contract_address || '',
      contractName: parts.contract_name || '',
    })
      .then((res: any) => {
        setFullName(res.fullName);
        setEmailAddress(res.emailAddress);
        setDiscordUsername(res.discordUsername);
        setTelegram(res.telegram);
        setTwitter(res.twitter);
        setWebsite(res.website);
        setDiscordServer(res.discordServer);
        setTwitterCollection(res.twitterCollection);
        setOwnerSwitch1(res.ownerSwitch1);
        setOwnerSwitch2(res.ownerSwitch2);
        setOwnerSwitch3(res.ownerSwitch3);
        setOwnerSwitch4(res.ownerSwitch4);
        setOwnerSwitch5(res.ownerSwitch5);
        setOwnerExplain(res.ownerExplain);
        setHighlight1(res.highlight1);
        setHighlight2(res.highlight2);
        setHighlight3(res.highlight3);
        setHighlight4(res.highlight4);
        setHighlight5(res.highlight5);
        setHighlight6(res.highlight6);
      })
      .catch((err) => {
        console.log(err);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.contractId]);

  function handleFullNameChange(e: React.ChangeEvent<HTMLInputElement>) {
    setFullNameError('');
    setFullName(e.target.value);
  }

  function handleFullNameBlur() {
    if (fullName) {
      const fullNameCheck = isValidFullName(fullName);
      setFullNameError(fullNameCheck.error);
    }
  }

  function handleEmailAddressChange(e: React.ChangeEvent<HTMLInputElement>) {
    setEmailError('');
    setEmailAddress(e.target.value);
  }

  function handleEmailAddressBlur() {
    if (emailAddress) {
      const emailAddressCheck = isValidContactInfo(emailAddress);
      setEmailError(emailAddressCheck.error);
    }
  }

  function handleTelegramChange(e: React.ChangeEvent<HTMLInputElement>) {
    setTelegramError('');
    setTelegram(e.target.value);
  }

  function handleTelegramBlur() {
    if (telegram) {
      const telegramCheck = isValidTelegram(telegram);
      setTelegramError(telegramCheck.error);
    }
  }

  function handleTwitterChange(e: React.ChangeEvent<HTMLInputElement>) {
    setTwitterError('');
    setTwitter(e.target.value);
  }

  function handleTwitterBlur() {
    if (twitter) {
      const twitterCheck = isValidTwitter(twitter);
      setTwitterError(twitterCheck.error);
    }
  }

  function handleTwitterCollectionChange(e: React.ChangeEvent<HTMLInputElement>) {
    setTwitterCollectionError('');
    setTwitterCollection(e.target.value);
  }

  function handleTwitterCollectionBlur() {
    if (twitterCollection) {
      const twitterCheck = isValidTwitter(twitterCollection);
      setTwitterCollectionError(twitterCheck.error);
    }
  }

  function handleDiscordUsernameChange(e: React.ChangeEvent<HTMLInputElement>) {
    setDiscordUsernameError('');
    setDiscordUsername(e.target.value);
  }

  function handleDiscordUsernameBlur() {
    if (discordUsername) {
      const discordCheck = isValidDiscordUsername(discordUsername);
      setDiscordUsernameError(discordCheck.error);
    }
  }

  function handleDiscordServerChange(e: React.ChangeEvent<HTMLInputElement>) {
    setDiscordServerError('');
    setDiscordServer(e.target.value);
  }

  function handleDiscordServerBlur() {
    if (discordServer) {
      const discordCheck = isValidDiscordInvite(discordServer);
      setDiscordServerError(discordCheck.error);
    }
  }

  function handleWebsiteChange(e: React.ChangeEvent<HTMLInputElement>) {
    setWebsiteError('');
    setWebsite(e.target.value);
  }

  function handleWebsiteBlur() {
    if (website) {
      const websiteCheck = isValidDomain(website);
      setWebsiteError(websiteCheck.error);
    }
  }

  function handleOwnerExplainChange(e: React.ChangeEvent<HTMLTextAreaElement>) {
    const ownerExplainCheck = isValidExplanation(e.target.value);
    setOwnerExplainError(ownerExplainCheck.error);
    setOwnerExplain(e.target.value);
  }

  function handleHightlight1Change(e: React.ChangeEvent<HTMLTextAreaElement>) {
    const highlightCheck = isValidDescription2(e.target.value);
    setHighlightError1(highlightCheck.error);
    setHighlight1(e.target.value);
  }

  function handleHightlight2Change(e: React.ChangeEvent<HTMLTextAreaElement>) {
    const highlightCheck = isValidDescription2(e.target.value);
    setHighlightError2(highlightCheck.error);
    setHighlight2(e.target.value);
  }

  function handleHightlight3Change(e: React.ChangeEvent<HTMLTextAreaElement>) {
    const highlightCheck = isValidDescription2(e.target.value);
    setHighlightError3(highlightCheck.error);
    setHighlight3(e.target.value);
  }

  function handleHightlight4Change(e: React.ChangeEvent<HTMLTextAreaElement>) {
    const highlightCheck = isValidDescription2(e.target.value);
    setHighlightError4(highlightCheck.error);
    setHighlight4(e.target.value);
  }

  function handleHightlight5Change(e: React.ChangeEvent<HTMLTextAreaElement>) {
    const highlightCheck = isValidDescription2(e.target.value);
    setHighlightError5(highlightCheck.error);
    setHighlight5(e.target.value);
  }

  function handleHightlight6Change(e: React.ChangeEvent<HTMLTextAreaElement>) {
    const highlightCheck = isValidDescription2(e.target.value);
    setHighlightError6(highlightCheck.error);
    setHighlight6(e.target.value);
  }

  async function handleSubmit() {
    const data = {
      emailAddress,
      website,
      twitter,
      discord,
      artistAddress,
      fullName,
      discordUsername,
      telegram,
      discordServer,
      twitterCollection,
      ownerSwitch1,
      ownerSwitch2,
      ownerSwitch3,
      ownerSwitch4,
      ownerSwitch5,
      highlight1,
      highlight2,
      highlight3,
      highlight4,
      highlight5,
      highlight6,
      ownerExplain,
      contractAddress: contractAddress || '',
      contractName: contractName || '',
    };

    // SEE https://developer.zendesk.com/documentation/developer-tools/working-with-the-zendesk-apis/making-requests-to-the-zendesk-api/#creating-data
    // use REACT_APP_ZENDESK_TOKEN

    // # New ticket info
    const subject = `Collection Deployed: ${collectionName}`;
    // const body = `Requester: ${emailAddress}; Subject: Collection Deployed: ${collectionName}; Ticket Form: Create Portal`;
    const body = `Requester: ${emailAddress}
Subject: Collection Deployed: ${collectionName}
Ticket Form: Create Portal

Requester Twitter: ${twitter}
Requester Telegram: ${telegram}
Requester Discord: ${discord}

Collection Website: ${website}
Collection Twitter: ${twitterCollection}
Collection Discord: ${discordServer}
Collection Contract ID: ${contractId}
Collection Deployer Address: ${currentUser}
Collection Artist Address: ${artistAddress}
Collection Royalty %: (default)

I am the original creator of the metadata: ${ownerSwitch1}
I have exclusive rights to use the metadata: ${ownerSwitch2}
The metadata is not used in any other collection or on any other blockchain: ${ownerSwitch3}
I have rights to commercialize the metadata (i.e. charge money for mints or royalties): ${ownerSwitch4}
The metadata does not use visuals, signature traits, logos, or trademarks from other businesses, projects, or collections: ${ownerSwitch5}
Ownership Explanation: ${ownerExplain}

Why are you launching this collection? ${highlight1}
Do you have a portfolio or other prior works you'd like to share with Gamma's community? ${highlight2}
Have there been any articles, interviews, or other press featuring you recently? ${highlight3}
Have your works been featured in galleries, museums, or other showcases? ${highlight4}
Does your project or its NFTs have a unique roadmap or other utility worth discussing? ${highlight5}
What else is interesting about you, your project, or your collectibles? ${highlight6}`;

    // # Package the data in a dictionary matching the expected JSON
    const ticketData = {
      request: {
        subject: subject,
        comment: { body: body },
      },
    };

    // # Encode the data to create a JSON payload
    const payload = JSON.stringify(ticketData);

    // # Set the request parameters
    const url = 'https://gammaio.zendesk.com/api/v2/requests.json';
    const pwd = process.env.REACT_APP_ZENDESK_TOKEN;
    const user = 'nick@gamma.io/token:' + pwd;
    const encoded = btoa(user);

    const headers = {
      'Content-Type': 'application/json',
      Authorization: 'Basic ' + encoded,
    };

    try {
      const res = await axios.post(url, payload, {
        headers: headers,
      });
      // console.log(res);

      handleMintRequest(data);

      setSubmitted(true);
    } catch (error) {
      console.log(error);
    }
  }

  function isValidSubmission() {
    if (
      fullName &&
      emailAddress &&
      ((ownerSwitch1 && ownerSwitch2 && ownerSwitch3 && ownerSwitch4 && ownerSwitch5) ||
        ownerExplain) &&
      !hasErrors()
    ) {
      return true;
    } else {
      return false;
    }
  }

  function hasErrors() {
    if (
      fullNameError ||
      emailError ||
      discordUsernameError ||
      discordServerError ||
      telegramError ||
      twitterError ||
      websiteError ||
      twitterCollectionError ||
      highlightError1 ||
      highlightError2 ||
      highlightError3 ||
      highlightError4 ||
      highlightError5 ||
      highlightError6 ||
      ownerExplainError
    ) {
      return true;
    } else {
      return false;
    }
  }

  if (submitted) {
    return (
      <div>
        <H3>Thanks for your submission!</H3>
        <Container>
          <ContainerItem>Check your email. We'll get back to you shortly.</ContainerItem>
        </Container>
      </div>
    );
  } else {
    return (
      <div>
        {props.isManaging ? (
          <h2>Complete your submission</h2>
        ) : (
          <H1>Congrats on deploying your own smart contract to the network!</H1>
        )}

        <Container>
          {props.txUrl ? (
            <ContainerItem>
              <TextLink href={props.txUrl} target="_blank" rel="noopener noreferrer">
                View your transaction.
              </TextLink>
            </ContainerItem>
          ) : null}

          {props.isManaging ? null : (
            <ContainerSection>
              <div>Please fill out the form below.</div>

              <ContainerItem>
                * This is optional, but used to help Gamma decide whether to display and potentially
                feature your collection on Gamma.io.
              </ContainerItem>

              <ContainerItem>
                <Link to="/studio/manage">
                  <CreateButton text="Do this later" />
                </Link>
              </ContainerItem>
            </ContainerSection>
          )}

          <ContainerSection>
            {/* <h2>Collection Details</h2> */}

            <H4>Individual Contact Details (private, not publicized)</H4>

            <ContainerItem>
              <LabelInput
                placeholder="e.g. John Doe"
                label="Full name"
                id="fullName"
                type="text"
                value={fullName}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleFullNameChange(e)}
                onBlur={() => handleFullNameBlur()}
                error={fullNameError}
              />
            </ContainerItem>

            <ContainerItem>
              <LabelInput
                note="Please be sure to include a valid email address as this is how Gamma will contact you."
                placeholder="hello@gamma.io"
                label="Email address"
                id="email"
                type="text"
                value={emailAddress}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleEmailAddressChange(e)}
                onBlur={() => handleEmailAddressBlur()}
                error={emailError}
              />
            </ContainerItem>

            <ContainerItem>
              <LabelInput
                placeholder="gammaSeller#42069"
                label="Discord username (optional)"
                id="discord"
                type="text"
                value={discordUsername}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  handleDiscordUsernameChange(e)
                }
                onBlur={() => handleDiscordUsernameBlur()}
                error={discordUsernameError}
              />
            </ContainerItem>

            <ContainerItem>
              <LabelInput
                placeholder="trygamma"
                label="Telegram username (optional)"
                id="telegram"
                type="text"
                value={telegram}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleTelegramChange(e)}
                onBlur={() => handleTelegramBlur()}
                error={telegramError}
              />
            </ContainerItem>

            <ContainerItem>
              <LabelInput
                placeholder="https://twitter.com/trygamma"
                label="Twitter profile (optional)"
                id="twitter"
                type="text"
                value={twitter}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleTwitterChange(e)}
                onBlur={() => handleTwitterBlur()}
                error={twitterError}
              />
            </ContainerItem>

            <H4>Collection Contact Details (public info, optional but recommended)</H4>

            <ContainerItem>
              <LabelInput
                placeholder="https://gamma.io"
                label="Website (optional)"
                id="website"
                type="text"
                value={website}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleWebsiteChange(e)}
                onBlur={() => handleWebsiteBlur()}
                error={websiteError}
              />
            </ContainerItem>

            <ContainerItem>
              <LabelInput
                placeholder="https://discord.gg/tmpSQc2qft"
                label="Discord server (optional)"
                id="discordServer"
                type="text"
                value={discordServer}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleDiscordServerChange(e)}
                onBlur={() => handleDiscordServerBlur()}
                error={discordServerError}
              />
            </ContainerItem>

            <ContainerItem>
              <LabelInput
                placeholder="trygamma"
                label="Twitter profile, if different than individual profile (optional)"
                id="twitterCollection"
                type="text"
                value={twitterCollection}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  handleTwitterCollectionChange(e)
                }
                onBlur={() => handleTwitterCollectionBlur()}
                error={twitterCollectionError}
              />
            </ContainerItem>

            <H4>Asset Ownership Details (Images, Videos, Music, Other Metadata)</H4>

            <ContainerItem>
              For the images, videos, music, or other information (i.e. metadata) used in this
              collection, please select which applies:
            </ContainerItem>

            <ContainerItem>
              <SwitchGeneric
                label="I am the original creator of the metadata"
                value={ownerSwitch1}
                checked={ownerSwitch1}
                onChange={(checked: boolean) => setOwnerSwitch1(checked)}
              />
            </ContainerItem>

            <ContainerItem>
              <SwitchGeneric
                label="I have exclusive rights to use the metadata"
                value={ownerSwitch2}
                checked={ownerSwitch2}
                onChange={(checked: boolean) => setOwnerSwitch2(checked)}
              />
            </ContainerItem>

            <ContainerItem>
              <SwitchGeneric
                label="The metadata is not used in any other collection or on any other blockchain"
                value={ownerSwitch3}
                checked={ownerSwitch3}
                onChange={(checked: boolean) => setOwnerSwitch3(checked)}
              />
            </ContainerItem>

            <ContainerItem>
              <SwitchGeneric
                label="I have rights to commercialize the metadata (i.e. charge money for mints or royalties)"
                value={ownerSwitch4}
                checked={ownerSwitch4}
                onChange={(checked: boolean) => setOwnerSwitch4(checked)}
              />
            </ContainerItem>

            <ContainerItem>
              <SwitchGeneric
                label="The metadata does not use visuals, signature traits, logos, or trademarks from other businesses, projects, or collections"
                value={ownerSwitch5}
                checked={ownerSwitch5}
                onChange={(checked: boolean) => setOwnerSwitch5(checked)}
              />
            </ContainerItem>

            <ContainerItem>
              <LabelTextArea
                placeholder=""
                label="If you answered “No” to any of the above, please explain:"
                id="ownerExplain"
                value={ownerExplain}
                onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                  handleOwnerExplainChange(e)
                }
                error={ownerExplainError}
              />
            </ContainerItem>
          </ContainerSection>

          <ContainerSection>
            <h2>Creator Highlights</h2>
            <div>
              Next, we’d like to ask that you share some information on your background as a
              creator. While this section is entirely optional, we regularly review this information
              to help curate Gamma’s featured collections, newsletter highlights, and social media
              mentions.
            </div>

            <ContainerItem>
              While we can’t guarantee that every submission will be featured, please only include
              information that you’re comfortable with Gamma sharing publicly with its community on
              social media or other mediums.
            </ContainerItem>

            <ContainerItem>
              <LabelTextArea
                placeholder=""
                label="Why are you launching this collection?"
                id="highlight1"
                value={highlight1}
                onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => handleHightlight1Change(e)}
                error={highlightError1}
              />
            </ContainerItem>

            <ContainerItem>
              <LabelTextArea
                placeholder=""
                label="Do you have a portfolio or other prior works you’d like to share with Gamma’s community?"
                id="highlight2"
                value={highlight2}
                onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => handleHightlight2Change(e)}
                error={highlightError2}
              />
            </ContainerItem>

            <ContainerItem>
              <LabelTextArea
                placeholder=""
                label="Have there been any articles, interviews, or other press featuring you recently?"
                id="highlight3"
                value={highlight3}
                onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => handleHightlight3Change(e)}
                error={highlightError3}
              />
            </ContainerItem>

            <ContainerItem>
              <LabelTextArea
                placeholder=""
                label="Have your works been featured in galleries, museums, or other showcases?"
                id="highlight4"
                value={highlight4}
                onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => handleHightlight4Change(e)}
                error={highlightError4}
              />
            </ContainerItem>

            <ContainerItem>
              <LabelTextArea
                placeholder=""
                label="Does your project or its NFTs have a unique roadmap or other utility worth discussing?"
                id="highlight5"
                value={highlight5}
                onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => handleHightlight5Change(e)}
                error={highlightError5}
              />
            </ContainerItem>

            <ContainerItem>
              <LabelTextArea
                placeholder=""
                label="What else is interesting about you, your project, or your collectibles?"
                id="highlight6"
                value={highlight6}
                onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => handleHightlight6Change(e)}
                error={highlightError6}
              />
            </ContainerItem>
          </ContainerSection>

          <ContainerItem>
            <ContinueButton
              text="Submit"
              onClick={() => handleSubmit()}
              disabled={!isValidSubmission()}
            />
          </ContainerItem>
        </Container>
      </div>
    );
  }
}
