import React from "react";
import { styled } from "@stitches/react";
import * as RadioGroupPrimitive from "@radix-ui/react-radio-group";

const StyledRadioGroup = styled(RadioGroupPrimitive.Root, {
  display: "flex",
  flexDirection: "column",
  gap: 12,
});

const StyledRadio = styled(RadioGroupPrimitive.Item, {
  all: "unset",
  backgroundColor: "white",
  width: 25,
  height: 25,
  marginRight: 5,
  borderRadius: "100%",
  border: "1px solid #dcdde2",
  // boxShadow: `0 2px 10px ${blackA.blackA7}`,
  cursor: "pointer",
  "&:hover": { backgroundColor: "#f5f5f7" },
  // "&:focus": { border: "1px solid #0055E1" },
});

const StyledIndicator = styled(RadioGroupPrimitive.Indicator, {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "100%",
  height: "100%",
  position: "relative",
  "&::after": {
    content: '""',
    display: "block",
    width: 11,
    height: 11,
    borderRadius: "50%",
    backgroundColor: "#0055E1",
  },
});

// Exports
export const RadioGroup = StyledRadioGroup;
export const RadioGroupRadio = StyledRadio;
export const RadioGroupIndicator = StyledIndicator;

// Your app...
const Flex = styled("div", {
  display: "flex",
  flexDirection: "column",
  border: "1px solid #dcdde2",
  borderRadius: 10,
  padding: 20,
});

const Label = styled("label", {
  lineHeight: 1,
  userSelect: "none",
  alignContent: "center",
  justifyItems: "center",
  gap: 10,
  fontWeight: "bold",
  cursor: "pointer",
  width: "fit-content",
});

const Note = styled("div", {
  paddingLeft: 42,
  paddingTop: 6,
  lineHeight: 1.3,
});

const List = styled("ul", {
  paddingLeft: 40,
  lineHeight: 1.3,
  margin: "10px 0 0 20px",
});

const RadioFlex = styled("div", {
  display: "flex",
  alignItems: "center",
  gap: 10,
});

const ContainerTitle = styled("div", {
  fontSize: "18px",
  lineHeight: "48px",
});

const ContainerNote = styled("div", {
  marginBottom: "20px",
});

export const RadioGroupWithNote = (props: {
  title: string;
  note?: string;
  data: Array<{ value: string; label: string; note: string }>;
  onChange: any;
}) => (
  <>
    <ContainerTitle>{props.title}</ContainerTitle>
    {props.note ? <ContainerNote>{props.note}</ContainerNote> : null}
    <form>
      <RadioGroup
        onValueChange={(value: string) => props.onChange(value)}
        defaultValue={props.data[0].value}
        aria-label="View density"
      >
        {props.data.map((element: any, idx: number) => {
          return (
            <Flex key={idx}>
              <RadioFlex>
                <RadioGroupRadio value={element.value} id={element.value + idx}>
                  <RadioGroupIndicator />
                </RadioGroupRadio>
                <Label htmlFor={element.value + idx}>{element.label}</Label>
              </RadioFlex>
              <Note>{element.note}</Note>
            </Flex>
          );
        })}
      </RadioGroup>
    </form>
  </>
);

export const RadioGroupWithList = (props: {
  title: string;
  note?: string;
  data: Array<{ value: string; label: string; items: Array<string> }>;
  onChange: any;
}) => (
  <>
    <ContainerTitle>{props.title}</ContainerTitle>
    {props.note ? <ContainerNote>{props.note}</ContainerNote> : null}
    <form>
      <RadioGroup
        onValueChange={(value: string) => props.onChange(value)}
        defaultValue={props.data[0].value}
        aria-label="View density"
      >
        {props.data.map((element: any, idx: number) => {
          return (
            <Flex key={idx}>
              <RadioFlex>
                <RadioGroupRadio value={element.value} id={element.value + idx}>
                  <RadioGroupIndicator />
                </RadioGroupRadio>
                <Label htmlFor={element.value + idx}>{element.label}</Label>
              </RadioFlex>
              <List>
                {element.items.map((item: string) => {
                  return <li>{item}</li>;
                })}
              </List>
            </Flex>
          );
        })}
      </RadioGroup>
    </form>
  </>
);
