import { useState } from 'react';
import { ContinueButton } from './buttons';
import CheckboxBasic, { CheckboxCreatorTerms3 } from './checkbox';
import { Container, ContainerItem, PercentRead, TextLink } from './common';
import { ScrollableCreatorTerms } from './scrollable-element';

export default function CreatorTerms(props: { onNextStep?: any }) {
  const [bottomReached, setBottomReached] = useState(false);
  const [agree1, setAgree1] = useState(false);
  const [agree2, setAgree2] = useState(false);
  const [agree3, setAgree3] = useState(false);
  const [percentComplete, setPercentComplete] = useState(0);

  function handleContinue() {
    props.onNextStep();
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  }

  function handleScrollBottom() {
    setBottomReached(true);
  }

  function handleScroll(percentComplete: number) {
    setPercentComplete(percentComplete);
  }

  function handleCheckbox1Change(checked: boolean) {
    setAgree1(checked);
  }

  function handleCheckbox2Change(checked: boolean) {
    setAgree2(checked);
  }

  function handleCheckbox3Change(checked: boolean) {
    setAgree3(checked);
  }

  function isAllAgreed() {
    return agree1 && agree2 && agree3;
  }

  return (
    <Container>
      <h2>Gamma Creator Terms of Use</h2>
      <div>
        We're excited that you are interested in being a creator of Bitcoin NFTs. Before proceeding
        publishing your collection, it's important that you review, understand, and agree to the
        following notices and terms.
      </div>
      <ContainerItem>You must read and accept all terms before proceeding.</ContainerItem>
      <ScrollableCreatorTerms
        onScrollBottom={() => handleScrollBottom()}
        onScroll={(percentComplete: number) => handleScroll(percentComplete)}
      ></ScrollableCreatorTerms>
      <ContainerItem>
        (You've read <strong>{percentComplete}%</strong> of the above Terms of Use)
      </ContainerItem>
      <ContainerItem>
        Recommended Help Articles Prior to Proceeding:
        <ul>
          <li>
            <TextLink
              target="_blank"
              rel="noopener noreferrer"
              href="https://support.gamma.io/hc/en-us/articles/6010964394131-How-do-I-use-the-Creators-Self-Service-Portal-"
            >
              How to use the Create Portal
            </TextLink>
          </li>
          <li>
            <TextLink
              target="_blank"
              rel="noopener noreferrer"
              href="https://support.gamma.io/hc/en-us/categories/5992643779987-Creator-Help-Center"
            >
              Creator Help Center
            </TextLink>
          </li>
        </ul>
      </ContainerItem>
      <ContainerItem>
        You must read the above terms to enable the checkboxes below. If you have read and
        understand all of the terms above, please confirm the following before proceeding with this
        process:
      </ContainerItem>
      <ContainerItem>
        <CheckboxBasic
          disabled={!bottomReached}
          id="cb1"
          onChange={(checked: boolean) => handleCheckbox1Change(checked)}
          label="I understand that once deployed, there are many ways in which my smart contract cannot be
        altered, and it can never be deleted. For this reason, it’s important that I follow the
        instructions in the help articles above."
        />
      </ContainerItem>
      <ContainerItem>
        <CheckboxBasic
          disabled={!bottomReached}
          id="cb2"
          onChange={(checked: boolean) => handleCheckbox2Change(checked)}
          label="I understand that Gamma will receive BOTH a 10% commission from each initial mints of my
        collection’s NFTs as well as a non-refundable network fee that is required to start the
        process needed to deploy my smart contract."
        />
      </ContainerItem>
      <ContainerItem>
        <CheckboxCreatorTerms3
          disabled={!bottomReached}
          id="cb3"
          onChange={(checked: boolean) => handleCheckbox3Change(checked)}
        />
      </ContainerItem>
      <ContainerItem>
        <strong>
          Note: You must check all three boxes to continue.
        </strong>
      </ContainerItem>
      <ContainerItem>
        <ContinueButton
          disabled={!isAllAgreed()}
          text="Continue"
          onClick={() => handleContinue()}
        />
      </ContainerItem>
    </Container>
  );
}
