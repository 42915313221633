import React, { Component } from "react";
import styles from "./index.module.scss";
import Button from "../../../components/Button";

import { darkmodeListener } from "../../../theme";
import TextInput from "../../../components/TextInput";
import {
  isValidCityCoinPrice,
  isValidCollectionSize,
  isValidMintPrice,
} from "../../../utils/SelfServe/validation";
import {
  callAirdrop,
  callFreezeMetadata,
  initMintpass,
  initPublicSale,
  revealArtwork,
  setAltMintPrice,
  setMintLimit,
  setMintPrice,
  setUsdaPrice,
  togglePause,
} from "../../../utils/SelfServe/contracts";
import UserTip from "../../../components/UserTip";
import Modal from "../../../components/Modal";

type AdminPanelProps = {
  setModal: any;
  syncCollectionData: any;
  contractAddress: string;
  contractName: string;
  altCurrencies: Array<any>;
  revealEnabled: boolean;
  contractType: string;
  mintpassStateChecked: boolean;
  mintpassEnabled: boolean;
  saleStateChecked: boolean;
  saleEnabled: boolean;
  airdropEnabled: boolean;
  revealData: any;
  paused: boolean;
  altPriceInputs: Array<any>;
  altPriceErrors: Array<any>;
};
type AdminPanelState = {
  mintPriceInput: string;
  mintPriceError?: string;
  usdaPriceInput: string;
  usdaPriceError?: string;
  altPriceInputs: Array<any>;
  altPriceErrors: Array<any>;
  mintLimitInput: string;
  mintLimitError?: string;
  showAdmin: boolean;
  showSafetyTip: boolean;
  modal?: any;
  functionName: string;
  altSymbol: string;
};

enum FunctionNames {
  FREEZE_METADATA = "Freeze Metadata",
  INIT_MINTPASS_SALE = "Init Mintpass Sale",
  INIT_PUBLIC_SALE = "Init Public Sale",
  SET_PRICE = "Set Price",
  SET_MINT_LIMIT = "Set Collection Size",
  TOGGLE_PAUSE = "Toggle Pause",
  AIRDROP_TOKENS = "Airdrop Tokens",
  REVEAL_ARTWORK = "Reveal Artwork",
  SET_ALT_PRICE = "Set Alt Price",
}

class AdminPanel extends Component<AdminPanelProps, AdminPanelState> {
  state = {
    mintPriceInput: "",
    mintPriceError: undefined,
    usdaPriceInput: "",
    usdaPriceError: undefined,
    altPriceInputs: this.props.altPriceInputs,
    altPriceErrors: this.props.altPriceErrors,
    mintLimitInput: "",
    mintLimitError: undefined,
    showAdmin: false,
    showSafetyTip: false,
    modal: undefined,
    functionName: "",
    altSymbol: "",
  };

  componentDidMount() {
    darkmodeListener(styles["theme-light"], styles["theme-dark"]);
  }

  setPriceButton() {
    return (
      <div className={styles.AdminButton}>
        <TextInput
          placeholder={"Enter mint price (STX)"}
          error={this.state.mintPriceError}
          value={this.state.mintPriceInput}
          onChange={this.changeMintPrice.bind(this)}
        />
        <Button
          text={FunctionNames.SET_PRICE}
          onClick={this.openSafetyTip.bind(this, FunctionNames.SET_PRICE)}
        />
      </div>
    );
  }

  setUsdaButton() {
    return (
      <div className={styles.AdminButton}>
        <TextInput
          placeholder={"Enter mint price (USDA)"}
          error={this.state.usdaPriceError}
          value={this.state.usdaPriceInput}
          onChange={this.changeUsdaPrice.bind(this)}
        />
        <Button
          text="SET USDA PRICE"
          onClick={this.openSafetyTip.bind(this, "SET USDA PRICE")}
        />
      </div>
    );
  }

  changeMintPrice(e: React.ChangeEvent<HTMLInputElement>) {
    this.setState({
      mintPriceInput: e.target.value,
    });
  }

  changeUsdaPrice(e: React.ChangeEvent<HTMLInputElement>) {
    this.setState({
      usdaPriceInput: e.target.value,
    });
  }

  setPriceBehavior() {
    this.setState({
      mintPriceError: "",
    });
    const priceCheck = isValidMintPrice(this.state.mintPriceInput);
    if (!priceCheck.isValid) {
      this.setState({
        mintPriceError: priceCheck.error,
      });
      return;
    }

    const mstx = parseInt(this.state.mintPriceInput) * 1000000;

    if (this.props.contractAddress && this.props.contractName) {
      setMintPrice(
        this.props.contractAddress,
        this.props.contractName,
        mstx,
        this.props.setModal.bind(this)
      );
    }
  }

  setUsdaBehavior() {
    this.setState({
      usdaPriceError: "",
    });
    const priceCheck = isValidCityCoinPrice(this.state.usdaPriceInput);
    if (!priceCheck.isValid) {
      this.setState({
        usdaPriceError: priceCheck.error,
      });
      return;
    }

    const musda = parseInt(this.state.usdaPriceInput) * 1000000;

    if (this.props.contractAddress && this.props.contractName) {
      setUsdaPrice(
        this.props.contractAddress,
        this.props.contractName,
        musda,
        this.props.setModal.bind(this)
      );
    }
  }

  altSetPriceButtons() {
    let altSetPriceButtons = [];

    if (
      this.props.altCurrencies != null &&
      this.props.altCurrencies.length > 0 &&
      this.props.altPriceErrors != null &&
      this.props.altPriceInputs != null &&
      this.props.altPriceErrors.length > 0 &&
      this.props.altPriceInputs.length > 0
    ) {
      for (let i = 0; i < this.props.altCurrencies.length; i++) {
        const currency = this.props.altCurrencies[i];
        const symbol: string = currency["symbol"];
        if (symbol == "BTC") {
          continue;
        }
        const text = `SET ${currency["symbol"]} PRICE`;
        let altButton = (
          <div key={i} className={styles.AdminButton}>
            <TextInput
              placeholder={`Enter mint price (${currency["symbol"]})`}
              error={
                this.state.altPriceErrors[i] != null
                  ? this.state.altPriceErrors[i]["error"]
                  : ""
              }
              value={
                this.state.altPriceInputs[i] != null
                  ? this.state.altPriceInputs[i]["input"]
                  : ""
              }
              onChange={this.changeAltMintPrice.bind(this, symbol)}
            />
            <Button
              text={text}
              onClick={this.openSafetyTip.bind(
                this,
                FunctionNames.SET_ALT_PRICE,
                currency["symbol"]
              )}
            />
          </div>
        );
        altSetPriceButtons.push(altButton);
      }
    }
    return altSetPriceButtons;
  }

  changeAltMintPrice(symbol: string, e: React.ChangeEvent<HTMLInputElement>) {
    let altInputs: Array<object> = this.state.altPriceInputs;
    const isAlt = (element: any) => element["symbol"] == symbol;
    const altIndex = altInputs.findIndex(isAlt);
    const alt = { symbol: symbol, input: e.target.value };
    if (altIndex != -1) {
      altInputs[altIndex] = alt;
      this.setState({
        altPriceInputs: altInputs,
      });
    }
  }

  setAltPriceBehavior(symbol: string) {
    let altInputs: Array<object> = this.state.altPriceInputs;
    let altErrors: Array<object> = this.state.altPriceErrors;
    const isAlt = (element: any) => element["symbol"] == symbol;
    const altIndex: number = altInputs.findIndex(isAlt);
    if (altIndex != -1) {
      const inputs: any = altInputs[altIndex];
      const input: string = inputs["input"];
      altErrors[altIndex] = { symbol: symbol, error: "" };
      this.setState({
        altPriceErrors: altErrors,
      });
      const priceCheck = isValidCityCoinPrice(input);
      if (!priceCheck.isValid) {
        altErrors[altIndex] = { symbol: symbol, error: priceCheck.error };
        this.setState({
          altPriceErrors: altErrors,
        });
        return;
      }

      // submit new price
      setAltMintPrice(
        this.props.contractAddress,
        this.props.contractName,
        parseInt(input),
        symbol,
        this.props.setModal.bind(this)
      );
    }
  }

  setMintLimitButton() {
    return (
      <div className={styles.AdminButton}>
        <TextInput
          placeholder={"Enter mint limit"}
          error={this.state.mintLimitError}
          value={this.state.mintLimitInput}
          onChange={this.changeMintLimit.bind(this)}
        />
        <Button
          text={FunctionNames.SET_MINT_LIMIT}
          onClick={this.openSafetyTip.bind(this, FunctionNames.SET_MINT_LIMIT)}
        />
      </div>
    );
  }

  changeMintLimit(e: React.ChangeEvent<HTMLInputElement>) {
    this.setState({
      mintLimitInput: e.target.value,
    });
  }

  setMintLimitBehavior() {
    this.setState({
      mintLimitError: "",
    });
    const limitCheck = isValidCollectionSize(this.state.mintLimitInput);
    if (!limitCheck.isValid) {
      this.setState({
        mintLimitError: limitCheck.error,
      });
      return;
    }

    if (this.state.mintLimitInput == "") {
      return;
    }

    if (this.props.contractAddress && this.props.contractName) {
      setMintLimit(
        this.props.contractAddress,
        this.props.contractName,
        parseInt(this.state.mintLimitInput),
        this.props.setModal.bind(this)
      );
    }
  }

  pauseButton() {
    return (
      <div className={styles.AdminButton}>
        <Button
          text={this.pauseText()}
          onClick={this.openSafetyTip.bind(this, FunctionNames.TOGGLE_PAUSE)}
        />
      </div>
    );
  }

  pauseBehavior() {
    if (this.props.contractAddress && this.props.contractName) {
      togglePause(
        this.props.contractAddress,
        this.props.contractName,
        this.props.setModal.bind(this)
      );
    }
  }

  pauseText() {
    let pauseText;
    if (this.props.paused) {
      pauseText = "Resume Minting";
    } else {
      pauseText = "Pause Minting";
    }
    return pauseText;
  }

  airdropButton() {
    if (!this.props.airdropEnabled) {
      return null;
    }

    return (
      <div className={styles.AdminButton}>
        <Button
          text={FunctionNames.AIRDROP_TOKENS}
          onClick={this.openSafetyTip.bind(this, FunctionNames.AIRDROP_TOKENS)}
        />
      </div>
    );
  }

  airdropBehavior() {
    if (this.props.contractAddress && this.props.contractName) {
      callAirdrop(
        this.props.contractAddress,
        this.props.contractName,
        this.props.setModal.bind(this)
      );
    }
  }

  mintpassButton() {
    if (
      this.props.mintpassStateChecked &&
      !this.props.mintpassEnabled &&
      !this.props.saleEnabled
    ) {
      return (
        <div className={styles.AdminButton}>
          <Button
            text="Init Mintpass Sale"
            onClick={this.mintpassBehavior.bind(this, 0)}
          />
        </div>
      );
    } else if (this.props.saleStateChecked && !this.props.saleEnabled) {
      return (
        <div className={styles.AdminButton}>
          <Button
            text="Init Public Sale"
            onClick={this.mintpassBehavior.bind(this, 1)}
          />
        </div>
      );
    }
  }

  mintpassBehavior(action: Number) {
    if (action == 0) {
      initMintpass(
        this.props.contractAddress,
        this.props.contractName,
        this.props.setModal.bind(this)
      );
    } else if (action == 1) {
      initPublicSale(
        this.props.contractAddress,
        this.props.contractName,
        this.props.setModal.bind(this)
      );
    }
  }

  syncButton() {
    return (
      <div className={styles.AdminButton}>
        <Button
          text="Sync Collection Data"
          onClick={this.props.syncCollectionData.bind(this, false)}
        />
      </div>
    );
  }

  revealButton() {
    if (!this.props.revealEnabled) {
      return;
    }

    return (
      <div className={styles.AdminButton}>
        <Button
          text={FunctionNames.REVEAL_ARTWORK}
          onClick={this.openSafetyTip.bind(this, FunctionNames.REVEAL_ARTWORK)}
        />
      </div>
    );
  }

  revealBehavior() {
    // call set base uri using revealMetadataHash from revealData

    const revealData: any = this.props.revealData;
    const hash = revealData["revealMetadataHash"];
    const uri = `ipfs://ipfs/${hash}/json/`;

    if (this.props.contractAddress && this.props.contractName) {
      revealArtwork(
        this.props.contractAddress,
        this.props.contractName,
        uri,
        this.props.setModal.bind(this)
      );
    }
  }

  freezeMetadataButton() {
    return (
      <div className={styles.AdminButton}>
        <Button
          text={FunctionNames.FREEZE_METADATA}
          onClick={this.openSafetyTip.bind(this, FunctionNames.FREEZE_METADATA)}
        />
      </div>
    );
  }

  freezeMetadataBehavior() {
    if (this.props.contractAddress && this.props.contractName) {
      callFreezeMetadata(
        this.props.contractAddress,
        this.props.contractName,
        this.props.setModal.bind(this)
      );
    }
  }

  adminPanel() {    
    let admin = [];
    admin.push(this.setPriceButton());
    // admin.push(this.altSetPriceButtons());
    admin.push(this.setUsdaButton())
    admin.push(this.setMintLimitButton());
    admin.push(this.pauseButton());
    admin.push(this.airdropButton());
    admin.push(this.revealButton());
    admin.push(this.freezeMetadataButton());

    if (this.props.contractType == "mintpass") {
      admin.push(this.mintpassButton());
    }

    // admin.push(this.syncButton());
    admin.push(<div className={styles.AdminDivider}>--- end of admin ---</div>);
    return admin;
  }

  toggleAdmin() {
    this.setState({
      showAdmin: !this.state.showAdmin,
    });
  }

  openSafetyTip(functionName: string, altSymbol?: string) {
    this.setState({
      functionName: functionName,
      altSymbol: altSymbol ? altSymbol : "",
      showSafetyTip: true,
    });
  }

  closeSafetyTip(accepted?: boolean) {
    this.modalClose();
    this.setState({ showSafetyTip: false });

    if (!accepted) {
      return;
    }

    console.log(this.state.functionName);
    

    if (this.state.functionName == FunctionNames.FREEZE_METADATA) {
      this.freezeMetadataBehavior();
    } else if (this.state.functionName == FunctionNames.AIRDROP_TOKENS) {
      this.airdropBehavior();
    } else if (this.state.functionName == FunctionNames.REVEAL_ARTWORK) {
      this.revealBehavior();
    } else if (this.state.functionName == FunctionNames.TOGGLE_PAUSE) {
      this.pauseBehavior();
    } else if (this.state.functionName == FunctionNames.SET_PRICE) {
      this.setPriceBehavior();
    } else if (this.state.functionName == FunctionNames.SET_MINT_LIMIT) {
      this.setMintLimitBehavior();
    } else if (this.state.functionName == FunctionNames.INIT_MINTPASS_SALE) {
      initMintpass(
        this.props.contractAddress,
        this.props.contractName,
        this.props.setModal.bind(this)
      );
    } else if (this.state.functionName == FunctionNames.INIT_PUBLIC_SALE) {
      initPublicSale(
        this.props.contractAddress,
        this.props.contractName,
        this.props.setModal.bind(this)
      );
    } else if (this.state.functionName == FunctionNames.SET_ALT_PRICE) {
      this.setAltPriceBehavior(this.state.altSymbol);
    } else if (this.state.functionName == "SET USDA PRICE") {
      this.setUsdaBehavior()
    }
  }

  modalClose() {
    this.setState({
      modal: undefined,
    });
  }

  render() {
    return (
      <div className={styles.MintContent}>
        <div className={styles.UploadContainer}></div>

        <div className={styles.AdminButton}>
          <Button
            text={this.state.showAdmin ? "Hide Admin" : "Show Admin"}
            onClick={this.toggleAdmin.bind(this)}
            style={"ERROR"}
          />
        </div>

        {this.state.showSafetyTip && (
          <Modal onClose={this.closeSafetyTip.bind(this)}>
            <UserTip
              onClose={this.closeSafetyTip.bind(this)}
              buttonText={"Call " + this.state.functionName}
            >
              <div className={styles.Tip}>
                Many contract function calls are one-time, one-way operations
                that will permanently change the state of your contract. Please
                review this article before proceeding.
              </div>
              <div className={styles.Tip}>
                <a
                  href="https://stxnft.zendesk.com/hc/en-us/articles/4415573183763"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  How can I manage my collection deployed using the Creators
                  Self Service Portal?
                </a>
              </div>
            </UserTip>
          </Modal>
        )}

        {this.state.showAdmin && this.adminPanel()}
      </div>
    );
  }
}

export default AdminPanel;
