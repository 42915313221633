import React, { Component } from 'react';
import styles from './index.module.scss';

import { darkmodeListener } from '../../theme';

import { renderPrice } from '../../utils';

import { styled } from '../../stitches.config';

const Primary = styled('div', {
  backgroundColor: '$text',
  color: '$background',
  display: 'flex',
  minWidth: '60px',
  height: '42px',
  alignItems: 'center',
  justifyContent: 'center',
  paddingLeft: '16px',
  paddingRight: '16px',
  cursor: 'pointer',
  transition: 'background 0.25s, color 0.25s, stroke 0.25s, border 0.25s',
  borderRadius: '9999px',
  boxSizing: 'border-box',
  fontSize: '16px',
  letterSpacing: '1.23px',
});

const Secondary = styled('div', {
  backgroundColor: '$border',
  color: '$background',
  display: 'flex',
  minWidth: '60px',
  height: '42px',
  alignItems: 'center',
  justifyContent: 'center',
  paddingLeft: '16px',
  paddingRight: '16px',
  cursor: 'pointer',
  transition: 'background 0.25s, color 0.25s, stroke 0.25s, border 0.25s',
  borderRadius: '9999px',
  boxSizing: 'border-box',
  fontSize: '16px',
  letterSpacing: '1.23px',
});

type ButtonProps = {
  text: string;
  style: 'PRIMARY' | 'SECONDARY' | 'ERROR';
  currency: string;
  price?: number;
  onClick?: (...args: any[]) => void;
};

type ButtonState = {};

class Button extends Component<ButtonProps, ButtonState> {
  static background = {
    PRIMARY: styles.Primary,
    SECONDARY: styles.Secondary,
    ERROR: styles.Error,
  };

  componentDidMount() {
    darkmodeListener(styles['theme-light'], styles['theme-dark']);
  }

  static defaultProps = {
    text: '',
    style: 'PRIMARY',
    currency: 'STX',
  };

  onClick() {
    if (this.props.onClick) {
      this.props.onClick();
    }
  }

  amount() {
    if (this.props.price !== undefined) {
      if (
        this.props.currency == 'STX' ||
        this.props.currency == 'BTC' ||
        this.props.currency == 'xBTC' ||
        this.props.currency == 'USDA'
      ) {
        return this.props.price;
      } else if (
        this.props.currency == 'BANANA' ||
        this.props.currency == 'SLIME' ||
        this.props.currency == 'MIA2' ||
        this.props.currency == 'NYC2'
      ) {
        return this.props.price;
      } else if (this.props.currency == 'ALEX' || this.props.currency == 'MEGA') {
        return this.props.price / 1e2;
      } else {
        return this.props.price * 1e6;
      }
    }
  }

  renderButton() {
    const price = this.amount();
    let symbol = this.props.currency;
    if (symbol === 'MIA2' || symbol === 'NYC2') {
      symbol = symbol.replace('2', '');
    }

    if (this.props.style == 'SECONDARY') {
      return (
        <Secondary onClick={this.onClick.bind(this)}>
          <span>{this.props.text}</span>

          {this.props.price !== undefined && <div className={styles.PriceSpacer}></div>}

          {price !== undefined && (
            <div className={styles.Price}>
              <div className={styles.PriceValue}>{renderPrice(price, symbol)}</div>
              <div className={styles.PriceText}>{symbol}</div>
            </div>
          )}
        </Secondary>
      );
    } else {
      return (
        <Primary onClick={this.onClick.bind(this)}>
          <span>{this.props.text}</span>

          {this.props.price !== undefined && <div className={styles.PriceSpacer}></div>}

          {price !== undefined && (
            <div className={styles.Price}>
              <div className={styles.PriceValue}>{renderPrice(price, symbol)}</div>
              <div className={styles.PriceText}>{symbol}</div>
            </div>
          )}
        </Primary>
      );
    }
  }

  render() {
    return this.renderButton();
  }
}

export default Button;
