import React from 'react';
import { Link } from 'react-router-dom';
import styles from './index.module.scss';
import logo from '../../../assets/logo.png';
import { EthConnectButton, StacksConnectButton } from '../../buttons';
import { useAtom } from 'jotai';
import { assetsAtom, currentUserAddressAtom, useResetAtoms } from '../../atoms';
import { WordLogo, SunIcon, UserIcon, IndicatorIcon } from '../../../utils/svgs';
import { Pointer } from '../../common';
import { ColourModeContext } from '../../../theme/ColorModeProvider';
import { ConnectButton } from '@rainbow-me/rainbowkit';
import { adminAddresses, ethAdopters } from '../../Common/AdminRoute';
import { DropdownMenuConnect } from '../../dropdown-menu';
import { useAccount } from 'wagmi';
import Dropdown from '../../Dropdown';

const ColorModeToggleButton = () => {
  return (
    <ColourModeContext.Consumer>
      {(context: any) => <Pointer onClick={context.cycleToggleMode}>{SunIcon({})}</Pointer>}
    </ColourModeContext.Consumer>
  );
};

function StudioHeader() {
  const [currentUserAddress, setCurrentUserAddress] = useAtom(currentUserAddressAtom);
  const { resetDeployAtoms } = useResetAtoms();
  const { isConnected, address } = useAccount();

  function clearAssets() {
    resetDeployAtoms();
  }

  return (
    <header className={styles.header}>
      <div>
        <Link onClick={() => clearAssets()} to="/">
          {WordLogo({ height: '30px' })}
        </Link>
      </div>
      <div className={styles.Nav}>
        <div className={styles.NavItem}>
          <Link onClick={() => clearAssets()} to="/getting-started">
            Create
          </Link>
        </div>

        <div className={styles.NavItem}>
          <Link onClick={() => clearAssets()} to="/minting-now">
            Minting Now
          </Link>
        </div>

        <div className={styles.NavItem}>
          <a href="https://stacks.gamma.io/auctions" target="_blank" rel="noopener noreferrer">
            Auctions
          </a>
        </div>

        {currentUserAddress ? (
          <div className={styles.NavItem}>
            <Link onClick={() => clearAssets()} to="/manage">
              Manage
            </Link>
          </div>
        ) : null}

        <div className={styles.NavItem}>
          <a href="https://stacks.gamma.io" target="_blank" rel="noopener noreferrer">
            Gamma.io
          </a>
        </div>

        <ColorModeToggleButton />

        {(currentUserAddress || address) &&
        (adminAddresses.includes(currentUserAddress) ||
          ethAdopters.includes(currentUserAddress)) ? (
          <>
            <DropdownMenuConnect></DropdownMenuConnect>
            <IndicatorIcon />
          </>
        ) : (
          <div className={styles.NavItem}>
            <StacksConnectButton signOutText="Sign out" signInText="Connect wallet" />
          </div>
        )}
      </div>
    </header>
  );
}

export default StudioHeader;
