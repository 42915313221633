import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { StacksMainnet } from '@stacks/network';
import {
  callReadOnlyFunction,
  uintCV,
  hexToCV,
  ClarityType,
  cvToJSON,
  contractPrincipalCV,
  standardPrincipalCV,
  stringAsciiCV,
} from '@stacks/transactions';
import { auctionContractAddress, auctionContractName, network } from '../utils/SelfServe/contracts';

// see https://github.com/GoogleChromeLabs/jsbi/issues/30
(BigInt.prototype as any).toJSON = function () {
  return this.toString();
};

export const coreApiUrl = 'https://stacks-mainnet.gamma.io/';

export const passthroughCall = async (appendage: string, root = coreApiUrl) => {
  try {
    return await (await fetch(`${root}${appendage}`)).json();
  } catch (e) {}
};

const useConditionallyCallControlledPassthroughApi = (appendage: string[]) => {
  const [hasBeenCalled, setHasBeenCalled] = useState(false);
  const queryClient = useQueryClient();

  useEffect(() => {
    if (!hasBeenCalled && !queryClient.getQueryState(appendage)?.data) {
      if (!appendage) return;
      setHasBeenCalled(true);
      fetch(
        `https://stacks.gamma.io/api/portal/pass-through?appendage=${encodeURIComponent(appendage[0])}`,
      )
        .then((x) => x.json())
        .then((x) => x.data)
        .then((x) => {
          if (!queryClient.getQueryState(appendage)?.data) {
            queryClient.setQueryData(appendage, x);
            queryClient.invalidateQueries(appendage);
          }
        })
        .catch((err) => {
          console.log(appendage);
          console.log(err);
        });
    }
  }, [appendage, hasBeenCalled, queryClient]);

  return hasBeenCalled;
};

export const usePassthroughCall = (appendage: string[]) => {
  useConditionallyCallControlledPassthroughApi(appendage);
  const query = async () => (appendage ? passthroughCall(appendage[0]) : null);
  return useQuery(appendage, query);
};

export type CallOptions = {
  contractAddress: string;
  contractName: string;
  functionName: string;
  functionArgs?: any[];
  senderAddress?: string; // contractAddress if none
  network?: StacksMainnet; // standard network if none
};

export type ReturnFormat = (res: any) => any;

export const standardReadOnlyCall = async (options: CallOptions, returnFormat = 'standard') => {
  try {
    if (options?.contractAddress) {
      const res = await callReadOnlyFunction({
        contractAddress: options.contractAddress,
        contractName: options.contractName,
        functionName: options.functionName,
        functionArgs: options.functionArgs || [],
        senderAddress: options.senderAddress || options.contractAddress,
        network,
      });
      return getReturnFormat(returnFormat)?.method(res);
    }
  } catch (e) {
    console.log(e);
  }
};

export const getReturnFormat = (name: string) =>
  potentialReturnFormats.find((x) => x.name === name);

export const potentialReturnFormats = [
  {
    name: 'alex',
    method: (res: any) => {
      const condition1 = res.type === ClarityType.ResponseOk;
      const condition2 = res.value.type === ClarityType.UInt;
      if (condition1 && condition2) return 1 / (Number(res.value.value) / 1e8);
      return null;
    },
  },
  {
    name: 'standard',
    method: (res: any) => cvToJSON(res).value.value,
  },
  {
    name: 'mintpasses',
    method: (res: any) => parseInt(cvToJSON(res).value),
  },
  {
    name: 'bid',
    method: (res: any) => cvToJSON(res).value,
  },
];

const useConditionallyCallControlledReadOnlyApi = (
  url: string,
  keys: any,
  options: CallOptions,
  returnFormat = 'standard',
) => {
  const [hasBeenCalled, setHasBeenCalled] = useState(false);
  const queryClient = useQueryClient();

  useEffect(() => {
    if (!hasBeenCalled && !queryClient.getQueryState(keys)?.data) {
      const params = new URLSearchParams({
        options: encodeURIComponent(JSON.stringify(options)),
        returnFormat: encodeURIComponent(JSON.stringify(returnFormat)),
      });
      if (!url) return;
      setHasBeenCalled(true);
      fetch(url + '?' + params)
        .then((x) => x.json())
        .then((x) => x.data)
        .then((x) => {
          if (!queryClient.getQueryState(keys)?.data) {
            queryClient.setQueryData(keys, x);
            queryClient.invalidateQueries(keys);
          }
        })
        .catch((e) => console.error(e));
    }
  }, [url, hasBeenCalled, queryClient, keys, options, returnFormat]);

  return hasBeenCalled;
};

export const useReadOnlyCall = (options: CallOptions, returnFormat = 'standard') => {
  const keys = ['standard-hiro-query', options];
  const url = 'https://stacks.gamma.io/api/portal';
  useConditionallyCallControlledReadOnlyApi(url, keys, options, returnFormat);
  const query = async () =>
    options.contractAddress ? standardReadOnlyCall(options, returnFormat) : null;
  return useQuery(keys, query);
};

export const useGetTokenUri = (contractAddress: string, contractName: string, tokenId: string) => {
  const functionArgs = [uintCV(tokenId)];
  return useReadOnlyCall(
    {
      contractAddress,
      contractName,
      functionName: 'get-token-uri',
      functionArgs,
    },
    'standard',
  );
};

export const useGetAuction = (contractAddress: string, contractName: string, tokenId: string) => {
  const functionArgs = [contractPrincipalCV(contractAddress, contractName), uintCV(tokenId)];
  return useReadOnlyCall(
    {
      contractAddress: auctionContractAddress,
      contractName: auctionContractName,
      functionName: 'get-auction',
      functionArgs,
    },
    'standard',
  );
};

export const useGetBid = (contractAddress: string, contractName: string, tokenId: string) => {
  const functionArgs = [contractPrincipalCV(contractAddress, contractName), uintCV(tokenId)];
  return useReadOnlyCall(
    {
      contractAddress: auctionContractAddress,
      contractName: auctionContractName,
      functionName: 'get-auction-bid',
      functionArgs,
    },
    'bid',
  );
};
