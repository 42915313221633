import React from 'react';
import { ContainerFull, ContainerItem, H2 } from '../../../components/common';

export default function Privacy() {
  return (
    <ContainerFull>
      <H2>Gamma Privacy Policy</H2>
      <div>
        PLEASE READ THIS PRIVACY POLICY AND THE RELATED TERMS OF USE CAREFULLY BEFORE USING THIS
        WEBSITE.
      </div>
      <div>
        The gamma.io website (the “Site”) is operated by STXNFT, Inc., doing business as Gamma
        (“Gamma,” “we”, “us”, or “our”). This Privacy Policy applies to your use of the Site and the
        content, features, functionality, and services offered on or through the Site, as described
        in our Terms of Use (collectively, the “Service”).
      </div>
      <div>
        Gamma is committed to protecting your privacy. This Privacy Policy includes guidelines for
        protecting your information and, depending on your jurisdiction, describing your rights
        under applicable data protection laws. This Privacy Policy discloses what type of
        information is collected, the methods used to collect it, how we utilize the information to
        better serve all interested parties and the choices you have about the way your information
        is collected and used.
      </div>
      <div>
        Please read this Privacy Policy carefully. If you do not agree with our policies and
        practices, your choice is not to use our Service. By accessing or using the Service, you
        agree to this Privacy Policy. This Policy may change from time to time (see Changes to the
        Privacy Policy section below). Your continued use of the Service after we make changes is
        deemed to be acceptance of those changes, so please check this Privacy Policy periodically
        for updates.
      </div>
      <div>
        For purposes of this Privacy Policy, “NFT” means a non-fungible token or similar digital
        item implemented on a blockchain (such as the Bitcoin blockchain), which uses smart
        contracts to link to or otherwise be associated with certain content or data.
      </div>
      <ol>
        <li style={{ marginBottom: '10px' }}>
          <div style={{ textDecoration: 'underline', marginBottom: '10px' }}>
            WHAT INFORMATION DO WE COLLECT?
          </div>
          <div>
            We collect Personal Data and Anonymous Data as described below. For purposes of this
            Privacy Policy “Personal Data” means data that allows someone to identify you
            individually, including, for example, your name, email address, as well as any other
            non-public information about you that is associated with or linked to any of the
            foregoing and “Anonymous Data” means data, including aggregated and de-identified data,
            that is not associated with or linked to your Personal Data. Anonymous Data does not, by
            itself, permit the identification of individual persons.
          </div>
        </li>
        <li style={{ marginBottom: '10px' }}>
          <div style={{ textDecoration: 'underline', marginBottom: '10px' }}>
            HOW DO WE USE THIS DATA?
          </div>
          <div style={{ marginBottom: '10px' }}>
            We process your Personal Data to run our business, provide the Service, personalize your
            experience on the Service, and improve the Service. Specifically, we use your Personal
            Data to:
          </div>
          <ul style={{ marginBottom: '10px' }}>
            <li style={{ marginBottom: '10px' }}>
              provide you with access to our Site and the Service;
            </li>
            <li style={{ marginBottom: '10px' }}>enhance our Service;</li>
            <li style={{ marginBottom: '10px' }}>
              improve the functionality and user-friendliness of the Service;
            </li>
            <li style={{ marginBottom: '10px' }}>
              provide customer support and respond to your requests, inquiries, and feedback;
            </li>
            <li style={{ marginBottom: '10px' }}>
              detect, prevent, and address fraud, violations of our terms or policies, including
              this Privacy Policy and the Terms of Use, and/or other harmful or unlawful activity;
            </li>
            <li style={{ marginBottom: '10px' }}>
              display your username next to the NFTs currently or previously accessible in your
              third-party wallet, and next to NFTs on which you have interacted;
            </li>
            <li style={{ marginBottom: '10px' }}>
              send you administrative notifications and security, support, and maintenance
              advisories;
            </li>
            <li style={{ marginBottom: '10px' }}>
              send you notifications related to actions on the Service, including notifications of
              offers on your NFTs;
            </li>
            <li style={{ marginBottom: '10px' }}>
              send you newsletters, promotional materials, and other notices related to our Services
              or third parties’ goods and services (subject to your preferences discussed in Section
              8(a) below);
            </li>
            <li style={{ marginBottom: '10px' }}>
              comply with applicable laws, cooperate with investigations by law enforcement or other
              authorities of suspected violations of law, and/or to pursue or defend against legal
              threats and/or claims; and
            </li>
            <li>act in any other way we may describe when you provide the Personal Data.</li>
          </ul>
          <div style={{ marginBottom: '10px' }}>
            We may create Anonymous Data records from Personal Data. We use this Anonymous Data to
            analyze request and usage patterns so that we may improve our Services and enhance
            Service navigation. We reserve the right to use Anonymous Data for any purpose and to
            disclose Anonymous Data to third parties without restriction (subject to applicable law,
            if any).
          </div>
        </li>
        <li style={{ marginBottom: '10px' }}>
          <div style={{ textDecoration: 'underline', marginBottom: '10px' }}>
            HOW LONG DO WE KEEP YOUR DATA?
          </div>
          <div style={{ marginBottom: '10px' }}>
            We keep your data if it is necessary to process your requests, operate our business and
            provide our Service, or as long as we are legally required to do so. For as long as we
            have your data, we will continue to protect the privacy and security of such data
            consistent with the Privacy Policy.
          </div>
        </li>
        <li style={{ marginBottom: '10px' }}>
          <div style={{ textDecoration: 'underline', marginBottom: '10px' }}>
            HOW DO WE PROTECT YOUR INFORMATION?
          </div>
          <div style={{ marginBottom: '10px' }}>
            We take precautions to protect your personal information, including reasonable physical,
            administrative, and technical safeguards to preserve the integrity and security of
            information collected through our Service. When you submit personal information via our
            Site and Service, your personal information is transmitted using secure sockets layer
            (SSL) encryption technology. We restrict access to your personal information to our
            authorized personnel and/or third-party providers as appropriate and necessary to
            provide our Site and products.
          </div>
          <div style={{ marginBottom: '10px' }}>
            The safety and security of your information also depends on you. You are responsible for
            the security of your digital wallet, and we urge you to take steps to ensure it is and
            remains secure. If you discover an issue related to your wallet, please contact your
            wallet provider. Additionally, if you create an account through our Site, you are
            responsible for keeping your user credentials confidential. We ask you not to share your
            passwords or secret keys with anyone.
          </div>
          <div style={{ marginBottom: '10px' }}>
            Unfortunately, the transmission of information via the internet is not completely
            secure. Although we do our best to protect your personal information, we cannot
            guarantee the security of your personal information transmitted using the Service. Any
            transmission of personal information is at your own risk. We are not responsible for
            circumvention of any privacy settings or security measures contained on the Site.
          </div>
        </li>
        <li style={{ marginBottom: '10px' }}>
          <div style={{ textDecoration: 'underline', marginBottom: '10px' }}>
            DO WE SHARE YOUR INFORMATION?
          </div>
          <div style={{ marginBottom: '10px' }}>
            We disclose your Personal Data as described below and as described elsewhere in this
            Privacy Policy.
          </div>
          <ol style={{ marginBottom: '10px', listStyle: 'lower-alpha' }}>
            <li style={{ marginBottom: '10px' }}>
              <span style={{ textDecoration: 'underline' }}>Third Party Service Providers</span>. We
              may share your Personal Data with third party service providers to: provide technical
              infrastructure services; conduct quality assurance testing; analyze how our Service is
              used; prevent, detect, and respond to unauthorized activities; provide technical and
              customer support; and/or to provide other support to us and to the Service.
            </li>
            <li style={{ marginBottom: '10px' }}>
              <span style={{ textDecoration: 'underline' }}>Affiliates</span>. We may share some or
              all of your Personal Data with any subsidiaries, joint ventures, or other companies
              under our common control (“Affiliates”), in which case we will require our Affiliates
              to honor this Privacy Policy.
            </li>
            <li style={{ marginBottom: '10px' }}>
              <span style={{ textDecoration: 'underline' }}>Corporate Restructuring</span>. We may
              share some or all of your Personal Data in connection with or during negotiation of
              any merger, financing, acquisition, or dissolution transaction or proceeding involving
              sale, transfer, divestiture, or disclosure of all or a portion of our business or
              assets. In the event of an insolvency, bankruptcy, or receivership, Personal Data may
              also be transferred as a business asset. If another company acquires our company,
              business, or assets, that company will possess the Personal Data collected by us and
              will assume the rights and obligations regarding your Personal Data as described in
              this Privacy Policy.
            </li>
            <li style={{ marginBottom: '10px' }}>
              <span style={{ textDecoration: 'underline' }}>Legal Requirements</span>. We may have
              to share your Personal Data to comply with any court order, law, or legal process,
              including to respond to any government or regulatory request.
            </li>
            <li>
              <span style={{ textDecoration: 'underline' }}>Other Disclosures</span>. We may also
              disclose your Personal Data to fulfill the purpose for which you provide it, for any
              other purpose disclosed by us when you provide it, or with your consent.
            </li>
          </ol>
          <div style={{ marginBottom: '10px' }}>
            We do not sell or share your personal information with any unaffiliated third party for
            marketing purposes.
          </div>
        </li>
        <li style={{ marginBottom: '10px' }}>
          <div style={{ textDecoration: 'underline', marginBottom: '10px' }}>
            LINKS TO THIRD-PARTY WEBSITES.
          </div>
          <div style={{ marginBottom: '10px' }}>
            Our Service may contain links to third-party websites. When you click on a link to any
            other website or location, you will leave our Service and go to another site, and
            another entity may collect Personal Data from you. We have no control over, do not
            review, and cannot be responsible for these third-party websites or their content.
            Please be aware that the terms of this Privacy Policy do not apply to these third-party
            websites or their content, or to any collection of your Personal Data after you click on
            links to such third-party websites. We encourage you to read the privacy policies of
            every website you visit. Any links to third-party websites or locations are for your
            convenience and do not signify our endorsement of such third parties or their products,
            content, or websites.
          </div>
        </li>
        <li style={{ marginBottom: '10px' }}>
          <div style={{ textDecoration: 'underline', marginBottom: '10px' }}>
            THIRD-PARTY WALLETS.
          </div>
          <div style={{ marginBottom: '10px' }}>
            To use our Service, you must use a third-party wallet (e.g., Hiro) which allows you to
            engage in transactions on public blockchains. Your interactions with any third-party
            wallet provider are governed by the applicable terms of service and privacy policy of
            that third party.
          </div>
        </li>
        <li style={{ marginBottom: '10px' }}>
          <div style={{ textDecoration: 'underline', marginBottom: '10px' }}>
            YOUR CHOICES REGARDING INFORMATION.
          </div>
          <div style={{ marginBottom: '10px' }}>
            You have several choices regarding the use of information on our Services:
          </div>
          <ol style={{ marginBottom: '10px', listStyle: 'lower-alpha' }}>
            <li style={{ marginBottom: '10px' }}>
              <span style={{ textDecoration: 'underline' }}>Email Communicaitons</span>. We may
              periodically send you newsletters and/or emails that directly promote the use of our
              Service or third parties’ goods and services. When you receive newsletters or
              promotional communications from us, you may indicate a preference to stop receiving
              these communications from us by following the unsubscribe instructions provided in the
              email you receive or through the “Notifications” preferences in your Settings page.
              Despite these preferences, we may send you occasional transactional service-related
              informational communications.
            </li>
            <li>
              <span style={{ textDecoration: 'underline' }}>Cookies</span>. If you decide at any
              time that you no longer wish to accept cookies from our Service for any of the
              purposes described above, you can change your browser’s settings to stop accepting
              cookies or to prompt you before accepting a cookie from the websites you visit. If you
              do not accept cookies, however, you may not be able to use all portions of the Service
              or all functionality of the Service.
            </li>
          </ol>
        </li>
        <li style={{ marginBottom: '10px' }}>
          <div style={{ textDecoration: 'underline', marginBottom: '10px' }}>YOUR RIGHTS.</div>
          <div style={{ marginBottom: '10px' }}>
            You can view, access, edit, or delete your Personal Data for certain aspects of the
            Service via your Settings page. Depending on where you live, you may have certain legal
            rights under applicable law, including but not limited to, the European and UK General
            Data Protection Regulations (“GDPR”) and the California Consumer Privacy Act (“CCPA”).
            For example, you may have the following rights:
          </div>
          <div style={{ marginBottom: '10px' }}>
            You may contact us at any time about any of these rights or any concern or question you
            have about our use of your Personal Data. If you wish to exercise your rights under the
            GDPR, CCPA, or other applicable data protection or privacy laws, please contact us at
            privacy@gamma.io, specify your request, and reference the applicable law. We may ask you
            to verify your identity, or ask for more information about your request. We will
            consider and act upon any above request in accordance with applicable law. We will not
            discriminate against you for exercising any of these rights. Please note that if you
            choose to delete your information or opt-out of the collection and use of your
            information, certain features of our Service or the Site may no longer be available to
            you.
          </div>
          <ul style={{ marginBottom: '10px' }}>
            <li style={{ marginBottom: '10px' }}>
              <span style={{ textDecoration: 'underline' }}>Right to limit</span> - means you can
              ask us to restrict or stop processing your Personal Data, if it not necessary for the
              purpose you provided it to us.
            </li>
            <li style={{ marginBottom: '10px' }}>
              <span style={{ textDecoration: 'underline' }}>Right to access</span> - means you can
              ask us for a copy of any Personal Data we have about you, if any.
            </li>
            <li style={{ marginBottom: '10px' }}>
              <span style={{ textDecoration: 'underline' }}>Right to correct</span> - means you may
              ask us to change and/or correct any Personal Data we have about you.
            </li>
            <li style={{ marginBottom: '10px' }}>
              <span style={{ textDecoration: 'underline' }}>Right to delete</span> - means you may
              ask us to delete any Personal Data we have about you and we will be happy to do so
              unless we are required to retain such information by law or regulation, or we have a
              right to retain subject to a user agreement for our internal business purposes.
            </li>
            <li>
              <span style={{ textDecoration: 'underline' }}>Right to transfer</span> - means you may
              request a copy of your Personal Data, in a commonly used and machine-readable format,
              be provided to you or to a third party you specify.
            </li>
          </ul>
          <div style={{ marginBottom: '10px' }}>
            Notwithstanding the above, we cannot edit or delete any information that is stored on a
            blockchain (e.g., the Bitcoin blockchain), as we do not have custody or control over any
            blockchains. The information stored on the blockchain may include purchases, sales, and
            transfers related to your blockchain address and NFTs held at that address.
          </div>
        </li>
        <li style={{ marginBottom: '10px' }}>
          <div style={{ textDecoration: 'underline', marginBottom: '10px' }}>
            PROTECTION OF CHILDREN.
          </div>
          <div style={{ marginBottom: '10px' }}>
            Our Site is not intended for children under the age of 13 (or 16 where applicable). We
            do not knowingly collect information from children under the age of 13 (or 16 where
            applicable). No one under the age of 13 (or 16 where applicable) may provide any
            information to or on the Site. If you are under the age of 13, do not use or provide any
            information on this Site. If we learn we have collected or received personal information
            from a child under 13 (or 16 where applicable) without verification or parental consent,
            we will delete that information. If you believe that we might have any information from
            or about a child under 13 (or 16), please contact us at privacy@gamma.io and provide us
            sufficient detail to enable us to delete that information from our databases.
          </div>
        </li>
        <li style={{ marginBottom: '10px' }}>
          <div style={{ textDecoration: 'underline', marginBottom: '10px' }}>
            CONSENT TO PROCESSING IN THE UNITED STATES.
          </div>
          <div style={{ marginBottom: '10px' }}>This Site is operated in the United States.</div>
          <div style={{ marginBottom: '10px' }}>
            By providing any information to Gamma, all users, including, without limitation, users
            in the member states of the European Union, Canada or elsewhere outside of the United
            States, fully understand and unambiguously consent to this Privacy Policy. By using our
            Site, participating in any of our services and/or providing us with your information,
            you consent and agree to the collection, transfer, storage and processing of your
            information to and in the United States.{' '}
          </div>
          <div style={{ marginBottom: '10px' }}>
            Personal Data is processed according to the provisions of this Privacy Policy and the
            requirements of applicable law wherever the data is located. If you are located outside
            of the United States, please be aware that data protection laws in the United States may
            differ from those of the country in which you are located, and your Personal Data can be
            subject to access requests from governments, courts, or law enforcement in the United
            States in accordance with and subject to the laws of the United States.
          </div>
        </li>
        <li style={{ marginBottom: '10px' }}>
          <div style={{ textDecoration: 'underline', marginBottom: '10px' }}>
            INTERNATIONAL TRANSFERS.
          </div>
          <div style={{ marginBottom: '10px' }}>
            Although our systems are generally located in the United States, we may also locate
            systems in other locations where we or our service providers maintain facilities. The
            storage location(s) are chosen in order to operate efficiently, to improve performance,
            and to create redundancies in order to protect the data in the event of a server outage
            or security incident. In addition, your Personal Data may be processed in the country in
            which it was collected and in other countries, including the United States, where laws
            regarding processing of Personal Data may be less stringent than the laws in your
            country. By using the Site and providing Personal Data, you expressly consent to any
            transfer of information outside of your country.{' '}
          </div>
        </li>
        <li style={{ marginBottom: '10px' }}>
          <div style={{ textDecoration: 'underline', marginBottom: '10px' }}>
            CHANGES TO THIS PRIVACY POLICY.
          </div>
          <div style={{ marginBottom: '10px' }}>
            We reserve the right, in our sole discretion, to modify, update, add to, discontinue,
            remove or otherwise change any portion of this Privacy Policy, in whole or in part, at
            any time. When we amend this Privacy Policy, we will revise the “last updated” date
            located at the top of this Privacy Policy.{' '}
          </div>
          <div style={{ marginBottom: '10px' }}>
            For changes to this Privacy Policy that we consider to be material, we will notify you
            through a notice on the Site’s homepage requesting a click-through consent. You are
            responsible for periodically visiting our Service and this Privacy Policy to check for
            any changes.
          </div>
        </li>
        <li style={{ marginBottom: '10px' }}>
          <div style={{ textDecoration: 'underline', marginBottom: '10px' }}>
            QUESTIONS; CONTACTING GAMMA; REPORTING VIOLATIONS.
          </div>
          <div>
            If you have any questions, concerns, or complaints about our Privacy Policy or our data
            collection or processing practices, or if you want to report any security violations to
            us, please contact us by using the “Submit a request” link{' '}
            <a
              href="https://support.gamma.io/hc/en-us/requests/new"
              target="_blank"
              rel="noopener noreferrer"
            >
              here
            </a>{' '}
            or at privacy@gamma.io.
          </div>
        </li>
        <li style={{ marginBottom: '10px' }}>
          <div style={{ textDecoration: 'underline', marginBottom: '10px' }}>Other Notices</div>
          <ol style={{ listStyleType: 'lower-alpha' }}>
            <li style={{ marginBottom: '10px' }}>
              <span style={{ textDecoration: 'underline' }}>No Endorsement</span>. Collections
              deployed using the Portal are not owned by, nor affiliated in any way, with Gamma.
              While we automatically collect 10% of mint revenues via the smart contracts deployed
              using our Portal, this in no way serves to acknowledge or endorse any given
              collection. This is an unmonitored, unmodifiable smart contract template that we do
              not have control over following deployment.
            </li>
            <li style={{ marginBottom: '10px' }}>
              <span style={{ textDecoration: 'underline' }}>No Guaranty</span>. Using our Portal
              does not guarantee your collection will be listed for mint or secondary sales on our
              marketplace. We simply offer this tool to help you with the creation of a tested and
              secure smart contract for your collection. After submission, we will review your
              details once again, and assuming it continues to meet all requirements, we will then
              follow-up with your mint page details.
            </li>
            <li style={{ marginBottom: '10px' }}>
              <span style={{ textDecoration: 'underline' }}>Removal and Control</span>. We reserve
              the right to remove collection mint pages, or prevent collections from appearing on
              our main marketplace for any or no reason if they are found to violate our standards
              or infringe upon any other person or entity's copyright or any law.
            </li>
            <li style={{ marginBottom: '10px' }}>
              <span style={{ textDecoration: 'underline' }}>Other Marketplaces</span>. Gamma will
              not, and has no ability to, prevent your continued use of your smart contract on other
              websites, such as your own or any other marketplace.
            </li>
          </ol>
        </li>
      </ol>
    </ContainerFull>
  );
}
