import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import styles from './index.module.scss';

import { darkmodeListener } from '../../theme';
import { gaSend } from '../../Analytics';

import makeEndpoint from '../../endpoints';

import Button from '../../components/Button';
import Card from '../../components/Card';
import Loading from '../../components/Loading';
import Renderer from '../../components/Renderer';

import icon from '../../components/Login/assets/icon.svg';

import {
  claim,
  getMintpassEnabled,
  getMintpasses,
  getPrice,
  getPublicSaleEnabled,
  getMintLimit,
  network,
  getAltPrice,
  getPaused,
  getSTXInBTC,
  swapBTCforSTX,
  ftClaim,
} from '../../utils/SelfServe/contracts';

import { AppConfig, UserSession, showConnect } from '@stacks/connect';

import InfiniteScroll from 'react-infinite-scroll-component';

import { renderer } from '../../utils';
import Modal from '../../components/Modal';
import Confirm from '../../components/Confirm';
import AdminPanel from './AdminPanel';
import UserTip from '../../components/UserTip';
import MintPriceIcon from '../../components/MintPriceIcon';
import { ContainerFull } from '../../components/common';
import { MintButton } from '../../components/buttons';
import { Link } from 'react-router-dom';

// import { getSTXInBTC, swapBTCforSTX } from "../../utils/swap";

const appConfig = new AppConfig(['store_write', 'publish_data']);
const userSession = new UserSession({ appConfig });

const axios = require('axios');

declare const lnswap: any;

type CollectionStandardProps = {
  name: string;
  collectionData?: any;
};

type CollectionStandardState = {
  name: string;
  contractAddress: string;
  contractName: string;
  description: string;
  image: string;
  total: number;
  lastTokenID: number;
  prices: number;
  currency?: string;
  saleData: Record<string, any>;
  sale: Array<any>;
  active: boolean;
  paused: boolean;
  userData?: any;
  offset: number;
  contractType: string;
  mintpassEnabled: boolean;
  saleEnabled: boolean;
  mintpassStateChecked: boolean;
  saleStateChecked: boolean;
  hasMintpasses: boolean;
  passesRemaining: number;
  collectionData: any;
  mintPriceInput: string;
  mintPriceError?: string;
  altPriceInputs: Array<any>;
  altPriceErrors: Array<any>;
  mintLimitInput: string;
  mintLimitError?: string;
  altCurrencies: Array<any>;
  modal?: any;
  showAdmin: boolean;
  currencySelected: string;
  numberSelected: number;
  claimOptions: Array<any>;
  airdropEnabled: boolean;
  fts: Array<any>;
  revealEnabled: boolean;
  revealData: any;
  websiteUrl: string;
  websiteText: string;
  showSafetyTip: boolean;
  showLightningTip: boolean;
  functionName: string;
  pairs?: any;
  lightningEnabled: boolean;
  artistAddress: string;
  btcAmount: number;
  alexCurrencies: Array<any>;
  showAlexTip: boolean;
  enlargeImage: boolean;
};

const fetchSize = 25;
class CollectionStandard extends Component<CollectionStandardProps, CollectionStandardState> {
  state = {
    name: '',
    contractAddress: '',
    artistAddress: '',
    contractName: '',
    description: '',
    image: '',
    total: 0,
    saleData: {},
    sale: [],
    lastTokenID: 0,
    prices: 0,
    currency: undefined,
    active: false,
    paused: false,
    userData: undefined,
    offset: 1,
    contractType: '',
    mintpassEnabled: false,
    saleEnabled: false,
    mintpassStateChecked: false,
    saleStateChecked: false,
    hasMintpasses: false,
    passesRemaining: 0,
    collectionData: undefined,
    mintPriceInput: '',
    mintPriceError: undefined,
    altPriceInputs: [],
    altPriceErrors: [],
    mintLimitInput: '',
    mintLimitError: undefined,
    altCurrencies: [],
    modal: undefined,
    showAdmin: false,
    currencySelected: 'STX',
    numberSelected: 1,
    claimOptions: [],
    airdropEnabled: false,
    fts: [],
    revealEnabled: false,
    revealData: {},
    websiteUrl: '',
    websiteText: '',
    showSafetyTip: false,
    showLightningTip: false,
    functionName: '',
    pairs: undefined,
    lightningEnabled: false,
    btcAmount: -1,
    alexCurrencies: [] as any,
    showAlexTip: false,
    enlargeImage: false,
  };

  async componentDidMount() {
    try {
      const userData = localStorage.getItem('userData');
      if (userData) {
        this.setState({
          userData: JSON.parse(userData),
        });
      }
    } catch {}

    darkmodeListener(styles['theme-light'], styles['theme-dark']);

    this.setState(
      {
        name: this.props.collectionData.name,
        contractAddress: this.props.collectionData.contractAddress,
        contractName: this.props.collectionData.contractName,
        description: this.props.collectionData.description,
        image: this.props.collectionData.image,
        total: parseInt(this.props.collectionData.total),
        prices: parseInt(this.props.collectionData.prices),
        active: this.props.collectionData.active,
        paused: this.props.collectionData.paused,
        currency: this.props.collectionData.currency,
        contractType: this.props.collectionData.contractType,
        collectionData: this.props.collectionData,
        saleEnabled: this.props.collectionData.saleEnabled,
        altCurrencies: this.props.collectionData.altCurrencies,
        claimOptions: this.props.collectionData.claimOptions,
        airdropEnabled: this.props.collectionData.airdropEnabled,
        revealEnabled: this.props.collectionData.revealEnabled,
        websiteUrl: this.props.collectionData.websiteUrl,
        websiteText: this.props.collectionData.websiteText,
        lightningEnabled: this.props.collectionData.lightningEnabled,
        artistAddress: this.props.collectionData.artistAddress,
        alexCurrencies: this.props.collectionData.alexCurrencies,
        enlargeImage: this.props.collectionData.enlargeImage,
      },
      () => {
        if (this.props.collectionData.revealEnabled) {
          const endpoint = makeEndpoint(
            `/get/reveal?address=${this.props.collectionData.contractAddress}&name=${this.props.collectionData.contractName}`,
          );
          axios
            .get(endpoint, {
              auth: {
                username: process.env.REACT_APP_LOGIN,
                password: process.env.REACT_APP_PASSWORD,
              },
            })
            .then((res: any) => {
              this.setState({ revealData: res.data });
            })
            .catch((err: any) => console.log(err));
        }

        if (this.props.collectionData.lightningEnabled) {
          fetch('https://api.lnswap.org:9007/getpairs')
            .then((res) => {
              return res.json();
            })
            .then((pairs) => {
              this.setState({ pairs });
            });
        }

        if (Number(this.props.collectionData.prices) >= 50000000) {
          const amount = Number(this.amount());
          if (isNaN(amount)) {
            return;
          }

          getSTXInBTC(amount / 1e6).then((res) => this.setState({ btcAmount: res * 1.04 }));
        }

        if (
          this.props.collectionData.contractType == 'mintpass' &&
          this.stxAddress() != null &&
          !this.state.saleEnabled
        ) {
          getMintpassEnabled(
            this.props.collectionData.contractAddress,
            this.props.collectionData.contractName,
            this.setMintpassEnabled.bind(this),
          );

          if (this.props.collectionData.overrideSaleEnabled) {
            this.setPublicSaleEnabled(true);
          } else if (contractName != 'the-guests') {
            getPublicSaleEnabled(
              this.props.collectionData.contractAddress,
              this.props.collectionData.contractName,
              this.setPublicSaleEnabled.bind(this),
            );
          }

          getMintpasses(
            this.props.collectionData.contractAddress,
            this.props.collectionData.contractName,
            this.stxAddress(),
            this.setMintpasses.bind(this),
          );
        }

        getPrice(
          this.props.collectionData.contractAddress,
          this.props.collectionData.contractName,
          this.setPrice.bind(this),
        );

        getMintLimit(
          this.props.collectionData.contractAddress,
          this.props.collectionData.contractName,
          this.setTotal.bind(this),
        );

        if (this.props.collectionData.overridePaused) {
          this.setPaused(false);
        } else {
          getPaused(
            this.props.collectionData.contractAddress,
            this.props.collectionData.contractName,
            this.setPaused.bind(this),
          );
        }

        if (
          this.props.collectionData.altCurrencies != null &&
          this.props.collectionData.altCurrencies.length > 0
        ) {
          let altInputs: Array<any> = [];
          let altErrors: Array<any> = [];

          for (let i = 0; i < this.props.collectionData.altCurrencies.length; i++) {
            const currency = this.props.collectionData.altCurrencies[i];
            const input = { symbol: currency['symbol'], input: '' };
            const error = { symbol: currency['symbol'], error: '' };
            altInputs.push(input);
            altErrors.push(error);

            getAltPrice(
              this.props.collectionData.contractAddress,
              this.props.collectionData.contractName,
              currency['symbol'].toLowerCase(),
              this.setAltPrice.bind(this),
            );
          }

          this.setState({
            altPriceInputs: altInputs,
            altPriceErrors: altErrors,
          });
        }

        if (
          this.props.collectionData.altCurrencies != null &&
          this.props.collectionData.altCurrencies.length > 0 &&
          this.props.collectionData.claimOptions != null &&
          this.props.collectionData.claimOptions.length > 0 &&
          !this.props.collectionData.claimOptions[0]['STX']
        ) {
          this.setState({
            currencySelected: this.props.collectionData.altCurrencies[0]['symbol'],
          });
        }

        var contractName = this.props.collectionData.contractName.replace(
          'panda-mint',
          'panda-nft',
        );
        contractName = contractName.replace('htl-mint', 'htl');
        contractName = contractName.replace('commoners-mint', 'commoners');
        contractName = contractName.replace('trubit-domino-mint', 'trubit-domino');
        contractName = contractName.replace('stacks-parrots-3d-mint', 'stacks-parrots-3d');
        fetch(
          makeEndpoint(
            '/api/nft/lastTokenID?address=' +
              this.props.collectionData.contractAddress +
              '&name=' +
              contractName +
              '&reload=true',
          ),
        );
        fetch(
          makeEndpoint(
            '/api/nft/lastTokenID?address=' +
              this.props.collectionData.contractAddress +
              '&name=' +
              contractName,
          ),
        )
          .then((res) => {
            return res.text();
          })
          .then((lastTokenStr) => {
            if (lastTokenStr === 'null') {
              return;
            }
            const lastToken = parseInt(lastTokenStr, 10);
            this.setState(
              {
                lastTokenID: lastToken,
              },
              () => {
                this.fetchNFTs();
              },
            );
          });
      },
    );

    fetch(makeEndpoint('/api/fts'))
      .then((res) => {
        return res.json();
      })
      .then((json) => {
        this.setState({ fts: json.tokens });
      })
      .catch((err) => console.log(err));
  }

  fetchNFTs() {
    if (this.state.contractName.includes('panda-mint')) {
      return;
    }
    var nfts: Array<any> = [];
    var numNFTs = Math.min(this.state.lastTokenID, fetchSize);
    if (this.state.lastTokenID < this.state.offset + numNFTs) {
      const diff = this.state.offset + numNFTs - this.state.lastTokenID;
      numNFTs -= diff;
    }

    var contractName = this.state.contractName.replace('panda-mint', 'panda-nft');
    contractName = contractName.replace('htl-mint', 'htl');
    contractName = contractName.replace('commoners-mint', 'commoners');
    contractName = contractName.replace('trubit-domino-mint', 'trubit-domino');
    contractName = contractName.replace('stacks-parrots-3d-mint', 'stacks-parrots-3d');
    for (var x = this.state.offset; x <= this.state.offset + numNFTs; x++) {
      nfts.push({
        contractAddress: this.state.contractAddress,
        tokenID: x,
        contractName: contractName,
        sold: true,
      });
    }

    var sale: Array<any> = this.state.sale;
    const filtered_nfts = nfts.filter((r: any) => {
      let filtered_list = sale.filter(
        (l: any) =>
          l.contractAddress == r.contractAddress &&
          l.contractName == r.contractName &&
          String(l.tokenID) == String(r.tokenID),
      );
      return filtered_list.length == 0;
    });

    sale.push(...filtered_nfts);
    this.setState(
      {
        sale: sale,
      },
      () => {
        this.fetchData(nfts);
      },
    );
  }

  getLength() {
    return this.state.sale.length;
  }

  fetchData(nfts: any) {
    for (var nft of nfts) {
      var contractName = nft.contractName.replace('panda-mint', 'panda-nft');
      contractName = contractName.replace('htl-mint', 'htl');
      contractName = contractName.replace('commoners-mint', 'commoners');
      var params = '';
      params += 'address=' + nft.contractAddress;
      params += '&name=' + contractName;
      params += '&id=' + nft.tokenID;
      const nftId = nft.contractAddress + '.' + contractName + ':' + nft.tokenID;
      var saleDataRecord: any = this.state.saleData;
      if (saleDataRecord[nftId]) {
        continue;
      }
      // fetch(makeEndpoint('/api/nft?') + params)
      fetch('https://legacy.stxnft.com/api/nft?' + params)
        .then((res) => res.json())
        .then((nftData) => {
          if (nftData.name) {
            var saleData = saleDataRecord;
            saleData[nftId] = nftData;
            this.setState({ saleData });
          }
        })
        .catch((err) => console.log(err));
    }
  }

  callLnswap = () => {
    lnswap(
      'swap',
      'triggerswap',
      this.stxAddress(),
      this.state.prices / 1000000,
      `${this.state.contractAddress}.${this.state.contractName}`,
      'claim',
      'true',
    ); // @ts-ignore
  };

  callAlexClaim = () => {
    if (!this.state.btcAmount) {
      return;
    }
    const ftPrice = Math.round(Number(this.state.btcAmount * 100000000));

    ftClaim(
      this.stxAddress(),
      this.state.contractAddress,
      this.state.contractName,
      this.state.currencySelected,
      ftPrice,
      this.state.prices * 100,
      this.setModal.bind(this),
    ); // @ts-ignore
  };

  getTotal() {
    return this.state.total;
  }

  getAvailable() {
    return this.getTotal() - this.state.lastTokenID;
  }

  getVolumeSold() {
    return this.state.lastTokenID * this.getFloorPrice();
  }

  getFloorPrice() {
    if (!this.state.currency) {
      return this.state.prices / 1e6;
    } else {
      return this.state.prices;
    }
  }

  nFormatter(num: number, digits: number) {
    const lookup = [
      { value: 1, symbol: '' },
      { value: 1e3, symbol: 'K' },
      { value: 1e6, symbol: 'M' },
      { value: 1e9, symbol: 'B' },
    ];
    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    var item = lookup
      .slice()
      .reverse()
      .find(function (item) {
        return num >= item.value;
      });
    return item ? (num / item.value).toFixed(digits).replace(rx, '$1') + item.symbol : '0';
  }

  renderCard() {
    return this.state.sale.map((x: any, idx) => {
      const saleData: any = this.state.saleData;
      const fullData: any = saleData[x.contractAddress + '.' + x.contractName + ':' + x.tokenID];
      if (fullData) {
        return (
          <Link to={`/collection/${x.contractName}/${x.tokenID}`}>
            <Card
              key={idx}
              renderer={renderer(fullData, undefined, 600)}
              collection={fullData.collection || fullData.name}
              name={fullData.name}
              currency={this.state.currency}
              // sold={this.state.prices}
            />
          </Link>
        );
      } else if (x.contractName) {
        const titled = x.contractName[0].toUpperCase() + x.contractName.substring(1);
        return (
          <Card
            key={idx}
            collection={titled}
            renderer={<Renderer size={600} />}
            currency={this.state.currency}
            name={titled + ' #' + x.tokenID}
          />
        );
      }
    });
  }

  amount() {
    let price = this.state.prices;
    if (
      this.state.altCurrencies != null &&
      this.state.altCurrencies.length > 0 &&
      this.state.currencySelected != 'STX'
    ) {
      for (let i = 0; i < this.state.altCurrencies.length; i++) {
        const currency = this.state.altCurrencies[i];
        if (currency['symbol'] == this.state.currencySelected) {
          price = currency['price'];
          break;
        }
      }
    }
    return price * this.state.numberSelected;
  }

  fiveAmount() {
    return this.state.prices * 5;
  }

  tenAmount() {
    return this.state.prices * 10;
  }

  mintText() {
    let mintText;
    if (this.state.active && this.state.paused) {
      mintText = 'Minting Paused';
    } else if (!this.state.active) {
      mintText = 'Pending Review';
    } else if (
      Math.floor(this.state.lastTokenID) >= Math.floor(this.state.total) &&
      this.state.total > 0
    ) {
      mintText = 'Visit Marketplace';
    } else if (
      this.state.contractType == 'mintpass' &&
      !this.state.mintpassEnabled &&
      !this.state.saleEnabled &&
      this.state.userData
    ) {
      mintText = 'Minting Closed';
    } else if (
      this.state.contractType == 'mintpass' &&
      this.state.mintpassEnabled &&
      !this.state.saleEnabled &&
      !this.state.hasMintpasses &&
      this.state.userData
    ) {
      mintText = 'Mintpass Only';
    } else if (this.state.userData) {
      this.state.numberSelected > 1
        ? (mintText = 'Mint ' + this.state.numberSelected)
        : (mintText = 'Mint');
    } else {
      mintText = 'Connect Stacks Wallet';
    }
    return mintText;
  }

  renderPrice() {
    return (
      !(
        (this.state.active && this.state.paused) ||
        !this.state.active ||
        (Math.floor(this.state.lastTokenID) >= Math.floor(this.state.total) &&
          this.state.total > 0) ||
        (this.state.contractType == 'mintpass' &&
          !this.state.mintpassEnabled &&
          !this.state.saleEnabled) ||
        (this.state.contractType == 'mintpass' &&
          this.state.mintpassEnabled &&
          !this.state.hasMintpasses)
      ) && this.state.userData
    );
  }

  stxAddress() {
    if (this.state.userData && network.isMainnet()) {
      const userData: any = this.state.userData;
      return userData.profile.stxAddress.mainnet;
    } else if (this.state.userData && !network.isMainnet()) {
      const userData: any = this.state.userData;
      return userData.profile.stxAddress.testnet;
    } else {
      return null;
    }
  }

  authenticate() {
    showConnect({
      appDetails: {
        name: 'Stacks NFT',
        icon: icon,
      },
      redirectTo: '/',
      onFinish: () => {
        gaSend('User', 'authenticate', 'User Authenticated');
        let userData = userSession.loadUserData();
        this.setState({ userData: userData });
        localStorage.setItem('userData', JSON.stringify(userData));
        window.location.reload();
      },
      userSession: userSession,
    });
  }

  openSafetyTip() {
    this.setState({ showSafetyTip: true });
  }

  closeSafetyTip(accepted?: boolean) {
    this.modalClose();
    this.setState({ showSafetyTip: false });
    if (accepted) {
      this.openClaim();
    }
  }

  openLightningTip() {
    this.setState({ showLightningTip: true });
  }

  closeLightningTip(accepted?: boolean) {
    this.modalClose();
    this.setState({ showLightningTip: false });
    if (accepted) {
      this.callLnswap();
    }
  }

  openAlexTip() {
    this.setState({ showAlexTip: true });
  }

  closeAlexTip(accepted?: boolean) {
    this.modalClose();
    this.setState({ showAlexTip: false });
    if (accepted) {
      this.callAlexClaim();
    }
  }

  openClaim() {
    // console.log(this.state.currencySelected);
    // console.log(this.amount());
    // return;

    if (this.state.userData) {
      claim(
        this.state.contractAddress,
        this.state.contractName,
        this.amount(),
        this.state.numberSelected,
        this.state.currencySelected,
        this.stxAddress(),
        this.setModal.bind(this),
        this.state.functionName,
        this.state.fts,
      );
    } else {
      this.authenticate();
    }
  }

  setTotal(total: number) {
    if (this.state.total != total) {
      console.log('total different');
      this.setState({
        total: total,
      });
      this.syncCollectionData(true);
    }
  }

  setPrice(price: number) {
    if (this.state.prices != price) {
      console.log('price different');
      this.setState({
        prices: price,
      });
      this.syncCollectionData(true);
    }
  }

  setPaused(paused: boolean) {
    if (this.state.paused != paused) {
      console.log('paused different');
      this.setState({
        paused: paused,
      });
      this.syncCollectionData(true);
    }
    // this.setState({
    //   paused: false,
    // });
  }

  setAltPrice(alt: any) {
    let currencies: Array<object> = this.state.altCurrencies;
    const isAlt = (element: any) => element['symbol'] == alt['symbol'];
    const altIndex = currencies.findIndex(isAlt);
    if (altIndex != -1) {
      const currency: any = currencies[altIndex];
      const price = currency['price'];
      if (price != alt['price']) {
        console.log(alt['symbol'] + ' alt price difference');

        currencies[altIndex] = alt;
        this.setState({
          altCurrencies: currencies,
        });
        this.syncCollectionData(true);
      }
    }
  }

  syncCollectionData(isAuto: boolean) {
    let data: any = this.state.collectionData;
    if (data) {
      data['prices'] = this.state.prices;
      data['total'] = this.state.total;
      data['paused'] = this.state.paused;
      data['saleEnabled'] = this.state.saleEnabled;
      data['altCurrencies'] = this.state.altCurrencies;
    }

    const url = makeEndpoint('/update');
    axios
      .post(url, data, {
        auth: {
          username: process.env.REACT_APP_LOGIN,
          password: process.env.REACT_APP_PASSWORD,
        },
      })
      .then(() => {
        console.log('Collection saved');
        if (!isAuto) {
          this.setModal({
            success: true,
          });
        }
      })
      .catch((err: any) => {
        console.error(err);
        if (!isAuto) {
          this.setModal({
            success: false,
            errorMessage: 'Sync failed',
          });
        }
      });
  }

  setMintpassEnabled(enabled: boolean) {
    this.setState({
      mintpassEnabled: enabled,
      mintpassStateChecked: true,
    });
  }

  setPublicSaleEnabled(enabled: boolean) {
    if (enabled && !this.state.saleEnabled) {
      this.setState({
        saleEnabled: enabled,
        saleStateChecked: true,
      });
      this.syncCollectionData(true);
    } else {
      this.setState({
        saleEnabled: enabled,
        saleStateChecked: true,
      });
    }
  }

  setMintpasses(passes: any) {
    console.log(`${this.stxAddress()} has ${passes} mintpasses`);

    this.setState({
      hasMintpasses: passes > 0,
      passesRemaining: passes,
    });
  }

  mintButton() {
    if (this.amount() >= 0 && this.renderPrice() && this.state.contractName == 'citybulls-nft') {
      return (
        <div className={styles.MintButton}>
          <Button
            text={this.mintText()}
            price={3000}
            currency="MIA"
            onClick={this.mintingBehavior.bind(this, this.state.numberSelected, 'MIA', 3000)}
          />
        </div>
      );
    }

    if (
      this.amount() >= 0 &&
      this.renderPrice() &&
      this.state.pairs &&
      this.state.currencySelected == 'BTC'
    ) {
      return (
        <div className={styles.MintButton}>
          <Button
            text={this.mintText()}
            price={
              ((this.state.prices / 1000000) * 1.02) / this.state.pairs['pairs']['BTC/STX']['rate']
            }
            currency={'BTC'}
            onClick={this.openLightningTip.bind(this)}
          />
        </div>
      );
    }

    if (
      this.amount() >= 50 &&
      this.renderPrice() &&
      this.state.currencySelected == 'xBTC'
      // this.state.alexCurrencies != null &&
      // this.state.alexCurrencies.includes(this.state.currencySelected)
    ) {
      if (this.state.btcAmount != -1) {
        return (
          <div className={styles.MintButton}>
            <Button
              text={this.mintText()}
              price={this.state.btcAmount}
              currency={this.state.currencySelected}
              onClick={this.openAlexTip.bind(this)}
            />
          </div>
        );
      } else {
        return (
          <div className={styles.MintButton}>
            <Button text="Loading" />
          </div>
        );
      }
    }

    if (this.amount() >= 0 && this.renderPrice()) {
      return (
        <div className={styles.MintButton}>
          <Button
            text={this.mintText()}
            price={this.amount()}
            currency={this.state.currencySelected}
            onClick={this.mintingBehavior.bind(
              this,
              this.state.numberSelected,
              this.state.currencySelected,
              this.amount(),
            )}
          />
        </div>
      );
    } else {
      return (
        <MintButton
          text={this.mintText()}
          onClick={this.mintingBehavior.bind(
            this,
            this.state.numberSelected,
            this.state.currencySelected,
            this.amount(),
          )}
        />
      );
    }
  }

  mintingBehavior(claimNumber: number, symbol: string, price: number) {
    if (!this.state.contractAddress || !this.state.contractName) {
      return;
    }

    // send to marketplace if sold out
    if (
      Math.floor(this.state.lastTokenID) >= Math.floor(this.state.total) &&
      this.state.total > 0
    ) {
      window.location.href = `https://stacks.gamma.io/collections/${this.state.contractAddress}.${this.state.contractName}/`;
      return;
    }

    // minting only disabled when paused if not contract owner
    if (this.state.paused && this.stxAddress() != this.state.contractAddress) {
      return;
    }

    // admin override
    if (!this.state.active) {
      return;
    }

    // both mintpass and sale have not been enabled yet mintpass contract
    if (
      this.state.contractType == 'mintpass' &&
      !this.state.mintpassEnabled &&
      !this.state.saleEnabled &&
      this.state.userData
    ) {
      return;
    }

    // mintpass only enabled and user doesn't have any mintpasses
    if (
      this.state.contractType == 'mintpass' &&
      this.state.mintpassEnabled &&
      !this.state.saleEnabled &&
      !this.state.hasMintpasses &&
      this.state.userData
    ) {
      return;
    }

    let functionName: string = 'claim';

    if (this.state.claimOptions != null && this.state.claimOptions.length > 0) {
      let options: Array<object> = this.state.claimOptions;
      const isOption = (element: any) => element['amount'] == this.state.numberSelected;
      const optionIndex = options.findIndex(isOption);
      if (optionIndex != -1) {
        const option: any = options[optionIndex];
        functionName = option[this.state.currencySelected];
      }
    } else if (symbol != 'STX') {
      functionName = functionName + '-' + symbol.toLowerCase();
    }

    this.setState({ functionName: functionName });

    this.openSafetyTip();
  }

  nextBlocks(offset: number) {
    this.setState(
      {
        offset: offset,
      },
      () => {
        this.fetchNFTs();
      },
    );
  }

  getBlocksNext() {
    this.nextBlocks(this.getLength());
  }

  hasMore() {
    return this.state.lastTokenID > this.state.offset;
  }

  setModal(modal: any) {
    this.setState({
      modal: modal,
    });
  }

  modalClose() {
    this.setState({
      modal: undefined,
    });
  }

  setCurrencySelected(currency: string) {
    this.setState({
      currencySelected: currency,
    });

    if (currency == 'xBTC') {
      this.setState({
        numberSelected: 1,
      });
    }
  }

  setNumberSelected(num: number) {
    this.setState({
      numberSelected: num,
    });
  }

  renderCurrencyButtons() {
    let altButtons: any = [];
    if (this.state.altCurrencies != null && this.state.altCurrencies.length > 0) {
      for (let i = 0; i < this.state.altCurrencies.length; i++) {
        const currency = this.state.altCurrencies[i];
        let symbol: string = currency['symbol'];
        if (symbol == 'BTC') {
          symbol = 'BTC (LN)';
        }
        if (symbol === 'MIA2' || symbol === 'NYC2') {
          symbol = symbol.replace('2', '');
        }
        const el = (
          <div style={{ margin: '10px' }} key={i + 1}>
            <Button
              text={symbol}
              style={this.state.currencySelected == currency['symbol'] ? 'PRIMARY' : 'SECONDARY'}
              onClick={this.setCurrencySelected.bind(this, currency['symbol'])}
            />
          </div>
        );
        altButtons.push(el);
      }
    }

    let alexButtons: any = [];

    if (this.state.prices >= 50000000) {
      let currency = 'xBTC';
      let symbol = 'xBTC (with ALEX)';
      const el = (
        <div style={{ margin: '10px' }}>
          <Button
            text={symbol}
            style={this.state.currencySelected == currency ? 'PRIMARY' : 'SECONDARY'}
            onClick={this.setCurrencySelected.bind(this, currency)}
          />
        </div>
      );
      alexButtons.push(el);
    }

    if (altButtons.length > 0 || alexButtons.length > 0) {
      let buttons: any = [];

      if (
        this.state.claimOptions &&
        this.state.claimOptions.length &&
        this.state.claimOptions[0]['STX'] !== undefined
      ) {
        let stx = (
          <div style={{ margin: '10px' }} key={0}>
            <Button
              text="STX"
              style={this.state.currencySelected == 'STX' ? 'PRIMARY' : 'SECONDARY'}
              onClick={this.setCurrencySelected.bind(this, 'STX')}
            />
          </div>
        );

        buttons.push(stx);
      }

      buttons.push(altButtons);
      buttons.push(alexButtons);
      return <div style={{ display: 'flex' }}>{buttons}</div>;
    } else {
      return null;
    }
  }

  renderNumberButtons() {
    if (
      this.state.claimOptions == null ||
      (this.state.claimOptions != null && this.state.claimOptions.length == 1)
    ) {
      return;
    }

    if (
      this.state.currencySelected == 'BTC' ||
      this.state.currencySelected == 'xBTC'
      // (this.state.alexCurrencies != null &&
      //   this.state.alexCurrencies.includes(this.state.currencySelected))
    ) {
      return;
    }

    let buttons: any = [];
    for (let i = 0; i < this.state.claimOptions.length; i++) {
      const option: any = this.state.claimOptions[i];
      const el = (
        <div style={{ margin: '10px' }} key={i}>
          <Button
            text={option['amount'].toString()}
            style={this.state.numberSelected == option['amount'] ? 'PRIMARY' : 'SECONDARY'}
            onClick={this.setNumberSelected.bind(this, option['amount'])}
          />
        </div>
      );
      buttons.push(el);
    }
    return <div className={styles.MintNumberButtons}>{buttons}</div>;
  }

  render() {
    return (
      <ContainerFull>
        <Helmet>
          <meta charSet="utf-8" />
          <title>{this.state.name || this.props.name} | Gamma.io</title>
          <meta property="og:title" content={`${this.state.name || this.props.name} | Gamma.io`} />
          <meta property="og:image" content={this.state.image} />
          <meta
            name="og:description"
            content={
              this.state.description.slice(0, 160) ||
              'The best place to find, collect, and sell NFTs secured by Bitcoin. Gamma is the open marketplace for Stacks NFTs, with thousands of NFTs to find & trade.'
            }
          />
        </Helmet>

        <div className={styles.MintHeader}>
          <div className={styles.MintLeftBar}>
            {this.state.enlargeImage ? (
              <img
                src={this.state.image}
                className={styles.MintImageSrcLarge}
                alt={this.state.name}
              />
            ) : (
              <img
                src={this.state.image}
                height={122}
                width={122}
                className={styles.MintImageSrc}
                alt={this.state.name}
              />
            )}
            <div className={styles.MintContentLeft}>
              <div className={styles.MintTitle}>{this.state.name}</div>

              <div className={styles.MintSummary}>{this.state.description}</div>

              {this.state.websiteUrl != undefined &&
                this.state.websiteUrl != '' &&
                !this.state.websiteUrl.includes('stxnft.com') && (
                  <div className={styles.MintWebsite}>
                    <a href={this.state.websiteUrl} target="_blank" rel="noopener noreferrer">
                      {this.state.websiteText != undefined && this.state.websiteText != ''
                        ? this.state.websiteText
                        : this.state.websiteUrl}
                    </a>
                  </div>
                )}
            </div>
          </div>
          <div className={styles.MintRightBar}>
            {this.state.active &&
              this.state.lastTokenID < this.state.total &&
              this.state.contractType == 'mintpass' &&
              !this.state.saleEnabled && (
                <div className={styles.MintpassBalanceContainer}>
                  <div className={styles.MintpassBalance}>
                    You have {this.state.passesRemaining} mintpasses remaining
                  </div>
                </div>
              )}

            {this.state.active &&
            !this.state.paused &&
            (this.state.lastTokenID <= this.state.total || this.state.total === 0) &&
            this.state.userData ? (
              <div className={styles.MintContentRight}>
                {this.renderCurrencyButtons()}
                {this.renderNumberButtons()}
                <div style={{ margin: '5px' }}></div>
                <div className={styles.MintButton}>{this.mintButton()}</div>
              </div>
            ) : (
              <div className={styles.MintContentRight}>
                <div className={styles.MintButton}>{this.mintButton()}</div>
              </div>
            )}

            <div className={styles.MintPrice}>
              {this.state.total > 0 ? (
                <div className={styles.MintPriceContainer}>
                  <div>{this.getTotal().toLocaleString('us')}</div>
                  <div className={styles.MintPriceTitle}>TOTAL</div>
                </div>
              ) : (
                <div className={styles.MintPriceContainer}>
                  <div>{this.state.lastTokenID.toLocaleString('us')}</div>
                  <div className={styles.MintPriceTitle}>MINTED</div>
                </div>
              )}
              {this.state.total > 0 ? (
                <div className={styles.MintPriceContainer}>
                  <div>{this.getAvailable().toLocaleString('us')}</div>
                  <div className={styles.MintPriceTitle}>REMAINING</div>
                </div>
              ) : null}
              <div className={styles.MintPriceContainer}>
                <div className={styles.MintPriceIconContainer}>
                  {!this.state.currency && <MintPriceIcon />}
                  <div>{this.getFloorPrice().toLocaleString('us')}</div>
                  {this.state.currency == 'MIA' && <div className={styles.MintPriceText}>MIA</div>}
                </div>
                <div className={styles.MintPriceTitle}>MINT PRICE</div>
              </div>
            </div>
          </div>
        </div>

        {this.state.showSafetyTip && (
          <Modal onClose={this.closeSafetyTip.bind(this)}>
            <UserTip onClose={this.closeSafetyTip.bind(this)}>
              <ul className={styles.Tip}>
                <li>
                  Gamma doesn't sponsor or endorse any collection - even those using{' '}
                  <a href="http://create.gamma.io" target="_blank" rel="noopener noreferrer">
                    create.gamma.io
                  </a>{' '}
                  to mint their collection.
                </li>
                <li>
                  If you're new to NFTs, we recommend reading{' '}
                  <a
                    href="https://support.gamma.io/hc/en-us/articles/6018361117075-What-are-some-ways-I-can-screen-for-high-quality-NFT-projects-"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    How to Screen for High-Quality Projects
                  </a>{' '}
                  before proceeding.
                </li>
                <li>
                  While NFTs often trade on our secondary marketplace, you should assume that there
                  could be no market for this NFT at any price.
                </li>
              </ul>
            </UserTip>
          </Modal>
        )}

        {this.state.showAlexTip && (
          <Modal onClose={this.closeAlexTip.bind(this)}>
            <UserTip onClose={this.closeAlexTip.bind(this)}>
              <ul className={styles.Tip}>
                <li>
                  Gamma doesn't sponsor or endorse any collection - even those using{' '}
                  <a href="http://create.gamma.io" target="_blank" rel="noopener noreferrer">
                    create.gamma.io
                  </a>{' '}
                  to mint their collection.
                </li>
                <li>
                  If you're new to NFTs, we recommend reading{' '}
                  <a
                    href="https://support.gamma.io/hc/en-us/articles/6018361117075-What-are-some-ways-I-can-screen-for-high-quality-NFT-projects-"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    How to Screen for High-Quality Projects
                  </a>{' '}
                  before proceeding.
                </li>
                <li>
                  While NFTs often trade on our secondary marketplace, you should assume that there
                  could be no market for this NFT at any price.
                </li>
              </ul>
            </UserTip>
          </Modal>
        )}

        {this.state.showLightningTip && (
          <Modal onClose={this.closeLightningTip.bind(this)}>
            <UserTip onClose={this.closeLightningTip.bind(this)}>
              <ul>
                <li>
                  Gamma doesn't sponsor or endorse any collection - even those using{' '}
                  <a href="http://create.gamma.io" target="_blank" rel="noopener noreferrer">
                    create.gamma.io
                  </a>{' '}
                  to mint their collection.
                </li>
                <li>
                  If you're new to NFTs, we recommend reading{' '}
                  <a
                    href="https://support.gamma.io/hc/en-us/articles/6018361117075-What-are-some-ways-I-can-screen-for-high-quality-NFT-projects-"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    How to Screen for High-Quality Projects
                  </a>{' '}
                  before proceeding.
                </li>
                <li>
                  While NFTs often trade on our secondary marketplace, you should assume that there
                  could be no market for this NFT at any price.
                </li>
              </ul>
            </UserTip>
          </Modal>
        )}

        {this.state.modal && (
          <Modal onClose={this.modalClose.bind(this)}>
            <Confirm
              success={this.state.modal['success']}
              transactionId={this.state.modal['successTransaction']}
              errorMessage={this.state.modal['errorMessage']}
              onClose={this.modalClose.bind(this)}
            />
          </Modal>
        )}

        {this.stxAddress() &&
          (this.stxAddress() == this.state.contractAddress ||
            this.stxAddress() == this.state.artistAddress) &&
          this.state.active && (
            <AdminPanel
              setModal={this.setModal.bind(this)}
              syncCollectionData={this.syncCollectionData.bind(this)}
              contractAddress={this.state.contractAddress}
              contractName={this.state.contractName}
              altCurrencies={this.state.altCurrencies}
              revealEnabled={this.state.revealEnabled}
              contractType={this.state.contractType}
              mintpassStateChecked={this.state.mintpassStateChecked}
              mintpassEnabled={this.state.mintpassEnabled}
              saleStateChecked={this.state.saleStateChecked}
              saleEnabled={this.state.saleEnabled}
              airdropEnabled={this.state.airdropEnabled}
              revealData={this.state.revealData}
              paused={this.state.paused}
              altPriceInputs={this.state.altPriceInputs}
              altPriceErrors={this.state.altPriceErrors}
            />
          )}

        <InfiniteScroll
          dataLength={this.getLength()}
          next={this.getBlocksNext.bind(this)}
          hasMore={this.hasMore()}
          loader={<Loading />}
          className={styles.MintPriceCardContainer}
          scrollableTarget={'page-wrapper'}
        >
          {this.renderCard()}
        </InfiniteScroll>
      </ContainerFull>
    );
  }
}

export default CollectionStandard;
