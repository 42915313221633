import { atom, useAtom } from 'jotai';
import { useEffect, useReducer } from 'react';

export const collectionNameAtom = atom('');
export const descriptionAtom = atom('');
export const emailAddressAtom = atom('');
export const websiteAtom = atom('');
export const twitterAtom = atom('');
export const discordAtom = atom('');
export const artistAddressAtom = atom('');
export const logoAtom = atom<any[]>([]);
export const logoEnlargedAtom = atom(false);
export const assetsAtom = atom<any[]>([]);
export const placeholderAtom = atom<any[]>([]);
export const filenamesEnabledAtom = atom(false);
export const attributesAtom = atom<any[]>([]);
export const priceAtom = atom('');
export const paymentOptionsAtom = atom(['stx']);
export const fungibleOptionsAtom = atom([]);
export const fungibleSelectedAtom = atom<{ symbol: string; price: number; selected: boolean }[]>(
  [],
);
export const stxusdAtom = atom('');
export const claimOptionsAtom = atom<number[]>([]);
export const mintpassAtom = atom<any[]>([]);
export const airdropAtom = atom<any[]>([]);
export const mintingEnabledAtom = atom(true);
export const mintsPerAddressAtom = atom('');
export const visibilityEnabledAtom = atom(true);
export const previewStepAtom = atom(1);
export const userAtom = atom({});
export const currentUserAddressAtom = atom('');
export const deployedContractsAtom = atom<string[]>([]);
export const deployedContractsDataAtom = atom<Record<string, any>>({});
export const categoryAtom = atom<number[]>([]);
export const editionAtom = atom<any[]>([]);
export const numberOfEditionsAtom = atom('');
export const contractIdAtom = atom('');
export const tokenNamesAtom = atom<string[]>([]);
export const tokenDescriptionsAtom = atom<string[]>([]);
export const editionNameAtom = atom('');
export const merkleRootAtom = atom('');
export const mintpassStartUnixAtom = atom(0);
export const mintpassEndUnixAtom = atom(0);
export const fundsRecipientAtom = atom('');
export const royaltyAtom = atom('');
export const symbolAtom = atom('');
export const publicStartUnixAtom = atom(0);
export const merkleEntriesAtom = atom<any[]>([]);
export const mintpassStartDateAtom = atom<any>(null);
export const mintpassEndDateAtom = atom<any>(null);
export const publicStartDateAtom = atom<any>(null);
export const licenseUriAtom = atom('');
export const licenseNameAtom = atom('');

export function useResetAtoms() {
  const [collectionName, setCollectionName] = useAtom(collectionNameAtom);
  const [description, setDescription] = useAtom(descriptionAtom);
  const [emailAddress, setEmailAddress] = useAtom(emailAddressAtom);
  const [website, setWebsite] = useAtom(websiteAtom);
  const [twitter, setTwitter] = useAtom(twitterAtom);
  const [discord, setDiscord] = useAtom(discordAtom);
  const [artistAddress, setArtistAddress] = useAtom(artistAddressAtom);
  const [logo, setLogo] = useAtom(logoAtom);
  const [logoEnlarged, setLogoEnlarged] = useAtom(logoEnlargedAtom);
  const [assets, setAssets] = useAtom(assetsAtom);
  const [paymentOptions, setPaymentOptions] = useAtom(paymentOptionsAtom);
  const [placeholder, setPlaceholder] = useAtom(placeholderAtom);
  const [attributes, setAttributes] = useAtom(attributesAtom);
  const [price, setPrice] = useAtom(priceAtom);
  const [claimOptions, setClaimOptions] = useAtom(claimOptionsAtom);
  const [mintpass, setMintpass] = useAtom(mintpassAtom);
  const [airdrop, setAirdrop] = useAtom(airdropAtom);
  const [mintingEnabled, setMintingEnabled] = useAtom(mintingEnabledAtom);
  const [mintsPerAddress, setMintsPerAddress] = useAtom(mintsPerAddressAtom);
  const [visibilityEnabled, setVisibilityEnabled] = useAtom(visibilityEnabledAtom);
  const [filenamesEnabled, setFilenamesEnabled] = useAtom(filenamesEnabledAtom);
  const [fungibleSelected, setFungibleSelected] = useAtom(fungibleSelectedAtom);
  const [category, setCategory] = useAtom(categoryAtom);
  const [edition, setEdition] = useAtom(editionAtom);
  const [numberOfEditions, setNumberOfEditions] = useAtom(numberOfEditionsAtom);
  const [contractId, setContractId] = useAtom(contractIdAtom);
  const [tokenNames, setTokenNames] = useAtom(tokenNamesAtom);
  const [tokenDescriptions, setTokenDescriptions] = useAtom(tokenDescriptionsAtom);
  const [editionName, setEditionName] = useAtom(editionNameAtom);
  const [merkleRoot, setMerkleRoot] = useAtom(merkleRootAtom);
  const [mintpassStartUnix, setMintpassStartUnix] = useAtom(mintpassStartUnixAtom);
  const [mintpassEndUnix, setMintpassEndUnix] = useAtom(mintpassEndUnixAtom);
  const [fundsRecipient, setFundsRecipient] = useAtom(fundsRecipientAtom);
  const [royalty, setRoyalty] = useAtom(royaltyAtom);
  const [symbol, setSymbol] = useAtom(symbolAtom);
  const [publicStartUnix, setPublicStartUnix] = useAtom(publicStartUnixAtom);
  const [merkleEntries, setMerkleEntries] = useAtom(merkleEntriesAtom);
  const [mintpassStartDate, setMintpassStartDate] = useAtom(mintpassStartDateAtom);
  const [mintpassEndDate, setMintpassEndDate] = useAtom(mintpassEndDateAtom);
  const [publicStartDate, setPublicStartDate] = useAtom(publicStartDateAtom);
  const [licenseUri, setLicenseUri] = useAtom(licenseUriAtom);
  const [licenseName, setLicenseName] = useAtom(licenseNameAtom);

  const resetDeployAtoms = () => {
    setCollectionName('');
    setDescription('');
    setEmailAddress('');
    setWebsite('');
    setTwitter('');
    setArtistAddress('');
    setLogo([]);
    setLogoEnlarged(false);
    setAssets([]);
    setPlaceholder([]);
    setFilenamesEnabled(false);
    setAttributes([]);
    setPrice('');
    setPaymentOptions(['stx']);
    setClaimOptions([]);
    setMintpass([]);
    setAirdrop([]);
    setMintingEnabled(true);
    setMintsPerAddress('');
    setVisibilityEnabled(true);
    setFungibleSelected([]);
    setDiscord('');
    setCategory([]);
    setEdition([]);
    setNumberOfEditions('');
    setContractId('');
    setTokenNames([]);
    setTokenDescriptions([]);
    setEditionName('');
    setMerkleRoot('');
    setMintpassStartUnix(0);
    setMintpassEndUnix(0);
    setFundsRecipient('');
    setRoyalty('');
    setSymbol('');
    setPublicStartUnix(0);
    setMerkleEntries([]);
    setMerkleRoot('');
    setMintpassStartDate(null);
    setMintpassEndDate(null);
    setPublicStartDate(null);
    setLicenseUri('');
    setLicenseName('');
  };

  return { resetDeployAtoms };
}
