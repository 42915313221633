import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import styles from './index.module.scss';

import { darkmodeListener } from '../../theme';
import { gaSend } from '../../Analytics';

import makeEndpoint from '../../endpoints';

import Card from '../../components/Card';
import Loading from '../../components/Loading';
import Renderer from '../../components/Renderer';

import icon from '../../components/Login/assets/icon.svg';

import {
  network,
  getPaused,
  listAuction,
  getAuction,
  getAuctionBid,
  auctionContractPrincipal,
} from '../../utils/SelfServe/contracts';

import { AppConfig, UserSession, showConnect } from '@stacks/connect';

import InfiniteScroll from 'react-infinite-scroll-component';

import { renderer } from '../../utils';
import Modal from '../../components/Modal';
import Confirm from '../../components/Confirm';
import UserTip from '../../components/UserTip';
import ListAuctionModal from '../../components/ListAuctionModal';
import TextInput from '../../components/TextInput';
import {
  checkMempool,
  getAllNftsForAuction,
  getBlockHeight,
  getContractEvents,
  getNftsForAuction,
  getTransactions,
} from '../../utils/api';
import { Link } from 'react-router-dom';
import { ContainerFull, EmptyDiv } from '../../components/common';
import { adminAddresses } from '../../components/Common/AdminRoute';
import { getFeaturedAuctions, postFeaturedAuctions } from '../../utils/Studio/serverCalls';

const appConfig = new AppConfig(['store_write', 'publish_data']);
const userSession = new UserSession({ appConfig });

const axios = require('axios');

type CollectionAuctionProps = {
  name: string;
  collectionData: any;
};

type CollectionAuctionState = {
  name: string;
  contractAddress: string;
  contractName: string;
  description: string;
  image: string;
  lastTokenID: number;
  saleData: Record<string, any>;
  sale: Array<any>;
  active: boolean;
  userData?: any;
  offset: number;
  contractType: string;
  modal?: any;
  showAdmin: boolean;
  websiteUrl: string;
  websiteText: string;
  showSafetyTip: boolean;
  functionName: string;
  artistAddress: string;
  showSellModal: boolean;
  showBidModal: boolean;
  listingId: number;
  listingBlockLength?: string;
  listingReservePrice?: string;
  listingType?: string;
  listingAuction?: any;
  listingBid?: string;
  auctionData: any;
  auctionBids: any;
  listedTokens: Array<any>;
  listedTokensDetails: Array<any>;
  auctionsFetched: boolean;
  blockHeight?: number;
  seconds: number;
  updatedAuction: string;
  updatedAuctionText: string;
};

const fetchSize = 25;
class CollectionAuction extends Component<CollectionAuctionProps, CollectionAuctionState> {
  state = {
    name: '',
    contractAddress: '',
    artistAddress: '',
    contractName: '',
    description: '',
    image: '',
    saleData: {},
    sale: [],
    lastTokenID: 0,
    active: false,
    userData: undefined,
    offset: 1,
    contractType: '',
    modal: undefined,
    showAdmin: false,
    websiteUrl: '',
    websiteText: '',
    showSafetyTip: false,
    functionName: '',
    showSellModal: false,
    showBidModal: false,
    listingId: 0,
    listingBlockLength: undefined,
    listingReservePrice: undefined,
    listingType: undefined,
    listingAuction: undefined,
    listingBid: undefined,
    auctionData: {},
    auctionBids: {},
    listedTokens: [],
    listedTokensDetails: [],
    auctionsFetched: false,
    blockHeight: undefined,
    seconds: 0,
    updatedAuction: '',
    updatedAuctionText: '',
  };

  interval: any;

  tick() {
    this.setState((state) => ({
      seconds: state.seconds + 1,
    }));

    if (this.state.listedTokensDetails && this.state.listedTokensDetails.length > 0) {
      // console.log(this.state.listedTokensDetails);
      const listedTokensDetails: any = this.state.listedTokensDetails;
      for (let i = 0; i < listedTokensDetails.length; i++) {
        const auctionDetails: any = listedTokensDetails[i];

        if (auctionDetails.secondsRemaining) {
          let totalSeconds = auctionDetails.secondsRemaining - this.state.seconds;
          let hours: any = Math.floor(totalSeconds / 3600);
          if (hours < 10) {
            hours = '0' + hours;
          }
          totalSeconds %= 3600;
          let minutes: any = Math.floor(totalSeconds / 60);
          if (minutes < 10) {
            minutes = '0' + minutes;
          }
          let seconds: any = totalSeconds % 60;
          if (seconds < 10) {
            seconds = '0' + seconds;
          }
          const timeRemaining = hours + ':' + minutes + ':' + seconds;
          // this.setState({ timeRemaining });
          auctionDetails.timeRemaining = timeRemaining;
          listedTokensDetails[i] = auctionDetails;
        }
      }
      this.setState({ listedTokensDetails });
    }

    if (this.state.seconds % 30 === 0 && this.state.seconds > 60) {
      this.fetchNFTs();
    }
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  async componentDidMount() {
    try {
      const userData = localStorage.getItem('userData');
      if (userData) {
        this.setState({
          userData: JSON.parse(userData),
        });
      }
    } catch {}

    darkmodeListener(styles['theme-light'], styles['theme-dark']);

    this.interval = setInterval(() => this.tick(), 1000);

    this.setState(
      {
        name: this.props.collectionData.name,
        contractAddress: this.props.collectionData.contractAddress,
        contractName: this.props.collectionData.contractName,
        description: this.props.collectionData.description,
        image: this.props.collectionData.image,
        active: this.props.collectionData.active,
        contractType: this.props.collectionData.contractType,
        websiteUrl: this.props.collectionData.websiteUrl,
        websiteText: this.props.collectionData.websiteText,
        artistAddress: this.props.collectionData.artistAddress,
      },
      () => {
        const contractName = this.props.collectionData.contractName;
        fetch(
          makeEndpoint(
            '/api/nft/lastTokenID?address=' +
              this.props.collectionData.contractAddress +
              '&name=' +
              contractName +
              '&reload=true',
          ),
        );
        fetch(
          makeEndpoint(
            '/api/nft/lastTokenID?address=' +
              this.props.collectionData.contractAddress +
              '&name=' +
              contractName,
          ),
        )
          .then((res) => {
            return res.text();
          })
          .then((lastTokenStr) => {
            if (lastTokenStr === 'null') {
              return;
            }
            let lastToken = parseInt(lastTokenStr, 10);

            if (
              contractName === 'write-your-own-path' ||
              contractName === 'other-worlds-astrobrain'
            ) {
              lastToken++;
            }

            this.setState(
              {
                lastTokenID: lastToken,
              },
              () => {
                this.fetchNFTs();
              },
            );
          });
      },
    );
  }

  async fetchNFTs() {
    var nfts: Array<any> = [];
    var numNFTs = Math.min(this.state.lastTokenID, fetchSize);
    if (this.state.lastTokenID < this.state.offset + numNFTs) {
      const diff = this.state.offset + numNFTs - this.state.lastTokenID;
      numNFTs -= diff;
    }

    const contractName = this.state.contractName;
    const auctionData: any = {};
    for (var x = this.state.offset; x <= this.state.offset + numNFTs; x++) {
      nfts.push({
        contractAddress: this.state.contractAddress,
        tokenID: x,
        contractName: contractName,
        sold: true,
      });

      auctionData[x] = null;
    }

    var sale: Array<any> = this.state.sale;
    const filtered_nfts = nfts.filter((r: any) => {
      let filtered_list = sale.filter(
        (l: any) =>
          l.contractAddress == r.contractAddress &&
          l.contractName == r.contractName &&
          String(l.tokenID) == String(r.tokenID),
      );
      return filtered_list.length == 0;
    });

    sale.push(...filtered_nfts);
    this.setState(
      {
        sale: sale,
      },
      () => {
        this.fetchData(nfts);
      },
    );

    const blockHeight = await getBlockHeight();

    let allAuctions = await getAllNftsForAuction();
    const nftsForAuction: any = [];
    for (let i = 0; i < allAuctions.length; i++) {
      const nft: any = allAuctions[i];
      if (nft.contractId === this.state.contractAddress + '.' + this.state.contractName) {
        nftsForAuction.push(nft);
      }
    }
    const listedTokens = await getNftsForAuction(
      auctionContractPrincipal,
      this.state.contractAddress,
      this.state.contractName,
      200,
    );

    this.setState({ listedTokens: nftsForAuction, blockHeight });
    this.fetchDetails(nftsForAuction);

    // checkMempool("SP1CSHTKVHMMQJ7PRQRFYW6SB4QAW6SR3XY2F81PA", "aux3").then(
    //   (res) => console.log(res)
    // );

    // getContractEvents("SP1CSHTKVHMMQJ7PRQRFYW6SB4QAW6SR3XY2F81PA", "aux3").then(
    //   (res) => console.log(res)
    // );

    // getTransactions("SP1CSHTKVHMMQJ7PRQRFYW6SB4QAW6SR3XY2F81PA.aux3").then(
    //   (res) => console.log(res)
    // );
  }

  async fetchDetails(nfts: Array<any>) {
    const details = [];
    if (nfts && nfts.length > 0) {
      for (let i = 0; i < nfts.length; i++) {
        const id = Number(nfts[i].tokenId);
        const auction = await getAuction(this.state.contractAddress, this.state.contractName, id);
        const bid = await getAuctionBid(this.state.contractAddress, this.state.contractName, id);

        const blockLength =
          auction && auction['block-length'] ? auction['block-length']['value'] : undefined;
        let endBlockHeight = undefined;
        let blocksRemaining = undefined;
        let secondsRemaining = undefined;

        if (
          auction &&
          auction['end-block-height']['value'] &&
          this.state.blockHeight &&
          blockLength
        ) {
          endBlockHeight = Number(auction['end-block-height']['value']['value']);
          blocksRemaining = endBlockHeight - this.state.blockHeight;
          secondsRemaining = blocksRemaining * 600;
        } else {
          endBlockHeight = undefined;
        }

        const detail = {
          tokenId: id,
          auction: auction,
          bid: bid,
          secondsRemaining: secondsRemaining,
          timeRemaining: '00:00:00',
        };
        details.push(detail);
      }
    }
    this.setState({
      listedTokensDetails: details,
      auctionsFetched: true,
    });
  }

  getLength() {
    return this.state.sale.length;
  }

  handleAuction(auction: any, id: number) {
    const tokenId = id.toString();
    const auctionData: any = this.state.auctionData;
    auctionData[tokenId] = auction;
    this.setState({ auctionData });
  }

  handleAuctionBid(bid: any, id: number) {
    const tokenId = id.toString();
    const auctionBids: any = this.state.auctionBids;
    auctionBids[tokenId] = bid;
    this.setState({ auctionBids });
  }

  fetchData(nfts: any) {
    for (var nft of nfts) {
      const contractName = nft.contractName;
      var params = '';
      params += 'address=' + nft.contractAddress;
      params += '&name=' + contractName;
      params += '&id=' + nft.tokenID;
      const nftId = nft.contractAddress + '.' + contractName + ':' + nft.tokenID;
      var saleDataRecord: any = this.state.saleData;
      if (saleDataRecord[nftId]) {
        continue;
      }
      fetch('https://legacy.stxnft.com/api/nft?' + params)
        .then((res) => res.json())
        .then((nftData) => {
          if (nftData.name) {
            var saleData = saleDataRecord;
            saleData[nftId] = nftData;
            this.setState({ saleData });
          }
        });
    }
  }

  async handleFeature(contractId: string, tokenId: string) {
    const nft: string = contractId + '::' + tokenId;
    const _featuredAuctions = await getFeaturedAuctions();
    let tokens: string[] = _featuredAuctions.tokens;
    let isFeatured: boolean = false;
    if (tokens.includes(nft)) {
      tokens = tokens.filter((e) => e !== nft);
    } else {
      tokens.unshift(nft);
      isFeatured = true;
    }
    _featuredAuctions.tokens = tokens;
    // const res = await postFeaturedAuctions(_featuredAuctions);
    // if (res === 'success' && isFeatured) {
    //   this.setState({ updatedAuctionText: 'added' });
    // } else if (res === 'success' && !isFeatured) {
    //   this.setState({ updatedAuctionText: 'removed' });
    // } else {
    //   this.setState({ updatedAuctionText: 'error' });
    // }
    // this.setState({ updatedAuction: contractId + '::' + tokenId });
  }

  findDetailById(id: number) {
    const result = this.state.listedTokensDetails.find((obj: any) => {
      return obj.tokenId === id;
    });
    return result;
  }

  renderAuctions() {
    return this.state.sale.map((x: any, idx) => {
      const saleData: any = this.state.saleData;
      const fullData: any = saleData[x.contractAddress + '.' + x.contractName + ':' + x.tokenID];

      const detail: any = this.findDetailById(x.tokenID);

      if (!detail) {
        return null;
      }

      let auctionText = undefined;
      const blockHeight: any = this.state.blockHeight;
      let sold = false;

      if (
        detail &&
        detail.auction['end-block-height'].value &&
        blockHeight &&
        detail.auction['end-block-height'].value.value <= blockHeight
      ) {
        sold = true;
      } else if (detail) {
        auctionText = 'LIVE';
      }

      const auctionFlag = !sold;

      let auctionDetailsText = '';
      if (sold && detail.bid.amount.value != 0) {
        auctionDetailsText = `SOLD FOR ${detail.bid.amount.value / 1e6} STX`;
      } else if (detail && detail.bid.amount.value != 0) {
        auctionDetailsText = `${detail.bid.amount.value / 1e6} STX - ${detail.timeRemaining}`;
      } else if (detail) {
        auctionDetailsText = `RESERVE ${detail.auction['reserve-price'].value / 1e6} STX`;
      } else if (auctionFlag && !this.state.auctionsFetched) {
        auctionDetailsText = 'Loading...';
      } else if (auctionFlag && this.state.auctionsFetched) {
        auctionDetailsText = 'NOT LISTED';
      }

      if (fullData) {
        return (
          <EmptyDiv>
            <Card
              key={idx}
              renderer={renderer(fullData, undefined, 600)}
              collection={fullData.collection || fullData.name}
              name={fullData.name}
              soldFlag={sold || undefined}
              auctionFlag={!sold}
              auctionLoading={!this.state.auctionsFetched}
              auctionText={auctionText}
              auctionDetails={detail}
              auctionDetailsText={auctionDetailsText}
              tokenPath={`/collection/${x.contractName}/${x.tokenID}`}
              collectionPath={`/collection/${x.contractName}}`}
            />
            {/* {adminAddresses.includes(this.stxAddress()) &&
            auctionDetailsText !== 'NOT LISTED' &&
            auctionDetailsText !== 'Loading...' ? (
              <EmptyDiv css={{ marginTop: 5 }}>
                <button
                  onClick={() =>
                    this.handleFeature(x.contractAddress + '.' + x.contractName, x.tokenID)
                  }
                >
                  feature
                </button>
                {x.contractAddress + '.' + x.contractName + '::' + x.tokenID ===
                this.state.updatedAuction ? (
                  <span>{this.state.updatedAuctionText}</span>
                ) : null}
              </EmptyDiv>
            ) : null} */}
          </EmptyDiv>
        );
      } else if (x.contractName) {
        const titled = x.contractName[0].toUpperCase() + x.contractName.substring(1);
        return (
          <Card
            key={idx}
            collection={titled}
            renderer={<Renderer size={600} />}
            name={titled + ' #' + x.tokenID}
          />
        );
      }
    });
  }

  renderCard() {
    return this.state.sale.map((x: any, idx) => {
      const saleData: any = this.state.saleData;
      const fullData: any = saleData[x.contractAddress + '.' + x.contractName + ':' + x.tokenID];

      const detail: any = this.findDetailById(x.tokenID);

      if (detail) {
        return null;
      }

      let auctionText = undefined;
      const blockHeight: any = this.state.blockHeight;
      let sold = false;

      if (
        detail &&
        detail.auction['end-block-height'].value &&
        blockHeight &&
        detail.auction['end-block-height'].value.value <= blockHeight
      ) {
        sold = true;
      } else if (detail) {
        auctionText = 'LIVE';
      }

      const auctionFlag = !sold;

      let auctionDetailsText = '';
      if (sold && detail.bid.amount.value != 0) {
        auctionDetailsText = `SOLD FOR ${detail.bid.amount.value / 1e6} STX`;
      } else if (detail && detail.bid.amount.value != 0) {
        auctionDetailsText = `${detail.bid.amount.value / 1e6} STX - ${detail.timeRemaining}`;
      } else if (detail) {
        auctionDetailsText = `RESERVE ${detail.auction['reserve-price'].value / 1e6} STX`;
      } else if (auctionFlag && !this.state.auctionsFetched) {
        auctionDetailsText = 'Loading...';
      } else if (auctionFlag && this.state.auctionsFetched) {
        auctionDetailsText = 'NOT LISTED';
      }

      if (fullData) {
        return (
          <EmptyDiv>
            <Card
              key={idx}
              renderer={renderer(fullData, undefined, 600)}
              collection={fullData.collection || fullData.name}
              name={fullData.name}
              soldFlag={sold || undefined}
              auctionFlag={!sold}
              auctionLoading={!this.state.auctionsFetched}
              auctionText={auctionText}
              auctionDetails={detail}
              auctionDetailsText={auctionDetailsText}
              tokenPath={`/collection/${x.contractName}/${x.tokenID}`}
              collectionPath={`/collection/${x.contractName}}`}
            />
            {/* {adminAddresses.includes(this.stxAddress()) &&
            auctionDetailsText !== 'NOT LISTED' &&
            auctionDetailsText !== 'Loading...' ? (
              <EmptyDiv css={{ marginTop: 5 }}>
                <button
                  onClick={() =>
                    this.handleFeature(x.contractAddress + '.' + x.contractName, x.tokenID)
                  }
                >
                  feature
                </button>
                {x.contractAddress + '.' + x.contractName + '::' + x.tokenID ===
                this.state.updatedAuction ? (
                  <span>{this.state.updatedAuctionText}</span>
                ) : null}
              </EmptyDiv>
            ) : null} */}
          </EmptyDiv>
        );
      } else if (x.contractName) {
        const titled = x.contractName[0].toUpperCase() + x.contractName.substring(1);
        return (
          <Card
            key={idx}
            collection={titled}
            renderer={<Renderer size={600} />}
            name={titled + ' #' + x.tokenID}
          />
        );
      }
    });
  }

  stxAddress() {
    if (this.state.userData && network.isMainnet()) {
      const userData: any = this.state.userData;
      return userData.profile.stxAddress.mainnet;
    } else if (this.state.userData && !network.isMainnet()) {
      const userData: any = this.state.userData;
      return userData.profile.stxAddress.testnet;
    } else {
      return null;
    }
  }

  authenticate() {
    showConnect({
      appDetails: {
        name: 'Stacks NFT',
        icon: icon,
      },
      redirectTo: '/',
      onFinish: () => {
        gaSend('User', 'authenticate', 'User Authenticated');
        let userData = userSession.loadUserData();
        this.setState({ userData: userData });
        localStorage.setItem('userData', JSON.stringify(userData));
        window.location.reload();
      },
      userSession: userSession,
    });
  }

  openSafetyTip() {
    this.setState({ showSafetyTip: true });
  }

  closeSafetyTip(accepted?: boolean) {
    this.modalClose();
    this.setState({ showSafetyTip: false });
    if (accepted) {
      // this.openClaim();
    }
  }

  syncCollectionData(isAuto: boolean) {
    let data: any = this.props.collectionData;
    // if (data) {
    //   data["paused"] = this.state.paused;
    // }

    const url = makeEndpoint('/update');
    axios
      .post(url, data, {
        auth: {
          username: process.env.REACT_APP_LOGIN,
          password: process.env.REACT_APP_PASSWORD,
        },
      })
      .then(() => {
        console.log('Collection saved');
        if (!isAuto) {
          this.setModal({
            success: true,
          });
        }
      })
      .catch((err: any) => {
        console.error(err);
        if (!isAuto) {
          this.setModal({
            success: false,
            errorMessage: 'Sync failed',
          });
        }
      });
  }

  nextBlocks(offset: number) {
    this.setState(
      {
        offset: offset,
      },
      () => {
        this.fetchNFTs();
      },
    );
  }

  getBlocksNext() {
    this.nextBlocks(this.getLength());
  }

  hasMore() {
    return this.state.lastTokenID > this.state.offset;
  }

  setModal(modal: any) {
    this.setState({
      modal: modal,
    });
  }

  modalClose() {
    this.setState({
      modal: undefined,
    });
  }

  handleBlockLength(e: any) {
    if (isNaN(e.target.value)) {
      return;
    }

    this.setState({
      listingBlockLength: e.target.value,
    });
  }

  handleReservePrice(e: any) {
    if (isNaN(e.target.value)) {
      return;
    }

    this.setState({
      listingReservePrice: e.target.value,
    });
  }

  handleAuctionType(e: any) {
    if (isNaN(e.target.value)) {
      return;
    }

    this.setState({
      listingType: e.target.value,
    });
  }

  openSellModal(tokenId: number) {
    this.setState({ showSellModal: true, listingId: tokenId });
  }

  closeSellModal(accepted?: boolean) {
    this.modalClose();
    this.setState({
      showSellModal: false,
    });

    if (accepted) {
      this.handleListAuction();
    } else {
      this.setState({
        listingId: 0,
        listingBlockLength: undefined,
        listingReservePrice: undefined,
        listingType: undefined,
      });
    }
  }

  openBidModal(tokenId: number) {
    const auctionData: any = this.state.auctionData;
    const auction: any = auctionData[tokenId];
    const auctionBids: any = this.state.auctionBids;
    const bid: any = auctionBids[tokenId];
    this.setState({
      showBidModal: true,
      listingId: tokenId,
      listingAuction: auction,
      listingBid: bid,
    });
    console.log(bid);
  }

  closeBidModal(accepted?: boolean) {
    this.modalClose();
    this.setState({
      showBidModal: false,
    });

    // if (accepted) {
    //   this.handleListAuction();
    // } else {
    //   this.setState({
    //     listingId: 0,
    //     listingBlockLength: undefined,
    //     listingReservePrice: undefined,
    //     listingType: undefined,
    //   });
    // }
  }

  handleListAuction() {
    listAuction(
      this.stxAddress(),
      this.state.contractAddress,
      this.state.contractName,
      Number(this.state.listingId),
      Number(this.state.listingBlockLength),
      Number(this.state.listingReservePrice),
      Number(this.state.listingType),
      this.setModal.bind(this),
    );
  }

  viewAuction(tokenId: number) {
    console.log('TODO: View auction ' + tokenId);
    window.location.href = `/collection/${this.state.contractName}/${tokenId}`;
  }

  render() {
    return (
      <ContainerFull>
        <Helmet>
          <meta charSet="utf-8" />
          <title>{this.state.name || this.props.name} | Gamma.io</title>
          <meta property="og:title" content={`${this.state.name || this.props.name} | Gamma.io`} />
          <meta property="og:image" content={this.state.image} />
          <meta
            name="og:description"
            content={
              this.state.description.slice(0, 160) ||
              'The best place to find, collect, and sell NFTs secured by Bitcoin. Gamma is the open marketplace for Stacks NFTs, with thousands of NFTs to find & trade.'
            }
          />
        </Helmet>

        <div className={styles.MintContent}>
          <div className={styles.MintHeader}>
            <div className={styles.MintLeftBar}>
              <img
                src={this.state.image}
                height={122}
                width={122}
                className={styles.MintImageSrc}
                alt={this.state.name}
              />
              <div className={styles.MintContentLeft}>
                <div className={styles.MintTitle}>{this.state.name}</div>

                <div className={styles.MintSummary}>{this.state.description}</div>

                {this.state.websiteUrl != undefined &&
                  this.state.websiteUrl != '' &&
                  !this.state.websiteUrl.includes('stxnft.com') && (
                    <div className={styles.MintWebsite}>
                      <a href={this.state.websiteUrl} target="_blank" rel="noopener noreferrer">
                        {this.state.websiteText != undefined && this.state.websiteText != ''
                          ? this.state.websiteText
                          : this.state.websiteUrl}
                      </a>
                    </div>
                  )}
              </div>
            </div>
          </div>

          {this.state.showSafetyTip && (
            <Modal onClose={this.closeSafetyTip.bind(this)}>
              <UserTip onClose={this.closeSafetyTip.bind(this)}>
                <ul className={styles.Tip}>
                  <li>
                    Gamma doesn't sponsor or endorse any collection - even those using{' '}
                    <a href="http://create.gamma.io" target="_blank" rel="noopener noreferrer">
                      create.gamma.io
                    </a>{' '}
                    to mint their collection.
                  </li>
                  <li>
                    If you're new to NFTs, we recommend reading{' '}
                    <a
                      href="https://stxnft.zendesk.com/hc/en-us/articles/4416081737363-What-are-some-ways-I-can-screen-for-high-quality-NFT-projects-"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      How to Screen for High-Quality Projects
                    </a>{' '}
                    before proceeding.
                  </li>
                  <li>
                    While NFTs often trade on our secondary marketplace, you should assume that
                    there could be no market for this NFT at any price.
                  </li>
                </ul>
              </UserTip>
            </Modal>
          )}

          {this.state.showSellModal && (
            <Modal onClose={this.closeSellModal.bind(this)}>
              <ListAuctionModal
                tokenId={this.state.listingId}
                onClose={this.closeSellModal.bind(this)}
                title={`Auction Details for Token #${this.state.listingId}`}
              >
                <div className={styles.ListAuctionInput}>
                  <div className={styles.ListAuctionLabel}>Enter block length</div>
                  <TextInput
                    placeholder={'block-length'}
                    // error={this.state.domainError}
                    value={this.state.listingBlockLength || ''}
                    onChange={(e) => this.handleBlockLength(e)}
                  />
                </div>
                <div className={styles.ListAuctionInput}>
                  <div className={styles.ListAuctionLabel}>Enter reserve price</div>
                  <TextInput
                    placeholder={'reserve-price'}
                    // error={this.state.domainError}
                    value={this.state.listingReservePrice || ''}
                    onChange={(e) => this.handleReservePrice(e)}
                  />
                </div>
                <div className={styles.ListAuctionInput}>
                  <div className={styles.ListAuctionLabel}>Enter auction type</div>
                  <TextInput
                    placeholder={'auction-type'}
                    // error={this.state.domainError}
                    value={this.state.listingType || ''}
                    onChange={(e) => this.handleAuctionType(e)}
                  />
                </div>
              </ListAuctionModal>
            </Modal>
          )}

          {this.state.modal && (
            <Modal onClose={this.modalClose.bind(this)}>
              <Confirm
                success={this.state.modal['success']}
                transactionId={this.state.modal['successTransaction']}
                errorMessage={this.state.modal['errorMessage']}
                onClose={this.modalClose.bind(this)}
              />
            </Modal>
          )}

          {this.state.showBidModal && this.state.listingAuction && (
            <Modal onClose={this.closeBidModal.bind(this)}>
              <ListAuctionModal
                tokenId={this.state.listingId}
                onClose={this.closeBidModal.bind(this)}
                title={`Auction Details for Token #${this.state.listingId}`}
                buttonText={'PLACE BID'}
              >
                <ul>
                  <li>Block length: {this.state.listingAuction['block-length']['value']}</li>

                  <li>
                    End block height:{' '}
                    {this.state.listingAuction['end-block-height']['value'] || 'Reserve not met'}
                  </li>
                  <li>Reserve price: {this.state.listingAuction['reserve-price']['value']}</li>
                  <li>Current high bid: {this.state.listingBid || 'No bids placed'}</li>
                </ul>
                <div className={styles.ListAuctionInput}>
                  <div className={styles.ListAuctionLabel}>Enter your bid amount in STX</div>
                  <TextInput
                    placeholder={'bid amount'}
                    // error={this.state.domainError}
                    value={this.state.listingBlockLength || ''}
                    onChange={(e) => this.handleBlockLength(e)}
                  />
                </div>
              </ListAuctionModal>
            </Modal>
          )}
        </div>

        {/* {(this.stxAddress() == this.state.contractAddress ||
          this.stxAddress() == this.state.artistAddress) &&
          this.state.active && (
            <AdminPanel
              setModal={this.setModal.bind(this)}
              syncCollectionData={this.syncCollectionData.bind(this)}
              contractAddress={this.state.contractAddress}
              contractName={this.state.contractName}
              contractType={this.state.contractType}
              paused={this.state.paused}
            />
          )} */}

        <InfiniteScroll
          dataLength={this.getLength()}
          next={this.getBlocksNext.bind(this)}
          hasMore={this.hasMore()}
          loader={<Loading />}
          className={styles.MintPriceCardContainer}
          scrollableTarget={'page-wrapper'}
        >
          {this.renderAuctions()}
          {this.renderCard()}
        </InfiniteScroll>
      </ContainerFull>
    );
  }
}

export default CollectionAuction;
