import makeEndpoint from '../../endpoints';
import { isMainnet } from '../SelfServe/contracts';

const axios = require('axios');

export function handlePublicCollection(
  data: {
    contractName: string;
    collectionName: string;
    description: string;
    emailAddress: string;
    website: string;
    twitter: string;
    discord: string;
    category: number[];
    artistAddress: string;
    logo: any[];
    assets: any[];
    fungibleSelected: { symbol: string; price: number; selected: boolean }[];
    placeholder: any[];
    attributes: any[];
    price: string;
    claimOptions: number[];
    mintpass: any[];
    airdrop: any[];
    mintingEnabled: boolean;
    mintsPerAddress: string;
    visibilityEnabled: boolean;
    currentUserAddress: string;
    logoEnlarged: boolean;
    filenamesEnabled: boolean;
    txId: string;
  },
  ipfs: {
    jsonHash: string;
    collectionImageName: string;
    assetsHash: string;
    placeholderHash: string;
    placeholderJsonHash: string;
    logoHash: string;
  },
  txUrl: string,
) {
  let claimOptions = [];
  let option: any = { amount: 1, STX: 'claim' };
  for (let i = 0; i < data.fungibleSelected.length; i++) {
    if (!data.fungibleSelected[i].selected) {
      continue;
    }
    const token = data.fungibleSelected[i];
    const symbol: string = token['symbol'];
    let lowerSymbol = symbol.toLowerCase();
    option[symbol] = 'claim-' + lowerSymbol;
  }
  claimOptions.push(option);
  if (data.claimOptions != null && data.claimOptions.length > 0) {
    let optionsMap: any = {
      2: 'two',
      3: 'three',
      4: 'four',
      5: 'five',
      6: 'six',
      7: 'seven',
      8: 'eight',
      9: 'nine',
      10: 'ten',
      15: 'fifteen',
      20: 'twenty',
      25: 'twentyfive',
    };
    for (let i = 0; i < data.claimOptions.length; i++) {
      const amount = data.claimOptions[i];
      if (amount === 1) {
        continue;
      }
      let option: any = {
        amount: amount,
        STX: `claim-${optionsMap[amount]}`,
      };
      for (let i = 0; i < data.fungibleSelected.length; i++) {
        if (!data.fungibleSelected[i].selected) {
          continue;
        }
        const token = data.fungibleSelected[i];
        const symbol: string = token['symbol'];
        const lowerSymbol = symbol.toLowerCase();
        option[symbol] = `claim-${optionsMap[amount]}-${lowerSymbol}`;
      }
      claimOptions.push(option);
    }
  }

  let altCurrencies: any = [];
  for (let i = 0; i < data.fungibleSelected.length; i++) {
    const fungible = data.fungibleSelected[i];
    if (fungible.selected) {
      altCurrencies.push(fungible);
    }
  }

  let metaHash = ipfs.jsonHash;
  // let collectionImage = `https://stxnft.mypinata.cloud/ipfs/${this.state.assetHash}/images/${this.state.collectionImageName}`;
  if (data.placeholder && ipfs.placeholderHash != '' && ipfs.placeholderJsonHash != '') {
    // collectionImage = `https://stxnft.mypinata.cloud/ipfs/${this.state.placeholderHash}`;
    metaHash = ipfs.placeholderJsonHash;
    // add reveal
    const revealUrl = makeEndpoint('/add/reveal');
    const reveal: any = {
      contractAddress: data.currentUserAddress,
      contractName: data.contractName,
      placeholderImageHash: ipfs.placeholderHash,
      placeholderMetadataHash: ipfs.placeholderJsonHash,
      revealImageHash: ipfs.assetsHash,
      revealMetadataHash: ipfs.jsonHash,
    };
    axios
      .post(revealUrl, reveal, {
        auth: {
          username: process.env.REACT_APP_LOGIN,
          password: process.env.REACT_APP_PASSWORD,
        },
      })
      .then(() => console.log('Reveal added'))
      .catch((err: any) => {
        console.error(err);
      });
  }
  let collectionImage = `https://stxnft.mypinata.cloud/ipfs/${ipfs.logoHash}`;
  let alexCurrencies: any = [];
  if (parseInt(data.price) >= 50) {
    alexCurrencies = ['xBTC'];
  }
  const contractData: any = {
    name: data.collectionName,
    image: collectionImage,
    description: data.description,
    websiteUrl: data.website,
    twitterUrl: data.twitter,
    discordUrl: data.discord,
    category: data.category,
    contact: data.emailAddress,
    contractAddress: data.currentUserAddress,
    active: false,
    paused: !data.mintingEnabled,
    contractName: data.contractName,
    total: data.assets.length,
    prices: parseInt(data.price) * 1000000,
    artistAddress: data.artistAddress,
    metaHash: metaHash,
    deployedTransactionUrl: txUrl,
    contractType: data.mintpass && data.mintpass.length ? 'mintpass' : 'default',
    saleEnabled: false,
    nonCustEnabled: true,
    altCurrencies: altCurrencies,
    featured: false,
    lastModified: Date.now(),
    claimOptions: claimOptions,
    airdropEnabled: data.airdrop && data.airdrop.length ? true : false,
    revealEnabled: !data.visibilityEnabled,
    lightningEnabled: false,
    auctionEnabled: false,
    alexCurrencies: alexCurrencies,
    enlargeImage: data.logoEnlarged,
    blockchainId: 1,
    txId: data.txId,
  };
  console.log(contractData);
  const url = makeEndpoint('/create');
  // save to server
  axios
    .post(url, contractData, {
      auth: {
        username: process.env.REACT_APP_LOGIN,
        password: process.env.REACT_APP_PASSWORD,
      },
    })
    .then(() => console.log('Collection saved'))
    .catch((err: any) => {
      console.error(err);
    });
  let zapierData = contractData;
  if (data.fungibleSelected != null && data.fungibleSelected.length > 0) {
    let currencies: any = {};
    for (let i = 0; i < data.fungibleSelected.length; i++) {
      const currency = data.fungibleSelected[i];
      currencies[currency['symbol']] = currency['price'];
    }
    zapierData['altCurrencies'] = currencies;
  }
  // send as webhook
  if (isMainnet) {
    const webhookUrl = 'https://hooks.zapier.com/hooks/catch/11337148/b1ut1x8/';
    axios
      .create({
        transformRequest: [(data: any, _headers: any) => JSON.stringify(zapierData)],
      })
      .post(webhookUrl, zapierData)
      .then((res: any) => console.log(res))
      .catch((err: any) => {
        console.error(err);
      });
  }
}

export function handlePersonalCollection(
  data: {
    contractName: string;
    collectionName: string;
    description: string;
    emailAddress: string;
    website: string;
    twitter: string;
    discord: string;
    category: number[];
    artistAddress: string;
    logo: any[];
    assets: any[];
    fungibleSelected: { symbol: string; price: number; selected: boolean }[];
    placeholder: any[];
    attributes: any[];
    price: string;
    claimOptions: number[];
    mintpass: any[];
    airdrop: any[];
    mintingEnabled: boolean;
    mintsPerAddress: string;
    visibilityEnabled: boolean;
    currentUserAddress: string;
    logoEnlarged: boolean;
    filenamesEnabled: boolean;
    txId: string;
  },
  ipfs: {
    logoHash: string;
    baseUris: string[];
  },
  txUrl: string,
) {
  // let metaHash = ipfs.jsonHash;

  let collectionImage = `https://stxnft.mypinata.cloud/ipfs/${ipfs.logoHash}`;
  const contractData: any = {
    name: data.collectionName,
    image: collectionImage,
    description: data.description,
    websiteUrl: data.website,
    twitterUrl: data.twitter,
    discordUrl: data.discord,
    category: data.category,
    contact: data.emailAddress,
    contractAddress: data.currentUserAddress,
    active: false,
    paused: false,
    contractName: data.contractName,
    total: 0,
    prices: 0,
    artistAddress: data.artistAddress,
    metaHash: '',
    deployedTransactionUrl: txUrl,
    contractType: 'default',
    saleEnabled: false,
    nonCustEnabled: true,
    altCurrencies: [],
    featured: false,
    lastModified: Date.now(),
    claimOptions: [],
    airdropEnabled: false,
    revealEnabled: false,
    lightningEnabled: false,
    auctionEnabled: true,
    alexCurrencies: [],
    enlargeImage: data.logoEnlarged,
    blockchainId: 1,
    txId: data.txId,
  };

  console.log(contractData);
  const url = makeEndpoint('/create');

  // save to server
  axios
    .post(url, contractData, {
      auth: {
        username: process.env.REACT_APP_LOGIN,
        password: process.env.REACT_APP_PASSWORD,
      },
    })
    .then(() => console.log('Collection saved'))
    .catch((err: any) => {
      console.error(err);
    });
  let zapierData = contractData;

  // send as webhook
  if (isMainnet) {
    const webhookUrl = 'https://hooks.zapier.com/hooks/catch/11337148/b1ut1x8/';
    axios
      .create({
        transformRequest: [(data: any, _headers: any) => JSON.stringify(zapierData)],
      })
      .post(webhookUrl, zapierData)
      .then((res: any) => console.log(res))
      .catch((err: any) => {
        console.error(err);
      });
  }
}

export function handleEditionsCollection(
  data: {
    contractName: string;
    collectionName: string;
    description: string;
    emailAddress: string;
    website: string;
    twitter: string;
    discord: string;
    category: number[];
    artistAddress: string;
    logo: any[];
    assets: any[];
    fungibleSelected: { symbol: string; price: number; selected: boolean }[];
    placeholder: any[];
    attributes: any[];
    price: string;
    claimOptions: number[];
    mintpass: any[];
    airdrop: any[];
    mintingEnabled: boolean;
    mintsPerAddress: string;
    visibilityEnabled: boolean;
    currentUserAddress: string;
    logoEnlarged: boolean;
    filenamesEnabled: boolean;
    txId: string;
    total: string;
  },
  ipfs: {
    logoHash: string;
    editionHash: string;
    jsonHash: any;
  },
  txUrl: string,
) {
  // let metaHash = ipfs.jsonHash;

  let claimOptions = [];
  let option: any = { amount: 1, STX: 'claim' };
  for (let i = 0; i < data.fungibleSelected.length; i++) {
    if (!data.fungibleSelected[i].selected) {
      continue;
    }
    const token = data.fungibleSelected[i];
    const symbol: string = token['symbol'];
    let lowerSymbol = symbol.toLowerCase();
    option[symbol] = 'claim-' + lowerSymbol;
  }
  claimOptions.push(option);
  if (data.claimOptions != null && data.claimOptions.length > 0) {
    let optionsMap: any = {
      2: 'two',
      3: 'three',
      4: 'four',
      5: 'five',
      6: 'six',
      7: 'seven',
      8: 'eight',
      9: 'nine',
      10: 'ten',
      15: 'fifteen',
      20: 'twenty',
      25: 'twentyfive',
    };
    for (let i = 0; i < data.claimOptions.length; i++) {
      const amount = data.claimOptions[i];
      if (amount === 1) {
        continue;
      }
      let option: any = {
        amount: amount,
        STX: `claim-${optionsMap[amount]}`,
      };
      for (let i = 0; i < data.fungibleSelected.length; i++) {
        if (!data.fungibleSelected[i].selected) {
          continue;
        }
        const token = data.fungibleSelected[i];
        const symbol: string = token['symbol'];
        const lowerSymbol = symbol.toLowerCase();
        option[symbol] = `claim-${optionsMap[amount]}-${lowerSymbol}`;
      }
      claimOptions.push(option);
    }
  }

  let altCurrencies: any = [];
  for (let i = 0; i < data.fungibleSelected.length; i++) {
    const fungible = data.fungibleSelected[i];
    if (fungible.selected) {
      altCurrencies.push(fungible);
    }
  }

  let alexCurrencies: any = [];
  if (parseInt(data.price) >= 50) {
    alexCurrencies = ['xBTC'];
  }

  let collectionImage = `https://stxnft.mypinata.cloud/ipfs/${ipfs.logoHash}`;
  const contractData: any = {
    name: data.collectionName,
    image: collectionImage,
    description: data.description,
    websiteUrl: data.website,
    twitterUrl: data.twitter,
    discordUrl: data.discord,
    category: data.category,
    contact: data.emailAddress,
    contractAddress: data.currentUserAddress,
    active: false,
    paused: !data.mintingEnabled,
    contractName: data.contractName,
    total: parseInt(data.total),
    prices: parseInt(data.price) * 1000000,
    artistAddress: data.artistAddress,
    metaHash: ipfs.jsonHash,
    deployedTransactionUrl: txUrl,
    contractType: data.mintpass && data.mintpass.length ? 'mintpass' : 'default',
    saleEnabled: false,
    nonCustEnabled: true,
    altCurrencies: altCurrencies,
    featured: false,
    lastModified: Date.now(),
    claimOptions: claimOptions,
    airdropEnabled: data.airdrop && data.airdrop.length ? true : false,
    revealEnabled: false,
    lightningEnabled: false,
    auctionEnabled: false,
    alexCurrencies: alexCurrencies,
    enlargeImage: data.logoEnlarged,
    blockchainId: 1,
    txId: data.txId,
  };

  console.log(contractData);
  const url = makeEndpoint('/create');

  // save to server
  axios
    .post(url, contractData, {
      auth: {
        username: process.env.REACT_APP_LOGIN,
        password: process.env.REACT_APP_PASSWORD,
      },
    })
    .then(() => console.log('Collection saved'))
    .catch((err: any) => {
      console.error(err);
    });
  let zapierData = contractData;

  // send as webhook
  if (isMainnet) {
    const webhookUrl = 'https://hooks.zapier.com/hooks/catch/11337148/b1ut1x8/';
    axios
      .create({
        transformRequest: [(data: any, _headers: any) => JSON.stringify(zapierData)],
      })
      .post(webhookUrl, zapierData)
      .then((res: any) => console.log(res))
      .catch((err: any) => {
        console.error(err);
      });
  }
}

export function handlePublicEthCollection(
  data: {
    contractName: string;
    collectionName: string;
    description: string;
    emailAddress: string;
    website: string;
    twitter: string;
    discord: string;
    category: number[];
    artistAddress: string;
    logo: any[];
    assets: any[];
    price: string;
    mintsPerAddress: string;
    currentUserAddress: string;
    logoEnlarged: boolean;
    txId: string;
    contractAddress: string;
  },
  ipfs: {
    jsonHash: string;
    logoHash: string;
  },
  txUrl: string,
) {
  let metaHash = ipfs.jsonHash;
  let collectionImage = `https://stxnft.mypinata.cloud/ipfs/${ipfs.logoHash}`;
  const contractData: any = {
    name: data.collectionName,
    image: collectionImage,
    description: data.description,
    websiteUrl: data.website,
    twitterUrl: data.twitter,
    discordUrl: data.discord,
    category: data.category,
    contact: data.emailAddress,
    contractAddress: data.contractAddress,
    active: false,
    contractName: data.contractName,
    total: data.assets.length,
    prices: data.price,
    artistAddress: data.artistAddress,
    metaHash: metaHash,
    deployedTransactionUrl: txUrl,
    featured: false,
    lastModified: Date.now(),
    enlargeImage: data.logoEnlarged,
    blockchainId: 2,
    txId: data.txId,
  };
  console.log(contractData);
  const url = makeEndpoint('/create');
  // save to server
  axios
    .post(url, contractData, {
      auth: {
        username: process.env.REACT_APP_LOGIN,
        password: process.env.REACT_APP_PASSWORD,
      },
    })
    .then(() => console.log('Collection saved'))
    .catch((err: any) => {
      console.error(err);
    });
  let zapierData = contractData;
  // send as webhook
  if (isMainnet) {
    const webhookUrl = 'https://hooks.zapier.com/hooks/catch/11337148/b1ut1x8/';
    axios
      .create({
        transformRequest: [(data: any, _headers: any) => JSON.stringify(zapierData)],
      })
      .post(webhookUrl, zapierData)
      .then((res: any) => console.log(res))
      .catch((err: any) => {
        console.error(err);
      });
  }
}

export function handleEditionsEthCollection(
  data: {
    contractName: string;
    collectionName: string;
    description: string;
    emailAddress: string;
    website: string;
    twitter: string;
    discord: string;
    category: number[];
    artistAddress: string;
    logo: any[];
    assets: any[];
    price: string;
    mintsPerAddress: string;
    currentUserAddress: string;
    logoEnlarged: boolean;
    txId: string;
    total: string;
    contractAddress: string;
  },
  ipfs: {
    logoHash: string;
    editionHash: string;
    jsonHash: any;
  },
  txUrl: string,
) {
  // let metaHash = ipfs.jsonHash;

  let collectionImage = `https://stxnft.mypinata.cloud/ipfs/${ipfs.logoHash}`;
  const contractData: any = {
    name: data.collectionName,
    image: collectionImage,
    description: data.description,
    websiteUrl: data.website,
    twitterUrl: data.twitter,
    discordUrl: data.discord,
    category: data.category,
    contact: data.emailAddress,
    contractAddress: data.contractAddress,
    active: false,
    contractName: data.contractName,
    total: parseInt(data.total),
    prices: data.price,
    artistAddress: data.artistAddress,
    metaHash: ipfs.jsonHash,
    deployedTransactionUrl: txUrl,
    featured: false,
    lastModified: Date.now(),
    enlargeImage: data.logoEnlarged,
    blockchainId: 2,
    txId: data.txId,
  };

  console.log(contractData);
  const url = makeEndpoint('/create');

  // save to server
  axios
    .post(url, contractData, {
      auth: {
        username: process.env.REACT_APP_LOGIN,
        password: process.env.REACT_APP_PASSWORD,
      },
    })
    .then(() => console.log('Collection saved'))
    .catch((err: any) => {
      console.error(err);
    });
  let zapierData = contractData;

  // send as webhook
  if (isMainnet) {
    const webhookUrl = 'https://hooks.zapier.com/hooks/catch/11337148/b1ut1x8/';
    axios
      .create({
        transformRequest: [(data: any, _headers: any) => JSON.stringify(zapierData)],
      })
      .post(webhookUrl, zapierData)
      .then((res: any) => console.log(res))
      .catch((err: any) => {
        console.error(err);
      });
  }
}

export function handleMintRequest(data: {
  emailAddress: string;
  website: string;
  twitter: string;
  discord: string;
  artistAddress: string;
  fullName: string;
  discordUsername: string;
  telegram: string;
  discordServer: string;
  twitterCollection: string;
  ownerSwitch1: boolean;
  ownerSwitch2: boolean;
  ownerSwitch3: boolean;
  ownerSwitch4: boolean;
  ownerSwitch5: boolean;
  highlight1: string;
  highlight2: string;
  highlight3: string;
  highlight4: string;
  highlight5: string;
  highlight6: string;
  ownerExplain: string;
  contractAddress: string;
  contractName: string;
}) {
  const url = makeEndpoint('/add/mint-request');

  axios
    .post(url, data, {
      auth: {
        username: process.env.REACT_APP_LOGIN,
        password: process.env.REACT_APP_PASSWORD,
      },
    })
    .then(() => console.log('Request saved'))
    .catch((err: any) => {
      console.error(err);
    });
}

export async function getMintRequest(data: { contractAddress: string; contractName: string }) {
  const url = makeEndpoint(
    `/get/mint-request?address=${data.contractAddress}&name=${data.contractName}`,
  );

  try {
    const res = await axios.get(url, {
      auth: {
        username: process.env.REACT_APP_LOGIN,
        password: process.env.REACT_APP_PASSWORD,
      },
    });
    return res.data;
  } catch (error) {
    console.log(error);
  }
}

export async function addAllowlist(data: {
  entries: { hash: string; maxCount: number; minter: string; price: number; proof: string[] }[];
  root: string;
}) {
  const url = makeEndpoint('/add/allowlist');
  try {
    const res = await axios.post(url, data, {
      auth: {
        username: process.env.REACT_APP_LOGIN,
        password: process.env.REACT_APP_PASSWORD,
      },
    });
    console.log(res);
    return 'success';
  } catch (e) {
    console.log(e);
    return 'error';
  }
}

export async function getAllowlist(data: { user: string; root: string }) {
  const url = makeEndpoint(`/get/allowlist?user=${data.user}&root=${data.root}`);

  try {
    const res = await axios.get(url, {
      auth: {
        username: process.env.REACT_APP_LOGIN,
        password: process.env.REACT_APP_PASSWORD,
      },
    });
    return res.data;
  } catch (error) {
    console.log(error);
  }
}

export async function verifyAndPublish(contractAddress: string, contractName: string) {
  const url = 'https://api.etherscan.io/api';
  const data = {
    apikey: process.env.REACT_APP_ETHERSCAN_KEY,
    module: 'contract',
    action: 'verifysourcecode',
    contractaddress: contractAddress,
    sourceCode: 'flattended source code',
    codeformat: 'solidity-single-file', // default is solidity-single-file
    contractname: contractName,
    compilerversion: 'v0.8.10+commit.fc410830', // see https://etherscan.io/solcversions for list of support versions
    optimizationUsed: 1,
    runs: 1000,
    licenseType: 3,
  };

  try {
    const res = await axios.post(url, data);
    return res.data;
  } catch (error) {
    console.log(error);
  }
}

export async function postFeaturedAuctions(data: { tokens: string[]; collections: string[] }) {
  const url = makeEndpoint('/post/featured-auctions');
  try {
    const res = await axios.post(url, data, {
      auth: {
        username: process.env.REACT_APP_LOGIN,
        password: process.env.REACT_APP_PASSWORD,
      },
    });
    return 'success';
  } catch (e) {
    return 'error';
  }
}

export async function getFeaturedAuctions() {
  const url = makeEndpoint('/api/featured-auctions');
  try {
    const res = await axios.get(url);
    return res.data;
  } catch (error) {
    console.log(error);
  }
}

export async function getExceptions() {
  const url = makeEndpoint('/api/exceptions');
  try {
    const res = await axios.get(url);
    console.log(res);
    return res.data;
  } catch (error) {
    console.log(error);
  }
}
