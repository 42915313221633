import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import styles from './index.module.scss';

import { darkmodeListener } from '../../theme';
import { gaSend } from '../../Analytics';

import makeEndpoint from '../../endpoints';

import Button from '../../components/Button';
import Card from '../../components/Card';
import Loading from '../../components/Loading';
import Renderer from '../../components/Renderer';

import icon from '../../components/Login/assets/icon.svg';

import {
  claim,
  getMintpassEnabled,
  getMintpasses,
  getPrice,
  getPublicSaleEnabled,
  getMintLimit,
  network,
  getAltPrice,
  getPaused,
  getSTXInBTC,
  swapBTCforSTX,
  ftClaim,
  getMintPermitted,
  mintBomber,
} from '../../utils/SelfServe/contracts';

import { AppConfig, UserSession, showConnect } from '@stacks/connect';

import InfiniteScroll from 'react-infinite-scroll-component';

import { renderer } from '../../utils';
import Modal from '../../components/Modal';
import Confirm from '../../components/Confirm';
import AdminPanel from './AdminPanel';
import UserTip from '../../components/UserTip';
import MintPriceIcon from '../../components/MintPriceIcon';
import header from '../../assets/megapontHeader.png';
import bomberGif from '../../assets/bomberGif.gif';
import bomberSizeGuide from '../../assets/bomberSizeGuide.png';
import {
  Container,
  ContainerFull,
  ContainerItem,
  ContainerSection,
  H1,
  H2,
  TextLink,
} from '../../components/common';

const appConfig = new AppConfig(['store_write', 'publish_data']);
const userSession = new UserSession({ appConfig });

const axios = require('axios');

declare const lnswap: any;

type MegapontAlphaJacketProps = {
  name: string;
  collectionData?: any;
};

type MegapontAlphaJacketState = {
  name: string;
  contractAddress: string;
  contractName: string;
  description: string;
  image: string;
  total: number;
  lastTokenID: number;
  prices: number;
  currency?: string;
  saleData: Record<string, any>;
  sale: Array<any>;
  active: boolean;
  paused: boolean;
  userData?: any;
  offset: number;
  contractType: string;
  mintpassEnabled: boolean;
  saleEnabled: boolean;
  mintpassStateChecked: boolean;
  saleStateChecked: boolean;
  hasMintpasses: boolean;
  passesRemaining: number;
  collectionData: any;
  mintPriceInput: string;
  mintPriceError?: string;
  altPriceInputs: Array<any>;
  altPriceErrors: Array<any>;
  mintLimitInput: string;
  mintLimitError?: string;
  altCurrencies: Array<any>;
  modal?: any;
  showAdmin: boolean;
  currencySelected: string;
  numberSelected: number;
  claimOptions: Array<any>;
  airdropEnabled: boolean;
  fts: Array<any>;
  revealEnabled: boolean;
  revealData: any;
  websiteUrl: string;
  websiteText: string;
  showSafetyTip: boolean;
  showLightningTip: boolean;
  functionName: string;
  pairs?: any;
  lightningEnabled: boolean;
  artistAddress: string;
  btcAmount: number;
  alexCurrencies: Array<any>;
  showAlexTip: boolean;
  enlargeImage: boolean;
  size: string;
  mintPermitted: boolean;
};

const fetchSize = 25;
class MegapontAlphaJacket extends Component<MegapontAlphaJacketProps, MegapontAlphaJacketState> {
  state = {
    name: '',
    contractAddress: '',
    artistAddress: '',
    contractName: '',
    description: '',
    image: '',
    total: 0,
    saleData: {},
    sale: [],
    lastTokenID: 0,
    prices: 0,
    currency: undefined,
    active: false,
    paused: false,
    userData: undefined,
    offset: 1,
    contractType: '',
    mintpassEnabled: false,
    saleEnabled: false,
    mintpassStateChecked: false,
    saleStateChecked: false,
    hasMintpasses: false,
    passesRemaining: 0,
    collectionData: undefined,
    mintPriceInput: '',
    mintPriceError: undefined,
    altPriceInputs: [],
    altPriceErrors: [],
    mintLimitInput: '',
    mintLimitError: undefined,
    altCurrencies: [],
    modal: undefined,
    showAdmin: false,
    currencySelected: 'STX',
    numberSelected: 1,
    claimOptions: [],
    airdropEnabled: false,
    fts: [],
    revealEnabled: false,
    revealData: {},
    websiteUrl: '',
    websiteText: '',
    showSafetyTip: false,
    showLightningTip: false,
    functionName: '',
    pairs: undefined,
    lightningEnabled: false,
    btcAmount: -1,
    alexCurrencies: [] as any,
    showAlexTip: false,
    enlargeImage: false,
    size: '',
    mintPermitted: false,
  };

  async componentDidMount() {
    try {
      const userData = localStorage.getItem('userData');
      if (userData) {
        this.setState({
          userData: JSON.parse(userData),
        });
      }
    } catch {}

    darkmodeListener(styles['theme-light'], styles['theme-dark']);

    fetch(makeEndpoint('/api/drop?name=' + this.props.name))
      .then((res) => {
        return res.json();
      })
      .then((artistData) => {
        this.setState(
          {
            name: artistData.name,
            contractAddress: artistData.contractAddress,
            contractName: artistData.contractName,
            description: artistData.description,
            image: artistData.image,
            total: 300,
            prices: artistData.prices,
            active: artistData.active,
            paused: artistData.paused,
            // currency: artistData.currency,
            contractType: artistData.contractType,
            collectionData: artistData,
            saleEnabled: artistData.saleEnabled,
            altCurrencies: artistData.altCurrencies,
            claimOptions: artistData.claimOptions,
            airdropEnabled: artistData.airdropEnabled,
            revealEnabled: artistData.revealEnabled,
            websiteUrl: artistData.websiteUrl,
            // websiteText: artistData.websiteText,
            lightningEnabled: artistData.lightningEnabled,
            artistAddress: artistData.artistAddress,
            alexCurrencies: artistData.alexCurrencies,
            enlargeImage: artistData.enlargeImage,
          },
          () => {
            // getPrice(artistData.contractAddress, artistData.contractName, this.setPrice.bind(this));

            if (this.stxAddress()) {
              getMintPermitted(
                artistData.contractAddress,
                artistData.contractName,
                this.stxAddress(),
                this.setMintPermitted.bind(this),
              );
            }

            // getMintLimit(
            //   artistData.contractAddress,
            //   artistData.contractName,
            //   this.setTotal.bind(this),
            // );

            // getPaused(
            //   artistData.contractAddress,
            //   artistData.contractName,
            //   this.setPaused.bind(this),
            // );

            fetch(
              makeEndpoint(
                '/api/nft/lastTokenID?address=' +
                  artistData.contractAddress +
                  '&name=' +
                  artistData.contractName +
                  '&reload=true',
              ),
            );
            fetch(
              makeEndpoint(
                '/api/nft/lastTokenID?address=' +
                  artistData.contractAddress +
                  '&name=' +
                  artistData.contractName,
              ),
            )
              .then((res) => {
                return res.text();
              })
              .then((lastTokenStr) => {
                if (lastTokenStr === 'null') {
                  return;
                }
                const lastToken = parseInt(lastTokenStr, 10);
                this.setState(
                  {
                    lastTokenID: lastToken,
                  },
                  () => {
                    this.fetchNFTs();
                  },
                );
              });
          },
        );
      });
  }

  fetchNFTs() {
    var nfts: Array<any> = [];
    var numNFTs = Math.min(this.state.lastTokenID, fetchSize);
    if (this.state.lastTokenID < this.state.offset + numNFTs) {
      const diff = this.state.offset + numNFTs - this.state.lastTokenID;
      numNFTs -= diff;
    }

    var contractName = this.state.contractName.replace('panda-mint', 'panda-nft');
    contractName = contractName.replace('htl-mint', 'htl');
    contractName = contractName.replace('commoners-mint', 'commoners');
    contractName = contractName.replace('trubit-domino-mint', 'trubit-domino');
    contractName = contractName.replace('stacks-parrots-3d-mint', 'stacks-parrots-3d');
    for (var x = this.state.offset; x <= this.state.offset + numNFTs; x++) {
      nfts.push({
        contractAddress: this.state.contractAddress,
        tokenID: x,
        contractName: contractName,
        sold: true,
      });
    }

    var sale: Array<any> = this.state.sale;
    const filtered_nfts = nfts.filter((r: any) => {
      let filtered_list = sale.filter(
        (l: any) =>
          l.contractAddress == r.contractAddress &&
          l.contractName == r.contractName &&
          String(l.tokenID) == String(r.tokenID),
      );
      return filtered_list.length == 0;
    });

    sale.push(...filtered_nfts);
    this.setState(
      {
        sale: sale,
      },
      () => {
        this.fetchData(nfts);
      },
    );
  }

  getLength() {
    return this.state.sale.length;
  }

  fetchData(nfts: any) {
    for (var nft of nfts) {
      var contractName = nft.contractName.replace('panda-mint', 'panda-nft');
      contractName = contractName.replace('htl-mint', 'htl');
      contractName = contractName.replace('commoners-mint', 'commoners');
      var params = '';
      params += 'address=' + nft.contractAddress;
      params += '&name=' + contractName;
      params += '&id=' + nft.tokenID;
      const nftId = nft.contractAddress + '.' + contractName + ':' + nft.tokenID;
      var saleDataRecord: any = this.state.saleData;
      if (saleDataRecord[nftId]) {
        continue;
      }
      fetch('https://legacy.stxnft.com/api/nft?' + params)
        .then((res) => res.json())
        .then((nftData) => {
          if (nftData.name) {
            var saleData = saleDataRecord;
            saleData[nftId] = nftData;
            this.setState({ saleData });
          }
        });
    }
  }

  getTotal() {
    return this.state.total;
  }

  getAvailable() {
    return this.getTotal() - this.state.lastTokenID;
  }

  getVolumeSold() {
    return this.state.lastTokenID * this.getFloorPrice();
  }

  getFloorPrice() {
    if (!this.state.currency) {
      return this.state.prices / 1e6;
    } else {
      return this.state.prices;
    }
  }

  nFormatter(num: number, digits: number) {
    const lookup = [
      { value: 1, symbol: '' },
      { value: 1e3, symbol: 'K' },
      { value: 1e6, symbol: 'M' },
      { value: 1e9, symbol: 'B' },
    ];
    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    var item = lookup
      .slice()
      .reverse()
      .find(function (item) {
        return num >= item.value;
      });
    return item ? (num / item.value).toFixed(digits).replace(rx, '$1') + item.symbol : '0';
  }

  renderCard() {
    return this.state.sale.map((x: any, idx) => {
      const saleData: any = this.state.saleData;
      const fullData: any = saleData[x.contractAddress + '.' + x.contractName + ':' + x.tokenID];
      if (fullData) {
        return (
          <Card
            key={idx}
            renderer={renderer(fullData)}
            collection={fullData.collection || fullData.name}
            name={fullData.name}
            currency={this.state.currency}
            // sold={this.state.prices}
          />
        );
      } else if (x.contractName) {
        const titled = x.contractName[0].toUpperCase() + x.contractName.substring(1);
        return (
          <Card
            key={idx}
            collection={titled}
            renderer={<Renderer />}
            currency={this.state.currency}
            name={titled + ' #' + x.tokenID}
          />
        );
      }
    });
  }

  amount() {
    let price = this.state.prices;
    return price;
  }

  mintText() {
    let mintText;
    if (this.state.active && this.state.paused) {
      mintText = 'Minting Paused';
    } else if (!this.state.active) {
      mintText = 'Pending Review';
    } else if (Math.floor(this.state.lastTokenID) >= Math.floor(this.state.total)) {
      mintText = 'Visit Marketplace';
    } else if (!this.stxAddress()) {
      mintText = 'Connect wallet';
    } else if (!this.state.mintPermitted) {
      mintText = 'Visit Marketplace*';
    } else if (this.state.userData) {
      this.state.size ? (mintText = 'Mint ' + this.state.size) : (mintText = 'Select a size to mint');
    } else {
      mintText = 'Connect Stacks Wallet';
    }
    return mintText;
  }

  renderPrice() {
    return (
      !(
        (this.state.active && this.state.paused) ||
        !this.state.active ||
        Math.floor(this.state.lastTokenID) >= Math.floor(this.state.total) ||
        (this.state.contractType == 'mintpass' &&
          !this.state.mintpassEnabled &&
          !this.state.saleEnabled) ||
        (this.state.contractType == 'mintpass' &&
          this.state.mintpassEnabled &&
          !this.state.hasMintpasses)
      ) &&
      this.state.userData &&
      this.state.mintPermitted
    );
  }

  stxAddress() {
    if (this.state.userData && network.isMainnet()) {
      const userData: any = this.state.userData;
      return userData.profile.stxAddress.mainnet;
    } else if (this.state.userData && !network.isMainnet()) {
      const userData: any = this.state.userData;
      return userData.profile.stxAddress.testnet;
    } else {
      return null;
    }
  }

  authenticate() {
    showConnect({
      appDetails: {
        name: 'Stacks NFT',
        icon: icon,
      },
      redirectTo: '/',
      onFinish: () => {
        gaSend('User', 'authenticate', 'User Authenticated');
        let userData = userSession.loadUserData();
        this.setState({ userData: userData });
        localStorage.setItem('userData', JSON.stringify(userData));
        window.location.reload();
      },
      userSession: userSession,
    });
  }

  openSafetyTip() {
    this.setState({ showSafetyTip: true });
  }

  closeSafetyTip(accepted?: boolean) {
    this.modalClose();
    this.setState({ showSafetyTip: false });
    if (accepted) {
      this.openClaim();
    }
  }

  openClaim() {
    if (this.state.userData) {
      mintBomber(
        this.state.contractAddress,
        this.state.contractName,
        this.amount(),
        this.state.size,
        this.stxAddress(),
        this.setModal.bind(this),
      );
    } else {
      this.authenticate();
    }
  }

  setMintPermitted(permitted: boolean) {
    console.log(permitted);
    this.setState({ mintPermitted: permitted });
  }

  syncCollectionData(isAuto: boolean) {
    // let data: any = this.state.collectionData;
    // if (data) {
    //   data['prices'] = this.state.prices;
    //   data['total'] = this.state.total;
    //   data['paused'] = this.state.paused;
    //   data['saleEnabled'] = this.state.saleEnabled;
    //   data['altCurrencies'] = this.state.altCurrencies;
    // }
    // const url = makeEndpoint('/update');
    // axios
    //   .post(url, data, {
    //     auth: {
    //       username: process.env.REACT_APP_LOGIN,
    //       password: process.env.REACT_APP_PASSWORD,
    //     },
    //   })
    //   .then(() => {
    //     console.log('Collection saved');
    //     if (!isAuto) {
    //       this.setModal({
    //         success: true,
    //       });
    //     }
    //   })
    //   .catch((err: any) => {
    //     console.error(err);
    //     if (!isAuto) {
    //       this.setModal({
    //         success: false,
    //         errorMessage: 'Sync failed',
    //       });
    //     }
    //   });
  }

  mintButton() {
    if (this.amount() >= 0 && this.renderPrice() && this.state.size) {
      return (
        <div className={styles.MintButton}>
          <Button
            text={this.mintText()}
            price={this.amount()}
            currency={this.state.currencySelected}
            onClick={this.mintingBehavior.bind(this, this.state.size, this.amount())}
          />
        </div>
      );
    } else {
      return (
        <div className={styles.MintButton}>
          <Button
            text={this.mintText()}
            onClick={this.mintingBehavior.bind(this, this.state.size, this.amount())}
          />
        </div>
      );
    }
  }

  mintingBehavior(size: string, price: number) {
    if (!this.state.contractAddress || !this.state.contractName) {
      return;
    }

    // send to marketplace if sold out
    if (Math.floor(this.state.lastTokenID) >= Math.floor(this.state.total)) {
      window.location.href = `https://stacks.gamma.io/collections/${this.state.contractAddress}.${this.state.contractName}/`;
      return;
    }

    // send to marketplace if not permitted
    if (this.stxAddress() && !this.state.mintPermitted) {
      window.location.href = `https://stacks.gamma.io/collections/${this.state.contractAddress}.${this.state.contractName}/`;
      return;
    }

    // minting only disabled when paused if not contract owner
    if (this.state.paused && this.stxAddress() != this.state.contractAddress) {
      return;
    }

    // admin override
    if (!this.state.active) {
      return;
    }

    if (this.stxAddress() && !this.state.size) {
      return;
    }

    let functionName: string = 'mint';

    this.setState({ functionName: functionName });

    // this.openSafetyTip();
    this.openClaim();
  }

  nextBlocks(offset: number) {
    this.setState(
      {
        offset: offset,
      },
      () => {
        this.fetchNFTs();
      },
    );
  }

  getBlocksNext() {
    this.nextBlocks(this.getLength());
  }

  hasMore() {
    return this.state.lastTokenID > this.state.offset;
  }

  setModal(modal: any) {
    this.setState({
      modal: modal,
    });
  }

  modalClose() {
    this.setState({
      modal: undefined,
    });
  }

  handleSize(value: string) {
    this.setState({
      size: value,
    });
  }

  renderSizeButtons() {
    let buttons: any = [];
    let sizes = ['XS', 'S', 'M', 'L', 'XL', '2XL', '3XL'];
    for (let i = 0; i < sizes.length; i++) {
      const size: any = sizes[i];
      const el = (
        <div style={{ margin: '10px' }} key={i}>
          <Button
            text={size}
            style={this.state.size === size ? 'PRIMARY' : 'SECONDARY'}
            onClick={this.handleSize.bind(this, size)}
          />
        </div>
      );
      buttons.push(el);
    }
    return <div style={{ display: 'flex', marginBottom: '10px' }}>{buttons}</div>;
  }

  render() {
    return (
      <ContainerFull>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Megapont Alpha Jacket | Gamma.io</title>
          <meta name="description" content="" />
        </Helmet>
        <div className={styles.Banner}>
          <img
            style={{ borderBottom: '5px solid red', boxSizing: 'border-box' }}
            className={styles.BannerImage}
            src={header}
          />
        </div>

        <div className={styles.Artists}>
          <H1>Megapont Anniversary Bomber Jacket</H1>
          <ContainerItem css={{ fontSize: '20px', lineHeight: '1.25em' }}>
            Celebrate the one-year anniversary of Megapont on Stacks with a commemorative
            collaboration between Megapont and Alpha Industries.
          </ContainerItem>
          <ContainerItem css={{ fontSize: '20px', lineHeight: '1.25em', marginBottom: '60px' }}>
            Minting a Megapont Bomber NFT will permit you to redeem the NFT to claim a physical
            jacket. While minting the NFT, you must select the size of the jacket. Please pay
            attention to the sizing, as this cannot be changed after the NFT has been minted.
          </ContainerItem>
          <H2 css={{ fontSize: '30px' }}>Shipping</H2>
          <ContainerItem css={{ fontSize: '20px', lineHeight: '1.25em' }}>
            The process for redeeming your NFT for the jacket will open in Q4 of 2022. We will be
            working with a logistics partner that will ship the items globally.{' '}
            <strong>The cost of shipping is included in the NFT mint price.</strong> Import duties
            and taxes are not included. The logistics partner will be US-based.
          </ContainerItem>
          <ContainerItem css={{ fontSize: '20px', lineHeight: '1.25em', marginBottom: '60px' }}>
            Once you have redeemed the NFT for the physical jacket, the NFT will no longer be
            transferable or tradeable.
          </ContainerItem>
          <H2 css={{ fontSize: '30px' }}>Size Guide</H2>
          <ContainerItem css={{ fontSize: '20px', lineHeight: '1.25em' }}>
            You can visit the Alpha Industries MA-1 jacket listing page and use their size finder
            tool to find the appropriate size for you. See the link below.
          </ContainerItem>
          <ContainerItem css={{ fontSize: '20px', lineHeight: '1.25em' }}>
            <TextLink
              css={{ color: 'Red' }}
              href="https://www.alphaindustries.com/products/mjm44530c1-mens-ma-1-slim-fit-bomber-jacket"
            >
              Alpha Industries MA-1 jacket listing page
            </TextLink>{' '}
          </ContainerItem>
          <ContainerItem css={{ fontWeight: 'bold', fontSize: '20px', lineHeight: '1.25em' }}>
            Please note: Once you have selected a size and minted the NFT, the size you have
            selected will be locked in and cannot be changed.
          </ContainerItem>
          <div className={styles.Banner}>
            <img
              style={{ borderRadius: '12px' }}
              className={styles.BannerImage}
              src={bomberSizeGuide}
            />
          </div>
        </div>
        <div className={styles.Center}>
          <img
            style={{ width: '400px', height: 'auto', borderRadius: '12px', margin: '50px 0' }}
            className={styles.BomberAnim}
            src={bomberGif}
          />
        </div>
        <div className={styles.Collections}>
          {/* <div className={styles.FakeMintWrapper}>
            <div className={styles.FakeMintButton}>
              <Button text="Minting Soon" />
            </div>
            <div className={styles.MintPrice}>
              <div className={styles.MintPriceContainer}>
                <div>33</div>
                <div className={styles.MintPriceTitle}>TOTAL</div>
              </div>
              <div className={styles.MintPriceContainer}>
                <div>33</div>
                <div className={styles.MintPriceTitle}>REMAINING</div>
              </div>
              <div className={styles.MintPriceContainer}>
                <div className={styles.MintPriceIconContainer}>
                  {!this.state.currency && <MintPriceIcon />}
                  <div>75</div>
                </div>
                <div className={styles.MintPriceTitle}>MINT PRICE</div>
              </div>
            </div>
          </div> */}

          <div className={styles.FakeMintWrapper}>
            {this.state.active &&
              this.state.lastTokenID < this.state.total &&
              this.state.passesRemaining > 0 &&
              this.state.mintpassEnabled && (
                <div className={styles.MintpassBalanceContainer}>
                  <div className={styles.MintpassBalance}>
                    You have this.state.passesRemaining mintpasses remaining
                  </div>
                </div>
              )}

            {this.state.active &&
            !this.state.paused &&
            this.state.lastTokenID <= this.state.total &&
            this.state.userData ? (
              <div className={styles.MintContentRight}>
                {this.state.mintPermitted ? this.renderSizeButtons() : null}
                <div style={{ margin: '5px' }}></div>
                <div className={styles.MintButton}>{this.mintButton()}</div>
              </div>
            ) : (
              <div className={styles.MintContentRight}>
                <div className={styles.MintButton}>{this.mintButton()}</div>
              </div>
            )}

            <div className={styles.MintPrice}>
              {/* <div className={styles.MintPriceContainer}>
                <div>{this.getTotal().toLocaleString("us")}</div>
                <div className={styles.MintPriceTitle}>TOTAL</div>
              </div> */}
              <div className={styles.MintPriceContainer}>
                <div>{this.getAvailable().toLocaleString('us')}</div>
                <div className={styles.MintPriceTitle}>REMAINING</div>
              </div>
              <div className={styles.MintPriceContainer}>
                <div className={styles.MintPriceIconContainer}>
                  {!this.state.currency && <MintPriceIcon />}
                  <div>{this.getFloorPrice().toLocaleString('us')}</div>
                  {this.state.currency == 'MIA' && <div className={styles.MintPriceText}>MIA</div>}
                </div>
                <div className={styles.MintPriceTitle}>MINT PRICE</div>
              </div>
            </div>

            {!this.state.mintPermitted ? (
              <div className={styles.MintpassBalanceContainer}>
                <div className={styles.MintpassBalance}>
                  *You must hold a Megapont Ape Club NFT or MEGA token to be eligible for this mint.
                </div>
              </div>
            ) : null}
          </div>

          {/* <div className={styles.MintHeader}>
            <div className={styles.MintRightBar}>
              {this.state.active &&
                this.state.lastTokenID < this.state.total &&
                this.state.passesRemaining > 0 &&
                this.state.mintpassEnabled && (
                  <div className={styles.MintpassBalanceContainer}>
                    <div className={styles.MintpassBalance}>
                      You have this.state.passesRemaining mintpasses remaining
                    </div>
                  </div>
                )}

              {this.state.active &&
              !this.state.paused &&
              this.state.lastTokenID <= this.state.total &&
              this.state.userData ? (
                <div className={styles.MintContentRight}>
                  {this.renderCurrencyButtons()}
                  {this.renderNumberButtons()}
                  <div style={{ margin: "5px" }}></div>
                  <div className={styles.MintButton}>{this.mintButton()}</div>
                </div>
              ) : (
                <div className={styles.MintContentRight}>
                  <div className={styles.MintButton}>{this.mintButton()}</div>
                </div>
              )}

              <div className={styles.MintPrice}>
                <div className={styles.MintPriceContainer}>
                  <div>{this.getTotal().toLocaleString("us")}</div>
                  <div className={styles.MintPriceTitle}>TOTAL</div>
                </div>
                <div className={styles.MintPriceContainer}>
                  <div>{this.getAvailable().toLocaleString("us")}</div>
                  <div className={styles.MintPriceTitle}>REMAINING</div>
                </div>
                <div className={styles.MintPriceContainer}>
                  <div className={styles.MintPriceIconContainer}>
                    {!this.state.currency && <MintPriceIcon />}
                    <div>{this.getFloorPrice().toLocaleString("us")}</div>
                    {this.state.currency == "MIA" && (
                      <div className={styles.MintPriceText}>MIA</div>
                    )}
                  </div>
                  <div className={styles.MintPriceTitle}>MINT PRICE</div>
                </div>
              </div>
            </div>
          </div> */}

          {this.state.showSafetyTip && (
            <Modal onClose={this.closeSafetyTip.bind(this)}>
              <UserTip onClose={this.closeSafetyTip.bind(this)}>
                <ul className={styles.Tip}>
                  <li>
                    STXNFT doesn't sponsor or endorse any collection - even those using{' '}
                    <a href="http://create.stxnft.com" target="_blank" rel="noopener noreferrer">
                      create.stxnft.com
                    </a>{' '}
                    to mint their collection.
                  </li>
                  <li>
                    If you're new to NFTs, we recommend reading{' '}
                    <a
                      href="https://stxnft.zendesk.com/hc/en-us/articles/4416081737363-What-are-some-ways-I-can-screen-for-high-quality-NFT-projects-"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      How to Screen for High-Quality Projects
                    </a>{' '}
                    before proceeding.
                  </li>
                  <li>
                    While NFTs often trade on our secondary marketplace, you should assume that
                    there could be no market for this NFT at any price.
                  </li>
                </ul>
              </UserTip>
            </Modal>
          )}

          {this.state.modal && (
            <Modal onClose={this.modalClose.bind(this)}>
              <Confirm
                success={this.state.modal['success']}
                transactionId={this.state.modal['successTransaction']}
                errorMessage={this.state.modal['errorMessage']}
                onClose={this.modalClose.bind(this)}
              />
            </Modal>
          )}

          {this.stxAddress() &&
            (this.stxAddress() == this.state.contractAddress ||
              this.stxAddress() == this.state.artistAddress) &&
            this.state.active && (
              <AdminPanel
                setModal={this.setModal.bind(this)}
                syncCollectionData={this.syncCollectionData.bind(this)}
                contractAddress={this.state.contractAddress}
                contractName={this.state.contractName}
                altCurrencies={this.state.altCurrencies}
                revealEnabled={this.state.revealEnabled}
                contractType={this.state.contractType}
                mintpassStateChecked={this.state.mintpassStateChecked}
                mintpassEnabled={this.state.mintpassEnabled}
                saleStateChecked={this.state.saleStateChecked}
                saleEnabled={this.state.saleEnabled}
                airdropEnabled={this.state.airdropEnabled}
                revealData={this.state.revealData}
                paused={this.state.paused}
                altPriceInputs={this.state.altPriceInputs}
                altPriceErrors={this.state.altPriceErrors}
              />
            )}

          <InfiniteScroll
            dataLength={this.getLength()}
            next={this.getBlocksNext.bind(this)}
            hasMore={this.hasMore()}
            loader={<Loading />}
            className={styles.MintPriceCardContainer}
            scrollableTarget={'page-wrapper'}
          >
            {this.renderCard()}
          </InfiniteScroll>
        </div>
      </ContainerFull>
    );
  }
}

export default MegapontAlphaJacket;
