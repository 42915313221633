import { green, red } from '@radix-ui/colors';
import { styled } from '@stitches/react';
import { useState } from 'react';
import { FileRejection, useDropzone } from 'react-dropzone';
import { BaseUriPreviewCard, EditionsPreviewCard, PreviewCard } from './cards';
import { ContainerItem } from './common';

const Outline = styled('div', {
  textAlign: 'center',
  border: '1px dashed #dcdde2',
  borderRadius: 10,
  padding: '40px 0',
  position: 'relative',
});

const Error = styled('span', {
  lineHeight: '28px',
  marginLeft: 10,
  color: red.red9,
  fontSize: 'small',
});

const Success = styled('span', {
  lineHeight: '28px',
  marginLeft: 10,
  color: green.green9,
  fontSize: 'small',
});

const Label = styled('div', {
  fontSize: 18,
  userSelect: 'none',
  maxWidth: 'fit-content',
  lineHeight: '48px',
});

const Note = styled('div', {
  marginBottom: '20px',
});

const Thumb = styled('div', {
  display: 'flex',
  borderRadius: 2,
  border: '1px solid #eaeaea',
  marginBottom: 8,
  marginRight: 8,
  width: '110px',
  height: '110px',
  padding: 4,
  boxSizing: 'border-box',
});

const ThumbLogo = styled('div', {
  position: 'absolute',
  bottom: '5%',
  left: '5px',
  display: 'flex',
  borderRadius: 2,
  border: '1px solid #eaeaea',
  // marginBottom: 8,
  // marginRight: 8,
  // width: '80px',
  height: '90%',
  padding: 4,
  boxSizing: 'border-box',
});

const thumbInner = {
  display: 'flex',
  minWidth: 0,
  overflow: 'hidden',
};

const img = {
  display: 'block',
  width: 'auto',
  height: '100%',
};

const maxTotalFileSize = 524288000;

function fileSizeValidator(files: any[]) {
  let totalFileSize = 0;
  for (let i = 0; i < files.length; i++) {
    const file = files[i];
    totalFileSize += file.size;
  }

  if (totalFileSize > maxTotalFileSize) {
    return {
      file: files,
      errors: [
        {
          code: 'total-file-size-too-large',
          message: 'File size is larger than 500mb',
        },
      ],
    };
  }

  return null;
}

function fileTypeValidator(files: any[]) {
  const validFiletypes = [
    'image/png',
    'image/jpeg',
    'image/gif',
    'image/bmp',
    'image/svg+xml',
    'image/webp',
    'image/apng',
    'video/mp4',
    'video/webm',
    'audio/mpeg',
    'audio/wav',
    'audio/x-wav',
  ];
  let invalid = false;
  let error = '';
  for (let i = 0; i < files.length; i++) {
    const file = files[i];
    if (!validFiletypes.includes(file.file.type)) {
      error = 'Invalid file type: ' + file.file.type;
      invalid = true;
      break;
    }
  }

  if (invalid) {
    return {
      file: files,
      errors: [
        {
          code: 'invalid-file-type',
          message: error,
        },
      ],
    };
  }

  return null;
}

function logoFileTypeValidator(files: any[]) {
  const validFiletypes = ['image/png', 'image/jpeg', 'image/gif'];
  let invalid = false;
  let error = '';
  for (let i = 0; i < files.length; i++) {
    const file = files[i];
    if (!validFiletypes.includes(file.file.type)) {
      error = 'Invalid file type: ' + file.file.type;
      invalid = true;
      break;
    }
  }

  if (invalid) {
    return {
      file: files,
      errors: [
        {
          code: 'invalid-file-type',
          message: error,
        },
      ],
    };
  }

  return null;
}

export const AssetsDropzone = (props: {
  onDrop: any;
  label?: string;
  note?: string;
  error?: string;
  successText?: string;
  showThumbs?: boolean;
  offset?: number;
  stepCallback?: any;
}) => {
  const [files, setFiles] = useState<any[]>([]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: (acceptedFiles, fileRejections) => {
      const files: any[] = [];
      for (let i = 0; i < acceptedFiles.length; i++) {
        const file = acceptedFiles[i];
        const image = {
          file,
          preview: URL.createObjectURL(file),
        };
        files.push(image);
      }

      const rejections: any = fileRejections;
      const fileSizeRejection = fileSizeValidator(files);
      if (fileSizeRejection) {
        rejections.push(fileSizeRejection);
      }
      const fileTypeRejection = fileTypeValidator(files);
      if (fileTypeRejection) {
        rejections.push(fileTypeRejection);
      }
      props.onDrop(files, rejections);

      setFiles(files);
    },
    // accept: {
    //   'image/png': ['.png'],
    //   'image/jpeg': ['.jpg'],
    //   'image/gif': ['.gif'],
    //   'image/bmp': ['.bmp'],
    //   'image/svg+xml': ['.svg'],
    //   'image/webp': ['.webp'],
    //   'image/apng': ['.apng'],
    //   'video/mp4': ['.mp4'],
    //   'video/webm': ['.webm'],
    //   'audio/mpeg': ['.mp3'],
    //   'audio/wav': ['.wav'],
    //   'audio/x-wav': ['.wav'],
    // },
    // accept: {
    //   'image/*': [],
    // },
  });

  return (
    <>
      <Label>
        {props.label}
        {props.error && <Error>{props.error}</Error>}
        {props.successText && <Success>{props.successText}</Success>}
      </Label>
      <Note>{props.note}</Note>
      <Outline {...getRootProps()}>
        <input {...getInputProps()} />
        {isDragActive ? (
          <div>Drop the files here...</div>
        ) : (
          <div>Drag and drop or click to browse</div>
        )}
      </Outline>
      {props.showThumbs ? (
        <PreviewCard
          offset={props.offset ? props.offset : 0}
          stepCallback={props.stepCallback ? (step: number) => props.stepCallback(step) : undefined}
        />
      ) : null}
    </>
  );
};

export const LogoDropzone = (props: {
  onDrop: any;
  label?: string;
  note?: string;
  error?: string;
  successText?: string;
  showThumbs?: boolean;
  files?: any;
}) => {
  // const [files, setFiles] = useState<any[]>([]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: (acceptedFiles, fileRejections) => {
      const files: any[] = [];
      for (let i = 0; i < acceptedFiles.length; i++) {
        const file = acceptedFiles[i];
        const image = {
          file,
          preview: URL.createObjectURL(file),
        };
        files.push(image);
      }

      const rejections: any = fileRejections;
      const fileTypeRejection = logoFileTypeValidator(files);
      if (fileTypeRejection) {
        rejections.push(fileTypeRejection);
      }

      props.onDrop(files, fileRejections);
      // setFiles(files);
    },
    // accept: {
    //   'image/jpeg': ['.jpg'],
    //   'image/gif': ['.gif'],
    //   'image/png': ['.png'],
    // },
    maxFiles: 1,
  });

  return (
    <>
      <Label>
        {props.label}
        {props.error && <Error>{props.error}</Error>}
        {props.successText && <Success>{props.successText}</Success>}
      </Label>
      <Note>{props.note}</Note>
      <Outline {...getRootProps()}>
        <input {...getInputProps()} />
        {isDragActive ? (
          <div>Drop the file here...</div>
        ) : (
          <div>Drag and drop or click to browse</div>
        )}
        {props.files && props.files.length > 0 ? (
          <ThumbLogo>
            <div style={thumbInner}>
              <img
                alt="not found"
                src={props.files[0].preview}
                style={img}
                // Revoke data uri after image is loaded
                // onLoad={() => {
                //   URL.revokeObjectURL(files[0].preview);
                // }}
              />
            </div>
          </ThumbLogo>
        ) : null}
      </Outline>
      {props.showThumbs ? <PreviewCard /> : null}
    </>
  );
};

export const EditionsDropzone = (props: {
  onDrop: any;
  label?: string;
  note?: string;
  error?: string;
  successText?: string;
  showThumbs?: boolean;
  files?: any;
}) => {
  // const [files, setFiles] = useState<any[]>([]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: (acceptedFiles, fileRejections) => {
      const files: any[] = [];
      for (let i = 0; i < acceptedFiles.length; i++) {
        const file = acceptedFiles[i];
        const image = {
          file,
          preview: URL.createObjectURL(file),
        };
        files.push(image);
      }

      const rejections: any = fileRejections;
      const fileTypeRejection = fileTypeValidator(files);
      if (fileTypeRejection) {
        rejections.push(fileTypeRejection);
      }
      props.onDrop(files, rejections);

      props.onDrop(files, fileRejections);
      // setFiles(files);
    },
    // accept: {
    //   'image/png': ['.png'],
    //   'image/jpeg': ['.jpg'],
    //   'image/gif': ['.gif'],
    //   'image/bmp': ['.bmp'],
    //   'image/svg+xml': ['.svg'],
    //   'image/webp': ['.webp'],
    //   'image/apng': ['.apng'],
    //   'video/mp4': ['.mp4'],
    //   'video/webm': ['.webm'],
    //   'audio/mpeg': ['.mp3'],
    //   'audio/wav': ['.wav'],
    //   'audio/x-wav': ['.wav'],
    // },
    maxFiles: 1,
  });

  function renderPreview() {
    if (props.files[0].file.type.includes('video')) {
      return (
        <video autoPlay controls muted>
          <source src={props.files[0].preview} type={props.files[0].file.type} />
        </video>
      );
    } else {
      return <img alt={props.files[0].file.name} src={props.files[0].preview} style={img} />;
    }
  }

  return (
    <>
      <Label>
        {props.label}
        {props.error && <Error>{props.error}</Error>}
        {props.successText && <Success>{props.successText}</Success>}
      </Label>
      <Note>{props.note}</Note>
      <Outline {...getRootProps()}>
        <input {...getInputProps()} />
        {isDragActive ? (
          <div>Drop the file here...</div>
        ) : (
          <div>Drag and drop or click to browse</div>
        )}
        {/* {props.files && props.files.length > 0 ? (
          <ThumbLogo>
            <div style={thumbInner}>
              {renderPreview()}
            </div>
          </ThumbLogo>
        ) : null} */}
      </Outline>
      {props.files && props.files.length ? (
        <ContainerItem>
          <EditionsPreviewCard asset={props.files[0]} />
        </ContainerItem>
      ) : null}

      {props.showThumbs ? <PreviewCard /> : null}
    </>
  );
};

export const BaseUriDropzone = (props: {
  onDrop: any;
  label?: string;
  note?: string;
  error?: string;
  successText?: string;
  showThumbs?: boolean;
  files?: any;
  tokenName?: string;
  tokenDescription?: string;
  attributes?: any[];
}) => {
  // const [files, setFiles] = useState<any[]>([]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: (acceptedFiles, fileRejections) => {
      const files: any[] = [];
      for (let i = 0; i < acceptedFiles.length; i++) {
        const file = acceptedFiles[i];
        const image = {
          file,
          preview: URL.createObjectURL(file),
        };
        files.push(image);
      }

      const rejections: any = fileRejections;
      const fileTypeRejection = fileTypeValidator(files);
      if (fileTypeRejection) {
        rejections.push(fileTypeRejection);
      }
      props.onDrop(files, rejections);
      // props.onDrop(files, fileRejections);
      // setFiles(files);
    },
    maxFiles: 1,
  });

  function renderPreview() {
    if (props.files[0].file.type.includes('video')) {
      return (
        <video autoPlay controls muted>
          <source src={props.files[0].preview} type={props.files[0].file.type} />
        </video>
      );
    } else {
      return <img alt={props.files[0].file.name} src={props.files[0].preview} style={img} />;
    }
  }

  return (
    <>
      <Label>
        {props.label}
        {props.error && <Error>{props.error}</Error>}
        {props.successText && <Success>{props.successText}</Success>}
      </Label>
      <Note>{props.note}</Note>
      <Outline {...getRootProps()}>
        <input {...getInputProps()} />
        {isDragActive ? (
          <div>Drop the file here...</div>
        ) : (
          <div>Drag and drop or click to browse</div>
        )}
      </Outline>
      {props.files && props.files.length ? (
        <ContainerItem>
          <BaseUriPreviewCard
            asset={props.files[0]}
            name={props.tokenName || props.files[0].file.name.split('.')[0]}
            description={props.tokenDescription || undefined}
            attributes={props.attributes}
          />
        </ContainerItem>
      ) : null}

      {props.showThumbs ? <PreviewCard /> : null}
    </>
  );
};

export const CsvDropzone = (props: {
  onDrop: any;
  label?: string;
  note?: string;
  error?: string;
  successText?: string;
  sample?: string;
}) => {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: (acceptedFiles, fileRejections) => {
      props.onDrop(acceptedFiles, fileRejections);
    },
    accept: {
      'text/csv': ['.csv'],
    },
    maxFiles: 1,
  });

  function renderText() {
    if (isDragActive) {
      return <div>Drop the file here...</div>;
    } else if (props.successText) {
      return <div>File added!</div>;
    } else {
      return <div>Drag and drop or click to browse</div>;
    }
  }

  return (
    <>
      <Label>
        {props.label}
        {props.error && <Error>{props.error}</Error>}
        {props.successText && <Success>{props.successText}</Success>}
      </Label>
      <Note>
        {props.note}{' '}
        {props.sample ? (
          <a
            style={{ textDecoration: 'underline' }}
            href={`/${props.sample}`}
            download={`/${props.sample}`}
          >
            Download a sample file.
          </a>
        ) : null}
      </Note>
      <Outline {...getRootProps()}>
        <input {...getInputProps()} />
        {/* {isDragActive ? (
          <div>Drop the file here...</div>
        ) : (
          <div>Drag and drop or click to browse</div>
        )} */}
        {renderText()}
      </Outline>
    </>
  );
};

export const AttributesDropzone = (props: {
  onDrop: any;
  label?: string;
  note?: string;
  error?: string;
  successText?: string;
  sample?: string;
}) => {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: (acceptedFiles, fileRejections) => {
      props.onDrop(acceptedFiles, fileRejections);
    },
    accept: {
      'text/csv': ['.csv'],
      'application/json': ['.json'],
    },
    maxFiles: 1,
  });

  function renderText() {
    if (isDragActive) {
      return <div>Drop the file here...</div>;
    } else if (props.successText) {
      return <div>File added!</div>;
    } else {
      return <div>Drag and drop or click to browse</div>;
    }
  }

  return (
    <>
      <Label>
        {props.label}
        {props.error && <Error>{props.error}</Error>}
        {props.successText && <Success>{props.successText}</Success>}
      </Label>
      <Note>
        {props.note}{' '}
        {props.sample ? (
          <a
            style={{ textDecoration: 'underline' }}
            href={`/${props.sample}`}
            download={`/${props.sample}`}
          >
            Download a sample file.
          </a>
        ) : null}
      </Note>
      <Outline {...getRootProps()}>
        <input {...getInputProps()} />
        {/* {isDragActive ? (
          <div>Drop the file here...</div>
        ) : (
          <div>Drag and drop or click to browse</div>
        )} */}
        {renderText()}
      </Outline>
    </>
  );
};
