import React, { Component } from 'react';
import styles from './index.module.scss';

import Actions from './Actions';
import Details from './Details';
import Renderer from '../../Renderer';

import Button from '../../Button';
import Select from '../Select';

import { darkmodeListener } from '../../../theme';
import { FrontCard } from '../../cards';

type FrontProps = {
  attributes?: any;
  attributesAction?: (...args: any[]) => void;
  bid?: number;
  buy?: number;
  crop: 'COVER' | 'CONTAIN' | 'FILL';
  renderer?: Renderer;
  bidFunction?: (...args: any[]) => void;
  buyFunction?: (...args: any[]) => void;
  sendFunction?: (...args: any[]) => void;
  sellFunction?: (...args: any[]) => void;
  unlistFunction?: (...args: any[]) => void;
  listAuctionFunction?: (...args: any[]) => void;
  featureAuctionFunction?: (...args: any[]) => void;
  unlistAuctionFunction?: (...args: any[]) => void;
  endAuctionFunction?: (...args: any[]) => void;
  viewAuctionPath?: string;
  viewFunction?: (...args: any[]) => void;
  link?: (...args: any[]) => void;
  profile?: (...args: any[]) => void;
  bidStyle?: 'PRIMARY' | 'SECONDARY' | 'ERROR';
  buyStyle?: 'PRIMARY' | 'SECONDARY' | 'ERROR';
  sellStyle?: 'PRIMARY' | 'SECONDARY' | 'ERROR';
  sendStyle?: 'PRIMARY' | 'SECONDARY' | 'ERROR';
  badges?: Array<'VERIFIED' | 'ERROR' | 'FEATURED'>;
  collection?: string;
  name?: string;
  sold?: number;
  soldFlag?: boolean;
  auctionFlag?: boolean;
  auctionText?: string;
  auctionDetails?: any;
  auctionLoading?: boolean;
  auctionDetailsText?: string;
  currency?: 'STX' | 'MIA' | 'BTC';
  auctionCount?: number;
  collectionPath?: string;
  tokenPath?: string;
  featureAuctionText?: string
};

type FrontState = {};

class Front extends Component<FrontProps, FrontState> {
  static defaultProps = {
    crop: 'COVER',
  };

  setCrop() {
    if (this.props.crop == 'COVER') {
      return { 'object-fit': 'cover' };
    } else if (this.props.crop == 'CONTAIN') {
      return { 'object-fit': 'contain' };
    } else {
      return { 'object-fit': 'fill' };
    }
  }

  componentDidMount() {
    darkmodeListener(styles['theme-light'], styles['theme-dark']);
  }

  render() {
    return (
      <FrontCard>
        {this.props.renderer && this.props.link && (
          <span className={styles.CardImgWrapper} onClick={this.props.link.bind(this)}>
            {this.props.renderer}
          </span>
        )}
        {this.props.renderer && this.props.tokenPath && (
          <span className={styles.CardImgWrapper}>
            <a href={this.props.tokenPath} target="_blank" rel="noopener noreferrer">
              {this.props.renderer}
            </a>
          </span>
        )}
        {this.props.renderer &&
          this.props.link == undefined &&
          this.props.tokenPath == undefined &&
          this.props.renderer}
        <Select profile={this.props.profile} />
        <Details
          attributes={this.props.attributes}
          attributesAction={this.props.attributesAction}
          badges={this.props.badges}
          collection={this.props.collection}
          name={this.props.name}
          auctionDetails={this.props.auctionDetails}
          auctionFlag={this.props.auctionFlag}
          auctionLoading={this.props.auctionLoading}
          auctionDetailsText={this.props.auctionDetailsText}
          collectionPath={this.props.collectionPath}
          tokenPath={this.props.tokenPath}
        />
        <Actions
          bid={this.props.bid}
          bidFunction={this.props.bidFunction}
          buy={this.props.buy}
          buyFunction={this.props.buyFunction}
          sendFunction={this.props.sendFunction}
          sellFunction={this.props.sellFunction}
          unlistFunction={this.props.unlistFunction}
          viewFunction={this.props.viewFunction}
          bidStyle={this.props.bidStyle}
          buyStyle={this.props.buyStyle}
          sellStyle={this.props.sellStyle}
          sendStyle={this.props.sendStyle}
          sold={this.props.sold}
          soldFlag={this.props.soldFlag}
          auctionFlag={this.props.auctionFlag}
          auctionText={this.props.auctionText}
          currency={this.props.currency}
          listAuctionFunction={this.props.listAuctionFunction}
          featureAuctionFunction={this.props.featureAuctionFunction}
          unlistAuctionFunction={this.props.unlistAuctionFunction}
          endAuctionFunction={this.props.endAuctionFunction}
          viewAuctionPath={this.props.viewAuctionPath}
          auctionCount={this.props.auctionCount}
          collectionPath={this.props.collectionPath}
          featureAuctionText={this.props.featureAuctionText}
        />
      </FrontCard>
    );
  }
}

export default Front;
