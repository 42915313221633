import React, { Component } from 'react';
import styles from './index.module.scss';

import Button from '../../../Button';

import { darkmodeListener } from '../../../../theme';

import { renderPrice } from '../../../../utils';
import { ManageAuctionButton, ViewAuctionButton } from '../../../buttons';
import { Link } from 'react-router-dom';
import { TextLink, Underline } from '../../../common';

type ActionsProps = {
  bid?: number;
  buy?: number;
  sold?: number;
  soldFlag?: boolean;
  auctionFlag?: boolean;
  auctionText?: string;
  currency: 'STX' | 'MIA' | 'BTC';
  bidFunction?: (...args: any[]) => void;
  buyFunction?: (...args: any[]) => void;
  sendFunction?: (...args: any[]) => void;
  sellFunction?: (...args: any[]) => void;
  unlistFunction?: (...args: any[]) => void;
  viewFunction?: (...args: any[]) => void;
  bidStyle: 'PRIMARY' | 'SECONDARY' | 'ERROR';
  buyStyle: 'PRIMARY' | 'SECONDARY' | 'ERROR';
  sellStyle: 'PRIMARY' | 'SECONDARY' | 'ERROR';
  sendStyle: 'PRIMARY' | 'SECONDARY' | 'ERROR';
  viewStyle: 'PRIMARY' | 'SECONDARY' | 'ERROR';
  listAuctionFunction?: (...args: any[]) => void;
  unlistAuctionFunction?: (...args: any[]) => void;
  endAuctionFunction?: (...args: any[]) => void;
  featureAuctionFunction?: (...args: any[]) => void;
  featureAuctionText?: string;
  viewAuctionPath?: string;
  auctionCount?: number;
  collectionPath?: string;
};

type ActionsState = {};

class Actions extends Component<ActionsProps, ActionsState> {
  static defaultProps = {
    bidStyle: 'SECONDARY',
    buyStyle: 'PRIMARY',
    sellStyle: 'SECONDARY',
    sendStyle: 'SECONDARY',
    currency: 'STX',
    viewStyle: 'SECONDARY',
  };

  componentDidMount() {
    darkmodeListener(styles['theme-light'], styles['theme-dark']);
  }

  onBuy() {
    if (this.props.buyFunction) {
      this.props.buyFunction();
    }
  }

  onBid() {
    if (this.props.bidFunction) {
      this.props.bidFunction();
    }
  }

  onSend() {
    if (this.props.sendFunction) {
      this.props.sendFunction();
    }
  }

  onSell() {
    if (this.props.sellFunction) {
      this.props.sellFunction();
    }
  }

  onUnlist() {
    if (this.props.unlistFunction) {
      this.props.unlistFunction();
    }
  }

  onUnlistAuction() {
    if (this.props.unlistAuctionFunction) {
      this.props.unlistAuctionFunction();
    }
  }

  onListAuction() {
    if (this.props.listAuctionFunction) {
      this.props.listAuctionFunction();
    }
  }

  onEndAuction() {
    if (this.props.endAuctionFunction) {
      this.props.endAuctionFunction();
    }
  }

  onView() {
    if (this.props.viewFunction) {
      this.props.viewFunction();
    }
  }

  onFeatureAuction() {
    if (this.props.featureAuctionFunction) {
      this.props.featureAuctionFunction();
    }
  }

  amount() {
    if (this.props.sold) {
      if (this.props.currency == 'STX' || this.props.currency == 'BTC') {
        return this.props.sold;
      } else {
        return this.props.sold * 1e6;
      }
    }
  }

  render() {
    const sold = this.amount();
    return (
      <div className={styles.Actions}>
        {this.props.sendFunction && (
          <Button text={'SEND'} style={this.props.sendStyle} onClick={this.onSend.bind(this)} />
        )}
        {this.props.sendFunction && this.props.sellFunction && (
          <div className={styles.Spacer}></div>
        )}
        {this.props.sellFunction && (
          <Button
            text={'LIST AUCTION'}
            style={this.props.sellStyle}
            onClick={this.onSell.bind(this)}
          />
        )}
        {this.props.unlistFunction && (
          <Button
            text={'UNLIST ' + '[' + renderPrice(this.props.buy ?? 0) + ' STX]'}
            style={this.props.sellStyle}
            onClick={this.onUnlist.bind(this)}
          />
        )}
        {this.props.viewAuctionPath && (
          <Link to={this.props.viewAuctionPath} target="_blank" rel="noopener noreferrer">
            <ViewAuctionButton text="View" />
          </Link>
        )}
        {this.props.auctionCount && this.props.auctionCount > 1 && this.props.collectionPath && (
          <TextLink
            css={{ marginTop: '6px' }}
            href={this.props.collectionPath}
            target="_blank"
            rel="noopener noreferrer"
          >
            {/* <Underline css={{ marginTop: '6px' }}> */}
            {/* <Link to={this.props.collectionPath} target="_blank" rel="noopener noreferrer"> */}
            View {this.props.auctionCount} auctions
            {/* </Link> */}
            {/* </Underline> */}
          </TextLink>
        )}
        {this.props.listAuctionFunction && (
          <ManageAuctionButton text="List" onClick={this.onListAuction.bind(this)} />
        )}
        {this.props.featureAuctionFunction && (
          <ManageAuctionButton text={this.props.featureAuctionText || "Feature"} onClick={this.onFeatureAuction.bind(this)} />
        )}
        {this.props.unlistAuctionFunction && (
          <ManageAuctionButton text="Unlist" onClick={this.onUnlistAuction.bind(this)} />
        )}
        {this.props.endAuctionFunction && (
          <ManageAuctionButton text="End" onClick={this.onEndAuction.bind(this)} />
        )}
        {this.props.bid && (
          <Button
            text={'BID'}
            price={this.props.bid}
            style={this.props.bidStyle}
            onClick={this.onBid.bind(this)}
          />
        )}
        {this.props.bidFunction && (
          <Button
            text={'PLACE BID'}
            // price={this.props.bid}
            style={this.props.bidStyle}
            onClick={this.onBid.bind(this)}
          />
        )}
        {this.props.viewFunction && (
          <Button
            text={'VIEW AUCTION'}
            // price={this.props.bid}
            style={this.props.viewStyle}
            onClick={this.onView.bind(this)}
          />
        )}
        {this.props.buy && this.props.bid && <div className={styles.Spacer}></div>}
        {this.props.buy && this.props.buyFunction && (
          <Button
            text={'BUY'}
            currency={this.props.currency}
            price={this.props.buy}
            style={this.props.buyStyle}
            onClick={this.onBuy.bind(this)}
          />
        )}
        {(this.props.sold != undefined || this.props.soldFlag != undefined) && (
          <div className={styles.SoldBadge}>SOLD</div>
        )}
        {this.props.auctionText != undefined &&
          (this.props.auctionText === 'LIVE' ||
            this.props.auctionText.includes('LIVE AUCTIONS')) && (
            <div className={styles.ListedBadge}>{this.props.auctionText}</div>
          )}
        {this.props.auctionText != undefined && this.props.auctionText === 'PENDING' && (
          <div className={styles.PendingBadge}>{this.props.auctionText}</div>
        )}
        {sold != undefined && (
          <div className={styles.PriceContainer}>
            <div className={styles.Price}>
              <div className={styles.SoldFor}>Sold for</div>
              {this.props.currency == 'STX' && (
                <div className={styles.PriceSymbol}>
                  <svg
                    width={'21px'}
                    height={'21px'}
                    viewBox={'0 0 21 21'}
                    version={'1.1'}
                    xmlns={'http://www.w3.org/2000/svg'}
                  >
                    <g strokeWidth={'1'} fill={'none'} fillRule={'evenodd'}>
                      <g transform={'translate(-398.000000, -268.000000)'}>
                        <g transform={'translate(399.000000, 269.000000)'}>
                          <g>
                            <line x1={'5'} y1={'7.5'} x2={'17'} y2={'7.5'}></line>
                            <line
                              x1={'-1.2490009e-16'}
                              y1={'7'}
                              x2={'8'}
                              y2={'7'}
                              strokeWidth="2"
                            ></line>
                            <line
                              x1={'2'}
                              y1={'7'}
                              x2={'8'}
                              y2={'7'}
                              strokeWidth={'2'}
                              strokeLinecap={'round'}
                            ></line>
                            <line
                              x1={'11'}
                              y1={'7'}
                              x2={'17'}
                              y2={'7'}
                              strokeWidth={'2'}
                              strokeLinecap={'round'}
                            ></line>
                            <line x1={'11'} y1={'7'} x2={'19'} y2={'7'} strokeWidth={'2'}></line>
                            <line
                              x1={'3'}
                              y1={'0'}
                              x2={'8'}
                              y2={'7'}
                              strokeWidth={'2'}
                              strokeLinejoin={'round'}
                            ></line>
                            <line
                              x1={'11'}
                              y1={'0'}
                              x2={'16'}
                              y2={'7'}
                              strokeWidth={'2'}
                              strokeLinejoin={'round'}
                              transform={
                                'translate(13.500000, 3.500000) scale(-1, 1) translate(-13.500000, -3.500000) '
                              }
                            ></line>
                          </g>
                          <g
                            transform={
                              'translate(9.500000, 14.500000) scale(-1, 1) rotate(-180.000000) translate(-9.500000, -14.500000) translate(0.000000, 10.500000)'
                            }
                          >
                            <line x1={'5'} y1={'7.5'} x2={'17'} y2={'7.5'}></line>
                            <line
                              x1={'-1.2490009e-16'}
                              y1={'7'}
                              x2={'8'}
                              y2={'7'}
                              strokeWidth="2"
                            ></line>
                            <line
                              x1={'2'}
                              y1={'7'}
                              x2={'8'}
                              y2={'7'}
                              strokeWidth={'2'}
                              strokeLinecap={'round'}
                            ></line>
                            <line
                              x1={'11'}
                              y1={'7'}
                              x2={'17'}
                              y2={'7'}
                              strokeWidth={'2'}
                              strokeLinecap={'round'}
                            ></line>
                            <line x1={'11'} y1={'7'} x2={'19'} y2={'7'} strokeWidth={'2'}></line>
                            <line
                              x1={'3'}
                              y1={'0'}
                              x2={'8'}
                              y2={'7'}
                              strokeWidth={'2'}
                              strokeLinejoin={'round'}
                            ></line>
                            <line
                              x1={'11'}
                              y1={'0'}
                              x2={'16'}
                              y2={'7'}
                              strokeWidth={'2'}
                              strokeLinejoin={'round'}
                              transform={
                                'translate(13.500000, 3.500000) scale(-1, 1) translate(-13.500000, -3.500000) '
                              }
                            ></line>
                          </g>
                        </g>
                      </g>
                    </g>
                  </svg>
                </div>
              )}
              <div className={styles.PriceValue}>{renderPrice(sold)}</div>
              {this.props.currency == 'MIA' && <div className={styles.PriceText}>MIA</div>}
              {this.props.currency == 'BTC' && <div className={styles.PriceText}>BTC</div>}
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default Actions;
