import { styled } from '@stitches/react';
import { useAtom } from 'jotai';
import {
  airdropAtom,
  artistAddressAtom,
  assetsAtom,
  categoryAtom,
  claimOptionsAtom,
  collectionNameAtom,
  currentUserAddressAtom,
  descriptionAtom,
  discordAtom,
  editionAtom,
  emailAddressAtom,
  fungibleSelectedAtom,
  logoAtom,
  mintingEnabledAtom,
  mintpassAtom,
  mintpassEndUnixAtom,
  mintpassStartUnixAtom,
  mintsPerAddressAtom,
  numberOfEditionsAtom,
  paymentOptionsAtom,
  placeholderAtom,
  priceAtom,
  publicStartUnixAtom,
  royaltyAtom,
  twitterAtom,
  userAtom,
  visibilityEnabledAtom,
  websiteAtom,
} from '../../../../components/atoms';
import { ContinueButton } from '../../../../components/buttons';
import { EditionsReviewCard, EthCard, ReviewCard } from '../../../../components/cards';
import { CardGrid, Container, ContainerItem, Logo } from '../../../../components/common';

const DetailsLeft = styled('div', {
  width: '40%',
  display: 'flex',
  flexDirection: 'column',
  gap: '20px',
  overflowWrap: 'break-word',
});

const CollectionName = styled('div', {
  fontSize: '32px',
});

const AdditionalDetails = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',
  margin: '20px 0',
});

function Review(props: { onNextStep: any }) {
  const [assets, setAssets] = useAtom(assetsAtom);
  const [logo, setLogo] = useAtom(logoAtom);
  const [collectionName, setCollectionName] = useAtom(collectionNameAtom);
  const [description, setDescription] = useAtom(descriptionAtom);
  const [website, setWebsite] = useAtom(websiteAtom);
  const [twitter, setTwitter] = useAtom(twitterAtom);
  const [artistAddress, setArtistAddress] = useAtom(artistAddressAtom);
  const [price, setPrice] = useAtom(priceAtom);
  const [royalty, setRoyalty] = useAtom(royaltyAtom)
  const [emailAddress, setEmailAddress] = useAtom(emailAddressAtom);
  const [paymentOptions, setPaymentOptions] = useAtom(paymentOptionsAtom);
  const [claimOptions, setClaimOptions] = useAtom(claimOptionsAtom);
  const [mintingEnabled, setMintingEnabled] = useAtom(mintingEnabledAtom);
  const [mintsPerAddress, setMintsPerAddress] = useAtom(mintsPerAddressAtom);
  const [mintpass, setMintpass] = useAtom(mintpassAtom);
  const [airdrop, setAirdrop] = useAtom(airdropAtom);
  const [category, setCategory] = useAtom(categoryAtom);
  const [user, setUser] = useAtom(userAtom);
  const [currentUserAddress, setCurrentUserAddress] = useAtom(currentUserAddressAtom);
  const [edition, setEdition] = useAtom(editionAtom);
  const [discord, setDiscord] = useAtom(discordAtom);
  const [numberOfEditions, setNumberOfEditions] = useAtom(numberOfEditionsAtom);
  const [fungibleSelected, setFungibleSelected] = useAtom(fungibleSelectedAtom);
  const [mintpassStartUnix, setMintpassStartUnix] = useAtom(mintpassStartUnixAtom);
  const [mintpassEndUnix, setMintpassEndUnix] = useAtom(mintpassEndUnixAtom);
  const [publicStartUnix, setPublicStartUnix] = useAtom(publicStartUnixAtom);

  // function renderCards() {
  //   let cards = [];
  //   for (let i = 0; i < parseInt(numberOfEditions); i++) {
  //     cards.push(<EditionsReviewCard isEth={true} />);
  //   }
  //   return cards;
  // }

  function handleContinue() {
    props.onNextStep();
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  }

  function getDate(unix: number) {
    const date = new Date(unix * 1000).toLocaleDateString()
    const time = new Date(unix * 1000).toLocaleTimeString();
    return `${date} at ${time}`;
  }

  return (
    <>
      <Container>
        <h2>Review your collection</h2>
        <DetailsLeft>
          {logo.length ? <Logo src={logo[0].preview} /> : null}
          <CollectionName>{collectionName}</CollectionName>
        </DetailsLeft>
        <AdditionalDetails>
          <div>Description: {description}</div>
          <div>
            Number of editions available to mint:{' '}
            {numberOfEditions === '4294967295' || !numberOfEditions ? 'Unlimited' : numberOfEditions}
          </div>
          <div>Price per edition: {price}</div>
          <div>
            Mints per address: {mintsPerAddress === '4294967295' || !mintsPerAddress ? 'Unlimited' : mintsPerAddress}
          </div>
          <div>Total mintpasses: {mintpass.length}</div>
          <div>Resale royalty: {royalty || 5}%</div>
          <div>Email address: {emailAddress}</div>
          {artistAddress ? <div>Artist address: {artistAddress}</div> : null}
          {twitter ? <div>Twitter: {twitter}</div> : null}
          {website ? <div>Website: {website}</div> : null}
          {category ? <div>Category: {category}</div> : null}
          {mintpassStartUnix ? <div>Presale start: {getDate(mintpassStartUnix)}</div> : null}
          {mintpassEndUnix ? <div>Presale end: {getDate(mintpassEndUnix)}</div> : null}
          {publicStartUnix ? <div>Public sale start: {getDate(publicStartUnix)}</div> : null}
        </AdditionalDetails>

        <ContainerItem>
          <ContinueButton disabled={false} text="Continue" onClick={() => handleContinue()} />
        </ContainerItem>
      </Container>
      {/* <CardGrid>{renderCards()}</CardGrid> */}
    </>
  );
}

export default Review;
