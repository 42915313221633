import React, { Component } from "react";
import styles from "./index.module.scss";

import { darkmodeListener } from "../../theme";

type TextAreaInputProps = {
  placeholder: string;
  error?: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
};

type TextAreaInputState = {};

class TextAreaInput extends Component<TextAreaInputProps, TextAreaInputState> {
  componentDidMount() {
    darkmodeListener(styles["theme-light"], styles["theme-dark"]);
  }

  render() {
    if (!this.props.error) {
      return (
        <textarea
          style={{
            width: "100%",
            height: "150px",
            resize: "none",
            border: "none",
            padding: "12px",
            fontWeight: "inherit",
            fontSize: "18px",
            borderRadius: "4px",
            fontFamily: "Roboto",
            color: "gray",
            letterSpacing: "1.5px",
          }}
          placeholder={this.props.placeholder}
          name="description"
          value={this.props.value}
          onChange={this.props.onChange.bind(this)}
          className={styles.TextInput}
        ></textarea>
      );
    } else {
      return (
        <div>
          <div className={styles.TextError}>{this.props.error}</div>
          <textarea
            style={{
              width: "100%",
              height: "150px",
              resize: "none",
              border: "none",
              padding: "12px",
              fontWeight: "inherit",
              fontSize: "18px",
              borderRadius: "4px",
              fontFamily: "Roboto",
              color: "gray",
              letterSpacing: "1.5px",
            }}
            placeholder={this.props.placeholder}
            name="description"
            value={this.props.value}
            onChange={this.props.onChange.bind(this)}
            className={styles.TextInput}
          ></textarea>
        </div>
      );
    }
  }
}

export default TextAreaInput;
