import React, { useEffect } from 'react';
import { styled } from '@stitches/react';
import { blackA, mauve } from '@radix-ui/colors';
import * as ToggleGroupPrimitive from '@radix-ui/react-toggle-group';
import { getEnabledCategories } from 'trace_events';

const StyledToggleGroup = styled(ToggleGroupPrimitive.Root, {
  display: 'flex',
  gap: 10,
  flexWrap: 'wrap',
});

const StyledItem = styled(ToggleGroupPrimitive.Item, {
  all: 'unset',
  // backgroundColor: '#f5f5f7',
  backgroundColor: '$stepBackground',
  color: '$text',

  cursor: 'pointer',
  // color: mauve.mauve11,
  padding: 12,
  minWidth: 50,
  display: 'flex',
  fontSize: 15,
  lineHeight: 1,
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: 12,
  // '&:hover': { backgroundColor: blackA.blackA3 },
  '&[data-state=on]': {
    backgroundColor: '$text',
    color: '$background',
  },
});

// Exports
export const ToggleGroup = StyledToggleGroup;
export const ToggleGroupItem = StyledItem;

// Your app...
export const ToggleGroupPayment = (props: { onChange: any; value?: any }) => (
  <ToggleGroup
    defaultValue={['stx']}
    type="multiple"
    aria-label="Payment options"
    value={props.value}
    onValueChange={(value: string[]) => props.onChange(value)}
  >
    <ToggleGroupItem value="stx" aria-label="stx">
      STX
    </ToggleGroupItem>
    <ToggleGroupItem value="mia2" aria-label="mia">
      MIA
    </ToggleGroupItem>
    <ToggleGroupItem value="nyc2" aria-label="nyc">
      NYC
    </ToggleGroupItem>
    {/* <ToggleGroupItem value="mia" aria-label="mia">
      MIA
    </ToggleGroupItem>
    <ToggleGroupItem value="nyc" aria-label="nyc">
      NYC
    </ToggleGroupItem> */}
    <ToggleGroupItem value="usda" aria-label="usda">
      USDA
    </ToggleGroupItem>
    <ToggleGroupItem value="xbtc" aria-label="xbtc">
      xBTC
    </ToggleGroupItem>
    <ToggleGroupItem value="banana" aria-label="banana">
      BANANA
    </ToggleGroupItem>
    <ToggleGroupItem value="slime" aria-label="slime">
      SLIME
    </ToggleGroupItem>
    <ToggleGroupItem value="alex" aria-label="alex">
      ALEX
    </ToggleGroupItem>
    {/* <ToggleGroupItem value="mega" aria-label="mega">
      MEGA
    </ToggleGroupItem> */}
  </ToggleGroup>
);

export const ToggleGroupClaim = (props: { onChange: any; value?: any }) => (
  <ToggleGroup
    defaultValue={['1']}
    type="multiple"
    aria-label="Claim options"
    value={props.value}
    onValueChange={(value: string[]) => props.onChange(value)}
  >
    <ToggleGroupItem value="1" aria-label="1">
      1
    </ToggleGroupItem>
    <ToggleGroupItem value="2" aria-label="2">
      2
    </ToggleGroupItem>
    <ToggleGroupItem value="3" aria-label="3">
      3
    </ToggleGroupItem>
    <ToggleGroupItem value="4" aria-label="4">
      4
    </ToggleGroupItem>
    <ToggleGroupItem value="5" aria-label="5">
      5
    </ToggleGroupItem>
    <ToggleGroupItem value="6" aria-label="6">
      6
    </ToggleGroupItem>
    <ToggleGroupItem value="7" aria-label="7">
      7
    </ToggleGroupItem>
    <ToggleGroupItem value="8" aria-label="8">
      8
    </ToggleGroupItem>
    <ToggleGroupItem value="9" aria-label="9">
      9
    </ToggleGroupItem>
    <ToggleGroupItem value="10" aria-label="10">
      10
    </ToggleGroupItem>
    <ToggleGroupItem value="15" aria-label="15">
      15
    </ToggleGroupItem>
    <ToggleGroupItem value="20" aria-label="20">
      20
    </ToggleGroupItem>
    <ToggleGroupItem value="25" aria-label="25">
      25
    </ToggleGroupItem>
  </ToggleGroup>
);

function renderCategories(categories: any[]) {
  let items = [];
  for (let i = 0; i < categories.length; i++) {
    const category = categories[i];
    items.push(
      <ToggleGroupItem key={i} value={category.id} aria-label={category.name}>
        {category.title}
      </ToggleGroupItem>,
    );
  }
  return items;
}

export const ToggleGroupCategory = (props: { onChange: any; categories?: any[]; value?: any }) => (
  <ToggleGroup
    // defaultValue={""}
    type="single"
    aria-label="Category"
    value={props.value}
    onValueChange={(value: string) => props.onChange(value)}
  >
    {props.categories ? renderCategories(props.categories) : null}
  </ToggleGroup>
);

export const ToggleTokenNaming = (props: { onChange: any; value?: any }) => (
  <ToggleGroup
    // defaultValue={['1']}
    type="single"
    aria-label="Token naming options"
    value={props.value}
    onValueChange={(value: string) => props.onChange(value)}
  >
    <ToggleGroupItem value="collection" aria-label="collection">
      Collection
    </ToggleGroupItem>
    <ToggleGroupItem value="filenames" aria-label="filenames">
      Filenames
    </ToggleGroupItem>
    <ToggleGroupItem value="custom" aria-label="custom">
      Custom
    </ToggleGroupItem>
  </ToggleGroup>
);

export const ToggleAuctionTypes = (props: { onChange: any; value?: any }) => (
  <ToggleGroup
    type="single"
    aria-label="View auction types"
    value={props.value}
    onValueChange={(value: string) => props.onChange(value)}
  >
    <ToggleGroupItem value="inProgress" aria-label="inProgress">
      In progress
    </ToggleGroupItem>
    <ToggleGroupItem value="reserveNotMet" aria-label="reserveNotMet">
      Reserve not met
    </ToggleGroupItem>
    <ToggleGroupItem value="recentlySold" aria-label="recentlySold">
      Recently sold
    </ToggleGroupItem>
  </ToggleGroup>
);

export const ToggleLiveAuctions = (props: { onChange: any; value?: any }) => (
  <ToggleGroup
    type="single"
    aria-label="Sort live auctions"
    value={props.value}
    onValueChange={(value: string) => props.onChange(value)}
  >
    <ToggleGroupItem value="blocksRemaining" aria-label="blocksRemaining">
      Blocks remaining
    </ToggleGroupItem>
    <ToggleGroupItem value="highestBid" aria-label="highestBid">
      Highest bid
    </ToggleGroupItem>
    <ToggleGroupItem value="lowestBid" aria-label="lowestBid">
      Lowest bid
    </ToggleGroupItem>
  </ToggleGroup>
);

export const ToggleReserveNotMetAuctions = (props: { onChange: any; value?: any }) => (
  <ToggleGroup
    type="single"
    aria-label="Sort reserve not met auctions"
    value={props.value}
    onValueChange={(value: string) => props.onChange(value)}
  >
    <ToggleGroupItem value="mostRecentlyListed" aria-label="mostRecentlyListed">
      Most recently listed
    </ToggleGroupItem>
    <ToggleGroupItem value="highestReservePrice" aria-label="highestReservePrice">
      Highest reserve price
    </ToggleGroupItem>
    <ToggleGroupItem value="lowestReservePrice" aria-label="lowestReservePrice">
      Lowest reserve price
    </ToggleGroupItem>
  </ToggleGroup>
);

export const ToggleGroupMintAmounts = (props: { onChange: any; total: number; value?: any }) => {
  function renderOptions() {
    let options = [];
    for (let i = 0; i < props.total; i++) {
      options.push(
        <ToggleGroupItem css={{ borderRadius: 9999 }} value={`${i + 1}`} aria-label={`${i + 1}`}>
          {i + 1}
        </ToggleGroupItem>,
      );
    }
    return options;
  }
  return (
    <ToggleGroup
      defaultValue="1"
      type="single"
      aria-label="Mint amounts"
      value={props.value}
      onValueChange={(value: string) => props.onChange(value)}
    >
      {renderOptions()}
    </ToggleGroup>
  );
};

export const ToggleBlockchain = (props: { onChange: any; value?: any }) => (
  <ToggleGroup
    defaultValue="stacks"
    type="single"
    aria-label="Sort reserve not met auctions"
    value={props.value}
    onValueChange={(value: string) => props.onChange(value)}
  >
    <ToggleGroupItem
      css={{ width: 100, height: 30, fontSize: 20 }}
      value="stacks"
      aria-label="stacks"
    >
      Stacks
    </ToggleGroupItem>
    <ToggleGroupItem
      css={{ width: 100, height: 30, fontSize: 20 }}
      value="ethereum"
      aria-label="ethereum"
    >
      Ethereum
    </ToggleGroupItem>
  </ToggleGroup>
);
