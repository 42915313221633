import { hexlify, parseEther } from 'ethers/lib/utils';
import Renderer from './components/Renderer';
import {
  isValidAirdrop,
  isValidAttributes,
  isValidAssets,
  isValidMintpass,
  isValidPlaceholder,
  isValidMintpassEth,
  isValidEthAddress,
  isValidEthAddresses,
} from './utils/SelfServe/validation';
import { BigNumber, ethers } from 'ethers';

const convertIPFS = (url: string) => {
  if (url && url.startsWith('ipfs://ipfs/')) {
    const uri = url.slice(7);
    const cloudflareUrl = 'https://stxnft.mypinata.cloud/' + uri;
    return cloudflareUrl;
  } else if (url && url.startsWith('ipfs://')) {
    const uri = url.slice(7);
    const cloudflareUrl = 'https://stxnft.mypinata.cloud/ipfs/' + uri;
    return cloudflareUrl;
  } else if (url && url.startsWith('ar://')) {
    const uri = url.slice(5);
    const arweaveUrl = 'https://arweave.net/' + uri;
    return arweaveUrl;
  } else {
    url = url.replace('cloudflare-ipfs.com', 'stxnft.mypinata.cloud');
    url = url.replace('ipfs.io', 'stxnft.mypinata.cloud');
    return url;
  }
};

const renderer = (nft: any, imgClass?: string, size?: number) => {
  if (nft.video && nft.video.src && nft.video.type && (nft.image || nft.imageUrl)) {
    return (
      <Renderer
        size={size}
        video={nft.video.src}
        src={nft.image || nft.imageUrl}
        type={nft.video.type}
      />
    );
  } else if (nft.video) {
    return <Renderer size={size} video={nft.video} src={nft.image || nft.imageUrl} />;
  } else if (nft.audio) {
    return (
      <Renderer
        text={nft.title ?? nft.name}
        audio={nft.audio}
        poster={nft.image || nft.bannerImage || nft.coverImage}
        size={size}
      />
    );
  } else if (nft.image || nft.imageUrl) {
    let imgClass2 =
      nft.collection &&
      (nft.collection.indexOf('Punks') !== -1 || nft.collection.indexOf('Bitgear') !== -1)
        ? 'pixelated'
        : '';
    if (imgClass) {
      return (
        <Renderer
          size={size}
          src={nft.image || nft.imageUrl}
          imageClasses={[imgClass, imgClass2].join(' ')}
        />
      );
    } else {
      return <Renderer size={size} src={nft.image || nft.imageUrl} imageClasses={imgClass2} />;
    }
  } else {
    return <Renderer size={size} text={nft.title ?? nft.name} />;
  }
};

const renderPrice = (price: number, symbol?: string) => {
  if (symbol === 'MEGA') {
    return price.toLocaleString('us');
  } else if (symbol == 'BTC' || symbol == 'xBTC') {
    return price.toFixed(6);
  } else {
    return parseFloat((price / 1e6).toFixed(2)).toLocaleString('us');
  }
};

const localPermalink = (x: any) => {
  window.location.href = '/nft/' + x.contractAddress + '.' + x.contractName + ':' + x.tokenID;
};

const parseCsv = async (file: any, type: string) => {
  return new Promise<any>((resolve, reject) => {
    const reader = new FileReader();
    const blob = new Blob([file], { type: 'text/csv' });
    reader.readAsText(blob);
    reader.onload = function () {
      if (reader.result == null) {
        return undefined;
      }

      let text: any = reader.result;
      text = text.trim();
      let rows;
      let arr;

      switch (type) {
        case 'airdrop':
          rows = text.split('\n');
          arr = rows.map(function (row: any) {
            return row.trim();
          });
          resolve(arr);
          break;

        case 'mintpass':
          rows = text.split('\n');
          arr = rows.map(function (row: any) {
            let values = row.split(',');
            values[0] = values[0].trim().toString();
            values[1] = parseInt(values[1]);
            return values;
          });
          resolve(arr);
          break;

        case 'mintpassEth':
          rows = text.split('\n');
          arr = rows.map(function (row: any) {
            let values = row.split(',');
            // return {
            //   minter: values[0].trim(),
            //   maxCount: parseInt(values[1]),
            //   price: parseInt(parseEther(values[2].toString()).toString()),
            // };
            return values;
          });
          resolve(arr);
          break;

        case 'attributes':
          rows = text.split('\n');
          arr = rows.map(function (row: any) {
            let values = row.split(',');
            for (let i = 0; i < values.length; i++) {
              let value = values[i].trim();
              values[i] = value;
            }
            return values;
          });
          resolve(arr);
          break;

        default:
          break;
      }
    };
  });
};

const parseJson = async (file: any) => {
  const blob = new Blob([file], { type: 'application/json' });
  const text = await blob.text();
  const json = JSON.parse(text);

  // sort json by edition or token_id
  let sortBy = 'token_id';
  if (!json[0][sortBy]) {
    sortBy = 'edition';
  }
  json.sort((a: any, b: any) => a[sortBy] - b[sortBy]);

  const attributes = json.map(function (element: any) {
    return element.attributes;
  });

  // generate header row
  const header: any[] = [];
  for (let i = 0; i < attributes.length; i++) {
    const attribute = attributes[i];
    for (let j = 0; j < attribute.length; j++) {
      const trait = attribute[j];
      if (!header.includes(trait.trait_type)) {
        header.push(trait.trait_type);
      }
    }
  }

  // build each row
  const rows = [];
  rows.push(header);
  for (let i = 0; i < attributes.length; i++) {
    const attribute = attributes[i];
    const row = Array(header.length).fill('');
    for (let j = 0; j < attribute.length; j++) {
      const trait = attribute[j];
      const traitType = trait.trait_type;
      const value = trait.value;
      const index = header.indexOf(traitType);
      row[index] = value;
    }
    rows.push(row);
  }

  return rows;
};

const handleFileInput = async (file: any, type: string) => {
  let data;
  let validation: { isValid: boolean; error: string } = {
    isValid: false,
    error: '',
  };

  switch (type) {
    case 'mintpassEth':
      data = await parseCsv(file, 'mintpassEth');
      validation = isValidMintpassEth(data);
      break;

    case 'mintpass':
      data = await parseCsv(file, 'mintpass');
      validation = isValidMintpass(data);
      break;

    case 'ethAddresses':
      data = await parseCsv(file, 'airdrop');
      validation = isValidEthAddresses(data);
      break;

    case 'airdrop':
      data = await parseCsv(file, 'airdrop');
      validation = isValidAirdrop(data);
      break;

    case 'attributes':
      if (file.type === 'application/json') {
        data = await parseJson(file);
      } else {
        data = await parseCsv(file, 'attributes');
      }
      validation = isValidAttributes(data);
      let records = [];
      const headers = data[0];
      for (let i = 1; i < data.length; i++) {
        const token = data[i];
        let recordRow = [];
        for (let j = 0; j < token.length; j++) {
          const attribute = token[j];
          let record = {} as Record<string, any>;
          record['trait'] = headers[j];
          record['value'] = attribute;
          // record["percentage"] = 0;
          recordRow.push(record);
        }
        records.push(recordRow);
      }
      data = records;
      break;

    case 'placeholder':
      data = file;
      validation = isValidPlaceholder(data);
      break;

    case 'coverImage':
      data = file;
      validation = isValidPlaceholder(data);
      break;

    default:
      break;
  }

  const stateObject: any = {};
  stateObject[type] = validation.isValid ? data : null;
  stateObject[type + 'Error'] = validation.error;

  if (type == 'placeholder') {
    const placeholderImage = URL.createObjectURL(file);
    stateObject['placeholderPreviewImage'] = placeholderImage;
  } else if (type == 'coverImage') {
    const audioImage = URL.createObjectURL(file);
    stateObject['coverImagePreview'] = audioImage;
  } else {
    stateObject[type + 'Size'] = validation.isValid ? data.length : '';
  }

  return stateObject;
};

const handleAssets = (files: any, address: string, exceptions: any) => {
  const assetsCheck = isValidAssets(files, address, exceptions);

  const stateObject: any = {};
  if (!assetsCheck.isValid) {
    stateObject['assetFilesError'] = assetsCheck.error;
    return stateObject;
  }

  let assets = [];
  if (files && files.length > 0) {
    let filenames = [];
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      assets.push(URL.createObjectURL(files[i]));
      filenames.push(file.name.split('.')[0]);
    }

    stateObject['assetFiles'] = files;
    stateObject['previewAssets'] = assets;
    stateObject['previewFileName'] = files[0].name;
    stateObject['previewIndex'] = 0;
    stateObject['collectionSize'] = files.length.toString();
    stateObject['filenames'] = filenames;
    stateObject['assetFilesError'] = undefined;
    return stateObject;
  }
};

const encodeFilename = (uri: string) => {
  return encodeURIComponent(uri).replace(/#/g, '%23');
};

const isVideo = (type: string) => {
  return type.indexOf('video') > -1;
};

const isImage = (type: string) => {
  return type.indexOf('image') > -1;
};

const isAudio = (type: string) => {
  return type.indexOf('audio') > -1;
};

const natSort = (arr: any[]) => {
  return arr
    .map((v) => {
      // split string into number/ascii substrings
      let processedName = [];
      let str = v.file.name;
      // console.log(str);

      for (let i = 0; i < str.length; i++) {
        let isNum = Number.isInteger(Number(str[i]));
        let j;
        for (j = i + 1; j < str.length; j++) {
          if (Number.isInteger(Number(str[j])) != isNum) {
            break;
          }
        }
        processedName.push(isNum ? Number(str.slice(i, j)) : str.slice(i, j));
        i = j - 1;
      }
      // console.log(processedName);
      return processedName;
    })
    .sort((a: any, b: any) => {
      let len = Math.min(a.length, b.length);
      for (let i = 0; i < len; i++) {
        if (a[i] != b[i]) {
          let isNumA = Number.isInteger(a[i]);
          let isNumB = Number.isInteger(b[i]);
          if (isNumA && isNumB) {
            return a[i] - b[i];
          } else if (isNumA) {
            return -1;
          } else if (isNumB) {
            return 1;
          } else {
            return a[i] < b[i] ? -1 : 1;
          }
        }
      }
      // in case of one string being a prefix of the other
      return a.length - b.length;
    })
    .map((v) => v.join(''));
};

async function fetchAttributes() {
  const data = [];
  let header = '';
  const rows = [];
  for (let i = 0; i < 777; i++) {
    header = '';
    const res = await fetch(
      `https://stxnft.mypinata.cloud/ipfs/QmdiB1MPrUtGCu9eexBXqh1L1wrFixvFcF4qXEJm4vDfYz/json/${
        i + 1
      }.json`,
    );
    const json = await res.json();
    const id = i + 1;
    const attributes = json.attributes;
    let row = '';
    for (let j = 0; j < attributes.length; j++) {
      const attribute = attributes[j];
      const trait = attribute.trait;
      const value = attribute.value;
      if (j === attributes.length - 1) {
        header += trait;
        row += value;
      } else {
        header += trait + ',';
        row += value + ',';
      }
    }
    rows.push(row);
  }
  data.push(header);
  for (let i = 0; i < rows.length; i++) {
    const row = rows[i];
    data.push(row);
  }
  let csv = '';
  for (let i = 0; i < data.length; i++) {
    const row = data[i];
    csv += row + '\n';
  }
  console.log(csv);
}

const dealWithEther = (price: string) => {
  if (price === '') {
    return 0;
  }
  return ethers.utils.parseEther(price);
};

const bigNumToNum = (value: BigNumber) => {
  return parseInt(value.toString());
};

export {
  convertIPFS,
  renderPrice,
  renderer,
  localPermalink,
  parseCsv,
  handleFileInput,
  handleAssets,
  encodeFilename,
  isVideo,
  isImage,
  isAudio,
  natSort,
  dealWithEther,
  bigNumToNum,
};
