import { encodeFilename, isAudio, isVideo } from '../utils';

const FormData = require('form-data');
const axios = require('axios');

export const submitPublicToIpfs = async (
  data: {
    contractName: string;
    collectionName: string;
    description: string;
    emailAddress: string;
    website: string;
    twitter: string;
    logo: any[];
    assets: any[];
    fungibleSelected: {};
    placeholder?: any[];
    attributes: any[];
    price: string;
    claimOptions: number[];
    mintpass: any[];
    airdrop: any[];
    mintingEnabled: boolean;
    mintsPerAddress: string;
    visibilityEnabled: boolean;
    currentUserAddress: string;
    logoEnlarged: boolean;
    filenamesEnabled: boolean;
  },
  progressCallback: any,
  onFinishCallback: any,
) => {
  let error = '';
  let assetsHash = '';
  let jsonHash = '';
  let placeholderHash = '';
  let placeholderJsonHash = '';
  let logoHash = '';

  progressCallback('Uploading files (could take a minute)...');

  if (data.placeholder && data.placeholder.length) {
    // upload placeholder image to ipfs
    let fd = new FormData();
    fd.append('file', data.placeholder[0].file);
    const collectionName = data.collectionName;
    const metadata = JSON.stringify({
      name: collectionName + '-placeholder',
    });
    fd.append('pinataMetadata', metadata);

    const url = 'https://api.pinata.cloud/pinning/pinFileToIPFS';
    let result;
    try {
      result = await axios.post(url, fd, {
        maxBodyLength: 'Infinity',
        headers: {
          'Content-Type': `multipart/form-data; boundary=${fd._boundary}`,
          pinata_api_key: process.env.REACT_APP_PINATA_API_KEY,
          pinata_secret_api_key: process.env.REACT_APP_PINATA_API_SECRET,
        },
      });
    } catch (error) {
      console.error('IPFS IMAGE UPLOAD - ' + error);
      progressCallback(
        'There was an issue uploading your placeholder image to IPFS. Please try again.',
      );
      return null;
    }

    placeholderHash = result.data.IpfsHash;
    console.log(`Placeholder at hash ${placeholderHash}`);
  }

  if (data.logo) {
    // upload audio image to ipfs
    let fd = new FormData();
    fd.append('file', data.logo[0].file);
    const collectionName = data.collectionName;
    const metadata = JSON.stringify({
      name: collectionName + '-logo',
    });
    fd.append('pinataMetadata', metadata);

    const url = 'https://api.pinata.cloud/pinning/pinFileToIPFS';
    let result;
    try {
      result = await axios.post(url, fd, {
        maxBodyLength: 'Infinity',
        headers: {
          'Content-Type': `multipart/form-data; boundary=${fd._boundary}`,
          pinata_api_key: process.env.REACT_APP_PINATA_API_KEY,
          pinata_secret_api_key: process.env.REACT_APP_PINATA_API_SECRET,
        },
      });
    } catch (error) {
      console.error('IPFS LOGO UPLOAD - ' + error);
      progressCallback('There was an issue uploading your logo to IPFS. Please try again.');
      return null;
    }

    logoHash = result.data.IpfsHash;
    console.log(`Logo at hash ${logoHash}`);
  }

  // image upload
  if (data.assets) {
    let fd = new FormData();

    for (let i = 0; i < data.assets.length; i++) {
      const file = data.assets[i].file;
      const path = 'images/' + file.name;
      fd.append('file', file, path);
    }

    const options = JSON.stringify({
      wrapWithDirectory: true,
    });
    fd.append('pinataOptions', options);

    const collectionName = data.collectionName;

    const metadata = JSON.stringify({
      name: collectionName + '-img',
    });
    fd.append('pinataMetadata', metadata);

    const url = 'https://api.pinata.cloud/pinning/pinFileToIPFS';

    let result;

    try {
      result = await axios.post(url, fd, {
        maxBodyLength: 'Infinity',
        headers: {
          'Content-Type': `multipart/form-data; boundary=${fd._boundary}`,
          pinata_api_key: process.env.REACT_APP_PINATA_API_KEY,
          pinata_secret_api_key: process.env.REACT_APP_PINATA_API_SECRET,
        },
      });
    } catch (error) {
      console.error('IPFS ASSETS UPLOAD - ' + error);
      progressCallback('There was an issue uploading your assets to IPFS. Please try again.');
      return null;
    }

    assetsHash = result.data.IpfsHash;
    console.log(`Files at hash ${assetsHash}`);
  }

  progressCallback('Uploading metadata...');

  // metadata upload
  if (data.assets) {
    let fd = new FormData();

    let meta = [];
    for (let i = 0; i < data.assets.length; i++) {
      const file = data.assets[i].file;

      let uri = `ipfs://ipfs/${assetsHash}/images/${encodeFilename(file.name)}`;
      const encodedUri = uri;

      let itemName;
      if (data.filenamesEnabled) {
        itemName = `${file.name.split('.')[0]}`;
      } else {
        itemName = `${data.collectionName} #${i + 1}`;
      }

      let obj: any = {
        sip: 16,
        name: itemName,
        properties: { collection: data.collectionName },
        description: data.description,
        size: data.assets.length,
        price: data.price,
        external_url: data.website,
        attributes: data.attributes != null ? data.attributes[i] : '',
        asset_type: file.type,
      };

      if (isVideo(file.type)) {
        obj['video'] = encodedUri;
      } else if (isAudio(file.type)) {
        obj['audio'] = encodedUri;
        if (data.logo) {
          obj['image'] = `ipfs://ipfs/${logoHash}`;
        }
      } else {
        obj['image'] = encodedUri;
      }

      meta.push(obj);
    }

    console.log(meta);

    for (let i = 0; i < meta.length; i++) {
      const obj = meta[i];
      const id = i + 1;
      const path = 'json/' + id + '.json';
      fd.append('file', new Blob([JSON.stringify(obj)], { type: 'application/json' }), path);
    }

    const options = JSON.stringify({
      wrapWithDirectory: true,
    });
    fd.append('pinataOptions', options);

    const collectionName = data.collectionName;

    const metadata = JSON.stringify({
      name: collectionName + '-meta',
    });
    fd.append('pinataMetadata', metadata);

    const url = 'https://api.pinata.cloud/pinning/pinFileToIPFS';

    let result;

    try {
      result = await axios.post(url, fd, {
        maxBodyLength: 'Infinity',
        headers: {
          'Content-Type': `multipart/form-data; boundary=${fd._boundary}`,
          pinata_api_key: process.env.REACT_APP_PINATA_API_KEY,
          pinata_secret_api_key: process.env.REACT_APP_PINATA_API_SECRET,
        },
      });
    } catch (error) {
      console.error('IPFS METADATA UPLOAD - ' + error);
      progressCallback('There was an issue uploading your metadata to IPFS. Please try again.');
      return null;
    }

    jsonHash = result.data.IpfsHash;
    console.log(`Json files at hash ${jsonHash}`);
  }

  // placeholder metadata upload
  if (data.assets && data.placeholder && data.placeholder.length && placeholderHash != '') {
    let fd = new FormData();

    let meta = [];
    for (let i = 0; i < data.assets.length; i++) {
      const file = data.assets[i];

      let uri = `ipfs://ipfs/${placeholderHash}`;

      let itemName;
      if (data.filenamesEnabled) {
        itemName = `${file.file.name.split('.')[0]}`;
      } else {
        itemName = `${data.collectionName} #${i + 1}`;
      }

      let obj = {
        collection: data.collectionName,
        name: itemName,
        image: uri,
        description: data.description,
        size: data.assets.length,
        price: data.price,
        external_url: data.website,
      };

      meta.push(obj);
    }

    for (let i = 0; i < meta.length; i++) {
      const obj = meta[i];
      const id = i + 1;
      const path = 'json/' + id + '.json';
      fd.append('file', new Blob([JSON.stringify(obj)], { type: 'application/json' }), path);
    }

    const options = JSON.stringify({
      wrapWithDirectory: true,
    });
    fd.append('pinataOptions', options);

    const collectionName = data.collectionName;

    const metadata = JSON.stringify({
      name: collectionName + '-meta',
    });
    fd.append('pinataMetadata', metadata);

    const url = 'https://api.pinata.cloud/pinning/pinFileToIPFS';

    let result;

    try {
      result = await axios.post(url, fd, {
        maxBodyLength: 'Infinity',
        headers: {
          'Content-Type': `multipart/form-data; boundary=${fd._boundary}`,
          pinata_api_key: process.env.REACT_APP_PINATA_API_KEY,
          pinata_secret_api_key: process.env.REACT_APP_PINATA_API_SECRET,
        },
      });
    } catch (error) {
      console.error('IPFS METADATA UPLOAD - ' + error);
      progressCallback('There was an issue uploading your metadata to IPFS. Please try again.');
      return null;
    }

    placeholderJsonHash = result.data.IpfsHash;
    console.log(`Placeholder json files at hash ${placeholderJsonHash}`);
  }

  const collectionImageIndex = Math.floor(Math.random() * data.assets.length);

  progressCallback('Assets uploaded. Deploy your contract...');

  return {
    jsonHash,
    collectionImageName: encodeFilename(data.assets[collectionImageIndex].name),
    assetsHash,
    placeholderHash,
    placeholderJsonHash,
    logoHash,
  };
};

export const submitPersonalToIpfs = async (
  data: {
    collectionName: string;
    logo: any[];
    filenamesEnabled: boolean;
    assets?: any[];
    attributes?: any[];
    tokenNames?: any[];
    tokenDescriptions?: any[];
  },
  progressCallback: any,
  onFinishCallback: any,
) => {
  let error = '';
  let logoHash = '';

  progressCallback('Uploading assets (could take a minute)...');

  if (data.logo) {
    // upload audio image to ipfs
    let fd = new FormData();
    fd.append('file', data.logo[0].file);
    const collectionName = data.collectionName;
    const metadata = JSON.stringify({
      name: collectionName + '-logo',
    });
    fd.append('pinataMetadata', metadata);

    const url = 'https://api.pinata.cloud/pinning/pinFileToIPFS';
    let result;
    try {
      result = await axios.post(url, fd, {
        maxBodyLength: 'Infinity',
        headers: {
          'Content-Type': `multipart/form-data; boundary=${fd._boundary}`,
          pinata_api_key: process.env.REACT_APP_PINATA_API_KEY,
          pinata_secret_api_key: process.env.REACT_APP_PINATA_API_SECRET,
        },
      });
    } catch (error) {
      console.error('IPFS LOGO UPLOAD - ' + error);
      progressCallback('There was an issue uploading your assets to IPFS. Please try again.');
      return null;
    }

    logoHash = result.data.IpfsHash;
    console.log(`Logo at hash ${logoHash}`);
  }

  let baseUris: string[] = [];
  if (data.assets && data.assets.length) {
    console.log(data.assets);

    let fd = new FormData();

    for (let i = 0; i < data.assets.length; i++) {
      const asset = data.assets[i].file;
      const id = i + 1;
      // const path = 'images/' + id;
      const path = 'images/' + asset.name;
      fd.append('file', asset, path);
    }

    let options = JSON.stringify({
      wrapWithDirectory: true,
    });
    fd.append('pinataOptions', options);

    let metadata = JSON.stringify({
      name: data.collectionName + '-img',
    });
    fd.append('pinataMetadata', metadata);

    let url = 'https://api.pinata.cloud/pinning/pinFileToIPFS';

    let result;

    try {
      result = await axios.post(url, fd, {
        maxBodyLength: 'Infinity',
        headers: {
          'Content-Type': `multipart/form-data; boundary=${fd._boundary}`,
          pinata_api_key: process.env.REACT_APP_PINATA_API_KEY,
          pinata_secret_api_key: process.env.REACT_APP_PINATA_API_SECRET,
        },
      });
    } catch (error) {
      console.error('IPFS ASSET UPLOAD - ' + error);
      progressCallback('There was an error uploading your assets to IPFS');
      return null;
    }

    let assetHash = result.data.IpfsHash;
    console.log(`Files at hash ${assetHash}`);

    progressCallback('Uploading metadata...');

    // metadata upload
    fd = new FormData();

    let meta = [];
    for (let i = 0; i < data.assets.length; i++) {
      const asset = data.assets[i];

      console.log(asset.file.name);
      let uri = `ipfs://ipfs/${assetHash}/images/${encodeFilename(asset.file.name)}`;
      // let uri = `ipfs://ipfs/${assetHash}/images/${i + 1}`;

      let itemName;
      if (data.tokenNames && data.tokenNames[i]) {
        itemName = data.tokenNames[i];
      } else if (data.filenamesEnabled) {
        itemName = `${asset.file.name.split('.')[0]}`;
      } else {
        itemName = `${data.collectionName} #${i + 1}`;
      }

      let obj: any = {
        sip: 16,
        name: itemName,
        properties: { collection: data.collectionName },
        asset_type: asset.file.type,
        attributes: data.attributes != null && data.attributes[i] ? data.attributes[i] : '',
        description:
          data.tokenDescriptions != null && data.tokenDescriptions[i]
            ? data.tokenDescriptions[i]
            : '',
      };

      if (isVideo(asset.file.type)) {
        obj['video'] = uri;
      } else if (isAudio(asset.file.type)) {
        obj['audio'] = uri;
      } else {
        obj['image'] = uri;
      }

      meta.push(obj);
    }

    console.log(meta);

    for (let i = 0; i < meta.length; i++) {
      const obj = meta[i];
      const id = i + 1;
      const path = 'json/' + id + '.json';
      fd.append('file', new Blob([JSON.stringify(obj)], { type: 'application/json' }), path);
    }

    options = JSON.stringify({
      wrapWithDirectory: true,
    });
    fd.append('pinataOptions', options);

    const collectionName = data.collectionName;

    metadata = JSON.stringify({
      name: collectionName + '-meta',
    });
    fd.append('pinataMetadata', metadata);

    url = 'https://api.pinata.cloud/pinning/pinFileToIPFS';

    try {
      result = await axios.post(url, fd, {
        maxBodyLength: 'Infinity',
        headers: {
          'Content-Type': `multipart/form-data; boundary=${fd._boundary}`,
          pinata_api_key: process.env.REACT_APP_PINATA_API_KEY,
          pinata_secret_api_key: process.env.REACT_APP_PINATA_API_SECRET,
        },
      });
    } catch (error) {
      console.error('IPFS METADATA UPLOAD - ' + error);
      progressCallback('There was an error uploading your metadata to IPFS');
      return null;
    }

    let jsonHash = result.data.IpfsHash;

    for (let i = 0; i < meta.length; i++) {
      const baseUri = `${jsonHash}/json/${i + 1}.json`;
      baseUris.push(baseUri);
    }

    console.log(`Base uris are ${baseUris}`);

    // progressCallback('Assets uploaded. Mint your tokens...');

    // return {
    //   baseUris,
    // };
  }

  progressCallback('Assets uploaded. Deploy your contract...');

  return {
    logoHash,
    baseUris,
  };
};

export const submitContinuousToIpfs = async (
  data: {
    collectionName: string;
    assets: any[];
    filenamesEnabled: boolean;
    lastTokenId: number;
    attributes?: any[];
    tokenNames?: any[];
    tokenDescriptions?: any[];
    customName?: string;
  },
  progressCallback: any,
  onFinishCallback: any,
) => {
  progressCallback('Uploading assets (could take a minute)...');

  console.log(data);

  let fd = new FormData();

  for (let i = 0; i < data.assets.length; i++) {
    const asset = data.assets[i].file;
    const filename = data.assets[i].file.name;
    const path = 'images/' + filename;
    fd.append('file', asset, path);
  }

  let options = JSON.stringify({
    wrapWithDirectory: true,
  });
  fd.append('pinataOptions', options);

  let metadata = JSON.stringify({
    name: data.collectionName + '-img',
  });
  fd.append('pinataMetadata', metadata);

  let url = 'https://api.pinata.cloud/pinning/pinFileToIPFS';

  let result;

  try {
    result = await axios.post(url, fd, {
      maxBodyLength: 'Infinity',
      headers: {
        'Content-Type': `multipart/form-data; boundary=${fd._boundary}`,
        pinata_api_key: process.env.REACT_APP_PINATA_API_KEY,
        pinata_secret_api_key: process.env.REACT_APP_PINATA_API_SECRET,
      },
    });
  } catch (error) {
    console.error('IPFS ASSET UPLOAD - ' + error);
    progressCallback('There was an error uploading your assets to IPFS');
    return null;
  }

  let assetHash = result.data.IpfsHash;
  console.log(`Files at hash ${assetHash}`);

  progressCallback('Uploading metadata...');

  // metadata upload
  fd = new FormData();

  let meta = [];
  for (let i = 0; i < data.assets.length; i++) {
    const asset = data.assets[i];

    let uri = `ipfs://ipfs/${assetHash}/images/${encodeFilename(asset.file.name)}`;

    let itemName;
    if (data.customName && data.assets.length === 1) {
      itemName = data.customName;
    } else if (data.tokenNames && data.tokenNames[i]) {
      itemName = data.tokenNames[i];
    } else if (data.filenamesEnabled) {
      itemName = `${asset.file.name.split('.')[0]}`;
    } else {
      itemName = `${data.collectionName} #${i + 1 + data.lastTokenId}`;
    }

    let obj: any = {
      sip: 16,
      name: itemName,
      properties: { collection: data.collectionName },
      asset_type: asset.file.type,
      attributes: data.attributes != null && data.attributes[i] ? data.attributes[i] : '',
      description:
        data.tokenDescriptions != null && data.tokenDescriptions[i]
          ? data.tokenDescriptions[i]
          : '',
    };

    if (isVideo(asset.file.type)) {
      obj['video'] = uri;
    } else if (isAudio(asset.file.type)) {
      obj['audio'] = uri;
    } else {
      obj['image'] = uri;
    }

    meta.push(obj);
  }

  console.log(meta);

  for (let i = 0; i < meta.length; i++) {
    const obj = meta[i];
    const id = i + 1;
    const path = 'json/' + id + '.json';
    fd.append('file', new Blob([JSON.stringify(obj)], { type: 'application/json' }), path);
  }

  options = JSON.stringify({
    wrapWithDirectory: true,
  });
  fd.append('pinataOptions', options);

  const collectionName = data.collectionName;

  metadata = JSON.stringify({
    name: collectionName + '-meta',
  });
  fd.append('pinataMetadata', metadata);

  url = 'https://api.pinata.cloud/pinning/pinFileToIPFS';

  try {
    result = await axios.post(url, fd, {
      maxBodyLength: 'Infinity',
      headers: {
        'Content-Type': `multipart/form-data; boundary=${fd._boundary}`,
        pinata_api_key: process.env.REACT_APP_PINATA_API_KEY,
        pinata_secret_api_key: process.env.REACT_APP_PINATA_API_SECRET,
      },
    });
  } catch (error) {
    console.error('IPFS METADATA UPLOAD - ' + error);
    progressCallback('There was an error uploading your metadata to IPFS');
    return null;
  }

  let jsonHash = result.data.IpfsHash;

  let baseUris = [];
  for (let i = 0; i < meta.length; i++) {
    const baseUri = `${jsonHash}/json/${i + 1}.json`;
    baseUris.push(baseUri);
  }

  console.log(`Base uris are ${baseUris}`);

  progressCallback('Assets uploaded. Complete the transaction...');

  return {
    baseUris,
  };
};

export const submitEditionsToIpfs = async (
  data: {
    collectionName: string;
    logo: any[];
    edition: any[];
    numberOfEditions: string;
    description: string;
    price: string;
    attributes: any[];
    editionName: string;
  },
  progressCallback: any,
  onFinishCallback: any,
) => {
  let error = '';
  let logoHash = '';

  progressCallback('Uploading assets (could take a minute)...');

  if (data.logo) {
    let fd = new FormData();
    fd.append('file', data.logo[0].file);
    const collectionName = data.collectionName;
    const metadata = JSON.stringify({
      name: collectionName + '-logo',
    });
    fd.append('pinataMetadata', metadata);

    const url = 'https://api.pinata.cloud/pinning/pinFileToIPFS';
    let result;
    try {
      result = await axios.post(url, fd, {
        maxBodyLength: 'Infinity',
        headers: {
          'Content-Type': `multipart/form-data; boundary=${fd._boundary}`,
          pinata_api_key: process.env.REACT_APP_PINATA_API_KEY,
          pinata_secret_api_key: process.env.REACT_APP_PINATA_API_SECRET,
        },
      });
    } catch (error) {
      console.error('IPFS LOGO UPLOAD - ' + error);
      progressCallback('There was an issue uploading your assets to IPFS. Please try again.');
      return null;
    }

    logoHash = result.data.IpfsHash;
    console.log(`Logo at hash ${logoHash}`);
  }

  let editionHash = '';

  if (data.edition) {
    // upload audio image to ipfs
    let fd = new FormData();
    fd.append('file', data.edition[0].file);
    const collectionName = data.collectionName;
    const metadata = JSON.stringify({
      name: collectionName + '-edition',
    });
    fd.append('pinataMetadata', metadata);

    const url = 'https://api.pinata.cloud/pinning/pinFileToIPFS';
    let result;
    try {
      result = await axios.post(url, fd, {
        maxBodyLength: 'Infinity',
        headers: {
          'Content-Type': `multipart/form-data; boundary=${fd._boundary}`,
          pinata_api_key: process.env.REACT_APP_PINATA_API_KEY,
          pinata_secret_api_key: process.env.REACT_APP_PINATA_API_SECRET,
        },
      });
    } catch (error) {
      console.error('IPFS ASSET UPLOAD - ' + error);
      progressCallback('There was an issue uploading your asset to IPFS. Please try again.');
      return null;
    }

    editionHash = result.data.IpfsHash;
    console.log(`Edition asset at hash ${editionHash}`);
  }

  progressCallback('Uploading metadata...');

  let fd = new FormData();

  const file = data.edition[0].file;

  let uri = `ipfs://ipfs/${editionHash}`;

  let itemName = `${file.name.split('.')[0]}`;
  if (data.editionName) {
    itemName = data.editionName;
  }

  let meta: any = {
    sip: 16,
    name: itemName,
    properties: { collection: data.collectionName },
    description: data.description,
    size: data.numberOfEditions,
    price: data.price,
    asset_type: file.type,
    attributes: data.attributes && data.attributes.length ? data.attributes[0] : [],
  };

  if (isVideo(file.type)) {
    meta['video'] = uri;
  } else if (isAudio(file.type)) {
    meta['audio'] = uri;
    if (data.logo) {
      meta['image'] = `ipfs://ipfs/${logoHash}`;
    }
  } else {
    meta['image'] = uri;
  }

  console.log(meta);

  fd.append('file', new Blob([JSON.stringify(meta)], { type: 'application/json' }));

  // options = JSON.stringify({
  //   wrapWithDirectory: true,
  // });
  // fd.append('pinataOptions', options);

  const collectionName = data.collectionName;

  let metadata = JSON.stringify({
    name: collectionName + '-meta',
  });
  fd.append('pinataMetadata', metadata);

  let url = 'https://api.pinata.cloud/pinning/pinFileToIPFS';

  let result;
  try {
    result = await axios.post(url, fd, {
      maxBodyLength: 'Infinity',
      headers: {
        'Content-Type': `multipart/form-data; boundary=${fd._boundary}`,
        pinata_api_key: process.env.REACT_APP_PINATA_API_KEY,
        pinata_secret_api_key: process.env.REACT_APP_PINATA_API_SECRET,
      },
    });
  } catch (error) {
    console.error('IPFS METADATA UPLOAD - ' + error);
    progressCallback('There was an error uploading your metadata to IPFS');
    return null;
  }

  let jsonHash = result.data.IpfsHash;

  progressCallback('Assets uploaded. Deploy your contract...');

  return {
    logoHash,
    editionHash,
    jsonHash,
  };
};

export const submitZoraDropToIpfs = async (
  data: {
    collectionName: string;
    description: string;
    logo: any[];
    assets: any[];
    attributes: any[];
    price: string;
    mintpass: any[];
    mintsPerAddress: string;
  },
  progressCallback: any,
  onFinishCallback: any,
) => {
  let error = '';
  let assetsHash = '';
  let jsonHash = '';
  let logoHash = '';
  let collectionHash = '';

  progressCallback('Uploading files (could take a minute)...');

  if (data.logo) {
    let fd = new FormData();
    fd.append('file', data.logo[0].file);
    let collectionName = data.collectionName;
    let metadata = JSON.stringify({
      name: collectionName + '-logo',
    });
    fd.append('pinataMetadata', metadata);

    const url = 'https://api.pinata.cloud/pinning/pinFileToIPFS';
    let result;
    try {
      result = await axios.post(url, fd, {
        maxBodyLength: 'Infinity',
        headers: {
          'Content-Type': `multipart/form-data; boundary=${fd._boundary}`,
          pinata_api_key: process.env.REACT_APP_PINATA_API_KEY,
          pinata_secret_api_key: process.env.REACT_APP_PINATA_API_SECRET,
        },
      });
    } catch (error) {
      console.error('IPFS LOGO UPLOAD - ' + error);
      progressCallback('There was an issue uploading your logo to IPFS. Please try again.');
      return null;
    }

    logoHash = result.data.IpfsHash;
    console.log(`Logo at hash ${logoHash}`);

    let obj: any = {
      name: data.collectionName,
      description: data.description,
      image: `ipfs://${logoHash}`,
      external_url: '',
    };

    fd = new FormData();
    fd.append('file', new Blob([JSON.stringify(obj)], { type: 'application/json' }));
    metadata = JSON.stringify({
      name: collectionName + '-collectionJson',
    });
    fd.append('pinataMetadata', metadata);
    try {
      result = await axios.post(url, fd, {
        maxBodyLength: 'Infinity',
        headers: {
          'Content-Type': `multipart/form-data; boundary=${fd._boundary}`,
          pinata_api_key: process.env.REACT_APP_PINATA_API_KEY,
          pinata_secret_api_key: process.env.REACT_APP_PINATA_API_SECRET,
        },
      });
    } catch (error) {
      console.error('IPFS COLLECTION JSON UPLOAD - ' + error);
      progressCallback(
        'There was an issue uploading your collection json to IPFS. Please try again.',
      );
      return null;
    }

    collectionHash = result.data.IpfsHash;
    console.log(`Collection at hash ${collectionHash}`);
  }

  if (data.assets) {
    let fd = new FormData();

    for (let i = 0; i < data.assets.length; i++) {
      const file = data.assets[i].file;
      const path = 'images/' + file.name;
      fd.append('file', file, path);
    }

    const options = JSON.stringify({
      wrapWithDirectory: true,
    });
    fd.append('pinataOptions', options);

    const collectionName = data.collectionName;

    const metadata = JSON.stringify({
      name: collectionName + '-img',
    });
    fd.append('pinataMetadata', metadata);

    const url = 'https://api.pinata.cloud/pinning/pinFileToIPFS';

    let result;

    try {
      result = await axios.post(url, fd, {
        maxBodyLength: 'Infinity',
        headers: {
          'Content-Type': `multipart/form-data; boundary=${fd._boundary}`,
          pinata_api_key: process.env.REACT_APP_PINATA_API_KEY,
          pinata_secret_api_key: process.env.REACT_APP_PINATA_API_SECRET,
        },
      });
    } catch (error) {
      console.error('IPFS ASSETS UPLOAD - ' + error);
      progressCallback('There was an issue uploading your assets to IPFS. Please try again.');
      return null;
    }

    assetsHash = result.data.IpfsHash;
    console.log(`Files at hash ${assetsHash}`);
  }

  progressCallback('Uploading metadata...');

  // metadata upload
  if (data.assets) {
    let fd = new FormData();

    let meta = [];
    for (let i = 0; i < data.assets.length; i++) {
      const file = data.assets[i].file;

      let uri = `ipfs://${assetsHash}/images/${encodeFilename(file.name)}`;
      const encodedUri = uri;

      let itemName = `${data.collectionName} #${i + 1}`;

      let obj: any = {
        name: itemName,
        properties: { collection: data.collectionName },
        description: data.description,
        attributes: data.attributes != null ? data.attributes[i] : '',
        asset_type: file.type,
        external_url: '',
      };

      if (isVideo(file.type)) {
        obj['video'] = encodedUri;
      } else if (isAudio(file.type)) {
        obj['audio'] = encodedUri;
      } else {
        obj['image'] = encodedUri;
      }

      meta.push(obj);
    }

    console.log(meta);

    for (let i = 0; i < meta.length; i++) {
      const obj = meta[i];
      const id = i + 1;
      const path = 'json/' + id;
      fd.append('file', new Blob([JSON.stringify(obj)], { type: 'application/json' }), path);
    }

    const options = JSON.stringify({
      wrapWithDirectory: true,
    });
    fd.append('pinataOptions', options);

    const collectionName = data.collectionName;

    const metadata = JSON.stringify({
      name: collectionName + '-meta',
    });
    fd.append('pinataMetadata', metadata);

    const url = 'https://api.pinata.cloud/pinning/pinFileToIPFS';

    let result;

    try {
      result = await axios.post(url, fd, {
        maxBodyLength: 'Infinity',
        headers: {
          'Content-Type': `multipart/form-data; boundary=${fd._boundary}`,
          pinata_api_key: process.env.REACT_APP_PINATA_API_KEY,
          pinata_secret_api_key: process.env.REACT_APP_PINATA_API_SECRET,
        },
      });
    } catch (error) {
      console.error('IPFS METADATA UPLOAD - ' + error);
      progressCallback('There was an issue uploading your metadata to IPFS. Please try again.');
      return null;
    }

    jsonHash = result.data.IpfsHash;
    console.log(`Json files at hash ${jsonHash}`);
  }

  const collectionImageIndex = Math.floor(Math.random() * data.assets.length);

  progressCallback('Assets uploaded. Deploy your contract...');

  return {
    jsonHash,
    assetsHash,
    logoHash,
    collectionHash,
  };
};

export const submitZoraEditionToIpfs = async (
  data: {
    collectionName: string;
    logo: any[];
    edition: any[];
    numberOfEditions: string;
    description: string;
    price: string;
    attributes: any[];
    editionName: string;
    mintpass: any[];
    mintsPerAddress: string;
  },
  progressCallback: any,
  onFinishCallback: any,
) => {
  let error = '';
  let logoHash = '';
  let editionHash = '';
  let jsonHash = '';
  let collectionHash = '';

  progressCallback('Uploading assets (could take a minute)...');

  if (data.logo) {
    let fd = new FormData();
    fd.append('file', data.logo[0].file);
    const collectionName = data.collectionName;
    let metadata = JSON.stringify({
      name: collectionName + '-logo',
    });
    fd.append('pinataMetadata', metadata);

    const url = 'https://api.pinata.cloud/pinning/pinFileToIPFS';
    let result;
    try {
      result = await axios.post(url, fd, {
        maxBodyLength: 'Infinity',
        headers: {
          'Content-Type': `multipart/form-data; boundary=${fd._boundary}`,
          pinata_api_key: process.env.REACT_APP_PINATA_API_KEY,
          pinata_secret_api_key: process.env.REACT_APP_PINATA_API_SECRET,
        },
      });
    } catch (error) {
      console.error('IPFS LOGO UPLOAD - ' + error);
      progressCallback('There was an issue uploading your assets to IPFS. Please try again.');
      return null;
    }

    logoHash = result.data.IpfsHash;
    console.log(`Logo at hash ${logoHash}`);

    let obj: any = {
      name: data.collectionName,
      description: data.description,
      image: `ipfs://${logoHash}`,
      external_url: '',
    };

    fd = new FormData();
    fd.append('file', new Blob([JSON.stringify(obj)], { type: 'application/json' }));
    metadata = JSON.stringify({
      name: collectionName + '-collectionJson',
    });
    fd.append('pinataMetadata', metadata);
    try {
      result = await axios.post(url, fd, {
        maxBodyLength: 'Infinity',
        headers: {
          'Content-Type': `multipart/form-data; boundary=${fd._boundary}`,
          pinata_api_key: process.env.REACT_APP_PINATA_API_KEY,
          pinata_secret_api_key: process.env.REACT_APP_PINATA_API_SECRET,
        },
      });
    } catch (error) {
      console.error('IPFS COLLECTION JSON UPLOAD - ' + error);
      progressCallback(
        'There was an issue uploading your collection json to IPFS. Please try again.',
      );
      return null;
    }

    collectionHash = result.data.IpfsHash;
    console.log(`Collection at hash ${collectionHash}`);
  }

  if (data.edition) {
    let fd = new FormData();
    fd.append('file', data.edition[0].file);
    const collectionName = data.collectionName;
    const metadata = JSON.stringify({
      name: collectionName + '-edition',
    });
    fd.append('pinataMetadata', metadata);

    const url = 'https://api.pinata.cloud/pinning/pinFileToIPFS';
    let result;
    try {
      result = await axios.post(url, fd, {
        maxBodyLength: 'Infinity',
        headers: {
          'Content-Type': `multipart/form-data; boundary=${fd._boundary}`,
          pinata_api_key: process.env.REACT_APP_PINATA_API_KEY,
          pinata_secret_api_key: process.env.REACT_APP_PINATA_API_SECRET,
        },
      });
    } catch (error) {
      console.error('IPFS ASSET UPLOAD - ' + error);
      progressCallback('There was an issue uploading your asset to IPFS. Please try again.');
      return null;
    }

    editionHash = result.data.IpfsHash;
    console.log(`Edition asset at hash ${editionHash}`);
  }

  progressCallback('Uploading metadata...');

  let fd = new FormData();

  const file = data.edition[0].file;

  let uri = `ipfs://${editionHash}`;

  let itemName = `${file.name.split('.')[0]}`;
  if (data.editionName) {
    itemName = data.editionName;
  }

  let meta: any = {
    name: itemName,
    properties: { collection: data.collectionName },
    description: data.description,
    asset_type: file.type,
    attributes: data.attributes != null ? data.attributes[0] : '',
  };

  if (isVideo(file.type)) {
    meta['video'] = uri;
  } else if (isAudio(file.type)) {
    meta['audio'] = uri;
    if (data.logo) {
      meta['image'] = `ipfs://${logoHash}`;
    }
  } else {
    meta['image'] = uri;
  }

  console.log(meta);

  fd.append('file', new Blob([JSON.stringify(meta)], { type: 'application/json' }));

  // options = JSON.stringify({
  //   wrapWithDirectory: true,
  // });
  // fd.append('pinataOptions', options);

  const collectionName = data.collectionName;

  let metadata = JSON.stringify({
    name: collectionName + '-meta',
  });
  fd.append('pinataMetadata', metadata);

  let url = 'https://api.pinata.cloud/pinning/pinFileToIPFS';

  let result;
  try {
    result = await axios.post(url, fd, {
      maxBodyLength: 'Infinity',
      headers: {
        'Content-Type': `multipart/form-data; boundary=${fd._boundary}`,
        pinata_api_key: process.env.REACT_APP_PINATA_API_KEY,
        pinata_secret_api_key: process.env.REACT_APP_PINATA_API_SECRET,
      },
    });
  } catch (error) {
    console.error('IPFS METADATA UPLOAD - ' + error);
    progressCallback('There was an error uploading your metadata to IPFS');
    return null;
  }

  jsonHash = result.data.IpfsHash;

  progressCallback('Assets uploaded. Deploy your contract...');

  return {
    logoHash,
    editionHash,
    jsonHash,
    collectionHash,
  };
};
