import fetch from 'cross-fetch';
import {
  Configuration,
  AccountsApi,
  SmartContractsApi,
  TransactionsApi,
  // BlocksApi,
  InfoApi,
  NonFungibleTokensApi,
} from '@stacks/blockchain-api-client';
import { uintCV, cvToHex, hexToCV, cvToJSON, contractPrincipalCV } from '@stacks/transactions';
import { basePath } from './SelfServe/contracts.ts';
import { usePassthroughCall } from '../hooks/passthrough';

export async function getTransactions(address) {
  const apiConfig = new Configuration({
    fetchApi: fetch,
    basePath: basePath,
  });

  const accountsApi = new AccountsApi(apiConfig);

  const txs = await accountsApi.getAccountTransactions({
    principal: address,
  });

  console.log(txs);
}

export async function getNftsForAuction(auctionAddress, nftAddress, nftName, total) {
  const apiConfig = new Configuration({
    fetchApi: fetch,
    basePath: basePath,
  });

  const accountsApi = new AccountsApi(apiConfig);

  let idsForAuction = [];
  for (let x = 0; x < total; x += 50) {
    const nfts = await accountsApi.getAccountNft({
      principal: auctionAddress,
      limit: 50,
      offset: x,
    });

    const nftPrincipal = nftAddress + '.' + nftName;
    if (nfts && nfts['nft_events'] && nfts['nft_events'].length > 0) {
      for (let i = 0; i < nfts['nft_events'].length; i++) {
        const nft = nfts['nft_events'][i];
        const principal = nft['asset_identifier'].split('::')[0].replace("'", '');
        const tokenId = nft['value']['repr'].replace('u', '');
        if (principal === nftPrincipal && !idsForAuction.includes(tokenId)) {
          idsForAuction.push(tokenId);
        }
      }
    }
  }

  return idsForAuction;
}

export async function getStxBalance(address) {
  const apiConfig = new Configuration({
    fetchApi: fetch,
    basePath: basePath,
  });

  const accountsApi = new AccountsApi(apiConfig);

  const res = await accountsApi.getAccountStxBalance({ principal: address });
  if (res) return res.balance;
  return null;
}

export async function getMints(assetIdentifier, total) {
  let data = [];
  for (let x = 0; x < total; x += 50) {
    const res = await fetch(
      `${basePath}/extended/v1/tokens/nft/mints?asset_identifier=${assetIdentifier}&offset=${x}&limit=50`,
    );
    const json = await res.json();
    const results = json.results;
    if (results && results.length) {
      data = data.concat(results);
    } else {
      break;
    }
  }
  const minters = data.map((mint) => {
    const recipient = mint.recipient;
    const id = mint.value.repr.replace('u', '');
    const minter = { recipient, id };
    return minter;
  });
  return minters;
}

export async function getOwners(contractId) {
  const owners = await fetch(`https://api.stacksdata.info/nft/contracts/${contractId}/owners`);
  const json = await owners.json();
  return json;
}

export async function getAuctionEvents(contractId, total) {
  const config = new Configuration({
    fetchApi: fetch,
    basePath: basePath,
  });

  const api = new SmartContractsApi(config);
  const data = [];
  for (let x = 0; x < total; x += 50) {
    const events = await api.getContractEventsById({
      contractId,
      limit: 50,
      offset: x,
    });

    console.log(events);

    // const results = events.results;
    // for (let i = 0; i < results.length; i++) {
    //   const result = results[i];
    //   let repr = result.contract_log.value.repr;
    //   const filtered = repr.split(/[()]+/).filter(function (e) {
    //     if (
    //       e.includes('amount') ||
    //       e.includes('token-id') ||
    //       e.includes('action') ||
    //       e.includes('buyer') ||
    //       e.includes('nft-asset-contract') ||
    //       e.includes('auction-id')
    //     ) {
    //       return e;
    //     } else {
    //       return null;
    //     }
    //   });

    //   let obj = {};
    //   for (let i = 0; i < filtered.length; i++) {
    //     let el = filtered[i];
    //     el = el.replaceAll('"', '');
    //     const key = el.split(' ')[0];
    //     let value = el.split(' ')[1];
    //     if (key === 'amount' || key === 'token-id' || key === 'auction-id') {
    //       value = value.replace('u', '');
    //     }
    //     obj[key] = value;
    //   }
    //   if (obj['token-id'] && obj['token-id'] === tokenId && obj['action'] === 'place-bid') {
    //     data.push(obj);
    //   }
    // }
  }

  return data;
}

export async function getAllNftsForAuction(auctionAddress, total, progressCallback) {
  try {
    const res = await fetch(
      'https://stacks-mainnet.gamma.io/nft-data-service/v1/nfts?owner=SP1CSHTKVHMMQJ7PRQRFYW6SB4QAW6SR3XY2F81PA.stxnft-auctions-v1',
    );
    const json = await res.json();
    return json.nfts;
  } catch (e) {}

  // const apiConfig = new Configuration({
  //   fetchApi: fetch,
  //   basePath: basePath,
  // });

  // const holdingsApi = new NonFungibleTokensApi(apiConfig);

  // let principalsForAuction = [];
  // let nftsForAuction = [];
  // for (let x = 0; x < total; x += 50) {
  //   const nfts = await holdingsApi.getNftHoldings({
  //     principal: auctionAddress,
  //     limit: 50,
  //     offset: x,
  //   });

  //   progressCallback(x);

  //   if (nfts && nfts['results'] && nfts['results'].length > 0) {
  //     for (let i = 0; i < nfts['results'].length; i++) {
  //       const nft = nfts['results'][i];
  //       const identifier = nft.asset_identifier.replace("'", '');
  //       const tokenId = nft['value']['repr'].replace('u', '');
  //       const principal = identifier + ':' + tokenId;
  //       if (!principalsForAuction.includes(principal)) {
  //         principalsForAuction.push(principal);
  //         nftsForAuction.push(nft);
  //       }
  //     }
  //   }
  // }

  // return nftsForAuction;
  return undefined;
}

export async function getBalance(address) {
  const apiConfig = new Configuration({
    fetchApi: fetch,
    basePath: basePath,
  });

  const accountsApi = new AccountsApi(apiConfig);

  const balance = await accountsApi.getAccountBalance({
    principal: address,
  });

  console.log(balance);
}

export async function getAssets(address) {
  const apiConfig = new Configuration({
    fetchApi: fetch,
    basePath: basePath,
  });

  const accountsApi = new AccountsApi(apiConfig);

  const assets = await accountsApi.getAccountAssets({
    principal: address,
  });

  return assets.results;
}

export async function callGetTokenUri(contractAddress, contractName, functionName, tokenId) {
  const apiConfig = new Configuration({
    fetchApi: fetch,
    basePath: basePath,
  });

  const contractApi = new SmartContractsApi(apiConfig);

  const id = uintCV(tokenId);

  const uri = await contractApi.callReadOnlyFunction({
    contractAddress: contractAddress,
    contractName: contractName,
    functionName: functionName,
    readOnlyFunctionArgs: {
      sender: contractAddress,
      arguments: [cvToHex(id)],
    },
  });

  return cvToJSON(hexToCV(uri.result)).value.value;
}

export async function getLastTokenId(address, name) {
  const config = new Configuration({
    fetchApi: fetch,
    basePath: basePath,
  });

  const api = new SmartContractsApi(config);

  const uri = await api.callReadOnlyFunction({
    contractAddress: address,
    contractName: name,
    functionName: 'get-last-token-id',
    readOnlyFunctionArgs: {
      sender: address,
      arguments: [],
    },
  });

  return cvToJSON(hexToCV(uri.result)).value.value;
}

// export async function getOwner(address, name, id) {
//   const config = new Configuration({
//     fetchApi: fetch,
//     basePath: basePath,
//   });

//   const api = new SmartContractsApi(config);

//   const uri = await api.callReadOnlyFunction({
//     contractAddress: address,
//     contractName: name,
//     functionName: "get-owner",
//     readOnlyFunctionArgs: {
//       sender: address,
//       arguments: [uintCV(id)],
//     },
//   });

//   return cvToJSON(hexToCV(uri.result)).value.value;
// }

export async function getContractEvents(contractAddress, contractName, tokenId, total) {
  const config = new Configuration({
    fetchApi: fetch,
    basePath: basePath,
  });

  const api = new SmartContractsApi(config);
  const data = [];
  for (let x = 0; x < total; x += 50) {
    const events = await api.getContractEventsById({
      contractId: `${contractAddress}.${contractName}`,
      limit: 50,
      offset: x,
    });
    const results = events.results;
    for (let i = 0; i < results.length; i++) {
      const result = results[i];
      let repr = result.contract_log.value.repr;
      const filtered = repr.split(/[()]+/).filter(function (e) {
        if (
          e.includes('amount') ||
          e.includes('token-id') ||
          e.includes('action') ||
          e.includes('buyer') ||
          e.includes('nft-asset-contract') ||
          e.includes('auction-id')
        ) {
          return e;
        } else {
          return null;
        }
      });

      let obj = {};
      for (let i = 0; i < filtered.length; i++) {
        let el = filtered[i];
        el = el.replaceAll('"', '');
        const key = el.split(' ')[0];
        let value = el.split(' ')[1];
        if (key === 'amount' || key === 'token-id' || key === 'auction-id') {
          value = value.replace('u', '');
        }
        obj[key] = value;
      }
      obj['tx-id'] = result.tx_id;
      if (obj['token-id'] && obj['token-id'] === tokenId && obj['action'] === 'place-bid') {
        data.push(obj);
      }
    }
  }

  return data;
}

export async function getAllContractEvents(contractAddress, contractName, total) {
  const config = new Configuration({
    fetchApi: fetch,
    basePath: basePath,
  });

  const api = new SmartContractsApi(config);
  const data = [];
  for (let x = 0; x < total; x += 50) {
    const events = await api.getContractEventsById({
      contractId: `${contractAddress}.${contractName}`,
      limit: 50,
      offset: x,
    });
    const results = events.results;
    // console.log(results)
    for (let i = 0; i < results.length; i++) {
      const result = results[i];
      let repr = result.contract_log.value.repr;
      const filtered = repr.split(/[()]+/).filter(function (e) {
        if (
          e.includes('amount') ||
          e.includes('token-id') ||
          e.includes('action') ||
          e.includes('buyer') ||
          e.includes('nft-asset-contract') ||
          e.includes('auction-id')
        ) {
          return e;
        } else {
          return null;
        }
      });

      let obj = {};
      for (let i = 0; i < filtered.length; i++) {
        let el = filtered[i];
        el = el.replaceAll('"', '');
        const key = el.split(' ')[0];
        let value = el.split(' ')[1];
        if (key === 'amount' || key === 'token-id' || key === 'auction-id') {
          value = value.replace('u', '');
        }
        obj[key] = value;
      }
      // if (obj['token-id'] && obj['token-id'] === tokenId && obj['action'] === 'place-bid') {
      //   data.push(obj);
      // }
      data.push(obj);
    }
  }

  return data;
}

export async function checkMempool(contractAddress, contractName, total) {
  const config = new Configuration({
    fetchApi: fetch,
    basePath: basePath,
  });

  const api = new TransactionsApi(config);
  const mempool = [];
  for (let i = 0; i < total; i += 50) {
    const transactions = await api.getMempoolTransactionList({
      limit: 50,
      offset: i,
      unanchored: true,
    });
    const results = transactions.results;
    const contractTxs = results.filter(function (el) {
      if (el.contract_call) {
        return el.contract_call.contract_id === `${contractAddress}.${contractName}`;
      } else {
        return null;
      }
    });

    for (let i = 0; i < contractTxs.length; i++) {
      const tx = contractTxs[i];
      mempool.push(tx);
    }
  }
  return mempool;
}

export async function getBlockHeight() {
  const config = new Configuration({
    fetchApi: fetch,
    basePath: basePath,
  });

  const api = new InfoApi(config);

  const info = await api.getCoreApiInfo();

  return info.stacks_tip_height;
}

export async function getBns(address) {
  // const res = await fetch(
  //   `https://legacy.stxnft.com/api/bns?address=${address}`
  // );

  const res = await fetch(
    `https://stacks-node-api.mainnet.stacks.co/v1/addresses/stacks/${address}`,
  );

  if (res._bodyBlob.size > 0 && res._bodyBlob.type === 'application/json') {
    const json = await res.json();
    if (json && json.names && json.names.length > 0) {
      return json.names[0];
    } else {
      return undefined;
    }
  } else {
    return undefined;
  }

  // if (res._bodyBlob.size > 0 && res._bodyBlob.type === "application/json") {
  //   const json = await res.json();
  //   console.log(json);
  //   if (json.name && json.namespace) {
  //     return json.name + "." + json.namespace;
  //   } else {
  //     return undefined;
  //   }
  // } else {
  //   return undefined;
  // }

  // const res = await resolvePrincipal(address);
  // if (res.name && res.namespace) {
  //   return res.name + "." + res.namespace;
  // } else {
  //   return undefined;
  // }
}

export async function getAuctionRoyalty(address, name) {
  const config = new Configuration({
    fetchApi: fetch,
    basePath: basePath,
  });

  const api = new SmartContractsApi(config);

  const principal = contractPrincipalCV(address, name);

  const uri = await api.callReadOnlyFunction({
    contractAddress: 'SP1CSHTKVHMMQJ7PRQRFYW6SB4QAW6SR3XY2F81PA',
    contractName: 'stxnft-auctions-v1',
    functionName: 'get-royalty',
    readOnlyFunctionArgs: {
      sender: address,
      arguments: [cvToHex(principal)],
    },
  });

  return cvToJSON(hexToCV(uri.result)).value['royalty-percent'].value;
}
