import { useAtom } from 'jotai';
import { useState } from 'react';
import { handleFileInput } from '../../../../utils';
import { ContinueButton } from '../../../../components/buttons';
import {
  AssetsDropzone,
  CsvDropzone,
  EditionsDropzone,
  LogoDropzone,
} from '../../../../components/dropzone';
import { RadioGroupWithNote } from '../../../../components/radio-group';
import {
  assetsAtom,
  attributesAtom,
  editionAtom,
  editionNameAtom,
  filenamesEnabledAtom,
  logoAtom,
  logoEnlargedAtom,
} from '../../../../components/atoms';
import { Container, ContainerItem } from '../../../../components/common';
import { SwitchEnlargedImage, SwitchFilenames } from '../../../../components/switch';
import { LabelInput } from '../../../../components/label';
import { isValidEditionName, isValidName } from '../../../../utils/SelfServe/validation';

function Media(props: { onNextStep: any }) {
  const [logo, setLogo] = useAtom(logoAtom);
  const [assets, setAssets] = useAtom(assetsAtom);
  const [attributes, setAttributes] = useAtom(attributesAtom);
  const [filenamesEnabled, setFilenamesEnabled] = useAtom(filenamesEnabledAtom);
  const [logoEnlarged, setLogoEnlarged] = useAtom(logoEnlargedAtom);
  const [edition, setEdition] = useAtom(editionAtom);
  const [editionName, setEditionName] = useAtom(editionNameAtom);

  const [logoError, setLogoError] = useState('');
  const [assetsError, setAssetsError] = useState('');
  const [attributesError, setAttributesError] = useState('');
  const [editionError, setEditionError] = useState('');
  const [editionNameError, setEditionNameError] = useState('');

  function handleLogoDrop(acceptedFiles: any[], rejectedFiles: any[]) {
    if (rejectedFiles[0]) {
      setLogoError(rejectedFiles[0].errors[0].message);
      setLogo([]);
    } else {
      setLogoError('');
      setLogo(acceptedFiles);
    }
  }

  function handleAssetDrop(acceptedFiles: any[], rejectedFiles: any[]) {
    if (rejectedFiles[0]) {
      setEditionError(rejectedFiles[0].errors[0].message);
      setEdition([]);
    } else {
      setEditionError('');
      setEdition(acceptedFiles);
    }
  }

  function handleEditionNameChange(e: React.ChangeEvent<HTMLInputElement>) {
    const nameCheck = isValidEditionName(e.target.value);
    setEditionNameError(nameCheck.error);
    setEditionName(e.target.value);
  }

  function handleLogoEnlargedChange(checked: boolean) {
    setLogoEnlarged(checked);
  }

  async function handleAttributesDrop(acceptedFiles: any[], rejectedFiles: any[]) {
    const csv = await handleFileInput(acceptedFiles[0], 'attributes');

    if (!edition.length) {
      setAttributesError('Upload your asset first');
    } else if (csv.attributes.length !== edition.length) {
      setAttributes([]);
      setAttributesError("Attributes don't match number of assets");
    } else if (rejectedFiles[0]) {
      setAttributes([]);
      setAttributesError(rejectedFiles[0].errors[0].message);
    } else {
      setAttributesError('');
      setAttributes(csv.attributes);
    }
  }

  function handleContinue() {
    props.onNextStep();
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  }

  return (
    <Container>
      <h2>Upload your media files</h2>

      <ContainerItem>
        <LogoDropzone
          onDrop={(acceptedFiles: [], rejectedFiles: []) =>
            handleLogoDrop(acceptedFiles, rejectedFiles)
          }
          label="Logo"
          note="Upload an image to be displayed as the logo for your collection.
          Recommended image size is 400x400px."
          error={logoError}
          successText={logo.length > 0 ? 'Success! Logo added' : ''}
          files={logo}
        />
      </ContainerItem>

      {/* <ContainerItem>
        <SwitchEnlargedImage
          value={logoEnlarged}
          onChange={(checked: boolean) => handleLogoEnlargedChange(checked)}
        />
      </ContainerItem> */}

      <ContainerItem>
        <EditionsDropzone
          onDrop={(acceptedFiles: [], rejectedFiles: []) =>
            handleAssetDrop(acceptedFiles, rejectedFiles)
          }
          label="Asset"
          note="Upload a single asset to be used as the asset for your collection. The same asset will be minted based on the number of editions you make available for purchase."
          error={editionError}
          successText={edition.length > 0 ? 'Success! Asset added' : ''}
          files={edition}
        />
      </ContainerItem>

      {edition && edition.length ? (
        <LabelInput
          placeholder="Enter a custom name for your NFT"
          label="Token name (optional)"
          id="collectionName"
          type="text"
          value={editionName}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleEditionNameChange(e)}
          error={editionNameError}
          note={
            'You can enter a custom name for your NFT below. Otherwise, the token name defaults to the filename of the uploaded asset.'
          }
        />
      ) : null}

      {edition && edition.length ? (
        <ContainerItem>
          <CsvDropzone
            onDrop={(acceptedFiles: [], rejectedFiles: []) =>
              handleAttributesDrop(acceptedFiles, rejectedFiles)
            }
            label="Attributes (optional)"
            note="Upload a .csv file of attributes for your assets. Format your file so that each row represents a token id and each column represents an attribute value."
            error={attributesError}
            successText={
              attributes.length ? `Success! Attributes for ${attributes.length} assets added` : ''
            }
            sample="attributes.csv"
          />
        </ContainerItem>
      ) : null}

      <ContainerItem>
        <ContinueButton
          disabled={!logo.length || !edition.length}
          text="Continue"
          onClick={() => handleContinue()}
        />
      </ContainerItem>
    </Container>
  );
}

export default Media;
