import React from 'react';
import { Link } from 'react-router-dom';
import {
  ContainerFull,
  ContainerItem,
  FooterContainer,
  FooterItem,
  FooterRight,
  TextLink,
  Underline,
} from '../common';

export default function Footer() {
  return (
    <FooterContainer>
      <div>Copyright 2022 Gamma.io Inc</div>
      <FooterRight>
        <FooterItem>
          <TextLink href="https://gamma.io/terms" target="_blank" rel="noopener noreferrer">
            Terms of Use
          </TextLink>
          {/* <Link to="/terms">
            <Underline>Terms of Use</Underline>
          </Link> */}
        </FooterItem>
        <FooterItem>
          <TextLink href="https://gamma.io/privacy" target="_blank" rel="noopener noreferrer">
            Privacy Policy
          </TextLink>
          {/* <Link to="/privacy">
            <Underline>Privacy Policy</Underline>
          </Link> */}
        </FooterItem>
        <FooterItem>
          <TextLink
            href="https://gamma.io/terms/creators"
            target="_blank"
            rel="noopener noreferrer"
          >
            Creator Terms
          </TextLink>
          {/* <Link to="/terms/creator-terms">
            <Underline>Creator Terms</Underline>
          </Link> */}
        </FooterItem>
      </FooterRight>
    </FooterContainer>
  );
}
