import React, { Component } from "react";
import { claim, ftClaim } from "../../utils/SelfServe/contracts";
import Button from "../Button";
import styles from "./index.module.scss";
import icon from "../../components/Login/assets/icon.svg";

declare const lnswap: any;

type MintButtonProps = {
  contractAddress: string;
  contractName: string;
  userAddress: string;
  active: boolean;
  paused: boolean;
  lastTokenId: number;
  total: number;
  contractType: string;
  mintpassEnabled: boolean;
  saleEnabled: boolean;
  userData: any;
  hasMintpasses: boolean;
  numberSelected: number;
  currencySelected: string;
  pairs: any;
  amount: number;
  claimOptions: Array<any>;
  prices: number;
  btcAmount: number;
  authenticateCallback: any;
  fts: Array<any>;
};
type MintButtonState = {};

class MintButton extends Component<MintButtonProps, MintButtonState> {
  mintText() {
    let mintText;
    if (this.props.active && this.props.paused) {
      mintText = "Minting Paused";
    } else if (!this.props.active) {
      mintText = "Pending Review";
    } else if (
      Math.floor(this.props.lastTokenId) >= Math.floor(this.props.total)
    ) {
      mintText = "Visit Marketplace";
    } else if (
      this.props.contractType == "mintpass" &&
      !this.props.mintpassEnabled &&
      !this.props.saleEnabled &&
      this.props.userData
    ) {
      mintText = "Minting Closed";
    } else if (
      this.props.contractType == "mintpass" &&
      this.props.mintpassEnabled &&
      !this.props.saleEnabled &&
      !this.props.hasMintpasses &&
      this.props.userData
    ) {
      mintText = "Mintpass Only";
    } else if (this.props.userData) {
      this.props.numberSelected > 1
        ? (mintText = "Mint " + this.props.numberSelected)
        : (mintText = "Mint");
    } else {
      mintText = "Connect Stacks Wallet";
    }
    return mintText;
  }

  mintButton() {
    if (
      this.props.amount >= 0 &&
      this.renderPrice() &&
      this.props.pairs &&
      this.props.currencySelected == "BTC"
    ) {
      return (
        <div className={styles.MintButton}>
          <Button
            text={this.mintText()}
            price={
              ((this.props.prices / 1000000) * 1.02) /
              this.props.pairs["pairs"]["BTC/STX"]["rate"]
            }
            currency={"BTC"}
            onClick={this.callLnswap.bind(this)}
          />
        </div>
      );
    }

    if (
      this.props.amount >= 50 &&
      this.renderPrice() &&
      this.props.currencySelected == "xBTC"
    ) {
      if (this.props.btcAmount != -1) {
        return (
          <div className={styles.MintButton}>
            <Button
              text={this.mintText()}
              price={this.props.btcAmount}
              currency={this.props.currencySelected}
              onClick={this.callAlexClaim.bind(this)}
            />
          </div>
        );
      } else {
        return (
          <div className={styles.MintButton}>
            <Button text="Loading" />
          </div>
        );
      }
    }

    if (this.props.amount >= 0 && this.renderPrice()) {
      return (
        <div className={styles.MintButton}>
          <Button
            text={this.mintText()}
            price={this.props.amount}
            currency={this.props.currencySelected}
            onClick={this.mintingBehavior.bind(
              this,
              this.props.currencySelected
            )}
          />
        </div>
      );
    } else {
      return (
        <div className={styles.MintButton}>
          <Button
            text={this.mintText()}
            onClick={this.mintingBehavior.bind(
              this,
              this.props.currencySelected
            )}
          />
        </div>
      );
    }
  }

  mintingBehavior(symbol: string) {
    if (!this.props.contractAddress || !this.props.contractName) {
      return;
    }

    // send to marketplace if sold out
    if (Math.floor(this.props.lastTokenId) >= Math.floor(this.props.total)) {
      window.location.href = `https://stxnft.com/collections/${this.props.contractAddress}.${this.props.contractName}/`;
      return;
    }

    // minting only disabled when paused if not contract owner
    if (
      this.props.paused &&
      this.props.userAddress != this.props.contractAddress
    ) {
      return;
    }

    // admin override
    if (!this.props.active) {
      return;
    }

    // both mintpass and sale have not been enabled yet mintpass contract
    if (
      this.props.contractType == "mintpass" &&
      !this.props.mintpassEnabled &&
      !this.props.saleEnabled &&
      this.props.userData
    ) {
      return;
    }

    // mintpass only enabled and user doesn't have any mintpasses
    if (
      this.props.contractType == "mintpass" &&
      this.props.mintpassEnabled &&
      !this.props.saleEnabled &&
      !this.props.hasMintpasses &&
      this.props.userData
    ) {
      return;
    }

    let functionName: string = "claim";

    if (this.props.claimOptions != null && this.props.claimOptions.length > 0) {
      let options: Array<object> = this.props.claimOptions;
      const isOption = (element: any) =>
        element["amount"] == this.props.numberSelected;
      const optionIndex = options.findIndex(isOption);
      if (optionIndex != -1) {
        const option: any = options[optionIndex];
        functionName = option[this.props.currencySelected];
      }
    } else if (symbol != "STX") {
      functionName = functionName + "-" + symbol.toLowerCase();
    }

    this.setState({ functionName: functionName });

    this.openClaim(functionName);
  }

  renderPrice() {
    return (
      !(
        (this.props.active && this.props.paused) ||
        !this.props.active ||
        Math.floor(this.props.lastTokenId) >= Math.floor(this.props.total) ||
        (this.props.contractType == "mintpass" &&
          !this.props.mintpassEnabled &&
          !this.props.saleEnabled) ||
        (this.props.contractType == "mintpass" &&
          this.props.mintpassEnabled &&
          !this.props.hasMintpasses)
      ) && this.props.userData
    );
  }

  callLnswap = () => {
    lnswap(
      "swap",
      "triggerswap",
      this.props.userAddress,
      this.props.prices / 1000000,
      `${this.props.contractAddress}.${this.props.contractName}`,
      "claim",
      "true"
    ); // @ts-ignore
  };

  callAlexClaim = () => {
    if (!this.props.btcAmount) {
      return;
    }
    const ftPrice = Math.round(Number(this.props.btcAmount * 100000000));

    ftClaim(
      this.props.userAddress,
      this.props.contractAddress,
      this.props.contractName,
      this.props.currencySelected,
      ftPrice,
      this.props.prices * 100,
      this.claimCallback.bind(this)
    ); // @ts-ignore
  };

  openClaim(functionName: string) {
    if (this.props.userData) {
      claim(
        this.props.contractAddress,
        this.props.contractName,
        this.props.amount,
        this.props.numberSelected,
        this.props.currencySelected,
        this.props.userAddress,
        this.claimCallback.bind(this),
        functionName,
        this.props.fts
      );
    } else {
      this.props.authenticateCallback();
    }
  }

  claimCallback() {
    console.log("claim callback");
  }

  render() {
    return this.mintButton();
  }
}

export default MintButton;
