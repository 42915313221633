import React, { useEffect, useState } from 'react';
import {
  CardGrid,
  CollectionFlex,
  Container,
  ContainerFull,
  ContainerItem,
  EmptyDiv,
  H1,
  MintHeader,
  TextLink,
  Underline,
  Wrapper,
} from '../../components/common';
import {
  useNetwork,
  useContractWrite,
  usePrepareContractWrite,
  useAccount,
  useContractReads,
  useBalance,
  useSigner,
  useContractRead,
} from 'wagmi';
import { BigNumber, ethers, utils } from 'ethers';
import { formatImageUrl } from '../../utils/format-image-url';
import { EthCard, Image } from '../../components/cards';
import { bigNumToNum, convertIPFS, dealWithEther } from '../../utils';
import { EthConnectButton, MintButton, MintManyButton } from '../../components/buttons';
import { ToggleGroupMintAmounts } from '../../components/toggle-group';
import { ContractCallEth } from '../../components/contract-call';
import { getMerkle, makeTree } from '../../utils/merkle';
import { dropAbi, etherscanUrlGeorli, etherscanUrlMainnet } from '../../utils/SelfServe/contracts';
import { verifyAndPublish } from '../../utils/Studio/serverCalls';
import { useAtom } from 'jotai';
import { currentUserAddressAtom } from '../../components/atoms';
import { adminAddresses } from '../../components/Common/AdminRoute';
import { ToastTransaction } from '../../components/toasts';
const functionDescriptions = require('../../utils/ethFunctionDescriptions.json');

const omittedFunctions = [
  'DEFAULT_ADMIN_ROLE',
  'MINTER_ROLE',
  'SALES_MANAGER_ROLE',
  'zoraFeeManager',
  'zoraFeeForAmount',
  'upgradeToAndCall',
  'upgradeTo',
  'approve',
  // 'finalizeOpenEdition',
  'setApprovalForAll',
  'safeTransferFrom',
  'getApproved',
  'getRoleAdmin',
  'contractVersion',
  'supportsInterface',
  'proxiableUUID',
  'grantRole',
  'hasRole',
  'renounceRole',
  'revokeRole',
  'isApprovedForAll',
  // 'metadataRenderer',
  'purchase',
  'purchasePresale',
  'transferFrom',
  // 'burn',
];

export default function EthDrop(props: { hash: string }) {
  useEffect(() => {
    const functions = dropAbi.filter((item: any) => {
      return item && item.type === 'function' && item.name !== 'initialize';
    });
    setFunctions(functions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const [contractAddress, setContractAddress] = useState('');
  const [price, setPrice] = useState('');
  const [maxSupply, setMaxSupply] = useState(0);
  const [totalMinted, setTotalMinted] = useState(0);
  const [tokens, setTokens] = useState<any[]>([]);
  const [collectionName, setCollectionName] = useState('');
  const [collectionDescription, setCollectionDescription] = useState('');
  const [collectionImage, setCollectionImage] = useState('');
  const [mintAmount, setMintAmount] = useState('1');
  const [maxPerAddress, setMaxPerAddress] = useState(0);
  const [presaleEnd, setPresaleEnd] = useState(0);
  const [presaleStart, setPresaleStart] = useState(0);
  const [publicSaleStart, setPublicSaleStart] = useState(0);
  const [publicSaleEnd, setPublicSaleEnd] = useState(0);
  const [presaleMerkleRoot, setPresaleMerkleRoot] = useState('');
  const [treasury, setTreasury] = useState('');
  const [functions, setFunctions] = useState<any[]>([]);
  const [selectedFunction, setSelectedFunction] = useState<any>({});
  const [owner, setOwner] = useState('');
  const [salesConfig, setSalesConfig] = useState<any[]>();
  const [verified, setVerified] = useState(false);
  const [presaleMints, setPresaleMints] = useState(0);
  const [presaleMaxCount, setPresaleMaxCount] = useState(0);
  const [presalePrice, setPresalePrice] = useState(0);
  const [publicActive, setPublicActive] = useState(false);
  const [presaleActive, setPresaleActive] = useState(false);
  const [merkleProof, setMerkleProof] = useState<string[]>([]);
  const [mintFunctionName, setMintFunctionName] = useState('');
  const [mintArgs, setMintArgs] = useState<any[]>([]);
  const [mintError, setMintError] = useState('');
  const [balanceOf, setBalanceOf] = useState(0);
  const [contractNotFound, setContractNotFound] = useState(false);
  const [toastUrl, setToastUrl] = useState('');
  const [currentUserAddress, setCurrentUserAddress] = useAtom(currentUserAddressAtom);
  const dropInterface = {
    contractInterface: dropAbi,
  };
  const network = useNetwork();
  const { address, isConnected } = useAccount();
  const { data, isError, isLoading } = useContractReads({
    contracts: [
      { ...dropInterface, functionName: 'owner', addressOrName: props.hash },
      { ...dropInterface, functionName: 'saleDetails', addressOrName: props.hash },
      { ...dropInterface, functionName: 'contractURI', addressOrName: props.hash },
    ],
    onSuccess(data: any) {
      if (data && (data[0] === null || data[1] === null || data[2] === null)) {
        setContractNotFound(true);
        return;
      }
      setOwner(data[0].toString());
      const saleDetails: {
        publicSaleActive: boolean;
        presaleActive: boolean;
        publicSalePrice: BigNumber;
        publicSaleStart: BigNumber;
        publicSaleEnd: BigNumber;
        presaleStart: BigNumber;
        presaleEnd: BigNumber;
        presaleMerkleRoot: string;
        maxSalePurchasePerAddress: BigNumber;
        totalMinted: BigNumber;
        maxSupply: BigNumber;
      } = data[1];
      setPrice(ethers.utils.formatUnits(saleDetails.publicSalePrice.toString()));
      setMaxPerAddress(bigNumToNum(saleDetails.maxSalePurchasePerAddress));
      setMaxSupply(bigNumToNum(saleDetails.maxSupply));
      setTotalMinted(bigNumToNum(saleDetails.totalMinted));
      setPresaleEnd(bigNumToNum(saleDetails.presaleEnd));
      setPresaleStart(bigNumToNum(saleDetails.presaleStart));
      setPublicSaleStart(bigNumToNum(saleDetails.publicSaleStart));
      setPublicSaleEnd(bigNumToNum(saleDetails.publicSaleEnd));
      setPresaleMerkleRoot(saleDetails.presaleMerkleRoot);
      setPublicActive(saleDetails.publicSaleActive);
      setPresaleActive(saleDetails.presaleActive);
      verifyPresale(saleDetails.presaleMerkleRoot, saleDetails.presaleActive);
      if (!saleDetails.presaleActive) {
        setMintFunctionName('purchase');
        setMintArgs([parseInt(mintAmount)]);
      }
      const contractUri: string = data[2];
      fetchCollectionData(contractUri);
    },
  });

  const {
    data: userData,
    isError: isUserError,
    isLoading: isUserLoading,
  } = useContractReads({
    contracts: [
      { ...dropInterface, functionName: 'balanceOf', addressOrName: props.hash, args: [address] },
    ],
    onSuccess(data: any) {
      if (data && data[0] === null) {
        return;
      }
      if (address) {
        setBalanceOf(bigNumToNum(data[0]));
      }
    },
  });

  const { data: signer, isError: isSignerError, isLoading: isSignerLoading } = useSigner();

  const {
    data: contractWriteData,
    isLoading: isContractWriteLoading,
    isSuccess: isContractWriteSuccess,
    isError: isContractWriteError,
    write: mint,
  } = useContractWrite({
    mode: 'recklesslyUnprepared',
    addressOrName: props.hash,
    contractInterface: dropAbi,
    functionName: mintFunctionName,
    args: mintArgs,
    overrides: {
      value: presaleActive
        ? (presalePrice * parseInt(mintAmount)).toString()
        : (parseInt(dealWithEther(price).toString()) * parseInt(mintAmount)).toString(),
    },
    onError: (e) => {
      // console.log('Error', e);
      if (e.message.includes('insufficient funds')) {
        setMintError('Error: Insufficent funds to mint');
      } else if (e.message.includes('cannot estimate gas')) {
        setMintError('Error: Cannot estimate gas');
      } else if (e.message.includes('missing argument')) {
        setMintError('Error: Missing argument');
      } else if (e.message.includes('user rejected')) {
        setMintError('');
      } else {
        setMintError('There was an error with minting');
      }
    },
    onSuccess: (res) => {
      // console.log(res);
      setToastUrl(
        `${network.chain?.id === 5 ? etherscanUrlGeorli : etherscanUrlMainnet}/tx/${res.hash}`,
      );
    },
  });

  const {
    data: presaleMintsData,
    isError: isPresaleMintsError,
    isLoading: isPresaleMintsLoading,
  } = useContractRead({
    ...dropInterface,
    functionName: 'presaleMintsByAddress',
    addressOrName: props.hash,
    args: [address],
    onSuccess(data) {
      if (!isConnected) {
        return;
      }
      setPresaleMints(parseInt(data.toString()));
    },
  });

  async function verifyPresale(root: string, presaleActive: boolean) {
    const merkleEntry = await getMerkle(address, root);
    if (merkleEntry) {
      setVerified(true);
      setPresaleMaxCount(merkleEntry.maxCount);
      setPresalePrice(merkleEntry.price);
      setMerkleProof(merkleEntry.proof);
    }

    if (merkleEntry && presaleActive) {
      setMintFunctionName('purchasePresale');
      setMintArgs([
        parseInt(mintAmount),
        parseInt(merkleEntry.maxCount),
        parseInt(merkleEntry.price),
        merkleEntry.proof,
      ]);
    }
  }

  async function fetchCollectionData(contractUri: any) {
    try {
      const ipfs = convertIPFS(contractUri);
      const res = await fetch(ipfs);
      let json = await res.json();
      setCollectionName(json.name);
      setCollectionDescription(json.description);
      setCollectionImage(formatImageUrl({ url: json.image, size: 200 }));
    } catch (e) {
      // console.log(e);
    }
  }

  function getTotalRemaining() {
    if (maxSupply === 4294967295) {
      return '∞';
    }

    return maxSupply - totalMinted;
  }

  function getMintAmounts() {
    if (!address) {
      return 0;
    }

    // collection minted out
    if (totalMinted >= maxSupply) {
      return 0;
    }

    if (publicActive && balanceOf >= maxPerAddress) {
      return 0;
    }

    // if (!presaleActive && !publicActive) {
    //   return 0;
    // }

    if (!publicActive && presaleMaxCount - presaleMints <= 5) {
      return presaleMaxCount - presaleMints;
    }

    if (publicActive && maxPerAddress <= 5) {
      return maxPerAddress;
    }

    if (publicActive && maxSupply - totalMinted <= 5) {
      return maxSupply - totalMinted;
    }

    return 5;
  }

  function getMintText() {
    if (!address) {
      return 'Connect your ETH wallet';
    }

    if (!presaleActive && !publicActive) {
      return 'Minting closed';
    }

    if (presaleActive && (getMintAmounts() === 0 || !verified)) {
      return 'Allowlist only';
    }

    if (getMintAmounts() === 0) {
      return 'No mints remaining';
    }

    return `Mint ${mintAmount}`;
  }

  function getMintBehaviour() {
    setMintError('');
    if ((presaleActive || publicActive) && getMintAmounts() > 0) {
      return mint?.();
    }
    return null;
  }

  function getMintStatus() {
    const presaleStartDate = new Date(presaleStart * 1000).toLocaleDateString();
    const presaleStartTime = new Date(presaleStart * 1000).toLocaleTimeString();
    const presaleEndDate = new Date(presaleEnd * 1000).toLocaleDateString();
    const presaleEndTime = new Date(presaleEnd * 1000).toLocaleTimeString();
    const publicSaleStartDate = new Date(publicSaleStart * 1000).toLocaleDateString();
    const publicSaleStartTime = new Date(publicSaleStart * 1000).toLocaleTimeString();
    const publicSaleEndDate = new Date(publicSaleEnd * 1000).toLocaleDateString();
    const publicSaleEndTime = new Date(publicSaleEnd * 1000).toLocaleTimeString();
    const now = Date.now();

    if (publicActive) {
      return '';
    }

    if (presaleActive) {
      return `Presale ends ${presaleEndDate} at ${presaleEndTime}${
        getMintAmounts() > 0
          ? ` (${getMintAmounts()} ${getMintAmounts() > 1 ? 'mintpasses' : 'mintpass'})`
          : ''
      }`;
    }

    if (!presaleActive && !publicActive && now < presaleStart * 1000) {
      return `Presale starts ${presaleStartDate} at ${presaleStartTime}${
        getMintAmounts() > 0
          ? ` (${getMintAmounts()} ${getMintAmounts() > 1 ? 'mintpasses' : 'mintpass'})`
          : ''
      }`;
    }

    if (!presaleActive && !publicActive && now < publicSaleStart * 1000) {
      return `Public sale starts ${publicSaleStartDate} at ${publicSaleStartTime}`;
    }
  }

  function renderCards() {
    const cards = [];
    for (let i = 0; i < totalMinted; i++) {
      cards.push(<EthCard address={props.hash} interface={dropAbi} tokenId={i + 1} />);
    }
    return cards;
  }

  function handleMintAmount(value: string) {
    if (!value) {
      return;
    }
    setMintAmount(value);
    setMintArgs(
      presaleActive
        ? [parseInt(value), presaleMaxCount, presalePrice, merkleProof]
        : [parseInt(value)],
    );
  }

  // 0x570865aEe8Fe4CdEdD2D1C8107BDf2F7024C0a43,0xF83D19A78b8d4DFB574eaEF073F84E64DCe89506,0x08eA9f58dc9785a39eD1883728f2A681cAEE75b1,0x5e62C717F5fFE00baF7c9554e1bD471d62B0B417,0xCFE79a361397F53FAFf20b19A49eF317fA107bab

  function renderAdminOptions() {
    if (!functions) {
      return null;
    }

    return functions.map((f: any) => {
      if (omittedFunctions.includes(f.name)) {
        return null;
      }
      return <option value={f.name}>{f.name}</option>;
    });
  }

  function handleAdminOptionChange() {
    const sel: any = document.getElementById('functions');
    const functionName = sel.options[sel.selectedIndex].text;
    const res = functions.filter((obj) => {
      return obj.name === functionName;
    });
    const description = functionDescriptions.filter((obj: any) => {
      return obj.name === functionName;
    });
    res[0].description = description[0].description;
    setSelectedFunction(res[0]);
  }

  function renderAdminFunction() {
    if (!selectedFunction || !selectedFunction.name) {
      return null;
    }
    let args = [];
    if (selectedFunction.inputs && selectedFunction.inputs.length) {
      for (let i = 0; i < selectedFunction.inputs.length; i++) {
        const input = selectedFunction.inputs[i];
        const arg = { name: input.name, type: input.type };
        args.push(arg);
      }
    }

    return (
      <ContractCallEth
        contractAddress={props.hash}
        method={selectedFunction.name}
        args={args}
        selectedFunction={selectedFunction}
        signer={signer}
        abi={dropAbi}
      />
    );
  }

  function getTotalPrice() {
    if (presalePrice && presaleActive) {
      const p = presalePrice * parseInt(mintAmount);
      return p / 1e18;
    } else {
      const p = parseFloat(price) * parseInt(mintAmount);
      return +p.toFixed(6);
    }
  }

  function handleToastClose() {
    setToastUrl('');
  }

  // if (!currentUserAddress || !adminAddresses.includes(currentUserAddress)) {
  //   return <ContainerFull>You're not authorized to view this page.</ContainerFull>;
  // }

  // if (!isConnected) {
  //   return (
  //     <ContainerFull>
  //       <EmptyDiv
  //         css={{
  //           width: '230px',
  //         }}
  //       >
  //         <EthConnectButton signInText="Connect your ETH wallet" />
  //       </EmptyDiv>
  //     </ContainerFull>
  //   );
  // }

  if (contractNotFound) {
    return <ContainerFull>Contract not found.</ContainerFull>;
  }

  if (props.hash) {
    const baseUri = network.chain?.id === 5 ? etherscanUrlGeorli : etherscanUrlMainnet;
    return (
      <ContainerFull>
        <MintHeader>
          <EmptyDiv
            css={{
              width: '40%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              gap: 20,
            }}
          >
            <Image css={{ width: 122 }} src={collectionImage} alt="" />
            <H1 css={{ marginBottom: 0 }}>{collectionName}</H1>
            <div>{collectionDescription}</div>
            <EmptyDiv
              css={{
                display: 'flex',
                gap: 8,
              }}
            >
              <TextLink
                href={`${baseUri}/address/${props.hash}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                Contract
              </TextLink>              
            </EmptyDiv>
          </EmptyDiv>
          <EmptyDiv
            css={{
              width: '60%',
              flexWrap: 'wrap',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              gap: 20,
            }}
          >
            {getMintAmounts() > 1 ? (
              <ToggleGroupMintAmounts
                onChange={(value: string) => handleMintAmount(value)}
                total={getMintAmounts()}
              />
            ) : null}

            {isConnected ? (
              <MintManyButton
                text={getMintText()}
                textRight={
                  getMintAmounts() > 0 && (presaleActive || publicActive)
                    ? `${getTotalPrice()} ETH`
                    : ''
                }
                onClick={() => getMintBehaviour()}
              ></MintManyButton>
            ) : (
              <EthConnectButton signInText="Connect your ETH wallet" />
            )}

            <EmptyDiv css={{ textAlign: 'center' }}>
              <EmptyDiv
                css={{
                  fontWeight: 500,
                  fontSize: 24,
                  letterSpacing: '1.85px',
                  display: 'inline-block',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  padding: '0 10px 0 10px',
                }}
              >
                <div>{totalMinted}</div>
                <EmptyDiv
                  css={{
                    fontFamily: 'Roboto',
                    fontWeight: 500,
                    fontSize: '10px',
                    color: '#d8d8e0',
                    letterSpacing: '1.37px',
                    paddingTop: '10px',
                  }}
                >
                  MINTED
                </EmptyDiv>
              </EmptyDiv>
              <EmptyDiv
                css={{
                  fontWeight: 500,
                  fontSize: 24,
                  letterSpacing: '1.85px',
                  display: 'inline-block',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  padding: '0 10px 0 10px',
                }}
              >
                <div>{getTotalRemaining()}</div>
                <EmptyDiv
                  css={{
                    fontFamily: 'Roboto',
                    fontWeight: 500,
                    fontSize: '10px',
                    color: '#d8d8e0',
                    letterSpacing: '1.37px',
                    paddingTop: '10px',
                  }}
                >
                  REMAINING
                </EmptyDiv>
              </EmptyDiv>
              <EmptyDiv
                css={{
                  fontWeight: 500,
                  fontSize: 24,
                  letterSpacing: '1.85px',
                  display: 'inline-block',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  padding: '0 10px 0 10px',
                }}
              >
                <EmptyDiv css={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  {/* <MintPriceIcon /> */}
                  <div>
                    {price}
                    <span style={{ fontSize: '14px' }}> ETH</span>
                  </div>
                </EmptyDiv>
                <EmptyDiv
                  css={{
                    fontFamily: 'Roboto',
                    fontWeight: 500,
                    fontSize: '10px',
                    color: '#d8d8e0',
                    letterSpacing: '1.37px',
                    paddingTop: '10px',
                  }}
                >
                  MINT PRICE
                </EmptyDiv>
              </EmptyDiv>
            </EmptyDiv>

            {getMintStatus() ? (
              <EmptyDiv css={{ fontSize: '14px' }}>{getMintStatus()}</EmptyDiv>
            ) : null}

            {mintError ? (
              <EmptyDiv css={{ fontSize: '14px', color: 'Red' }}>{mintError}</EmptyDiv>
            ) : null}
          </EmptyDiv>
        </MintHeader>
        {address && owner && address.toLowerCase() === owner.toLowerCase() ? (
          <Container>
            <select name="functions" id="functions" onChange={() => handleAdminOptionChange()}>
              <option disabled selected={!selectedFunction || !selectedFunction.name}>
                {' '}
                -- select a function --{' '}
              </option>
              {renderAdminOptions()}
            </select>{' '}
            <button onClick={() => setSelectedFunction([])}>reset</button>
            <ContainerItem>{renderAdminFunction()}</ContainerItem>
          </Container>
        ) : null}

        <CardGrid>{renderCards()}</CardGrid>

        {toastUrl ? <ToastTransaction onClose={() => handleToastClose()} url={toastUrl} /> : null}
      </ContainerFull>
    );
  } else {
    return <ContainerFull>Contract not found</ContainerFull>;
  }
}
