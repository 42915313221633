import React, { Component } from 'react'
import styles from './index.module.scss'

import { darkmodeListener } from '../../theme';

type CheckboxInputProps = {
  label: string
  defaultChecked: boolean
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
}

type CheckboxInputState = {}

class CheckboxInput extends Component<CheckboxInputProps, CheckboxInputState> {
  componentDidMount() {
    darkmodeListener(styles['theme-light'], styles['theme-dark']);
  }

  render() {
    return (
      <div className={styles.CheckboxInput}>
        <label>
          <input
            type={"checkbox"}
            defaultChecked={this.props.defaultChecked}
            onChange={this.props.onChange.bind(this)}
          />
          {" "}{this.props.label}
        </label>
      </div>
    );
  }
}

export default CheckboxInput
