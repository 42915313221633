import React, { Component } from 'react';
import styles from './index.module.scss';

import { darkmodeListener } from '../../theme';
import makeEndpoint from '../../endpoints';

import Drop from '../../components/Drop';
import { Link } from 'react-router-dom';
import { renderer } from '../../utils';
import {
  getAuction,
  getAuctionBid,
  listAuction,
  network,
  placeBid,
  getOwner,
  endAuction,
  auctionContractAddress,
  auctionContractName,
  basePath,
} from '../../utils/SelfServe/contracts';
import {
  checkMempool,
  getBlockHeight,
  getBns,
  getContractEvents,
  getStxBalance,
  getTransactions,
} from '../../utils/api';
import Button from '../../components/Button';
import { showConnect, AppConfig, UserSession, getUserData } from '@stacks/connect';
import { gaSend } from '../../Analytics';
import icon from '../../components/Login/assets/icon.svg';
import Modal from '../../components/Modal';
import Confirm from '../../components/Confirm';
import ListAuctionModal from '../../components/ListAuctionModal';
import TextInput from '../../components/TextInput';

import * as stacks from '@stacks/blockchain-api-client';
import { io } from 'socket.io-client';
import { BidButton, CreateButton, MintButton } from '../../components/buttons';
import { CollectionRow, ContainerFull, Row, TextLink } from '../../components/common';
import { adminAddresses } from '../../components/Common/AdminRoute';
// const socketUrl = 'https://stacks-node-api.mainnet.stacks.co/';
const socketUrl = basePath;
const socket = io(socketUrl, {
  transports: ['websocket'],
});
const sc = new stacks.StacksApiSocketClient(socket);

const appConfig = new AppConfig(['store_write', 'publish_data']);
const userSession = new UserSession({ appConfig });

type AuctionProps = {
  collectionData?: any;
  name: string;
  id: string;
};
type AuctionState = {
  collectionData: any;
  tokenData: any;
  auctionData: any;
  bidData: any;
  contractAddress: string;
  contractName: string;
  tokenId: string;
  auctionId?: string;
  userData?: any;
  showBidModal: boolean;
  bidAmount?: string;
  showSellModal: boolean;
  listingBlockLength?: string;
  listingReservePrice?: string;
  listingType?: string;
  modal?: any;
  owner?: string;
  blockHeight?: number;
  mempoolBids: Array<any>;
  bidHistory: Array<any>;
  auctionFetched: boolean;
  bidFetched: boolean;
  mempoolFetched: boolean;
  blockHeightFetched: boolean;
  ownerFetched: boolean;
  bidHistoryFetched: boolean;
  blockLengthError?: string;
  reservePriceError?: string;
  bidError?: string;
  reservePrice?: number;
  highBid?: number;
  highBidder?: string;
  highBidderBns?: string;
  sellerAddress?: string;
  endBlockHeight?: number;
  blocksRemaining?: number;
  hoursRemaining?: number;
  secondsRemaining?: number;
  timeRemaining?: string;
  seconds: number;
  loadingText?: string;
  websiteUrl: string;
  exceptions?: any;
  stxBalance?: number;
};

class Auction extends Component<AuctionProps, AuctionState> {
  state = {
    collectionData: undefined,
    tokenData: undefined,
    auctionData: undefined,
    bidData: undefined,
    contractAddress: '',
    contractName: this.props.name,
    tokenId: this.props.id,
    auctionId: undefined,
    userData: undefined,
    showBidModal: false,
    bidAmount: undefined,
    showSellModal: false,
    listingBlockLength: undefined,
    listingReservePrice: undefined,
    listingType: '2',
    modal: undefined,
    owner: undefined,
    blockHeight: undefined,
    mempoolBids: [],
    bidHistory: [],
    auctionFetched: false,
    bidFetched: false,
    mempoolFetched: false,
    blockHeightFetched: false,
    bidHistoryFetched: false,
    ownerFetched: false,
    blockLengthError: undefined,
    reservePriceError: undefined,
    bidError: undefined,
    reservePrice: undefined,
    highBid: undefined,
    highBidder: undefined,
    highBidderBns: undefined,
    sellerAddress: undefined,
    endBlockHeight: undefined,
    blocksRemaining: undefined,
    hoursRemaining: undefined,
    secondsRemaining: undefined,
    timeRemaining: undefined,
    seconds: 0,
    loadingText: undefined,
    websiteUrl: '',
    exceptions: undefined,
    stxBalance: undefined,
  };

  interval: any;

  tick() {
    this.setState((state) => ({
      seconds: state.seconds + 1,
    }));

    const blockLength =
      this.state.auctionData && this.state.auctionData['block-length']
        ? this.state.auctionData['block-length']['value']
        : undefined;
    let endBlockHeight = undefined;
    let blocksRemaining = undefined;
    let secondsRemaining = undefined;

    if (
      this.state.auctionData &&
      this.state.auctionData['end-block-height']['value'] &&
      this.state.blockHeight &&
      blockLength
    ) {
      endBlockHeight = Number(this.state.auctionData['end-block-height']['value']['value']);
      blocksRemaining = endBlockHeight - this.state.blockHeight - 1;
      secondsRemaining = blocksRemaining * 600;
    } else {
      endBlockHeight = undefined;
    }

    if (blocksRemaining && secondsRemaining) {
      let totalSeconds = secondsRemaining - this.state.seconds;
      let hours: any = Math.floor(totalSeconds / 3600);
      if (hours < 10) {
        hours = '0' + hours;
      }
      totalSeconds %= 3600;
      let minutes: any = Math.floor(totalSeconds / 60);
      if (minutes < 10) {
        minutes = '0' + minutes;
      }
      let seconds: any = totalSeconds % 60;
      if (seconds < 10) {
        seconds = '0' + seconds;
      }

      let timeRemaining = `${blocksRemaining} ${blocksRemaining === 1 ? 'block' : 'blocks'}`;

      if (blocksRemaining > 3 && secondsRemaining > 1800) {
        timeRemaining = `${hours}h : ${minutes}m : ${seconds}s (${blocksRemaining} blocks)`;
      }

      this.setState({
        timeRemaining,
        secondsRemaining,
        blocksRemaining,
        endBlockHeight,
      });
    } else {
      this.setState({
        blocksRemaining,
      });
    }

    // if (this.state.seconds % 30 === 0 && this.state.seconds > 60) {
    //   this.fetchAuctionData();
    // }
  }

  componentWillUnmount() {
    clearInterval(this.interval);

    sc.unsubscribeMempool();
    sc.unsubscribeBlocks();
  }

  componentDidMount() {
    darkmodeListener(styles['theme-light'], styles['theme-dark']);

    this.interval = setInterval(() => this.tick(), 1000);

    sc.subscribeMempool();
    sc.subscribeBlocks();

    sc.socket.on('mempool', (data) => this.handleMempoolSocket(data));
    sc.socket.on('block', (data) => this.handleBlockSocket(data));

    const userData = localStorage.getItem('userData');
    if (userData) {
      const parsedUserData = JSON.parse(userData);
      this.setState({
        userData: parsedUserData,
      });
      if (parsedUserData && network.isMainnet()) {
        getStxBalance(parsedUserData.profile.stxAddress.mainnet).then((balance: any) =>
          this.setState({ stxBalance: parseInt(balance) }),
        );
      }
    }

    fetch(makeEndpoint('/api/exceptions'))
      .then((res) => {
        return res.json();
      })
      .then((json) => {
        this.setState({
          exceptions: json,
        });
      })
      .catch((err) => console.log(err));

    if (this.props.collectionData) {
      this.setState({
        contractAddress: this.props.collectionData.contractAddress,
        collectionData: this.props.collectionData,
        websiteUrl: this.props.collectionData['websiteUrl'],
      });
      this.fetchAuctionData();
    } else {
      fetch(makeEndpoint('/api/drop?name=' + this.props.name))
        .then((res) => {
          return res.json();
        })
        .then((data) => {
          this.setState({
            contractAddress: data.contractAddress,
            collectionData: data,
            websiteUrl: data['websiteUrl'],
          });
          this.fetchTokenData();
          this.fetchAuctionData();
        });
    }
  }

  async handleMempoolSocket(data: any) {
    if (
      data.contract_call &&
      data.contract_call.contract_id === `${auctionContractAddress}.${auctionContractName}` &&
      data.contract_call.function_name === 'place-bid'
    ) {
      this.fetchMempool();
    }
  }

  handleBlockSocket(data: any) {
    this.setState({ blockHeight: data.height, seconds: 0 });
    this.fetchAuctionData();
  }

  async fetchMempool() {
    const mempool = await checkMempool(auctionContractAddress, auctionContractName, 200);
    const mempoolBids: any = [];
    if (mempool != null && mempool.length > 0) {
      for (let i = 0; i < mempool.length; i++) {
        const tx: any = mempool[i];
        const functionName = tx['contract_call']['function_name'];
        const functionArgs = tx['contract_call']['function_args'];
        const senderAddress = tx['sender_address'].replace("'", '');
        const txId = tx['tx_id'];

        if (functionName === 'place-bid') {
          const nftAssetContract = functionArgs[0]['repr'].replace("'", '');
          const tokenId = functionArgs[1]['repr'].replace('u', '');
          const amount = functionArgs[2]['repr'].replace('u', '');
          const bid: any = {
            senderAddress,
            nftAssetContract,
            tokenId,
            amount,
            txId,
          };
          if (
            tokenId === this.state.tokenId &&
            nftAssetContract === `${this.state.contractAddress}.${this.state.contractName}`
          ) {
            const senderBns = await getBns(senderAddress);
            bid['senderBns'] = senderBns;
            mempoolBids.push(bid);
          }
        }
      }
      // sort mempool bids high to low
      mempoolBids.sort((a: any, b: any) => b.amount - a.amount);
    }
    this.setState({ mempoolBids: mempoolBids, mempoolFetched: true });
  }

  async fetchContractEvents() {
    const events = await getContractEvents(
      auctionContractAddress,
      auctionContractName,
      this.state.tokenId,
      600,
    );

    const bidHistory: any = [];
    for (let i = 0; i < events.length; i++) {
      const event: any = events[i];
      const buyer = event.buyer.replace("'", '');
      if (event['auction-id'] === this.state.auctionId) {
        const buyerBns = await getBns(buyer);
        event['buyerBns'] = buyerBns;
        bidHistory.push(event);
      } else if (
        event['nft-asset-contract'] ===
          this.state.contractAddress + '.' + this.state.contractName &&
        event['token-id'] === this.state.tokenId
      ) {
        const buyerBns = await getBns(buyer);
        event['buyerBns'] = buyerBns;
        bidHistory.push(event);
      }
    }

    this.setState({ bidHistory, bidHistoryFetched: true });
  }

  async fetchAuctionData() {
    this.setState({ loadingText: 'Loading auction details...' });

    getAuction(
      this.state.contractAddress,
      this.state.contractName,
      Number(this.state.tokenId),
    ).then((auctionData) => {
      const sellerAddress = auctionData
        ? auctionData['seller-address']['value'].replace("'", '')
        : undefined;

      const reservePrice = auctionData ? auctionData['reserve-price']['value'] / 1e6 : undefined;

      const auctionId = auctionData ? auctionData['auction-id']['value'] : undefined;

      this.setState({
        auctionData: auctionData,
        sellerAddress,
        reservePrice,
        auctionId,
        auctionFetched: true,
      });
    });

    getAuctionBid(
      this.state.contractAddress,
      this.state.contractName,
      Number(this.state.tokenId),
    ).then((bidData) => {
      this.setState({ bidData: bidData, bidFetched: true });
      if (bidData) {
        const highBid = Number(bidData['amount']['value']) / 1e6;
        const highBidder = bidData['buyer']['value'].replace("'", '');
        getBns(highBidder).then((bns) => {
          this.setState({
            highBid,
            highBidder,
            highBidderBns: bns,
          });
        });
      }
    });

    // getNfts("SP1CSHTKVHMMQJ7PRQRFYW6SB4QAW6SR3XY2F81PA.aux3", 50, 0).then(
    //   (res) => console.log(res)
    // );

    // getTransactions("SP1CSHTKVHMMQJ7PRQRFYW6SB4QAW6SR3XY2F81PA.aux3").then(
    //   (res) => console.log(res)
    // );

    if (!this.state.owner) {
      getOwner(
        this.state.contractAddress,
        this.state.contractName,
        Number(this.state.tokenId),
      ).then((owner) => {
        this.setState({ owner: owner.replace("'", ''), ownerFetched: true });
      });
    }

    if (!this.state.blockHeight) {
      getBlockHeight().then((blockHeight) => {
        this.setState({ blockHeight, blockHeightFetched: true });
      });
    }

    if (!this.state.mempoolFetched) {
      this.fetchMempool();
    }

    this.fetchContractEvents();
  }

  handleAuction(auction: any, id: number) {
    this.setState({ auctionData: auction });
  }

  handleAuctionBid(bid: any, id: number) {
    this.setState({ bidData: bid });
  }

  stxAddress() {
    if (this.state.userData && network.isMainnet()) {
      const userData: any = this.state.userData;
      return userData.profile.stxAddress.mainnet;
    } else if (this.state.userData && !network.isMainnet()) {
      const userData: any = this.state.userData;
      return userData.profile.stxAddress.testnet;
    } else {
      return null;
    }
  }

  authenticate() {
    showConnect({
      appDetails: {
        name: 'Stacks NFT',
        icon: icon,
      },
      redirectTo: '/',
      onFinish: () => {
        gaSend('User', 'authenticate', 'User Authenticated');
        let userData = userSession.loadUserData();
        this.setState({ userData: userData });
        localStorage.setItem('userData', JSON.stringify(userData));
        window.location.reload();
      },
      userSession: userSession,
    });
  }

  fetchTokenData() {
    var params = '';
    params += 'address=' + this.state.contractAddress;
    params += '&name=' + this.state.contractName;
    params += '&id=' + this.state.tokenId;
    // fetch(makeEndpoint('/api/nft?') + params)
    fetch('https://legacy.stxnft.com/api/nft?' + params)
      .then((res) => res.json())
      .then((data) => {
        if (data.name) {
          this.setState({ tokenData: data });
        }
      });
  }

  closeBidModal(accepted?: boolean) {
    // this.modalClose();
    this.setState({
      showBidModal: false,
    });
  }

  openBidModal() {
    if (!this.state.userData) {
      this.authenticate();
      return;
    }

    this.setState({
      showBidModal: true,
    });
  }

  handleBidAmount(e: any) {
    if (isNaN(e.target.value)) {
      return;
    }

    this.setState({
      bidAmount: e.target.value,
    });
  }

  handleBlockLength(e: any) {
    if (isNaN(e.target.value)) {
      return;
    }

    const blockLength = e.target.value.replaceAll('.', '');

    this.setState({
      listingBlockLength: blockLength,
    });
  }

  handleReservePrice(e: any) {
    if (isNaN(e.target.value)) {
      return;
    }

    this.setState({
      listingReservePrice: e.target.value,
    });
  }

  handleAuctionType(e: any) {
    if (isNaN(e.target.value)) {
      return;
    }

    this.setState({
      listingType: e.target.value,
    });
  }

  openSellModal() {
    this.setState({ showSellModal: true });
  }

  closeSellModal() {
    this.setState({
      showSellModal: false,
    });
  }

  handlePlaceBid() {
    this.setState({
      bidError: undefined,
    });

    if (!this.state.bidAmount) {
      this.setState({
        bidError: 'Enter a valid bid amount (must be a number)',
      });
      return;
    }

    if (
      this.state.highBid &&
      this.state.bidAmount &&
      this.state.bidAmount < this.state.highBid + 1
    ) {
      this.setState({
        bidError: 'Enter a bid at least 1 STX greater than the highest bid',
      });
      return;
    }

    if (
      !this.state.highBid &&
      this.state.bidAmount &&
      this.state.reservePrice &&
      this.state.bidAmount < this.state.reservePrice
    ) {
      this.setState({
        bidError: 'Enter a bid greater than or equal to the reserve price',
      });
      return;
    }

    if (
      this.state.stxBalance &&
      this.state.bidAmount &&
      this.state.stxBalance / 1e6 < this.state.bidAmount
    ) {
      this.setState({
        bidError: 'Insufficient funds for this bid amount',
      });
      return;
    }

    this.closeBidModal();

    placeBid(
      this.stxAddress(),
      this.state.contractAddress,
      this.state.contractName,
      Number(this.state.tokenId),
      Number(this.state.bidAmount) * 1e6,
      this.setModal.bind(this),
      this.state.highBidder,
      this.state.highBid,
    );

    this.setState({ bidAmount: undefined });
  }

  handleListAuction() {
    this.setState({
      blockLengthError: undefined,
      reservePriceError: undefined,
    });

    let blockLengthError = undefined;
    let reservePriceError = undefined;
    if (!this.state.listingBlockLength || Number(this.state.listingBlockLength) <= 1) {
      blockLengthError = 'Enter a valid block length (must be an integer > 1)';
    }

    if (!this.state.listingReservePrice || Number(this.state.listingReservePrice < 0.69)) {
      reservePriceError = 'Enter a valid reserve price (must be a number >= 1 STX)';
    }

    this.setState({ blockLengthError, reservePriceError });

    if (blockLengthError || reservePriceError) {
      return;
    }

    this.closeSellModal();

    listAuction(
      this.stxAddress(),
      this.state.contractAddress,
      this.state.contractName,
      Number(this.state.tokenId),
      Number(this.state.listingBlockLength) + 1,
      Number(this.state.listingReservePrice) * 1e6,
      Number(this.state.listingType),
      this.setModal.bind(this),
    );

    this.setState({
      listingBlockLength: undefined,
      listingReservePrice: undefined,
      // listingType: undefined,
    });
  }

  handleEndAuction() {
    endAuction(
      this.stxAddress(),
      this.state.contractAddress,
      this.state.contractName,
      Number(this.state.tokenId),
      this.setModal.bind(this),
      this.state.highBid || 0,
    );
  }

  setModal(modal: any) {
    this.setState({
      modal: modal,
    });
  }

  modalClose() {
    const modal: any = this.state.modal;
    if (modal.fetchMempool) {
      this.fetchMempool();
    }
    this.setState({
      modal: undefined,
    });
  }

  isFetched() {
    return (
      this.state.auctionFetched &&
      this.state.bidFetched &&
      // this.state.mempoolFetched &&
      this.state.blockHeightFetched &&
      this.state.ownerFetched
      // this.state.bidHistoryFetched
    );
  }

  reserveNotMet() {
    return (
      this.state.endBlockHeight != 0 &&
      !this.state.endBlockHeight &&
      this.state.reservePrice &&
      this.state.bidHistory.length == 0 &&
      this.state.bidHistoryFetched
    );
  }

  // reserveMet() {
  //   return (
  //     this.state.endBlockHeight &&
  //     this.state.reservePrice &&
  //     this.state.bidHistory.length == 0 &&
  //     this.state.bidHistoryFetched
  //   );
  // }

  auctionEnded() {
    return (
      this.state.highBid &&
      this.state.highBidder &&
      (!this.state.blocksRemaining ||
        (this.state.blocksRemaining && this.state.blocksRemaining <= 0))
    );
  }

  auctionActive() {
    return this.state.blocksRemaining && this.state.blocksRemaining > 0;
  }

  isBuyer() {
    return this.state.highBid && this.stxAddress() === this.state.highBidder;
  }

  isSeller() {
    return this.state.sellerAddress && this.stxAddress() === this.state.sellerAddress;
  }

  renderDetails() {
    if (this.state.auctionData && this.isFetched()) {
      let highBidder: any = this.state.highBidder;
      if (highBidder && this.state.highBidderBns) {
        highBidder = this.state.highBidderBns;
      } else if (this.state.highBidder) {
        highBidder = `${highBidder.substring(0, 4)}...${highBidder.substring(
          highBidder.length - 4,
        )}`;
      }

      return (
        <div>
          <div className={styles.detailsWrapper}>
            {this.auctionEnded() &&
            (this.isBuyer() || this.isSeller() || adminAddresses.includes(this.stxAddress())) ? (
              <div className={styles.buttonWrapper}>
                <Button
                  text="End Auction"
                  // price={this.props.bid}
                  style={'PRIMARY'}
                  onClick={this.handleEndAuction.bind(this)}
                />
              </div>
            ) : null}

            {this.reserveNotMet() || this.auctionActive() ? (
              <div className={styles.buttonWrapper}>
                <BidButton
                  text={'Place Bid'}
                  // price={this.props.bid}
                  // style={'PRIMARY'}
                  onClick={this.openBidModal.bind(this)}
                />
              </div>
            ) : null}

            {this.state.highBid && this.state.highBidder && this.auctionActive() ? (
              <div className={styles.highBid}>
                Current high bid {this.state.highBid} STX by{' '}
                <TextLink
                  href={`https://stacks.gamma.io/${this.state.highBidder}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {highBidder}
                </TextLink>
              </div>
            ) : null}

            {this.state.blocksRemaining &&
            this.state.blocksRemaining > 0 &&
            this.state.timeRemaining ? (
              <div className={styles.detailsLineWrapper}>
                Auction ends in <span className={styles.time}>{this.state.timeRemaining}</span>
              </div>
            ) : null}

            {this.reserveNotMet() && <div>Reserve not met at {this.state.reservePrice} STX</div>}

            {this.state.endBlockHeight != 0 &&
            !this.state.endBlockHeight &&
            this.state.reservePrice &&
            this.state.bidHistory.length > 0 &&
            !this.state.highBid ? (
              <div>
                Current high bid {this.state.bidHistory[0]['amount'] / 1e6} STX (microblock)
              </div>
            ) : null}

            {this.auctionEnded() ? (
              <div>
                Auction ended with a high bid of {this.state.highBid} STX by{' '}
                <TextLink
                  href={`https://stacks.gamma.io/${this.state.highBidder}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {highBidder}
                </TextLink>
              </div>
            ) : null}
          </div>

          {this.state.mempoolFetched &&
          this.state.mempoolBids &&
          this.state.mempoolBids.length > 0 ? (
            <div className={styles.detailsWrapper}>
              <h3>Pending Bids</h3>
              {this.state.mempoolBids.map((bid: any) => {
                return (
                  <div className={styles.detailsLineWrapper}>
                    <div>
                      {bid.amount / 1e6} STX{' '}
                      <TextLink
                        href={`https://explorer.stacks.co/txid/${bid['txId']}?chain=mainnet`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        bid
                      </TextLink>{' '}
                      by{' '}
                      <TextLink
                        href={`https://stacks.gamma.io/${bid.senderAddress}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {bid.senderBns ||
                          `${bid.senderAddress.substring(0, 4)}...${bid.senderAddress.substring(
                            bid.senderAddress.length - 4,
                          )}`}
                      </TextLink>
                    </div>
                  </div>
                );
              })}
            </div>
          ) : !this.state.mempoolFetched ? (
            <div className={styles.detailsWrapper}>
              <div>Loading pending transactions...</div>
            </div>
          ) : null}

          {this.state.bidHistoryFetched &&
          this.state.bidHistory &&
          this.state.bidHistory.length > 0 ? (
            <div className={styles.detailsWrapper}>
              <h3>Bid History</h3>
              {this.state.bidHistory.map((bid: any, idx) => {
                if (
                  bid['token-id'] === this.state.tokenId &&
                  bid['auction-id'] === this.state.auctionId
                ) {
                  return (
                    <div className={styles.detailsLineWrapper}>
                      <div>
                        {bid.amount / 1e6} STX{' '}
                        <TextLink
                          href={`https://explorer.stacks.co/txid/${bid['tx-id']}?chain=mainnet`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          bid
                        </TextLink>{' '}
                        by{' '}
                        <TextLink
                          href={`https://stacks.gamma.io/${bid.buyer.replace("'", '')}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {bid.buyerBns ||
                            `${bid.buyer.substring(0, 4)}...${bid.buyer.substring(
                              bid.buyer.length - 4,
                            )}`}
                          {/* {bid.buyer} */}
                        </TextLink>
                      </div>
                    </div>
                  );
                }
              })}
            </div>
          ) : !this.state.bidHistoryFetched ? (
            <div className={styles.detailsWrapper}>
              <div>Loading bid history...</div>
            </div>
          ) : null}

          {this.state.bidHistoryFetched &&
          this.state.mempoolFetched &&
          this.state.blocksRemaining &&
          this.state.blocksRemaining > 0 ? (
            <div className={styles.note}>* Details refresh automatically on new events</div>
          ) : null}
        </div>
      );
    } else if (!this.isFetched()) {
      return <div className={styles.detailsWrapper}>{this.state.loadingText}</div>;
    } else if (this.state.auctionData === null && this.state.owner === this.stxAddress()) {
      return (
        <Button
          text={'List Auction'}
          // price={this.props.bid}
          style={'PRIMARY'}
          onClick={this.openSellModal.bind(this)}
        />
      );
    } else if (
      !this.state.auctionData &&
      this.state.bidHistory &&
      this.state.bidHistory.length > 0
    ) {
      return (
        <div className={styles.detailsWrapper}>
          <h3>Bid History</h3>
          {this.state.bidHistory.map((bid: any, idx) => {
            if (bid['token-id'] === this.state.tokenId) {
              return (
                <div className={styles.detailsLineWrapper}>
                  <div>
                    {bid.amount / 1e6} STX bid by{' '}
                    <TextLink
                      href={`https://stacks.gamma.io/${bid.buyer}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {bid.buyerBns ||
                        `${bid.buyer.substring(0, 4)}...${bid.buyer.substring(
                          bid.buyer.length - 4,
                        )}`}
                      {/* {bid.buyer} */}
                    </TextLink>
                  </div>
                </div>
              );
            }
          })}
        </div>
      );
    } else {
      return '';
    }
  }

  renderTitle() {
    if (
      this.state.collectionData &&
      this.state.collectionData['name'] &&
      this.state.collectionData['name'] === '$AINT$' &&
      this.state.exceptions
    ) {
      return (
        <h1>
          {
            this.state.exceptions['exceptionsByContract'][
              'SP207ESW7AKHTPRYAAD9QP8Q1TE1F57D2S8RGPJCC.saints'
            ]['title']
          }
        </h1>
      );
    } else if (
      this.state.collectionData &&
      this.state.collectionData['name'] &&
      this.state.collectionData['name'] === 'Artists Behind the Projects' &&
      this.state.tokenData
    ) {
      return <h1>{this.state.tokenData['name']}</h1>;
    } else if (this.state.tokenData) {
      return <h1>{this.state.tokenData['name']}</h1>;
    } else if (this.state.collectionData && this.state.collectionData['name']) {
      return <h1>{this.state.collectionData['name']}</h1>;
    } else {
      return null;
    }
  }

  renderCollection() {
    if (
      this.state.collectionData &&
      this.state.collectionData['name'] &&
      this.state.collectionData['name'] === 'Artists Behind the Projects' &&
      this.state.tokenData
    ) {
      return (
        <h2>{this.state.tokenData['attributes'][0]['value']} - Artists Behind the Projects</h2>
      );
    } else if (this.state.collectionData && this.state.collectionData['collection']) {
      return (
        <Link to={'/collection/' + this.props.name}>
          <h2>{this.state.collectionData['collection']}</h2>
        </Link>
      );
    } else if (this.state.tokenData) {
      return (
        <Link to={'/collection/' + this.props.name}>
          <h2>{this.state.tokenData['collection']}</h2>
        </Link>
      );
    } else {
      return null;
    }
  }

  renderDescription() {
    if (this.state.tokenData && this.state.tokenData['description']) {
      return <h3>{this.state.tokenData['description']}</h3>;
    } else if (
      this.state.collectionData &&
      this.state.collectionData['name'] &&
      this.state.collectionData['name'] === '$AINT$' &&
      this.state.exceptions
    ) {
      return (
        <>
          <h3>
            {
              this.state.exceptions['exceptionsByContract'][
                'SP207ESW7AKHTPRYAAD9QP8Q1TE1F57D2S8RGPJCC.saints'
              ]['description']
            }
          </h3>
        </>
      );
    } else if (this.state.collectionData && this.state.collectionData['description']) {
      return <h3>{this.state.collectionData['description']}</h3>;
    } else if (this.state.tokenData && this.state.tokenData['description']) {
      return <h3>{this.state.tokenData['description']}</h3>;
    } else {
      return null;
    }
  }

  renderLiveIndicator() {
    if (this.auctionActive()) {
      return <div className={styles.ListedBadge}>LIVE</div>;
    } else {
      return null;
    }
  }

  render() {
    return (
      <ContainerFull>
        {this.state.tokenData && (
          <div className={styles.individualNftWrapper}>
            <div className={styles.imgWrapper}>
              {this.renderLiveIndicator()}
              {renderer(this.state.tokenData, 'largeImage', 1200)}
            </div>
            <div className={styles.metaWrapper}>
              {this.renderTitle()}
              {this.renderCollection()}
              {this.renderDescription()}
              <Row css={{ marginBottom: 30 }}>
                <TextLink
                  href={`https://stacks.gamma.io/collections/${this.state.contractAddress}.${this.state.contractName}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Visit marketplace
                  {/* <Button
                    text="Visit marketplace"
                    // price={this.props.bid}
                    style={'PRIMARY'}
                    // onClick={this.handleEndAuction.bind(this)}
                  /> */}
                </TextLink>
                {this.state.websiteUrl != '' &&
                  this.state.websiteUrl != undefined &&
                  !this.state.websiteUrl.includes('stxnft.com') && (
                    <TextLink
                      href={this.state.websiteUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {this.state.websiteUrl}
                    </TextLink>
                  )}
              </Row>
              {/* {this.state.websiteUrl != '' &&
                this.state.websiteUrl != undefined &&
                !this.state.websiteUrl.includes('stxnft.com') && (
                  <div className={styles.detailsWrapper}>
                    <TextLink
                      href={this.state.websiteUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {this.state.websiteUrl}
                    </TextLink>
                  </div>
                )} */}
              {/* <div className={styles.buttonWrapper}>
                <TextLink
                  href={`https://stacks.gamma.io/collections/${this.state.contractAddress}.${this.state.contractName}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Visit marketplace
                </TextLink>
              </div> */}
              <div className={styles.details}>{this.renderDetails()}</div>
            </div>
          </div>
        )}

        {this.state.showSellModal && (
          <Modal onClose={this.closeSellModal.bind(this)}>
            <ListAuctionModal
              tokenId={Number(this.state.tokenId)}
              onClose={this.handleListAuction.bind(this)}
              title={`Auction Details for Token #${this.state.tokenId}`}
            >
              <div className={styles.ListAuctionInput}>
                <div className={styles.ListAuctionLabel}>
                  Enter block length (10 minutes per block)
                </div>
                <TextInput
                  placeholder={'Block length 144 = 24 hrs'}
                  error={this.state.blockLengthError}
                  value={this.state.listingBlockLength || ''}
                  onChange={(e) => this.handleBlockLength(e)}
                />
              </div>
              <div className={styles.ListAuctionInput}>
                <div className={styles.ListAuctionLabel}>Enter reserve price in STX</div>
                <TextInput
                  placeholder={'Minimum bid amount'}
                  error={this.state.reservePriceError}
                  value={this.state.listingReservePrice || ''}
                  onChange={(e) => this.handleReservePrice(e)}
                />
              </div>
              {/* <div className={styles.ListAuctionInput}>
                <div className={styles.ListAuctionLabel}>
                  Enter auction type
                </div>
                <TextInput
                  placeholder={"auction-type"}
                  // error={this.state.domainError}
                  value={this.state.listingType || ""}
                  onChange={(e) => this.handleAuctionType(e)}
                />
              </div> */}
            </ListAuctionModal>
          </Modal>
        )}

        {this.state.modal && (
          <Modal onClose={this.modalClose.bind(this)}>
            <Confirm
              success={this.state.modal['success']}
              transactionId={this.state.modal['successTransaction']}
              errorMessage={this.state.modal['errorMessage']}
              onClose={this.modalClose.bind(this)}
            />
          </Modal>
        )}

        {this.state.showBidModal && (
          <Modal onClose={this.closeBidModal.bind(this)}>
            <ListAuctionModal
              tokenId={Number(this.state.tokenId)}
              onClose={this.handlePlaceBid.bind(this)}
              title={`Auction Details for Token #${this.state.tokenId}`}
              buttonText={'Place Bid'}
            >
              <div className={styles.ListAuctionInput}>
                <div className={styles.ListAuctionLabel}>Enter your bid amount in STX</div>
                <TextInput
                  placeholder={'bid amount'}
                  error={this.state.bidError}
                  value={this.state.bidAmount || ''}
                  onChange={(e) => this.handleBidAmount(e)}
                />
              </div>
            </ListAuctionModal>
          </Modal>
        )}
      </ContainerFull>
    );
  }
}

export default Auction;
