import { useEffect, useState } from 'react';
import {
  CreateCardGrid,
  ContainerFull,
  FunctionName,
  H1,
  TextLink,
  Wrapper,
  CardGrid,
  H2,
  Bullet,
  Step,
  QuickTip,
  Bullets,
  StepTitle,
  StepSubtitle,
  CollectionFlex,
  FlexColumn,
  QuickTipClear,
  Underline,
  ContainerItem,
  H4,
  H40,
  H3,
  Bottom,
  FinePrint,
  Centered,
  FlexWrapper,
  EmptyDiv,
  ContainerSection,
} from '../../../components/common';
import { currentUserAddressAtom, useResetAtoms } from '../../../components/atoms';
import { Link, useHistory } from 'react-router-dom';
import { CreateCard, EmptyCard, HoverIcon } from '../../../components/cards';
import { PlusCircleIcon, CopyIcon, GridIcon, InfoIcon, CheckCircleIcon } from '../../../utils/svgs';
import { AppConfig, UserSession } from '@stacks/connect';
import { ClockIcon } from '@radix-ui/react-icons';
import Footer from '../../../components/Studio/footer';
import {
  BlogPillButton,
  CreateButton,
  EthConnectButton,
  HelpButton,
} from '../../../components/buttons';
import { ToggleBlockchain } from '../../../components/toggle-group';
import { Label } from '../../../components/label';
import { adminAddresses, ethAdopters } from '../../../components/Common/AdminRoute';
import { useAtom } from 'jotai';
import { ConnectButton, useConnectModal } from '@rainbow-me/rainbowkit';
import { useAccount } from 'wagmi';
import { relative } from 'path';

const appConfig = new AppConfig(['store_write', 'publish_data']);
const userSession = new UserSession({ appConfig });

const optionData: any[] = [
  {
    type: 'Continuous',
    bullets1: [
      "NFTs are minted directly to the creator's address upon creation",
      'The creator can auction or list NFTs for sale on the marketplace; each can have a different price',
      'Collectors can list NFTs on the marketplace, where creators receive royalties with each sale',
    ],
    bullets2: [
      'Buyers do not mint NFTs; creators list them on the marketplace or put them up for auction',
      'Artwork will always be visible before purchase, so there will be no element of surprise',
      'Sale prices and NFT sales cannot be configured in mass, which can be time-consuming',
    ],
    hoverText: [
      'If you want buyers to mint the NFTS, this is supported by both Public Mint and Editions collections',
      "If you'd prefer to have the artwork visible only after purchase, you can select either the Public Mint and Editions option",
      "If you'd like the ability to configure sale prices and NFT sales in mass, this is supported by both Public Mint and Editions collections",
    ],
    path: '/create/continuous',
  },
  {
    type: 'Public Mint',
    bullets1: [
      'Individual buyers will mint NFTs from a public mint page without first seeing the artwork',
      "Once the NFT is purchased, the artwork will be revealed on the collector's profile",
      'Collectors can list NFTs on the marketplace, where creators receive royalties with each sale',
    ],
    bullets2: [
      'You cannot add more NFTs to this collection type over time',
      "Buyers will not know what the collection's NFTs look like until they are minted",
      "NFTs don't exist until they are minted, so you won't be able to find them before purchase",
    ],
    hoverText: [
      'If you want to add more NFTs to your collection over time, this is supported by Continuous collections',
      "If you'd prefer buyers know what the NFTs look like before buying, you can create a Continous collection instead",
      'If you want to be able to discover NFTs on the marketplace before its first purchase, this is supported by Continuous collections',
    ],
    path: '/create/public',
  },
  {
    type: 'Editions',
    bullets1: [
      'Individual buyers will mint NFTs from a public mint page',
      'Each collector will receive a token that looks and functions the same; it will appear identical',
      'Collectors can list NFTs on the marketplace, where creators receive royalties with each sale',
    ],
    bullets2: [
      'You cannot add more NFTs to this collection type over time',
      'Individual NFTs cannot have their own artwork or descriptive metadata',
      "NFTs don't exist until they are minted, so you won't be able to find them before purchase",
    ],
    hoverText: [
      'If you want to add more NFTs to your collection over time, this is supported by Continuous collections',
      'If you want each NFT to be unique, this is supported by both Continous and Public Mint collections',
      'If you want to be able to discover NFTs on the marketplace before its first purchase, this is supported by Continuous collections',
    ],
    path: '/create/editions',
  },
  {
    type: 'Public Mint (ETH)',
    bullets1: [
      'Individual buyers will mint NFTs from a public mint page without first seeing the artwork',
      "Once the NFT is purchased, the artwork will be revealed on the collector's profile",
      'Collectors can list NFTs on the marketplace, where creators receive royalties with each sale',
    ],
    bullets2: [
      'You cannot add more NFTs to this collection type over time',
      "Buyers will not know what the collection's NFTs look like until they are minted",
      "NFTs don't exist until they are minted, so you won't be able to find them before purchase",
    ],
    hoverText: [
      'If you want to add more NFTs to your collection over time, this is supported by Continuous collections',
      "If you'd prefer buyers know what the NFTs look like before buying, you can create a Continous collection instead",
      'If you want to be able to discover NFTs on the marketplace before its first purchase, this is supported by Continuous collections',
    ],
    path: '/create/public-eth',
  },
  {
    type: 'Editions (ETH)',
    bullets1: [
      'Individual buyers will mint NFTs from a public mint page',
      'Each collector will receive a token that looks and functions the same; it will appear identical',
      'Collectors can list NFTs on the marketplace, where creators receive royalties with each sale',
    ],
    bullets2: [
      'You cannot add more NFTs to this collection type over time',
      'Individual NFTs cannot have their own artwork or descriptive metadata',
      "NFTs don't exist until they are minted, so you won't be able to find them before purchase",
    ],
    hoverText: [
      'If you want to add more NFTs to your collection over time, this is supported by Continuous collections',
      'If you want each NFT to be unique, this is supported by both Continous and Public Mint collections',
      'If you want to be able to discover NFTs on the marketplace before its first purchase, this is supported by Continuous collections',
    ],
    path: '/create/editions-eth',
  },
];

function Create() {
  const [currentUserAddress, setCurrentUserAddress] = useAtom(currentUserAddressAtom);
  const [selectedOption, setSelectedOption] = useState(0);
  const [blockchain, setBlockchain] = useState('stacks');
  const { isConnected } = useAccount();

  useEffect(() => {
    goToTop();
  }, []);

  function goToTop() {
    window.scrollTo(0, 0);
    setSelectedOption(0);
  }

  function handleBlockchain(value: string) {
    if (!value) {
      return;
    }
    setBlockchain(value);
  }

  return (
    <>
      <ContainerFull>
        <H1>Create a new collection</H1>
        {userSession.isUserSignedIn() ? (
          <FlexColumn>
            <Step>
              <StepTitle>Select your smart contract type</StepTitle>
              <StepSubtitle>
                Smart contracts are powered by Stacks, a programming layer that settles onto
                Bitcoin.
              </StepSubtitle>
              <StepSubtitle>
                To inscribe a single native Bitcoin ordinal NFT, go{' '}
                <a
                  style={{ textDecoration: 'underline', color: 'gray', textUnderlineOffset: '3px' }}
                  href="https://gamma.io/ordinals"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  here
                </a>
                .
              </StepSubtitle>
            </Step>

            <BlogPillButton
              text="View our Creator Help Videos"
              onClick={() => window.open('https://vimeo.com/showcase/9800165', '_blank')}
            />

            {adminAddresses.includes(currentUserAddress) ||
            ethAdopters.includes(currentUserAddress) ? (
              <ContainerItem>
                {isConnected ? (
                  <>
                    <h3>Which blockchain do you want to deploy on?</h3>
                    <ToggleBlockchain onChange={(value: string) => handleBlockchain(value)} />
                  </>
                ) : (
                  <>
                    <h3>Connect your ethereum wallet for more options.</h3>
                    <EthConnectButton
                      style={{ width: 180, height: 30, fontSize: 20, borderRadius: 12 }}
                    />
                  </>
                )}
              </ContainerItem>
            ) : null}

            {blockchain === 'stacks' ? (
              <CreateCardGrid>
                <CreateCard onClick={() => setSelectedOption(1)} selected={selectedOption === 1}>
                  <H3>
                    <Bullet>{PlusCircleIcon({})}Continuous</Bullet>
                  </H3>
                  <h4 style={{ marginBottom: '10px' }}>Best for</h4>
                  <div>Auctions, 1/1 artworks and collectibles, works released over time</div>
                </CreateCard>
                <CreateCard onClick={() => setSelectedOption(2)} selected={selectedOption === 2}>
                  <H3>
                    <Bullet>{GridIcon({})} Public Mint</Bullet>
                  </H3>
                  <h4 style={{ marginBottom: '10px' }}>Best for</h4>
                  <div>
                    Large primary mints, surprise artwork, profile picture-style collections
                  </div>
                </CreateCard>
                <CreateCard onClick={() => setSelectedOption(3)} selected={selectedOption === 3}>
                  <H3>
                    <Bullet>{CopyIcon({})} Editions</Bullet>
                  </H3>
                  <h4 style={{ marginBottom: '10px' }}>Best for</h4>
                  <div>Proof of attendance tokens, NFT tickets, software license NFTs</div>
                </CreateCard>
              </CreateCardGrid>
            ) : (
              <CreateCardGrid>
                <CreateCard onClick={() => setSelectedOption(4)} selected={selectedOption === 4}>
                  <H3>
                    <Bullet>{GridIcon({})} Public Mint (ETH)</Bullet>
                  </H3>
                  <h4 style={{ marginBottom: '10px' }}>Best for</h4>
                  <div>
                    Large primary mints, surprise artwork, profile picture-style collections
                  </div>
                </CreateCard>
                <CreateCard onClick={() => setSelectedOption(5)} selected={selectedOption === 5}>
                  <H3>
                    <Bullet>{CopyIcon({})} Editions (ETH)</Bullet>
                  </H3>
                  <h4 style={{ marginBottom: '10px' }}>Best for</h4>
                  <div>Proof of attendance tokens, NFT tickets, software license NFTs</div>
                </CreateCard>
              </CreateCardGrid>
            )}

            {selectedOption > 0 ? (
              <>
                <Step>
                  <StepTitle>How {optionData[selectedOption - 1].type} contracts work</StepTitle>
                  <QuickTipClear>
                    <Bullets>
                      <Bullet>
                        <CheckCircleIcon /> {optionData[selectedOption - 1].bullets1[0]}
                      </Bullet>
                      <Bullet>
                        <CheckCircleIcon /> {optionData[selectedOption - 1].bullets1[1]}
                      </Bullet>
                      <Bullet>
                        <CheckCircleIcon />
                        {optionData[selectedOption - 1].bullets1[2]}
                      </Bullet>
                    </Bullets>
                  </QuickTipClear>
                </Step>

                <Step>
                  <StepTitle>
                    Important mentions about {optionData[selectedOption - 1].type} contracts
                  </StepTitle>
                  <QuickTip>
                    <Bullets>
                      <Bullet>
                        <li>{optionData[selectedOption - 1].bullets2[0]}</li>
                        <HoverIcon text={optionData[selectedOption - 1].hoverText[0]} />
                      </Bullet>
                      <Bullet>
                        <li>{optionData[selectedOption - 1].bullets2[1]}</li>
                        <HoverIcon text={optionData[selectedOption - 1].hoverText[1]} />
                      </Bullet>
                      <Bullet>
                        <li>{optionData[selectedOption - 1].bullets2[2]}</li>
                        <HoverIcon text={optionData[selectedOption - 1].hoverText[2]} />
                      </Bullet>
                    </Bullets>
                  </QuickTip>
                </Step>

                <Bottom>
                  <Centered>
                    <H1>Ready to continue?</H1>
                    <ContainerItem>
                      <StepSubtitle>Your selection is final once you continue.</StepSubtitle>
                    </ContainerItem>
                    <StepSubtitle>
                      You've selected <strong>{optionData[selectedOption - 1].type}</strong>.
                    </StepSubtitle>
                  </Centered>

                  <Centered style={{ display: 'flex', gap: '20px' }}>
                    <Link to={optionData[selectedOption - 1].path}>
                      <CreateButton text="Continue" />
                    </Link>
                    <div onClick={() => goToTop()}>
                      <HelpButton text="Change selection" />
                    </div>
                  </Centered>

                  <FinePrint>
                    By clicking to continue, you agree to our{' '}
                    <Link to="/terms/creator-terms">
                      <Underline>Creator Terms</Underline>
                    </Link>
                  </FinePrint>
                </Bottom>
              </>
            ) : null}
          </FlexColumn>
        ) : (
          <div>Connect your wallet to continue.</div>
        )}
      </ContainerFull>
      {selectedOption > 0 ? <Footer /> : <ContainerSection></ContainerSection>}
    </>
  );
}

export default Create;
