const makeEndpoint = (uri: string) => {
  const env = process.env.NODE_ENV;
  if (env == "development") {
    return "http://localhost:8443" + uri;
  } else {
    return uri;
  }
};

export default makeEndpoint;
