import React, { Component } from "react";
import { Helmet } from "react-helmet";
import styles from "./index.module.scss";

import { darkmodeListener } from "../../theme";
import { gaSend } from "../../Analytics";

import makeEndpoint from "../../endpoints";

import Button from "../../components/Button";
import Card from "../../components/Card";
import Loading from "../../components/Loading";
import Renderer from "../../components/Renderer";

import icon from "../../components/Login/assets/icon.svg";

import {
  claim,
  getMintpassEnabled,
  getMintpasses,
  getPrice,
  getPublicSaleEnabled,
  getMintLimit,
  network,
  getAltPrice,
  getPaused,
  getSTXInBTC,
  swapBTCforSTX,
  ftClaim,
} from "../../utils/SelfServe/contracts";

import { AppConfig, UserSession, showConnect } from "@stacks/connect";

import InfiniteScroll from "react-infinite-scroll-component";

import { renderer } from "../../utils";
import Modal from "../../components/Modal";
import Confirm from "../../components/Confirm";
import UserTip from "../../components/UserTip";
import MintPriceIcon from "../../components/MintPriceIcon";

// import { getSTXInBTC, swapBTCforSTX } from "../../utils/swap";

const appConfig = new AppConfig(["store_write", "publish_data"]);
const userSession = new UserSession({ appConfig });

const axios = require("axios");

declare const lnswap: any;

type CollectionWidgetProps = {
  name: string;
  collectionData?: any;
};

type CollectionWidgetState = {
  name: string;
  contractAddress: string;
  contractName: string;
  description: string;
  image: string;
  total: number;
  lastTokenID: number;
  prices: number;
  currency?: string;
  saleData: Record<string, any>;
  sale: Array<any>;
  active: boolean;
  paused: boolean;
  userData?: any;
  offset: number;
  contractType: string;
  mintpassEnabled: boolean;
  saleEnabled: boolean;
  mintpassStateChecked: boolean;
  saleStateChecked: boolean;
  hasMintpasses: boolean;
  passesRemaining: number;
  collectionData: any;
  mintPriceInput: string;
  mintPriceError?: string;
  altPriceInputs: Array<any>;
  altPriceErrors: Array<any>;
  mintLimitInput: string;
  mintLimitError?: string;
  altCurrencies: Array<any>;
  modal?: any;
  showAdmin: boolean;
  currencySelected: string;
  numberSelected: number;
  claimOptions: Array<any>;
  airdropEnabled: boolean;
  fts: Array<any>;
  revealEnabled: boolean;
  revealData: any;
  websiteUrl: string;
  websiteText: string;
  showSafetyTip: boolean;
  showLightningTip: boolean;
  functionName: string;
  pairs?: any;
  lightningEnabled: boolean;
  artistAddress: string;
  btcAmount: number;
  alexCurrencies: Array<any>;
  showAlexTip: boolean;
};

const fetchSize = 25;
class CollectionWidget extends Component<
  CollectionWidgetProps,
  CollectionWidgetState
> {
  state = {
    name: "",
    contractAddress: "",
    artistAddress: "",
    contractName: "",
    description: "",
    image: "",
    total: 0,
    saleData: {},
    sale: [],
    lastTokenID: 0,
    prices: 0,
    currency: undefined,
    active: false,
    paused: false,
    userData: undefined,
    offset: 1,
    contractType: "",
    mintpassEnabled: false,
    saleEnabled: false,
    mintpassStateChecked: false,
    saleStateChecked: false,
    hasMintpasses: false,
    passesRemaining: 0,
    collectionData: undefined,
    mintPriceInput: "",
    mintPriceError: undefined,
    altPriceInputs: [],
    altPriceErrors: [],
    mintLimitInput: "",
    mintLimitError: undefined,
    altCurrencies: [],
    modal: undefined,
    showAdmin: false,
    currencySelected: "STX",
    numberSelected: 1,
    claimOptions: [],
    airdropEnabled: false,
    fts: [],
    revealEnabled: false,
    revealData: {},
    websiteUrl: "",
    websiteText: "",
    showSafetyTip: false,
    showLightningTip: false,
    functionName: "",
    pairs: undefined,
    lightningEnabled: false,
    btcAmount: -1,
    alexCurrencies: [] as any,
    showAlexTip: false,
  };

  async componentDidMount() {
    try {
      const userData = localStorage.getItem("userData");
      if (userData) {
        this.setState({
          userData: JSON.parse(userData),
        });
      }
    } catch {}

    darkmodeListener(styles["theme-light"], styles["theme-dark"]);

    fetch(makeEndpoint("/api/drop?name=" + this.props.name))
      .then((res) => {
        return res.json();
      })
      .then(
        (artistData) => {
          this.setState({
            name: artistData.name,
            contractAddress: artistData.contractAddress,
            contractName: artistData.contractName,
            description: artistData.description,
            image: artistData.image,
            total: artistData.total,
            prices: artistData.prices,
            active: artistData.active,
            paused: artistData.paused,
            currency: artistData.currency,
            contractType: artistData.contractType,
            collectionData: artistData,
            saleEnabled: artistData.saleEnabled,
            altCurrencies: artistData.altCurrencies,
            claimOptions: artistData.claimOptions,
            airdropEnabled: artistData.airdropEnabled,
            revealEnabled: artistData.revealEnabled,
            websiteUrl: artistData.websiteUrl,
            websiteText: artistData.websiteText,
            lightningEnabled: artistData.lightningEnabled,
            artistAddress: artistData.artistAddress,
            alexCurrencies: artistData.alexCurrencies,
          });
        },
        () => {
          if (Number(this.state.prices) >= 50000000) {
            const amount = Number(this.amount());
            if (isNaN(amount)) {
              return;
            }

            getSTXInBTC(amount / 1e6).then((res) =>
              this.setState({ btcAmount: res * 1.04 })
            );
          }

          if (
            this.state.contractType == "mintpass" &&
            this.stxAddress() != null &&
            !this.state.saleEnabled
          ) {
            getMintpassEnabled(
              this.state.contractAddress,
              this.state.contractName,
              this.setMintpassEnabled.bind(this)
            );
            getPublicSaleEnabled(
              this.state.contractAddress,
              this.state.contractName,
              this.setPublicSaleEnabled.bind(this)
            );
            getMintpasses(
              this.state.contractAddress,
              this.state.contractName,
              this.stxAddress(),
              this.setMintpasses.bind(this)
            );
          }

          getPrice(
            this.state.contractAddress,
            this.state.contractName,
            this.setPrice.bind(this)
          );

          getMintLimit(
            this.state.contractAddress,
            this.state.contractName,
            this.setTotal.bind(this)
          );

          getPaused(
            this.state.contractAddress,
            this.state.contractName,
            this.setPaused.bind(this)
          );

          var contractName = this.state.contractName.replace(
            "panda-mint",
            "panda-nft"
          );
          contractName = contractName.replace("htl-mint", "htl");
          contractName = contractName.replace("commoners-mint", "commoners");
          contractName = contractName.replace(
            "trubit-domino-mint",
            "trubit-domino"
          );
          contractName = contractName.replace(
            "stacks-parrots-3d-mint",
            "stacks-parrots-3d"
          );
          fetch(
            makeEndpoint(
              "/api/nft/lastTokenID?address=" +
                this.state.contractAddress +
                "&name=" +
                contractName +
                "&reload=true"
            )
          );
          fetch(
            makeEndpoint(
              "/api/nft/lastTokenID?address=" +
                this.state.contractAddress +
                "&name=" +
                contractName
            )
          )
            .then((res) => {
              return res.text();
            })
            .then((lastTokenStr) => {
              if (lastTokenStr === "null") {
                return;
              }
              const lastToken = parseInt(lastTokenStr, 10);
              this.setState(
                {
                  lastTokenID: lastToken,
                },
                () => {
                  this.fetchNFTs();
                }
              );
            });
        }
      );

    fetch(makeEndpoint("/api/fts"))
      .then((res) => {
        return res.json();
      })
      .then((json) => {
        this.setState({ fts: json.tokens });
      })
      .catch((err) => console.log(err));
  }

  fetchNFTs() {
    if (this.state.contractName.includes("panda-mint")) {
      return;
    }
    var nfts: Array<any> = [];
    var numNFTs = Math.min(this.state.lastTokenID, fetchSize);
    if (this.state.lastTokenID < this.state.offset + numNFTs) {
      const diff = this.state.offset + numNFTs - this.state.lastTokenID;
      numNFTs -= diff;
    }

    var contractName = this.state.contractName.replace(
      "panda-mint",
      "panda-nft"
    );
    contractName = contractName.replace("htl-mint", "htl");
    contractName = contractName.replace("commoners-mint", "commoners");
    contractName = contractName.replace("trubit-domino-mint", "trubit-domino");
    contractName = contractName.replace(
      "stacks-parrots-3d-mint",
      "stacks-parrots-3d"
    );
    for (var x = this.state.offset; x <= this.state.offset + numNFTs; x++) {
      nfts.push({
        contractAddress: this.state.contractAddress,
        tokenID: x,
        contractName: contractName,
        sold: true,
      });
    }

    var sale: Array<any> = this.state.sale;
    const filtered_nfts = nfts.filter((r: any) => {
      let filtered_list = sale.filter(
        (l: any) =>
          l.contractAddress == r.contractAddress &&
          l.contractName == r.contractName &&
          String(l.tokenID) == String(r.tokenID)
      );
      return filtered_list.length == 0;
    });

    sale.push(...filtered_nfts);
    this.setState(
      {
        sale: sale,
      },
      () => {
        this.fetchData(nfts);
      }
    );
  }

  getLength() {
    return this.state.sale.length;
  }

  fetchData(nfts: any) {
    for (var nft of nfts) {
      var contractName = nft.contractName.replace("panda-mint", "panda-nft");
      contractName = contractName.replace("htl-mint", "htl");
      contractName = contractName.replace("commoners-mint", "commoners");
      var params = "";
      params += "address=" + nft.contractAddress;
      params += "&name=" + contractName;
      params += "&id=" + nft.tokenID;
      const nftId =
        nft.contractAddress + "." + contractName + ":" + nft.tokenID;
      var saleDataRecord: any = this.state.saleData;
      if (saleDataRecord[nftId]) {
        continue;
      }
      fetch("https://legacy.stxnft.com/api/nft?" + params)
        .then((res) => res.json())
        .then((nftData) => {
          if (nftData.name) {
            var saleData = saleDataRecord;
            saleData[nftId] = nftData;
            this.setState({ saleData });
          }
        });
    }
  }

  getTotal() {
    return this.state.total;
  }

  getAvailable() {
    return this.getTotal() - this.state.lastTokenID;
  }

  getVolumeSold() {
    return this.state.lastTokenID * this.getFloorPrice();
  }

  getFloorPrice() {
    if (!this.state.currency) {
      return this.state.prices / 1e6;
    } else {
      return this.state.prices;
    }
  }

  nFormatter(num: number, digits: number) {
    const lookup = [
      { value: 1, symbol: "" },
      { value: 1e3, symbol: "K" },
      { value: 1e6, symbol: "M" },
      { value: 1e9, symbol: "B" },
    ];
    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    var item = lookup
      .slice()
      .reverse()
      .find(function (item) {
        return num >= item.value;
      });
    return item
      ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol
      : "0";
  }

  amount() {
    let price = this.state.prices;
    if (
      this.state.altCurrencies != null &&
      this.state.altCurrencies.length > 0 &&
      this.state.currencySelected != "STX"
    ) {
      for (let i = 0; i < this.state.altCurrencies.length; i++) {
        const currency = this.state.altCurrencies[i];
        if (currency["symbol"] == this.state.currencySelected) {
          price = currency["price"];
          break;
        }
      }
    }
    return price * this.state.numberSelected;
  }

  mintText() {
    let mintText;
    if (this.state.active && this.state.paused) {
      mintText = "MINTING PAUSED";
    } else if (!this.state.active) {
      mintText = "PENDING REVIEW";
    } else if (
      Math.floor(this.state.lastTokenID) >= Math.floor(this.state.total)
    ) {
      mintText = "VISIT MARKETPLACE";
    } else if (
      this.state.contractType == "mintpass" &&
      !this.state.mintpassEnabled &&
      !this.state.saleEnabled &&
      this.state.userData
    ) {
      mintText = "MINTING CLOSED";
    } else if (
      this.state.contractType == "mintpass" &&
      this.state.mintpassEnabled &&
      !this.state.saleEnabled &&
      !this.state.hasMintpasses &&
      this.state.userData
    ) {
      mintText = "MINTPASS ONLY";
    } else if (this.state.userData) {
      this.state.numberSelected > 1
        ? (mintText = "MINT " + this.state.numberSelected)
        : (mintText = "MINT");
    } else {
      mintText = "CONNECT WALLET TO MINT";
    }
    return mintText;
  }

  renderPrice() {
    return (
      !(
        (this.state.active && this.state.paused) ||
        !this.state.active ||
        Math.floor(this.state.lastTokenID) >= Math.floor(this.state.total) ||
        (this.state.contractType == "mintpass" &&
          !this.state.mintpassEnabled &&
          !this.state.saleEnabled) ||
        (this.state.contractType == "mintpass" &&
          this.state.mintpassEnabled &&
          !this.state.hasMintpasses)
      ) && this.state.userData
    );
  }

  stxAddress() {
    if (this.state.userData && network.isMainnet()) {
      const userData: any = this.state.userData;
      return userData.profile.stxAddress.mainnet;
    } else if (this.state.userData && !network.isMainnet()) {
      const userData: any = this.state.userData;
      return userData.profile.stxAddress.testnet;
    } else {
      return null;
    }
  }

  authenticate() {
    showConnect({
      appDetails: {
        name: "Stacks NFT",
        icon: icon,
      },
      redirectTo: "/",
      onFinish: () => {
        gaSend("User", "authenticate", "User Authenticated");
        let userData = userSession.loadUserData();
        this.setState({ userData: userData });
        localStorage.setItem("userData", JSON.stringify(userData));
        window.location.reload();
      },
      userSession: userSession,
    });
  }

  openClaim() {
    if (this.state.userData) {
      claim(
        this.state.contractAddress,
        this.state.contractName,
        this.amount(),
        this.state.numberSelected,
        this.state.currencySelected,
        this.stxAddress(),
        this.setModal.bind(this),
        this.state.functionName,
        this.state.fts
      );
    } else {
      this.authenticate();
    }
  }

  setTotal(total: number) {
    if (this.state.total != total) {
      console.log("total different");
      this.setState({
        total: total,
      });
      this.syncCollectionData(true);
    }
  }

  setPrice(price: number) {
    if (this.state.prices != price) {
      console.log("price different");
      this.setState({
        prices: price,
      });
      this.syncCollectionData(true);
    }
  }

  setPaused(paused: boolean) {
    if (this.state.paused != paused) {
      console.log("paused different");
      this.setState({
        paused: paused,
      });
      this.syncCollectionData(true);
    }
  }

  syncCollectionData(isAuto: boolean) {
    let data: any = this.state.collectionData;
    if (data) {
      data["prices"] = this.state.prices;
      data["total"] = this.state.total;
      data["paused"] = this.state.paused;
      data["saleEnabled"] = this.state.saleEnabled;
      data["altCurrencies"] = this.state.altCurrencies;
    }
  }

  setMintpassEnabled(enabled: boolean) {
    this.setState({
      mintpassEnabled: enabled,
      mintpassStateChecked: true,
    });
  }

  setPublicSaleEnabled(enabled: boolean) {
    if (enabled && !this.state.saleEnabled) {
      this.setState({
        saleEnabled: enabled,
        saleStateChecked: true,
      });
      this.syncCollectionData(true);
    } else {
      this.setState({
        saleEnabled: enabled,
        saleStateChecked: true,
      });
    }
  }

  setMintpasses(passes: any) {
    console.log(`${this.stxAddress()} has ${passes} mintpasses`);

    this.setState({
      hasMintpasses: passes > 0,
      passesRemaining: passes,
    });
  }

  mintButton() {
    if (
      this.amount() >= 0 &&
      this.renderPrice() &&
      this.state.contractName == "citybulls-nft"
    ) {
      return (
        <div className={styles.MintButton}>
          <Button
            text={this.mintText()}
            price={3000}
            currency="MIA"
            onClick={this.mintingBehavior.bind(
              this,
              this.state.numberSelected,
              "MIA",
              3000
            )}
          />
        </div>
      );
    }

    if (this.amount() >= 0 && this.renderPrice()) {
      return (
        <div className={styles.MintButton}>
          <Button
            text={this.mintText()}
            price={this.amount()}
            currency={this.state.currencySelected}
            onClick={this.mintingBehavior.bind(
              this,
              this.state.numberSelected,
              this.state.currencySelected,
              this.amount()
            )}
          />
        </div>
      );
    } else {
      return (
        <div className={styles.MintButton}>
          <Button
            text={this.mintText()}
            onClick={this.mintingBehavior.bind(
              this,
              this.state.numberSelected,
              this.state.currencySelected,
              this.amount()
            )}
          />
        </div>
      );
    }
  }

  mintingBehavior(claimNumber: number, symbol: string, price: number) {
    if (!this.state.contractAddress || !this.state.contractName) {
      return;
    }

    // send to marketplace if sold out
    if (Math.floor(this.state.lastTokenID) >= Math.floor(this.state.total)) {
      window.location.href = `https://stxnft.com/collections/${this.state.contractAddress}.${this.state.contractName}/`;
      return;
    }

    // minting only disabled when paused if not contract owner
    if (this.state.paused && this.stxAddress() != this.state.contractAddress) {
      return;
    }

    // admin override
    if (!this.state.active) {
      return;
    }

    // both mintpass and sale have not been enabled yet mintpass contract
    if (
      this.state.contractType == "mintpass" &&
      !this.state.mintpassEnabled &&
      !this.state.saleEnabled &&
      this.state.userData
    ) {
      return;
    }

    // mintpass only enabled and user doesn't have any mintpasses
    if (
      this.state.contractType == "mintpass" &&
      this.state.mintpassEnabled &&
      !this.state.saleEnabled &&
      !this.state.hasMintpasses &&
      this.state.userData
    ) {
      return;
    }

    let functionName: string = "claim";

    if (this.state.claimOptions != null && this.state.claimOptions.length > 0) {
      let options: Array<object> = this.state.claimOptions;
      const isOption = (element: any) =>
        element["amount"] == this.state.numberSelected;
      const optionIndex = options.findIndex(isOption);
      if (optionIndex != -1) {
        const option: any = options[optionIndex];
        functionName = option[this.state.currencySelected];
      }
    } else if (symbol != "STX") {
      functionName = functionName + "-" + symbol.toLowerCase();
    }

    this.setState({ functionName: functionName });

    this.openClaim();
  }

  nextBlocks(offset: number) {
    this.setState(
      {
        offset: offset,
      },
      () => {
        this.fetchNFTs();
      }
    );
  }

  getBlocksNext() {
    this.nextBlocks(this.getLength());
  }

  hasMore() {
    return this.state.lastTokenID > this.state.offset;
  }

  setModal(modal: any) {
    this.setState({
      modal: modal,
    });
  }

  modalClose() {
    this.setState({
      modal: undefined,
    });
  }

  setCurrencySelected(currency: string) {
    this.setState({
      currencySelected: currency,
    });

    if (currency == "xBTC") {
      this.setState({
        numberSelected: 1,
      });
    }
  }

  setNumberSelected(num: number) {
    this.setState({
      numberSelected: num,
    });
  }

  renderCurrencyButtons() {
    let altButtons: any = [];
    if (
      this.state.altCurrencies != null &&
      this.state.altCurrencies.length > 0
    ) {
      for (let i = 0; i < this.state.altCurrencies.length; i++) {
        const currency = this.state.altCurrencies[i];
        let symbol: string = currency["symbol"];
        if (symbol == "BTC") {
          symbol = "BTC (LN)";
        }
        const el = (
          <div style={{ margin: "10px" }} key={i + 1}>
            <Button
              text={symbol}
              style={
                this.state.currencySelected == currency["symbol"]
                  ? "PRIMARY"
                  : "SECONDARY"
              }
              onClick={this.setCurrencySelected.bind(this, currency["symbol"])}
            />
          </div>
        );
        altButtons.push(el);
      }
    }

    let alexButtons: any = [];

    if (this.state.prices >= 50000000) {
      let currency = "xBTC";
      let symbol = "xBTC (with ALEX)";
      const el = (
        <div style={{ margin: "10px" }}>
          <Button
            text={symbol}
            style={
              this.state.currencySelected == currency ? "PRIMARY" : "SECONDARY"
            }
            onClick={this.setCurrencySelected.bind(this, currency)}
          />
        </div>
      );
      alexButtons.push(el);
    }

    if (altButtons.length > 0 || alexButtons.length > 0) {
      let buttons: any = [];

      let stx = (
        <div style={{ margin: "10px" }} key={0}>
          <Button
            text="STX"
            style={
              this.state.currencySelected == "STX" ? "PRIMARY" : "SECONDARY"
            }
            onClick={this.setCurrencySelected.bind(this, "STX")}
          />
        </div>
      );

      buttons.push(stx);
      buttons.push(altButtons);
      buttons.push(alexButtons);
      return <div style={{ display: "flex" }}>{buttons}</div>;
    } else {
      return null;
    }
  }

  renderNumberButtons() {
    if (
      this.state.claimOptions == null ||
      (this.state.claimOptions != null && this.state.claimOptions.length == 1)
    ) {
      return;
    }

    if (
      this.state.currencySelected == "BTC" ||
      this.state.currencySelected == "xBTC"
      // (this.state.alexCurrencies != null &&
      //   this.state.alexCurrencies.includes(this.state.currencySelected))
    ) {
      return;
    }

    let buttons: any = [];
    for (let i = 0; i < this.state.claimOptions.length; i++) {
      const option: any = this.state.claimOptions[i];
      const el = (
        <div style={{ margin: "10px" }} key={i}>
          <Button
            text={option["amount"].toString()}
            style={
              this.state.numberSelected == option["amount"]
                ? "PRIMARY"
                : "SECONDARY"
            }
            onClick={this.setNumberSelected.bind(this, option["amount"])}
          />
        </div>
      );
      buttons.push(el);
    }
    return <div style={{ display: "flex" }}>{buttons}</div>;
  }

  render() {
    return (
      <div className={styles.Collections}>
        {this.state.active &&
          this.state.lastTokenID < this.state.total &&
          this.state.passesRemaining > 0 &&
          this.state.mintpassEnabled && (
            <div className={styles.MintpassBalanceContainer}>
              <div className={styles.MintpassBalance}>
                You have this.state.passesRemaining mintpasses remaining
              </div>
            </div>
          )}

        {this.state.active &&
        !this.state.paused &&
        this.state.lastTokenID <= this.state.total &&
        this.state.userData ? (
          <div className={styles.MintContent}>
            {/* {this.renderCurrencyButtons()} */}
            {/* {this.renderNumberButtons()} */}
            <div style={{ margin: "10px" }}></div>
            <div className={styles.MintButton}>{this.mintButton()}</div>
          </div>
        ) : (
          <div className={styles.MintContent}>
            <div className={styles.MintButton}>{this.mintButton()}</div>
          </div>
        )}

        {/* <div className={styles.MintPrice}>
          <div className={styles.MintPriceContainer}>
            <div>{this.getTotal().toLocaleString("us")}</div>
            <div className={styles.MintPriceTitle}>TOTAL</div>
          </div>
          <div className={styles.MintPriceContainer}>
            <div>{this.getAvailable().toLocaleString("us")}</div>
            <div className={styles.MintPriceTitle}>REMAINING</div>
          </div>
          <div className={styles.MintPriceContainer}>
            <div className={styles.MintPriceIconContainer}>
              {!this.state.currency && <MintPriceIcon />}
              <div>{this.getFloorPrice().toLocaleString("us")}</div>
              {this.state.currency == "MIA" && (
                <div className={styles.MintPriceText}>MIA</div>
              )}
            </div>
            <div className={styles.MintPriceTitle}>MINT PRICE</div>
          </div>
        </div> */}
      </div>
    );
  }
}

export default CollectionWidget;
