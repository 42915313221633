import { useAtom } from 'jotai';
import { ContinueButton } from '../../../../components/buttons';
import {
  airdropAtom,
  artistAddressAtom,
  assetsAtom,
  attributesAtom,
  categoryAtom,
  claimOptionsAtom,
  collectionNameAtom,
  contractIdAtom,
  currentUserAddressAtom,
  descriptionAtom,
  discordAtom,
  editionAtom,
  editionNameAtom,
  emailAddressAtom,
  filenamesEnabledAtom,
  fundsRecipientAtom,
  fungibleOptionsAtom,
  fungibleSelectedAtom,
  logoAtom,
  logoEnlargedAtom,
  merkleEntriesAtom,
  merkleRootAtom,
  mintingEnabledAtom,
  mintpassAtom,
  mintpassEndUnixAtom,
  mintpassStartUnixAtom,
  mintsPerAddressAtom,
  numberOfEditionsAtom,
  paymentOptionsAtom,
  placeholderAtom,
  priceAtom,
  publicStartUnixAtom,
  royaltyAtom,
  symbolAtom,
  twitterAtom,
  visibilityEnabledAtom,
  websiteAtom,
} from '../../../../components/atoms';
import { Container, ContainerItem, TextLink } from '../../../../components/common';
import { submitZoraEditionToIpfs } from '../../../../utils/ipfs';
import { useState } from 'react';
import { ethers, utils, BigNumber, providers } from 'ethers';
import { addAllowlist, handleEditionsEthCollection } from '../../../../utils/Studio/serverCalls';
import { useAccount, useContractWrite, useSigner, useNetwork, useProvider } from 'wagmi';
import { dealWithEther } from '../../../../utils';
import {
  ethDeployAddressGoerli,
  ethDeployAddressMainnet,
  etherscanUrlGeorli,
  etherscanUrlMainnet,
} from '../../../../utils/SelfServe/contracts';
import { getContractAddress } from '@ethersproject/address';
const ZoraNFTCreatorProxy_ABI = require('../../../../../node_modules/@zoralabs/nft-drop-contracts/dist/artifacts/ZoraNFTCreatorV1.sol/ZoraNFTCreatorV1.json');

function Publish(props: { onNextStep: any }) {
  const [collectionName, setCollectionName] = useAtom(collectionNameAtom);
  const [description, setDescription] = useAtom(descriptionAtom);
  const [emailAddress, setEmailAddress] = useAtom(emailAddressAtom);
  const [website, setWebsite] = useAtom(websiteAtom);
  const [twitter, setTwitter] = useAtom(twitterAtom);
  const [discord, setDiscord] = useAtom(discordAtom);
  const [category, setCategory] = useAtom(categoryAtom);
  const [artistAddress, setArtistAddress] = useAtom(artistAddressAtom);
  const [logo, setLogo] = useAtom(logoAtom);
  const [assets, setAssets] = useAtom(assetsAtom);
  const [paymentOptions, setPaymentOptions] = useAtom(paymentOptionsAtom);
  const [fungibleOptions, setFungibleOptions] = useAtom(fungibleOptionsAtom);
  const [fungibleSelected, setFungibleSelected] = useAtom(fungibleSelectedAtom);
  const [placeholder, setPlaceholder] = useAtom(placeholderAtom);
  const [attributes, setAttributes] = useAtom(attributesAtom);
  const [price, setPrice] = useAtom(priceAtom);
  const [claimOptions, setClaimOptions] = useAtom(claimOptionsAtom);
  const [mintpass, setMintpass] = useAtom(mintpassAtom);
  const [airdrop, setAirdrop] = useAtom(airdropAtom);
  const [mintingEnabled, setMintingEnabled] = useAtom(mintingEnabledAtom);
  const [mintsPerAddress, setMintsPerAddress] = useAtom(mintsPerAddressAtom);
  const [visibilityEnabled, setVisibilityEnabled] = useAtom(visibilityEnabledAtom);
  const [currentUserAddress, setCurrentUserAddress] = useAtom(currentUserAddressAtom);
  const [logoEnlarged, setLogoEnlarged] = useAtom(logoEnlargedAtom);
  const [filenamesEnabled, setFilenamesEnabled] = useAtom(filenamesEnabledAtom);
  const [edition, setEdition] = useAtom(editionAtom);
  const [numberOfEditions, setNumberOfEditions] = useAtom(numberOfEditionsAtom);
  const [contractId, setContractId] = useAtom(contractIdAtom);
  const [editionName, setEditionName] = useAtom(editionNameAtom);
  const [merkleRoot, setMerkleRoot] = useAtom(merkleRootAtom);
  const [merkleEntries, setMerkleEntries] = useAtom(merkleEntriesAtom);
  const [mintpassStartUnix, setMintpassStartUnix] = useAtom(mintpassStartUnixAtom);
  const [mintpassEndUnix, setMintpassEndUnix] = useAtom(mintpassEndUnixAtom);
  const [publicStartUnix, setPublicStartUnix] = useAtom(publicStartUnixAtom);
  const [fundsRecipient, setFundsRecipient] = useAtom(fundsRecipientAtom);
  const [royalty, setRoyalty] = useAtom(royaltyAtom);
  const [symbol, setSymbol] = useAtom(symbolAtom);
  const [progress, setProgress] = useState('');
  const [tx, setTx] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [editionInputs, setEditionInputs] = useState<any>(undefined);
  const { data: signer, isError: isSignerError, isLoading: isSignerLoading } = useSigner();
  const { address, isConnected } = useAccount();
  const network = useNetwork();
  const provider = useProvider();

  async function handlePublish() {
    setErrorMessage('');
    if (!signer || !address || !provider) {
      return;
    }
    let contractName = collectionName
      .replaceAll(' ', '-')
      .replaceAll('(', '')
      .replaceAll(')', '')
      .replace(/^-+|-+$/g, '')
      .replaceAll('---', '-')
      .toLowerCase();
    const ipfsData = {
      collectionName,
      logo,
      edition,
      numberOfEditions,
      description,
      price,
      attributes,
      editionName,
      mintpass,
      mintsPerAddress,
    };
    const result = await submitZoraEditionToIpfs(ipfsData, handleProgress, handleOnSubmitFinish);
    if (!result) {
      return;
    }
    const inputs = {
      contractName: collectionName,
      contractSymbol: symbol,
      contractAdmin: address,
      contractMaxSupply: numberOfEditions || '4294967295',
      secondaryRoyalties:
        royalty && !isNaN(parseInt(royalty)) ? (parseInt(royalty) * 100).toString() : '500',
      fundsRecipient: artistAddress || address,
      salesConfig: {
        priceEther: price,
        perWalletMintCap: mintsPerAddress || '4294967295',
        publicSaleStart: publicStartUnix, // makes it so edition will be live to start
        publicSaleEnd: '18446744073709551615', // makes it so edition will be live to start
        presaleStart: mintpassStartUnix.toString(),
        presaleEnd: mintpassEndUnix.toString(),
        presaleMerkleRoot:
          merkleRoot || '0x0000000000000000000000000000000000000000000000000000000000000000',
      },
      editionDescription: description,
      metadataAnimationURI: '',
      metadataImageURI: `ipfs://${result.editionHash}`,
    };
    console.log(inputs);
    const ethDeployAddress =
      network.chain?.id === 5 ? ethDeployAddressGoerli : ethDeployAddressMainnet;
    const contractInterface = ZoraNFTCreatorProxy_ABI.abi;
    const args = [
      inputs.contractName,
      inputs.contractSymbol,
      inputs.contractMaxSupply,
      inputs.secondaryRoyalties,
      inputs.fundsRecipient,
      inputs.contractAdmin,
      [
        dealWithEther(inputs.salesConfig.priceEther),
        inputs.salesConfig.perWalletMintCap,
        inputs.salesConfig.publicSaleStart,
        inputs.salesConfig.publicSaleEnd,
        inputs.salesConfig.presaleStart,
        inputs.salesConfig.presaleEnd,
        inputs.salesConfig.presaleMerkleRoot,
      ],
      inputs.editionDescription,
      inputs.metadataAnimationURI,
      inputs.metadataImageURI,
    ];

    // if (merkleRoot && merkleEntries && merkleEntries.length) {
    //   await addAllowlist({ entries: merkleEntries, root: merkleRoot });
    // }

    const method = 'createEdition';
    const value = BigNumber.from('0');
    const MINT_TX_CONFIRMATIONS = 2;
    try {
      const contract = new ethers.Contract(ethDeployAddress, contractInterface, signer);
      const gasEstimate = contract.connect(signer).estimateGas[method](...args, { value });
      const gasLimit = (await gasEstimate).mul(120).div(100);
      const txCount = await provider.getTransactionCount(ethDeployAddress);
      const contractAddress = getContractAddress({
        from: ethDeployAddress,
        nonce: txCount,
      });
      const tx = await contract.connect(signer)[method](...args, { gasLimit, value });
      setProgress('');
      setTx(tx.hash);
      handleEditionsEthCollection(
        {
          contractName,
          collectionName,
          description,
          emailAddress,
          website,
          twitter,
          discord,
          category,
          artistAddress: address,
          logo,
          assets,
          price,
          mintsPerAddress: mintsPerAddress || '0',
          currentUserAddress: address,
          logoEnlarged,
          txId: tx.hash,
          total: numberOfEditions || '0',
          contractAddress,
        },
        { jsonHash: result.jsonHash, logoHash: result.logoHash, editionHash: result.editionHash },
        `${network.chain?.id === 5 ? etherscanUrlGeorli : etherscanUrlMainnet}/tx/${tx.hash}`,
      );
      const res = await tx.wait(MINT_TX_CONFIRMATIONS);
    } catch (e: any) {
      console.error(e);
      if (e.code === 'ACTION_REJECTED') {
        setProgress('Contract deploy was cancelled by the user');
      } else if (e.code) {
        setProgress('Deploy cancelled with error code ' + e.code);
        setErrorMessage(e.message);
      } else {
        setProgress('Deploy was cancelled due to an unkown error');
      }
    }
  }

  function handleProgress(progress: string, hash?: string) {
    setProgress(progress);
    if (hash) {
      setTx(hash);
    }
  }

  function handleOnSubmitFinish() {
    console.log('finished');
  }

  function renderProgress() {
    const baseUri = network.chain?.id === 5 ? etherscanUrlGeorli : etherscanUrlMainnet;
    if (tx) {
      return (
        <div>
          <div>{progress}</div>
          <ContainerItem>
            <TextLink
              href={`${baseUri}/tx/${tx}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              View your transaction
            </TextLink>
          </ContainerItem>
          <ContainerItem>
            <TextLink href={`/manage`} target="_blank" rel="noopener noreferrer">
              Manage your collection
            </TextLink>{' '}
            (will appear under My Ethereum Contracts after your transaction confirms)
          </ContainerItem>
        </div>
      );
    } else {
      return (
        <>
          <div>{progress}</div>

          {errorMessage ? (
            <ContainerItem css={{ color: 'Red' }}>
              <code>{errorMessage}</code>
            </ContainerItem>
          ) : null}
        </>
      );
    }
  }

  return (
    <Container>
      <h2>Publish your collection</h2>
      <div>
        Hit publish to save your details, upload your files to IPFS, and deploy your contract to
        mainnet.
      </div>
      <ContainerItem>
        <ContinueButton
          disabled={!signer || !address}
          text="Publish"
          onClick={() => handlePublish()}
        />
      </ContainerItem>
      {renderProgress()}
    </Container>
  );
}

export default Publish;
