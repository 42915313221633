import { RadioGroupWithList, RadioGroupWithNote } from '../../../../components/radio-group';
import { ToggleGroupClaim, ToggleGroupPayment } from '../../../../components/toggle-group';
import { Label, LabelInput } from '../../../../components/label';
import { CsvDropzone, LogoDropzone } from '../../../../components/dropzone';
import { useAtom } from 'jotai';
import { handleFileInput } from '../../../../utils';
import { useEffect, useState } from 'react';
import { ContinueButton } from '../../../../components/buttons';
import {
  airdropAtom,
  claimOptionsAtom,
  currentUserAddressAtom,
  fungibleOptionsAtom,
  fungibleSelectedAtom,
  mintingEnabledAtom,
  mintpassAtom,
  mintsPerAddressAtom,
  paymentOptionsAtom,
  placeholderAtom,
  priceAtom,
  stxusdAtom,
  visibilityEnabledAtom,
} from '../../../../components/atoms';
import {
  Container,
  ContainerItem,
  ContainerItemNote,
  ContainerItemTitle,
  EmptyDiv,
} from '../../../../components/common';
import makeEndpoint from '../../../../endpoints';

function Minting(props: { onNextStep: any }) {
  const [price, setPrice] = useAtom(priceAtom);
  const [paymentOptions, setPaymentOptions] = useAtom(paymentOptionsAtom);
  const [claimOptions, setClaimOptions] = useAtom(claimOptionsAtom);
  const [mintpass, setMintpass] = useAtom(mintpassAtom);
  const [airdrop, setAirdrop] = useAtom(airdropAtom);
  const [mintingEnabled, setMintingEnabled] = useAtom(mintingEnabledAtom);
  const [mintsPerAddress, setMintsPerAddress] = useAtom(mintsPerAddressAtom);
  const [visibilityEnabled, setVisibilityEnabled] = useAtom(visibilityEnabledAtom);
  const [placeholder, setPlaceholder] = useAtom(placeholderAtom);
  const [fungibleOptions, setFungibleOptions] = useAtom(fungibleOptionsAtom);
  const [fungibleSelected, setFungibleSelected] = useAtom(fungibleSelectedAtom);
  const [stxusd, setStxusd] = useAtom(stxusdAtom);
  const [currentUserAddress, setCurrentUserAddress] = useAtom(currentUserAddressAtom);

  const [priceError, setPriceError] = useState('');
  const [mintpassError, setMintpassError] = useState('');
  const [airdropError, setAirdropError] = useState('');
  const [mintsPerAddressError, setMintsPerAddressError] = useState('');
  const [placeholderError, setPlaceholderError] = useState('');
  const [claimStrings, setClaimStrings] = useState(['1']);
  const [megaPrice, setMegaPrice] = useState('');

  function handlePriceChange(e: React.ChangeEvent<HTMLInputElement>) {
    setPrice(e.target.value);
    updateFungiblePrices(e.target.value, paymentOptions);
  }

  function handleMegaPriceChange(e: React.ChangeEvent<HTMLInputElement>) {
    setMegaPrice(e.target.value);
    let updatedFungibleSelected = fungibleSelected.map((item) => {
      if (item.symbol === 'MEGA' && parseInt(e.target.value) > 0) {
        return { ...item, price: parseInt(e.target.value) * 100, selected: true };
      } else if (item.symbol === 'MEGA' && (parseInt(e.target.value) <= 0 || !e.target.value)) {
        return { ...item, price: 0, selected: false };
      }
      return item;
    });
    setFungibleSelected(updatedFungibleSelected);
  }

  function handlePaymentOptionsChange(paymentOptions: string[]) {
    setPaymentOptions(paymentOptions);
    updateFungiblePrices(price, paymentOptions);
  }

  function handleClaimOptionsChange(claimStrings: string[]) {
    claimStrings.sort(function (a, b) {
      return parseInt(a) - parseInt(b);
    });
    setClaimStrings(claimStrings);
  }

  async function handleMintpassDrop(acceptedFiles: any[], rejectedFiles: any[]) {
    const csv = await handleFileInput(acceptedFiles[0], 'mintpass');

    if (csv.mintpassError) {
      setMintpass([]);
      setMintpassError(csv.mintpassError);
    } else if (rejectedFiles[0]) {
      setMintpass([]);
      setMintpassError(rejectedFiles[0].errors[0].message);
    } else {
      setMintpass(csv.mintpass);
      setMintpassError('');
    }
  }

  async function handleAirdropDrop(acceptedFiles: any[], rejectedFiles: any[]) {
    const csv = await handleFileInput(acceptedFiles[0], 'airdrop');

    if (csv.airdropError) {
      setAirdrop([]);
      setAirdropError(csv.airdropError);
    } else if (rejectedFiles[0]) {
      setAirdrop([]);
      setAirdropError(rejectedFiles[0].errors[0].message);
    } else {
      setAirdrop(csv.airdrop);
      setAirdropError('');
    }
  }

  function handleMintingStatusChange(value: string) {
    setMintingEnabled(value === 'enabled');
  }

  function handleMintsPerAddressChange(e: React.ChangeEvent<HTMLInputElement>) {
    setMintsPerAddress(parseInt(e.target.value).toString());
  }

  function handleVisibilityChange(value: string) {
    setVisibilityEnabled(value === 'revealed');
  }

  function handlePlaceholderDrop(acceptedFiles: any[], rejectedFiles: any[]) {
    if (rejectedFiles[0]) {
      setPlaceholderError(rejectedFiles[0].errors[0].message);
      setPlaceholder([]);
    } else {
      setPlaceholderError('');
      setPlaceholder(acceptedFiles);
    }
  }

  function handleContinue() {
    const claimOptions = [];
    for (let i = 0; i < claimStrings.length; i++) {
      const claimInt = parseInt(claimStrings[i]);
      claimOptions.push(claimInt);
    }
    setClaimOptions(claimOptions);
    props.onNextStep();
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  }

  function updateFungiblePrices(price: string, paymentOptions: string[]) {
    const fungibles = [];
    for (let i = 0; i < fungibleOptions.length; i++) {
      const token = fungibleOptions[i];

      if (token['symbol'] === 'MEGA') {
        fungibles.push({
          symbol: token['symbol'],
          price: parseInt(megaPrice) * 100,
          selected: parseInt(megaPrice) > 0 ? true : false,
        });
        continue;
      }

      let tokenUsd: number = token['price'];
      let symbol: string = token['symbol'];
      if (symbol === 'xBTC') {
        // symbol = "sats";
        tokenUsd = tokenUsd / 100000000;
      }
      let stxUsd = parseFloat(stxusd);
      let stxMintPrice = parseInt(price);
      let tokenMintPrice = (stxUsd / tokenUsd) * stxMintPrice;

      let fungiblePrice = 0;
      if (tokenUsd !== 0 && !isNaN(stxMintPrice)) {
        fungiblePrice = Math.round(tokenMintPrice);
      } else if (tokenUsd !== 0 && isNaN(stxMintPrice)) {
        fungiblePrice = Math.round((stxUsd / tokenUsd) * 1);
      }

      if (
        symbol === 'USDA' ||
        symbol === 'BANANA' ||
        symbol === 'SLIME' ||
        symbol === 'MIA2' ||
        symbol === 'NYC2'
      ) {
        fungiblePrice = fungiblePrice * 1000000;
      }

      if (symbol === 'ALEX') {
        fungiblePrice = fungiblePrice * 100000000;
      }

      if (symbol === 'USDA' && fungiblePrice === 0) {
        fungiblePrice = 1000000;
      }

      fungibles.push({
        symbol: token['symbol'],
        price: fungiblePrice,
        selected: paymentOptions.includes(symbol.toLowerCase()) ? true : false,
      });
    }
    setFungibleSelected(fungibles);
  }

  useEffect(() => {
    async function fetchPaymentOptions() {
      try {
        const res = await fetch(makeEndpoint('/api/fts'));
        const json = await res.json();
        setFungibleOptions(json.tokens);
        setStxusd(json.stacks['price']);
      } catch (error) {
        console.error(error);
      }
    }

    if (!fungibleOptions.length) {
      fetchPaymentOptions();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function renderPaymentOptions() {
    let options = [];
    for (let i = 0; i < fungibleSelected.length; i++) {
      const fungible: any = fungibleSelected[i];
      const selected: boolean = fungible['selected'];
      if (selected) {
        let price: number = fungible['price'];
        let symbol: string = fungible['symbol'];
        if (symbol === 'MIA2' || symbol === 'NYC2') {
          price = price / 1e6;
          symbol = symbol.replace('2', '');
        } else if (symbol === "ALEX") {
          price = price / 1e8;
        } else if (symbol === 'BANANA' || symbol === 'SLIME' || symbol === 'USDA') {
          price = price / 1e6;
        } else if (symbol === 'xBTC') {
          price = price / 1e8;
        } else if (symbol === 'MEGA') {
          price = price / 1e2;
        }
        options.push(
          <li>
            {price} {symbol}
          </li>,
        );
      }
    }
    return options;
  }

  return (
    <Container>
      <h2>Define your mint settings</h2>

      <ContainerItem>
        <LabelInput
          placeholder="25 STX"
          id="price"
          label="Price"
          type="number"
          value={price}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => handlePriceChange(e)}
        />
      </ContainerItem>

      <ContainerItem>
        <ul>{renderPaymentOptions()}</ul>
      </ContainerItem>

      <ContainerItem>
        <ContainerItemTitle>Payment options</ContainerItemTitle>
        <ToggleGroupPayment
          value={paymentOptions}
          onChange={(value: string[]) => handlePaymentOptionsChange(value)}
        />
        <LabelInput
          type="number"
          placeholder="10 MEGA"
          label="MEGA"
          id="mega"
          note="If you would like to enable minting with MEGA, you can enter the price below."
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleMegaPriceChange(e)}
        />
      </ContainerItem>

      <ContainerItem>
        <ContainerItemTitle>Claim options</ContainerItemTitle>
        <ContainerItemNote>
          Define how many NFTs can be purchased in a single transaction. Each number that's selected
          represents an additional option available to buyers.
        </ContainerItemNote>
        <ToggleGroupClaim
          value={claimStrings}
          onChange={(value: string[]) => handleClaimOptionsChange(value)}
        />
      </ContainerItem>

      <ContainerItem>
        <CsvDropzone
          onDrop={(acceptedFiles: [], rejectedFiles: []) =>
            handleMintpassDrop(acceptedFiles, rejectedFiles)
          }
          label="Mintpass"
          note="Upload a .csv file that includes the numbers of pre-mints allowed per wallet address. 
                Each row must include a single wallet address and an integer representing the
                number of mints allowed for that address."
          error={mintpassError}
          successText={
            mintpass.length ? `Success! ${mintpass.length} wallets added to mintpass` : ''
          }
          sample="mintpass.csv"
        />
      </ContainerItem>

      <ContainerItem>
        <CsvDropzone
          onDrop={(acceptedFiles: [], rejectedFiles: []) =>
            handleAirdropDrop(acceptedFiles, rejectedFiles)
          }
          label="Airdrop"
          note="Upload a .csv file with a list of wallet addresses eligible for airdrop. 
                Each row must include a single wallet address. If
                an address is to receive multiple airdrops, list their
                address that many times. Airdrops are triggered by the deployer
                using a public function."
          error={airdropError}
          successText={airdrop.length ? `Success! ${airdrop.length} tokens to be airdropped` : ''}
          sample="airdrop.csv"
        />
      </ContainerItem>

      <RadioGroupWithNote
        title="Minting Status"
        note="Choose whether you want to launch your collection with minting
            enabled or disabled"
        data={[
          {
            value: 'enabled',
            label: 'Enabled',
            note: 'People will be able to mint as soon as your collection launches',
          },
          {
            value: 'disabled',
            label: 'Disabled',
            note: 'Minting will be disabled by default, until you enable it later',
          },
        ]}
        onChange={(value: string) => handleMintingStatusChange(value)}
      />

      <ContainerItem>
        <LabelInput
          placeholder="1"
          id="maximumMints"
          label="Maximum mints per address (optional)"
          type="number"
          value={mintsPerAddress}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleMintsPerAddressChange(e)}
        />
      </ContainerItem>

      <ContainerItem>
        <RadioGroupWithList
          title="Visibility"
          note="Choose whether you want the assets to be revealed or hidden during the
          mint. Hiding assets during the mint is useful if certain NFTs in your
          collection are more rare than others. You can update the assets
          later."
          data={[
            {
              value: 'revealed',
              label: 'Revealed',
              items: [
                "You'll upload assets for all items in your collection",
                'People will be able to see the NFT they mint',
              ],
            },
            {
              value: 'hidden',
              label: 'Hidden',
              items: [
                "You'll upload assets for all items in your collection",
                "People won't see which particular NFT they're minting",
                "You'll replace the placeholder when you want to reveal the assets",
              ],
            },
          ]}
          onChange={(value: string) => handleVisibilityChange(value)}
        />
      </ContainerItem>

      {!visibilityEnabled ? (
        <ContainerItem>
          <LogoDropzone
            onDrop={(acceptedFiles: [], rejectedFiles: []) =>
              handlePlaceholderDrop(acceptedFiles, rejectedFiles)
            }
            label="Placeholder"
            note="Upload an image to act as a placeholder until your collection is revealed"
            error={placeholderError}
            successText={placeholder.length > 0 ? 'Success! Placeholder added' : ''}
          />
        </ContainerItem>
      ) : null}

      <ContainerItem>
        <ContinueButton
          disabled={
            !price ||
            !claimStrings.length ||
            !paymentOptions.length ||
            (!visibilityEnabled && !placeholder.length)
          }
          text="Continue"
          onClick={() => handleContinue()}
        />
      </ContainerItem>

      <ContainerItem>
        <EmptyDiv css={{ color: 'gray' }}>
          * Gamma.io sets royalties for secondary sales to 5% by default. Contact us directly at
          support@gamma.io after deploying your collection to update the royalty amount.
        </EmptyDiv>
      </ContainerItem>
    </Container>
  );
}

export default Minting;
