import { styled } from '../stitches.config';

export const Container = styled('div', {
  maxWidth: '620px',
});

export const ContainerSection = styled('div', {
  margin: '0 0 50px 0',
});

export const ContainerItem = styled('div', {
  margin: '20px 0',
  position: 'relative',
});

export const ContainerItemTitle = styled('div', {
  fontSize: 18,
  userSelect: 'none',
  maxWidth: 'fit-content',
  lineHeight: '48px',
});

export const ContainerItemNote = styled('div', {
  marginBottom: '20px',
});

export const Wrapper = styled('div', {
  margin: '0 auto',
  display: 'flex',
  flexDirection: 'column',
  // gap: '10px',
  maxWidth: '1200px',
  padding: '0 30px 50px',
});

export const FlexWrapper = styled('div', {
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'space-between',
});

export const ContainerFull = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '20px',
  maxWidth: '1200px',
  margin: '0 auto',
  padding: '0 30px',
  // width: "100%",
});

export const Rows = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  maxWidth: 'fit-content',
  gap: 10,
});

export const Grid = styled('div', {
  position: 'relative',
  gridTemplateColumns: '300px minmax(100%, auto)',
  // gridTemplateColumns: "300px 1200px",
  // gridTemplateColumns: "300px minmax(600px, 1500px)",
  alignItems: 'flex-start',
  alignContent: 'flex-start',
});

export const CollectionGrid = styled('div', {
  display: 'grid',
  gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))',
  gap: '20px',
});

export const CollectionFlex = styled('div', {
  display: 'flex',
  gap: '50px',
});

export const ManageFlexColumn = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: 20,
});

export const CollectionRow = styled('div', {
  display: 'flex',
  gap: 30,
  flexWrap: 'wrap',
  borderBottom: '1px solid',
  borderColor: '$border',
  height: '50px',
  paddingBottom: 20,
  alignItems: 'center',
  justifyContent: 'space-between',
});

export const HeaderRow = styled('div', {
  display: 'flex',
  gap: 30,
  flexWrap: 'wrap',
  // height: '50px',
  paddingBottom: 30,
  // alignItems: 'center',
  justifyContent: 'space-between',
});

export const FlexColumn = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '50px',
});

export const Row = styled('div', {
  display: 'flex',
  gap: 10,
  flexWrap: 'wrap',
});

export const FunctionName = styled('span', {
  textDecoration: 'underline',
  cursor: 'pointer',
  maxWidth: 'fit-content',
  whiteSpace: 'nowrap',
});

export const H1 = styled('h1', {
  margin: '0 0 30px 0',
});

export const H2 = styled('h2', {
  margin: '0 0 20px 0',
});

export const H3 = styled('h3', {
  margin: '0 0 10px 0',
});

export const H4 = styled('h4', {
  fontSize: '18px',
  margin: '50px 0 0 0',
});

export const H40 = styled('h4', {
  fontSize: '18px',
  margin: 0,
});

export const ULNone = styled('ul', {
  listStyleType: 'none',
});

export const CardGrid = styled('div', {
  display: 'grid',
  gridTemplateColumns: 'repeat(auto-fill, minmax(200px, 1fr))',
  gap: '25px',
  marginBottom: '50px',
});

export const CreateCardGrid = styled('div', {
  display: 'grid',
  // gridTemplateColumns: 'repeat(auto-fill, minmax(250px, 1fr))',
  gridTemplateColumns: '1fr 1fr 1fr',
  gap: '35px',
  // marginBottom: '50px',
});

export const QuickTipsGrid = styled('div', {
  display: 'grid',
  // gridTemplateColumns: 'repeat(auto-fill, minmax(250px, 1fr))',
  gridTemplateColumns: '1fr 1fr',
  gap: '35px',
  // marginBottom: '50px',
});

export const Pointer = styled('div', {
  cursor: 'pointer',
});

export const TextLink = styled('a', {
  // display: 'flex',
  textDecoration: 'underline',
  width: 'fit-content',

  // '&:hover': {
  //   textDecoration: 'none',
  // },
});

export const Underline = styled('span', {
  textDecoration: 'underline',
  width: 'fit-content',
});

export const Logo = styled('img', {
  width: '112px',
  height: '112px',
});

export const LogoEnlarged = styled('img', {
  width: '300px',
  height: '300px',
});

export const Thumbnail = styled('img', {
  height: '100%',
  borderRadius: '6px',
});

export const Divider = styled('hr', {
  width: '100%',
  borderTop: '1px solid black',
});

export const QuickTip = styled('div', {
  backgroundColor: '$stepBackground',
  border: '1px solid',
  borderColor: '$border',
  borderRadius: '24px',
  padding: '40px 50px',
  fontSize: '18px',
  gap: '50px',
  maxWidth: '100%',
});

export const QuickTipClear = styled('div', {
  border: '1px solid',
  borderColor: '$border',
  borderRadius: '24px',
  padding: '40px 50px',
  fontSize: '18px',
  gap: '50px',
  maxWidth: '100%',
});

export const Step = styled('div', {
  // padding: '50px',
  display: 'flex',
  flexDirection: 'column',
  fontSize: '18px',
  gap: '30px',
  maxWidth: '1200px',
});

export const FooterContainer = styled('div', {
  // borderColor: '$border',
  // padding: '50px 0',

  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  flexWrap: 'wrap',
  // margin-bottom: 30px;
  margin: '0 auto 0',
  padding: '30px',
  maxWidth: '1200px',
  fontSize: '14px',
  color: 'Gray',
});

export const FooterRight = styled('div', {
  textAlign: 'center',
  display: 'flex',
  alignItems: 'center',
  gap: '20px',
});

export const FooterItem = styled('div', {
  // margin: '10px 0',
  position: 'relative',
});

export const PercentRead = styled('div', {
  fontStyle: 'italic',
});

export const Bullets = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '20px',
});

export const Bullet = styled('div', {
  display: 'flex',
  alignItems: 'center',
  gap: '20px',
});

export const StepTitle = styled('div', {
  fontSize: '34px',
  fontWeight: '600',
});

export const StepSubtitle = styled('div', {
  fontSize: '20px',
});

export const Tab = styled('div', {
  fontSize: '20px',
  cursor: 'pointer',
  textUnderlineOffset: '10px',
});

export const Bottom = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  padding: '50px 30px 100px',
  maxWidth: '1200px',
  margin: '0 auto',
  gap: '30px',
});

export const Centered = styled('div', {
  textAlign: 'center',
  margin: '0 auto',
});

export const FinePrint = styled('div', {
  textAlign: 'center',
  marginBottom: '50px',
});

export const HowItWorks = styled('div', {
  padding: '90px 30px',
  margin: '0 auto',
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: '$stepBackground',
  gap: '60px',
  color: '$text',
});

export const MintHeader = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  alignItems: 'center',
  paddingTop: '50px',
  paddingBottom: '30px',
});

export const EmptyDiv = styled('div', {});
