import React, { Component } from 'react';
import makeEndpoint from '../../endpoints';
import CollectionStandard from '../CollectionStandard';
import CollectionAuction from '../CollectionAuction';
import EthDrop from '../EthDrop';
import { isEthAddress } from '../../utils/helpers';

type CollectionProps = {
  name: string;
};
type CollectionState = {
  artistData: any;
  isEthCollection: boolean;
};

class Collection extends Component<CollectionProps, CollectionState> {
  state = {
    artistData: undefined,
    isEthCollection: false,
  };

  async componentDidMount() {
    if (!isEthAddress(this.props.name)) {
      fetch(makeEndpoint('/api/drop?name=' + this.props.name.toLowerCase()))
        .then((res) => {
          return res.json();
        })
        .then((artistData) => {
          this.setState({ artistData: artistData });
        });
    }
  }

  render() {
    if (isEthAddress(this.props.name)) {
      return <EthDrop hash={this.props.name} />;
    } else if (this.state.artistData && this.state.artistData['auctionEnabled']) {
      return <CollectionAuction name={this.props.name} collectionData={this.state.artistData} />;
    } else if (this.state.artistData) {
      return <CollectionStandard name={this.props.name} collectionData={this.state.artistData} />;
    } else {
      return <div></div>;
    }
  }
}

export default Collection;
