import styles from "./index.module.scss";

function ProgressBar(props: {
  steps: Array<string>;
  step: number;
  onStepSelected: any;
  header?: string;
}) {
  function renderSteps() {
    return props.steps.map((name: string, idx: number) => {
      return (
        <div
          key={idx}
          // onClick={() => props.onStepSelected(idx + 1)}
          className={idx + 1 === props.step ? styles.CurrentStep : styles.Step}
        >
          {name}
        </div>
      );
    });
  }

  return (
    <div className={styles.Container}>
      {props.header ? <h3>{props.header}</h3> : null}
      {renderSteps()}
    </div>
  );
}

export default ProgressBar;
