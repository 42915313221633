import { styled } from '@stitches/react';
import { useAtom } from 'jotai';
import {
  airdropAtom,
  artistAddressAtom,
  assetsAtom,
  categoryAtom,
  claimOptionsAtom,
  collectionNameAtom,
  currentUserAddressAtom,
  descriptionAtom,
  emailAddressAtom,
  fungibleSelectedAtom,
  logoAtom,
  mintingEnabledAtom,
  mintpassAtom,
  mintsPerAddressAtom,
  paymentOptionsAtom,
  placeholderAtom,
  priceAtom,
  twitterAtom,
  userAtom,
  visibilityEnabledAtom,
  websiteAtom,
} from '../../../../components/atoms';
import { ContinueButton } from '../../../../components/buttons';
import { ReviewCard } from '../../../../components/cards';
import { CardGrid, Container, ContainerItem, EmptyDiv, Logo } from '../../../../components/common';

const Details = styled('div', {
  justifyContent: 'space-between',
  margin: '50px 0',

  variants: {
    display: {
      hasFlex: { display: 'flex' },
      noFlex: { display: '' },
    },
  },
});

const DetailsLeft = styled('div', {
  width: '40%',
  display: 'flex',
  flexDirection: 'column',
  gap: '20px',
  overflowWrap: 'break-word',
});

const DetailsRight = styled('div', {
  textAlign: 'right',
});

const Stats = styled('div', {
  height: 'fit-content',
  display: 'flex',
  overflowWrap: 'break-word',
  border: '1px solid #dcdde2',
  borderRadius: '16px',
  padding: 20,
});

const CollectionName = styled('div', {
  fontSize: '32px',
});

const Links = styled('div', {
  display: 'flex',
  gap: '10px',
});

const Stat = styled('div', {
  padding: '0 30px',
  borderRight: '1px solid #dcdde2',
  textAlign: 'center',
  display: 'flex',
  flexDirection: 'column',
  gap: '6px',
  '&:last-child': {
    borderRight: 'none',
    paddingRight: 'none',
  },
});

const AdditionalDetails = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',
  margin: '20px 0',
});

function Review(props: { onNextStep: any }) {
  const [assets, setAssets] = useAtom(assetsAtom);
  const [logo, setLogo] = useAtom(logoAtom);
  const [collectionName, setCollectionName] = useAtom(collectionNameAtom);
  const [description, setDescription] = useAtom(descriptionAtom);
  const [website, setWebsite] = useAtom(websiteAtom);
  const [twitter, setTwitter] = useAtom(twitterAtom);
  const [artistAddress, setArtistAddress] = useAtom(artistAddressAtom);
  const [price, setPrice] = useAtom(priceAtom);
  const [emailAddress, setEmailAddress] = useAtom(emailAddressAtom);
  const [paymentOptions, setPaymentOptions] = useAtom(paymentOptionsAtom);
  const [claimOptions, setClaimOptions] = useAtom(claimOptionsAtom);
  const [mintingEnabled, setMintingEnabled] = useAtom(mintingEnabledAtom);
  const [mintsPerAddress, setMintsPerAddress] = useAtom(mintsPerAddressAtom);
  const [visibilityEnabled, setVisibilityEnabled] = useAtom(visibilityEnabledAtom);
  const [placeholder, setPlaceholder] = useAtom(placeholderAtom);
  const [mintpass, setMintpass] = useAtom(mintpassAtom);
  const [airdrop, setAirdrop] = useAtom(airdropAtom);
  const [category, setCategory] = useAtom(categoryAtom);
  const [user, setUser] = useAtom(userAtom);
  const [currentUserAddress, setCurrentUserAddress] = useAtom(currentUserAddressAtom);
  const [fungibleSelected, setFungibleSelected] = useAtom(fungibleSelectedAtom);

  function renderCards() {
    let cards = [];
    const limit = assets.length < 25 ? assets.length : 25;
    for (let i = 0; i < limit; i++) {
      cards.push(<ReviewCard tokenId={i + 1} />);
    }
    return cards;
  }

  function renderPaymentOptions() {
    return fungibleSelected.map((item: any, idx: number) => {
      if (item.selected) {
        return (
          <span>
            {item.symbol.toUpperCase()}
            {idx < fungibleSelected.length - 1 ? ', ' : null}
          </span>
        );
      }
      return null;
    });
  }

  function renderClaimOptions() {
    return claimOptions.map((option: any, idx: number) => {
      return (
        <span>
          {option}
          {idx < claimOptions.length - 1 ? ', ' : null}
        </span>
      );
    });
  }

  function handleContinue() {
    props.onNextStep();
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  }

  return (
    <>
      <Container>
        <h2>Review your collection</h2>
        {/* <div>
          Review the details of your collection one more time below before
          deploying your contract.
        </div> */}
        <ContainerItem>
          <AdditionalDetails>
            {/* <h3>Additional Details</h3> */}
            <div>Email address (will remain private): {emailAddress}</div>
            <div>Artist address: {artistAddress || currentUserAddress}</div>
            <div>Additional payment options: {renderPaymentOptions()}</div>
            <div>Claim options: {renderClaimOptions()}</div>
            <div>Minting status: {mintingEnabled ? 'Enabled on launch' : 'Disabled on launch'}</div>
            <div>
              Mints per address: {parseInt(mintsPerAddress) > 0 ? mintsPerAddress : 'Unlimited'}
            </div>
            <div>Total mintpasses: {mintpass.length}</div>
            <div>Total airdrops: {airdrop.length}</div>
            <div>Visibility: {visibilityEnabled ? 'Revealed on launch' : 'Hidden on launch'}</div>
            {/* <div>Category: {" "}{category === "" ? "No category selected" : category}</div> */}
          </AdditionalDetails>
        </ContainerItem>

        <ContainerItem>
          <ContinueButton disabled={false} text="Continue" onClick={() => handleContinue()} />
        </ContainerItem>

        <ContainerItem>
          <EmptyDiv css={{ color: 'gray' }}>
            * Gamma.io sets royalties for secondary sales to 5% by default. Contact us directly at
            support@gamma.io after deploying your collection to update the royalty amount.
          </EmptyDiv>
        </ContainerItem>
      </Container>
      <Details
        display={{
          '@initial': 'noFlex',
          '@media (min-width: 620px)': 'hasFlex',
        }}
      >
        <DetailsLeft>
          {logo.length ? <Logo src={logo[0].preview} /> : null}
          <CollectionName>{collectionName}</CollectionName>
          <div>{description}</div>
          <Links>
            {website ? (
              <span>
                <a href={website} target="_blank" rel="noopener noreferrer">
                  {website.split('//')[1]}
                </a>{' '}
              </span>
            ) : null}
            {twitter ? (
              <span>
                <a href={'https://' + twitter} target="_blank" rel="noopener noreferrer">
                  @{twitter.split('/')[1]}
                </a>{' '}
              </span>
            ) : null}
          </Links>
        </DetailsLeft>
        <DetailsRight>
          <Stats>
            <Stat>
              <div>Items</div>
              <div>{assets.length}</div>
            </Stat>
            <Stat>
              <div>Holders</div>
              <div>0</div>
            </Stat>
            <Stat>
              <div>Floor Price</div>
              <div>{price} STX</div>
            </Stat>
            <Stat>
              <div>Sales</div>
              <div>0 STX</div>
            </Stat>
          </Stats>
        </DetailsRight>
      </Details>

      <CardGrid>{renderCards()}</CardGrid>
    </>
  );
}

export default Review;
