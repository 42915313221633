import { ethers } from 'ethers';

export const getContractName = (collectionName: string) => {
  let contractName = collectionName
    .replaceAll(' ', '-')
    .replaceAll('(', '')
    .replaceAll(')', '')
    .replace(/^-+|-+$/g, '')
    .toLowerCase();

  contractName = contractName.replaceAll('---', '-');
  return contractName;
};

export function getContractParts(contract_id: string) {
  if (!contract_id) return {};
  let [contract_address, contract_name] = contract_id.split('.');
  let token_id;
  let asset_id;
  if (contract_name?.includes('::')) {
    const parts = contract_name.split('::');
    contract_name = parts[0];
    if (!parts[1].includes(':')) {
      asset_id = parts[1];
    } else {
      // is a fully_qualified_token_id
      let additionalParts = parts[1].split(':');
      asset_id = additionalParts[0];
      token_id = additionalParts[1];
    }
  }
  return {
    contract_id: `${contract_address}.${contract_name}`,
    contract_address,
    contract_name,
    asset_id,
    token_id,
  };
}

export const isEthAddress = (val: string) => {
  return ethers.utils.isAddress(val);
};
