import { styled } from '../stitches.config';
import { AppConfig, UserSession, showConnect } from '@stacks/connect';
import { gaSend } from '../Analytics';
import { useEffect, useState } from 'react';
import { useAtom } from 'jotai';
import { assetsAtom, currentUserAddressAtom, userAtom } from './atoms';
import { useHistory } from 'react-router-dom';
import { isMainnet } from '../utils/SelfServe/contracts';
import BlogPillBg from '../assets/blog-pill-bg.png';
import { useAccount, useConnect, useDisconnect } from 'wagmi';
import { Pointer, TextLink, Underline } from './common';

const appConfig = new AppConfig(['store_write', 'publish_data']);
const userSession = new UserSession({ appConfig });

const Continue = styled('button', {
  borderRadius: '10px',
  height: '60px',
  width: '100%',
  fontSize: '16px',
  lineHeight: '24px',
  backgroundColor: '$text',
  color: '$background',
  cursor: 'pointer',
  border: '1px solid #dcdde2',
});

const ContinueDisabled = styled('button', {
  borderRadius: '10px',
  height: '60px',
  width: '100%',
  fontSize: '16px',
  lineHeight: '24px',
  backgroundColor: '#dcdde2',
  color: 'black',
  cursor: 'not-allowed',
  border: '1px solid #dcdde2',
});

const ContinueOutlineFit = styled('button', {
  borderRadius: '10px',
  height: '60px',
  width: 'fit-content',
  fontSize: '16px',
  lineHeight: '24px',
  backgroundColor: '$background',
  color: '$text',
  cursor: 'pointer',
  border: '1px solid',
  borderColor: '$text',
  padding: '0 16px',
});

const Cancel = styled('div', {
  width: 'fit-content',
  cursor: 'pointer',
  '&:hover': {
    textDecoration: 'underline',
  },
});

const Create = styled('div', {
  // border: "1px solid",
  cursor: 'pointer',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '8px',
  height: '50px',
  padding: '6px 24px',
  fontSize: '20px',
  // lineHeight: '24px',
  backgroundColor: '$text',
  color: '$background',
});

const CreateSmall = styled('div', {
  // border: "1px solid",
  cursor: 'pointer',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '8px',
  height: '50px',
  padding: '0 16px',
  fontSize: '16px',
  // lineHeight: '24px',
  backgroundColor: '$text',
  color: '$background',
  width: 'fit-content',
});

const CreateSmallOutline = styled('div', {
  // border: "1px solid",
  cursor: 'pointer',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '8px',
  height: '50px',
  padding: '0 16px',
  fontSize: '16px',
  // lineHeight: '24px',
  backgroundColor: '$background',
  color: '$text',
  width: 'fit-content',
  border: '1px solid',
  borderColor: '$text',
});

const Help = styled('div', {
  // border: "1px solid",
  cursor: 'pointer',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '8px',
  height: '50px',
  padding: '6px 24px',
  fontSize: '20px',
  // lineHeight: '24px',
  backgroundColor: '$background',
  color: '$text',
  border: '1px solid',
  borderColor: '$text',
});

const CreateWhite = styled('div', {
  // border: '1px solid',
  cursor: 'pointer',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '8px',
  height: '50px',
  padding: '6px 24px',
  fontSize: '20px',
  // lineHeight: '24px',
  backgroundColor: 'white',
  color: 'black',
});

const CreateBlack = styled('div', {
  // border: '1px solid',
  cursor: 'pointer',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '8px',
  height: '50px',
  padding: '6px 24px',
  fontSize: '20px',
  // lineHeight: '24px',
  backgroundColor: 'black',
  color: 'white',
});

const Connect = styled('div', {
  // width: '100px',
  fontSize: '16px',
  backgroundColor: '$text',
  color: '$background',
  cursor: 'pointer',
  padding: '12px 20px',

  // height: "20px",
  borderRadius: '9999px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

const Mint = styled('div', {
  backgroundColor: '$text',
  color: '$background',
  // padding: '8px',
  // height: "20px",

  width: '250px',
  display: 'flex',
  // flex-grow: 1;
  minWidth: '60px',
  height: '42px',
  alignItems: 'center',
  justifyContent: 'center',
  paddingLeft: '16px',
  paddingRight: '16px',
  cursor: 'pointer',
  transition: 'background 0.25s, color 0.25s, stroke 0.25s, border 0.25s',
  borderRadius: '9999px',
  boxSizing: 'border-box',
  // font-family: Roboto;
  // font-weight: 500;
  fontSize: '16px',
  letterSpacing: '1.23px',
  // @include theme-aware("background", "color-twentyfive");
  // @include theme-aware("color", "color-twentytwo");
  // @include theme-aware("stroke", "color-twenty");
});

const Bid = styled('div', {
  backgroundColor: '$text',
  color: '$background',
  // padding: '8px',
  // height: "20px",

  width: '250px',
  display: 'flex',
  // flex-grow: 1;
  minWidth: '100%',
  height: '42px',
  alignItems: 'center',
  justifyContent: 'center',
  paddingLeft: '16px',
  paddingRight: '16px',
  cursor: 'pointer',
  transition: 'background 0.25s, color 0.25s, stroke 0.25s, border 0.25s',
  borderRadius: '9999px',
  boxSizing: 'border-box',
  // font-family: Roboto;
  // font-weight: 500;
  fontSize: '16px',
  letterSpacing: '1.23px',
  // @include theme-aware("background", "color-twentyfive");
  // @include theme-aware("color", "color-twentytwo");
  // @include theme-aware("stroke", "color-twenty");
});

const ManageAuction = styled('div', {
  backgroundColor: '$text',
  color: '$background',
  // padding: '8px',
  // height: "20px",
  margin: '10px 10px 0 0',

  width: 'fit-content',
  display: 'flex',
  // minWidth: '100%',
  height: '42px',
  alignItems: 'center',
  justifyContent: 'center',
  paddingLeft: '16px',
  paddingRight: '16px',
  cursor: 'pointer',
  transition: 'background 0.25s, color 0.25s, stroke 0.25s, border 0.25s',
  borderRadius: '9999px',
  boxSizing: 'border-box',
  // font-family: Roboto;
  // font-weight: 500;
  fontSize: '16px',
  letterSpacing: '1.23px',
  // @include theme-aware("background", "color-twentyfive");
  // @include theme-aware("color", "color-twentytwo");
  // @include theme-aware("stroke", "color-twenty");
});

const Spacer = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
});

const BlogPill = styled('div', {
  color: 'white',
  width: '300px',
  display: 'flex',
  // flex-grow: 1;
  minWidth: '60px',
  height: '42px',
  alignItems: 'center',
  justifyContent: 'center',
  paddingLeft: '16px',
  paddingRight: '16px',
  cursor: 'pointer',
  transition: 'background 0.25s, color 0.25s, stroke 0.25s, border 0.25s',
  borderRadius: '9999px',
  boxSizing: 'border-box',
  fontSize: '16px',
  letterSpacing: '1.23px',
  // @include theme-aware("background", "color-twentyfive");
  // @include theme-aware("color", "color-twentytwo");
  // @include theme-aware("stroke", "color-twenty");
  background: `url(${BlogPillBg}) 58% / 100%`,
});

export const DateButton = styled('div', {
  color: '$background',
  background: '$text',
  width: '300px',
  display: 'flex',
  // flex-grow: 1;
  minWidth: '60px',
  height: '42px',
  alignItems: 'center',
  justifyContent: 'center',
  paddingLeft: '16px',
  paddingRight: '16px',
  cursor: 'pointer',
  borderRadius: '9999px',
  boxSizing: 'border-box',
  fontSize: '16px',
  letterSpacing: '1.23px',
});

// export const DateButton = (props: { text: string }) => {
//   const date = props.text;
//   return <Date>{date}</Date>;
// };

export const BlogPillButton = (props: { text: string; onClick: any }) => {
  return <BlogPill onClick={() => props.onClick()}>{props.text}</BlogPill>;
};

export const CancelButton = (props: { text: string; onClick: any }) => {
  return <Cancel onClick={() => props.onClick()}>{props.text}</Cancel>;
};

export const CreateButton = (props: { text: string }) => {
  return <Create>{props.text}</Create>;
};

export const CreateSmallButton = (props: { text: string; onClick: any }) => {
  return <CreateSmall onClick={() => props.onClick()}>{props.text}</CreateSmall>;
};

export const CreateSmallButtonOutline = (props: { text: string }) => {
  return <CreateSmallOutline>{props.text}</CreateSmallOutline>;
};

export const HelpButton = (props: { text: string }) => {
  return <Help>{props.text}</Help>;
};

export const CreateWhiteButton = (props: { text: string }) => {
  return <CreateWhite>{props.text}</CreateWhite>;
};

export const CreateBlackButton = (props: { text: string }) => {
  return <CreateBlack>{props.text}</CreateBlack>;
};

export const MintButton = (props: { text: string; onClick: any }) => {
  return <Mint onClick={() => props.onClick()}>{props.text}</Mint>;
};

export const MintManyButton = (props: { text: string; onClick: any; textRight?: string }) => {
  if (props.textRight) {
    return (
      <Mint onClick={() => props.onClick()}>
        <Spacer>
          <div>{props.text}</div>
          <div>{props.textRight}</div>
        </Spacer>
      </Mint>
    );
  } else {
    return (
      <Mint onClick={() => props.onClick()}>
        <div>{props.text}</div>
      </Mint>
    );
  }
};

export const BidButton = (props: { text: string; onClick: any }) => {
  return <Bid onClick={() => props.onClick()}>{props.text}</Bid>;
};

export const ManageAuctionButton = (props: { text: string; onClick: any }) => {
  return <ManageAuction onClick={() => props.onClick()}>{props.text}</ManageAuction>;
};

export const ViewAuctionButton = (props: { text: string }) => {
  return <ManageAuction>{props.text}</ManageAuction>;
};

export const ContinueButton = (props: { text: string; onClick: any; disabled: boolean }) => {
  function renderButton() {
    if (props.disabled) {
      return (
        <ContinueDisabled disabled={props.disabled} onClick={() => props.onClick()}>
          {props.text}
        </ContinueDisabled>
      );
    } else {
      return (
        <Continue disabled={props.disabled} onClick={() => props.onClick()}>
          {props.text}
        </Continue>
      );
    }
  }
  return renderButton();
};

export const ContinueButtonOutlineFit = (props: { text: string; onClick: any }) => {
  return <ContinueOutlineFit onClick={() => props.onClick()}>{props.text}</ContinueOutlineFit>;
};

export const StacksConnectButton = (props: {
  style?: any;
  isText?: boolean;
  signInText?: string;
  signOutText?: string;
}) => {
  const [user, setUser] = useAtom(userAtom);
  const [currentUserAddress, setCurrentUserAddress] = useAtom(currentUserAddressAtom);

  useEffect(() => {
    try {
      const userData = localStorage.getItem('userData');
      if (userData) {
        setUser(JSON.parse(userData));
        if (isMainnet) {
          setCurrentUserAddress(JSON.parse(userData).profile.stxAddress.mainnet);
        } else {
          setCurrentUserAddress(JSON.parse(userData).profile.stxAddress.testnet);
        }
      }
    } catch {}
  }, [setUser, setCurrentUserAddress]);

  function authenticate() {
    showConnect({
      appDetails: {
        name: 'Gamma.io',
        icon: window.location.origin + './icon.png',
      },
      redirectTo: '/',
      onFinish: (payload) => {
        gaSend('User', 'authenticate', 'User Authenticated');
        let userData = userSession.loadUserData();
        setUser({ userData });
        localStorage.setItem('userData', JSON.stringify(userData));
        // if (userData) {
        //   // console.log(JSON.parse(userData).profile.stxAddress.mainnet);
        //   setUser(JSON.parse(userData));
        //   if (isMainnet) {
        //     setCurrentUserAddress(JSON.parse(userData).profile.stxAddress.mainnet);
        //   } else {
        //     setCurrentUserAddress(JSON.parse(userData).profile.stxAddress.testnet);
        //   }
        // }
        window.location.reload();
      },
      userSession: userSession,
    });
  }

  function logout() {
    localStorage.clear();
    setUser({});
    window.location.reload();
  }

  function handleConnect() {
    if (userSession.isUserSignedIn()) {
      logout();
    } else {
      authenticate();
    }
  }

  if (props.isText) {
    return (
      <Pointer>
        <Underline onClick={() => handleConnect()}>
          {userSession.isUserSignedIn()
            ? props.signOutText
              ? props.signOutText
              : 'Sign out Stacks'
            : props.signInText
            ? props.signInText
            : 'Connect Stacks'}
        </Underline>
        {currentUserAddress ? (
          <>
            {' | '}
            <span></span>
            {currentUserAddress?.substring(0, 4)}...
            {currentUserAddress.substring(currentUserAddress.length - 4)}
          </>
        ) : null}
      </Pointer>
    );
  } else {
    return (
      <Connect onClick={() => handleConnect()}>
        {userSession.isUserSignedIn()
          ? props.signOutText
            ? props.signOutText
            : 'Sign out Stacks'
          : props.signInText
          ? props.signInText
          : 'Connect Stacks'}
      </Connect>
    );
  }
};

export const EthConnectButton = (props: {
  style?: any;
  isText?: boolean;
  signInText?: string;
  signOutText?: string;
}) => {
  const { isConnected, address } = useAccount();
  const { connect, connectors, isLoading } = useConnect({
    onSuccess(data) {
      console.log(data);
      window.location.reload()
    },
  });
  const { disconnect } = useDisconnect();

  const handleSignIn = async function () {
    connect({ connector: connectors[0] });
  };

  const handleSignOut = async function () {
    disconnect();
  };

  if (props.isText) {
    return (
      <Pointer>
        <Underline
          css={props.style || ''}
          onClick={isConnected ? () => handleSignOut() : () => handleSignIn()}
        >
          {isConnected
            ? props.signOutText
              ? props.signOutText
              : 'Sign out Ethereum'
            : props.signInText
            ? props.signInText
            : 'Connect Ethereum'}
        </Underline>
        {address ? (
          <>
            {' | '}
            <span></span>
            {address?.substring(0, 4)}...{address.substring(address.length - 4)}
          </>
        ) : null}
      </Pointer>
    );
  } else {
    return (
      <Connect
        css={props.style || ''}
        onClick={isConnected ? () => handleSignOut() : () => handleSignIn()}
      >
        {isConnected
          ? props.signOutText
            ? props.signOutText
            : 'Sign out Ethereum'
          : props.signInText
          ? props.signInText
          : 'Connect Ethereum'}
      </Connect>
    );
  }
};
