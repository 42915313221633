import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { AppConfig, UserSession } from '@stacks/connect';
import { network } from '../../utils/SelfServe/contracts';
const appConfig = new AppConfig(['store_write', 'publish_data']);
const userSession = new UserSession({ appConfig });

export const adminAddresses = [
  'SP1CSHTKVHMMQJ7PRQRFYW6SB4QAW6SR3XY2F81PA', // trevor
  'SP1XPG9QFX5M95G36SGN9R8YJ4KJ0JB7ZXNH892N6', // jamil
  'SP2QDMH88MEZ8FFAYHW4B0BTXJRTHX8XBD54FE7HJ', // nick
  'SPQS1J3X9FJ6N4E9K2MW81W5DNBSCC8ZPHR6K2YA', // carole
  'SP3K22XKPT9WJFCE957J94J6XXVZHP7747YNPDTFD', // brett
  'SP1ZTC41HNC5PS8A7K444GBHN4104JXJ5EWRHTDM8', // jack L
  'SP2R3CHRAP1HE4M64X1NZXHZT41JG3XGNHJW4HX2W', // jack N
  'SP14814KM6CBCJZMD15JJ58Q3E2S3NCB6SDXM8C79', // sam
  'SP3WZACEBVVEB4F3SPWQ4N6CWT9Z74VCBA9P16CY5', // albert
  'SP1QXC4BK4WJEWBT18RBDZ04K7AA0PN0CP45H64F5', // tracy
  'SP2KYP13520BR3CN0JAGRJGHEF0EATAVT4580WF3G', // albert 2
];

export const ethAdopters = [
  'SP2HV9HYWZRAPTCC10VXCK72P3W4F9NDB8E1HBEZH', // boozy
  'SP2F2KH0RVX6GF1Y9FWMMSR9RHG0TW3NN72D724NX', // suss
  'SP364J7EDJXRE1FPDZDABP9M58HPY4G88BFCP2HD0', // longstreet
  'SPCBX0GCHMK9GP717F23ZP7V2NM2A0EJ8D634N44', // grymm
  'SP1FJN5P7V9W2K96VN7YWGH7VP36RB5K5JW1R9HF7', // anibal
  'SPV00QHST52GD7D0SEWV3R5N04RD4Q1PMA3TE2MP', // mannymoe
  'SP3BRRCHKMPBR60V8ES9J5YF40VXWMABWXK4SEB9G', // arnofromearth
  'SP3Z0WAQN1YB6N80PZK1CNJB6CMD1F89Y093CWY5W', // frank trask
  'SP3BVZ44BGYS0VM277KET4M5T8EDKN5PN03FDC2AK', // trevor alt
  'SPJSCH3DDEJ8GQPGYZBHSB4F3HX5Q222CG89PSAB', // TheArtist
  'SP1CSHTKVHMMQJ7PRQRFYW6SB4QAW6SR3XY2F81PA', // trevor
  'SP3K22XKPT9WJFCE957J94J6XXVZHP7747YNPDTFD', // brett
  'SP1XPG9QFX5M95G36SGN9R8YJ4KJ0JB7ZXNH892N6', // jamil
  'SP2QDMH88MEZ8FFAYHW4B0BTXJRTHX8XBD54FE7HJ', // nick
  'SP3ZZMJEQEQ9WY56X7YBA9KRHS7CQGTWNHTTQA1GW', // boozy alt
];

export const auctionAdminAddresses = [
  'SP1CSHTKVHMMQJ7PRQRFYW6SB4QAW6SR3XY2F81PA', // trevor
  'SP3K22XKPT9WJFCE957J94J6XXVZHP7747YNPDTFD', // brett
  'SP1XPG9QFX5M95G36SGN9R8YJ4KJ0JB7ZXNH892N6', // jamil
  'SP2QDMH88MEZ8FFAYHW4B0BTXJRTHX8XBD54FE7HJ', // nick
  'SP1QXC4BK4WJEWBT18RBDZ04K7AA0PN0CP45H64F5', // tracy
];

export function EthRoute(props) {
  function stxAddress() {
    if (userSession.isUserSignedIn() && network.isMainnet()) {
      let userData = userSession.loadUserData();
      return userData.profile.stxAddress.mainnet;
    } else if (window.location.port == '3000') {
      return 'test';
    } else {
      return null;
    }
  }

  return (
    <>
      {stxAddress() != null && ethAdopters.includes(stxAddress()) ? (
        <Route {...props} />
      ) : (
        <Redirect to="/" />
      )}
    </>
  );
}

function AdminRoute(props) {
  function stxAddress() {
    if (userSession.isUserSignedIn() && network.isMainnet()) {
      let userData = userSession.loadUserData();
      return userData.profile.stxAddress.mainnet;
    } else if (window.location.port == '3000') {
      return 'test';
    } else {
      return null;
    }
  }

  return (
    <>
      {stxAddress() != null && adminAddresses.includes(stxAddress()) ? (
        <Route {...props} />
      ) : (
        <Redirect to="/" />
      )}
    </>
  );
}

export default AdminRoute;
