import React, { useEffect, useState } from 'react';
import {
  AssetsDropzone,
  BaseUriDropzone,
  CsvDropzone,
  EditionsDropzone,
} from '../../../../components/dropzone';
import {
  Bullet,
  CardGrid,
  CollectionFlex,
  CollectionGrid,
  CollectionRow,
  Container,
  ContainerFull,
  ContainerItem,
  ContainerItemNote,
  ContainerSection,
  CreateCardGrid,
  EmptyDiv,
  FunctionName,
  Grid,
  H1,
  H2,
  H3,
  HeaderRow,
  Logo,
  LogoEnlarged,
  Pointer,
  QuickTipClear,
  QuickTipsGrid,
  Row,
  Rows,
  StepSubtitle,
  StepTitle,
  Tab,
  TextLink,
  ULNone,
  Underline,
  Wrapper,
} from '../../../../components/common';
import { useAtom } from 'jotai';
import {
  assetsAtom,
  attributesAtom,
  collectionNameAtom,
  currentUserAddressAtom,
  filenamesEnabledAtom,
  tokenDescriptionsAtom,
  tokenNamesAtom,
} from '../../../../components/atoms';
import {
  ContinueButton,
  ContinueButtonOutlineFit,
  CreateSmallButton,
  CreateSmallButtonOutline,
  HelpButton,
} from '../../../../components/buttons';
import {
  auctionAddresses,
  auctionContractAddress,
  auctionContractName,
  auctionContractPrincipal,
  basePath,
  endAuction,
  getAuction,
  getAuctionBid,
  isMainnet,
  listAuction,
  revealArtwork,
  selfMint,
  setTokenBaseUri,
  unlistAuction,
} from '../../../../utils/SelfServe/contracts';
import { ContractCall, SetBaseUri } from '../../../../components/contract-call';
import { getContractParts } from '../../../../utils/helpers';
import { submitContinuousToIpfs } from '../../../../utils/ipfs';
import { Link, useHistory, useLocation } from 'react-router-dom';
import makeEndpoint from '../../../../endpoints';
import {
  Switch,
  SwitchFilenames,
  SwitchGeneric,
  SwitchReadOnly,
} from '../../../../components/switch';
import {
  checkMempool,
  getAllNftsForAuction,
  getAuctionRoyalty,
  getBlockHeight,
  getLastTokenId,
  getMints,
  getNftsForAuction,
  getOwners,
} from '../../../../utils/api';
import MintRequest from '../../MintRequest';
import { handleFileInput, renderer } from '../../../../utils';
import { CopyIcon } from '@radix-ui/react-icons';
import { ToastCopyAddress, ToastTransaction } from '../../../../components/toasts';
import {
  LabelInput,
  LabelQuickAction,
  LabelQuickDownload,
  LabelTextArea,
  LabelUpdateRoyalty,
} from '../../../../components/label';
import { ExternalLinkIcon, InfoIcon } from '../../../../utils/svgs';
import { EmptyCard, ReviewCard } from '../../../../components/cards';
import Card from '../../../../components/Card';
import Modal from '../../../../components/Modal';
import ListAuctionModal from '../../../../components/ListAuctionModal';
import TextInput from '../../../../components/TextInput';
import Confirm from '../../../../components/Confirm';
import {
  getFeaturedAuctions,
  getMintRequest,
  postFeaturedAuctions,
} from '../../../../utils/Studio/serverCalls';
import {
  isValidContactInfo,
  isValidDescription2,
  isValidName,
} from '../../../../utils/SelfServe/validation';
import { io } from 'socket.io-client';
import * as stacks from '@stacks/blockchain-api-client';
import FeatureAuctionModal from '../../../../components/FeatureAuctionModal';

const axios = require('axios');
// const socketUrl = 'https://stacks-node-api.mainnet.stacks.co/';
const socketUrl = basePath;
const socket = io(socketUrl, {
  transports: ['websocket'],
});
const sc = new stacks.StacksApiSocketClient(socket);

function StudioCollection(props: { contractId: string; selectedOption?: string }) {
  const search = useLocation().search;
  const functionName = new URLSearchParams(search).get('function');
  const viewmode = new URLSearchParams(search).get('viewmode');
  const tab = new URLSearchParams(search).get('tab');

  const history = useHistory();

  const [assets, setAssets] = useAtom(assetsAtom);
  const [currentUserAddress, setCurrentUserAddress] = useAtom(currentUserAddressAtom);
  const [filenamesEnabled, setFilenamesEnabled] = useAtom(filenamesEnabledAtom);
  const [collectionName, setCollectionName] = useAtom(collectionNameAtom);
  const [attributes, setAttributes] = useAtom(attributesAtom);
  const [tokenDescriptions, setTokenDescriptions] = useAtom(tokenDescriptionsAtom);
  const [tokenNames, setTokenNames] = useAtom(tokenNamesAtom);

  const [assetsError, setAssetsError] = useState('');
  const [contractType, setContractType] = useState('');
  const [attributesError, setAttributesError] = useState('');
  const [contractInterface, setContractInterface] = useState({
    functions: [],
    variables: [],
    maps: [],
    fungible_tokens: [],
    non_fungible_tokens: [],
  });
  const [selectedFunction, setSelectedFunction] = useState<{
    name: string;
    access: string;
    args: [{ name: string; type: string }];
  }>({
    name: '',
    access: '',
    args: [{ name: '', type: '' }],
  });
  const [selectedOption, setSelectedOption] = useState('');
  const [contractParts, setContractParts] = useState({});
  const [contractAddress, setContractAddress] = useState('');
  const [contractName, setContractName] = useState('');
  const [progress, setProgress] = useState('');

  const [collectionData, setCollectionData] = useState({});
  const [logo, setLogo] = useState('');
  const [txUrl, setTxUrl] = useState('');
  const [lastTokenId, setLastTokenId] = useState(0);
  const [artistAddress, setArtistAddress] = useState('');
  const [currentTab, setCurrentTab] = useState(tab || 'Overview');
  const [royaltyPercent, setRoyaltyPercent] = useState<any>(undefined);
  const [blockHeight, setBlockHeight] = useState(0);
  const [auctionDetails, setAuctionDetails] = useState<any[]>([]);
  const [auctionsFetched, setAuctionsFetched] = useState(false);
  const [ownedTokens, setOwnedTokens] = useState<any[]>([]);
  const [ownedTokensFetched, setOwnedTokensFetched] = useState(false);
  const [nftIdsForAuction, setNftIdsForAuction] = useState<any[]>([]);
  const [blockLengthError, setBlockLengthError] = useState('');
  const [reservePriceError, setReservePriceError] = useState('');
  const [listingBlockLength, setListingBlockLength] = useState(undefined);
  const [listingReservePrice, setListingReservePrice] = useState(undefined);
  const [showListModal, setShowListModal] = useState(false);
  const [showFeatureModal, setShowFeatureModal] = useState(false);
  const [modal, setModal] = useState<any>(undefined);
  const [tokenId, setTokenId] = useState(0);
  const [showReadOnly, setShowReadOnly] = useState(false);
  const [mintRequested, setMintRequested] = useState(true);
  const [auctionRequestEmail, setAuctionRequestEmail] = useState('');
  const [auctionRequestEmailError, setAuctionRequestEmailError] = useState('');
  const [auctionRequestSent, setAuctionRequestSent] = useState(false);
  const [mempoolTxs, setMempoolTxs] = useState<any[]>([]);
  const [pendingTxs, setPendingTxs] = useState<any[]>([]);
  const [assetIdentifier, setAssetIdentifier] = useState('');
  const [toastUrl, setToastUrl] = useState('');
  const [previewStep, setPreviewStep] = useState(1);
  const [currentTokenDescription, setCurrentTokenDescription] = useState('');
  const [currentTokenDescriptionError, setCurrentTokenDescriptionError] = useState('');
  const [currentTokenName, setCurrentTokenName] = useState('');
  const [currentTokenNameError, setCurrentTokenNameError] = useState('');
  const [mints, setMints] = useState([]);
  const [owners, setOwners] = useState([]);
  const [baseUriAsset, setBaseUriAsset] = useState<any[]>([]);
  const [baseUriAssetName, setBaseUriAssetName] = useState('');
  const [baseUriAssetDescription, setBaseUriAssetDescription] = useState('');
  const [baseUriTokenId, setBaseUriTokenId] = useState('');
  const [claimSubmitted, setClaimSubmitted] = useState(false);
  const [revealData, setRevealData] = useState<any>({});
  const [featuredAuctions, setFeaturedAuctions] = useState<{
    tokens: string[];
    collections: string[];
  }>({ tokens: [], collections: [] });
  const [featuredTokens, setFeaturedTokens] = useState<string[]>([]);

  useEffect(() => {
    sc.subscribeMempool();
    sc.subscribeBlocks();
    sc.socket.on('mempool', (data) => handleMempoolSocket(data));
    sc.socket.on('block', (data) => handleBlockSocket(data));

    const contractParts = getContractParts(props.contractId);
    const contractAddress = contractParts.contract_address;
    const contractName = contractParts.contract_name;
    setContractParts(contractParts);
    setContractAddress(contractAddress || '');
    setContractName(contractName || '');

    if (contractName) {
      // setCollectionName(contractName);

      fetch(makeEndpoint('/api/drop?name=' + contractName.toLowerCase()))
        .then((res) => {
          return res.json().catch((err) => {
            setCollectionName(contractName);
          });
        })
        .then((data) => {
          setCollectionData(data);
          setLogo(data['image']);
          setCollectionName(data['name'] || contractName);
          setArtistAddress(data['artistAddress']);

          if (data.revealEnabled) {
            const endpoint = makeEndpoint(
              `/get/reveal?address=${data.contractAddress}&name=${data.contractName}`,
            );
            axios
              .get(endpoint, {
                auth: {
                  username: process.env.REACT_APP_LOGIN,
                  password: process.env.REACT_APP_PASSWORD,
                },
              })
              .then((res: any) => {
                setRevealData(res.data);
              })
              .catch((err: any) => console.log(err));
          }
        })
        .catch((err) => console.error(err));
    }

    if (contractAddress && contractName) {
      getLastTokenId(contractAddress, contractName)
        .then((res) => {
          setLastTokenId(parseInt(res));
        })
        .catch((err) => console.error(err));

      getBlockHeight().then((blockHeight) => {
        setBlockHeight(blockHeight);
      });

      getAuctionRoyalty(contractAddress, contractName)
        .then((res) => {
          setRoyaltyPercent(parseInt(res));
        })
        .catch((err) => console.error(err));

      getNftsForAuction(auctionContractPrincipal, contractAddress, contractName, 1000)
        .then((res) => {
          setAuctionsFetched(false);
          if (res.length) {
            setNftIdsForAuction(res);
            fetchDetails(res);
          } else {
            setAuctionsFetched(true);
          }
        })
        .catch((err) => console.error(err));

      getFeaturedAuctions().then((res: any) => {
        setFeaturedAuctions(res);
        if (!res) return;
        const featuredTokens = res.tokens.filter(
          (e: string) => e.split('::')[0] === contractAddress + '.' + contractName,
        );
        setFeaturedTokens(featuredTokens);
      });

      fetch(`${basePath}/v2/contracts/interface/${contractAddress}/${contractName}`)
        .then((res) => {
          return res.json();
        })
        .then((obj) => {
          setContractInterface(obj);
          if (obj.maps.length && obj.maps[0].name === 'cids') {
            setContractType('continuous');
          }

          if (obj.functions.length && functionName) {
            const match = obj['functions'].filter((f: any) => {
              return f.name === functionName;
            });
            if (match.length) {
              setSelectedFunction(match[0]);
              handleSetCurrentTab('AdvancedFunctions');
            }
          }

          if (obj.non_fungible_tokens.length) {
            const assetIdentifier = `${contractAddress}.${contractName}::${obj.non_fungible_tokens[0].name}`;
            setAssetIdentifier(assetIdentifier);

            fetch(
              `${basePath}/extended/v1/tokens/nft/holdings?principal=${currentUserAddress}&asset_identifiers=${assetIdentifier}`,
            )
              .then((res) => {
                return res.json();
              })
              .then((obj) => {
                const results = obj.results;
                if (results.length) {
                  fetchOwnedTokens(results);
                }
              })
              .catch((err) => console.log(err));

            getMints(assetIdentifier, 10000)
              .then((res: any) => {
                setMints(res);
              })
              .catch((err) => console.log(err));

            getOwners(contractParts.contract_id)
              .then((res: any) => {
                setOwners(res);
              })
              .catch((err) => console.log(err));
          }
        })
        .catch((err) => console.error(err));

      getMintRequest({
        contractAddress,
        contractName,
      })
        .then((res: any) => {
          if (res) {
            setMintRequested(true);
          } else {
            setMintRequested(false);
          }
        })
        .catch((err) => {
          console.log(err);
        });

      fetchMempool();
      fetchPendingTransactions();
    }

    return function cleanup() {
      sc.unsubscribeMempool();
      sc.unsubscribeBlocks();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.contractId, currentUserAddress]);

  async function fetchOwnedTokens(nfts: Array<any>) {
    const tokens = [];
    setOwnedTokensFetched(false);
    for (let i = 0; i < nfts.length; i++) {
      const nft = nfts[i];
      const tokenId = nft.value.repr.replace('u', '');
      const contractId = nft.asset_identifier.split('::')[0];
      let params = '';
      params += 'address=' + contractId.split('.')[0];
      params += '&name=' + contractId.split('.')[1];
      params += '&id=' + tokenId;
      const res = await fetch('https://legacy.stxnft.com/api/nft?' + params);
      const data = await res.json();

      const token = { contractAddress, contractName, tokenId, data };
      tokens.push(token);
    }
    setOwnedTokensFetched(true);
    setOwnedTokens(tokens);
  }

  async function handleMempoolSocket(data: any) {
    if (
      data.contract_call &&
      data.contract_call.contract_id === `${auctionContractAddress}.${auctionContractName}` &&
      data.contract_call.function_name === 'list-auction'
    ) {
      fetchMempool();
    }

    if (
      data.sender_address === currentUserAddress &&
      data.contract_call &&
      data.contract_call.contract_id === `${contractAddress}.${contractName}`
    ) {
      fetchPendingTransactions();
    }
  }

  async function handleBlockSocket(data: any) {
    console.log('--- BLOCK HEIGHT: ' + data.height + ' ---');

    try {
      const res = await fetch(
        `${basePath}/extended/v1/tokens/nft/holdings?principal=${currentUserAddress}&asset_identifiers=${assetIdentifier}`,
      );
      const json = await res.json();
      if (json && json.results && json.results.length) {
        fetchOwnedTokens(json.results);
      }
    } catch (error) {
      console.log(error);
    }

    try {
      const res = await getNftsForAuction(
        auctionContractPrincipal,
        contractAddress,
        contractName,
        1000,
      );
      setAuctionsFetched(false);
      if (res.length) {
        setNftIdsForAuction(res);
        fetchDetails(res);
      } else {
        setAuctionsFetched(true);
      }
    } catch (error) {
      console.log(error);
    }

    fetchMempool();
    fetchPendingTransactions();
  }

  async function fetchPendingTransactions() {
    const mempool = await checkMempool(contractAddress, contractName, 200);
    if (mempool && mempool.length) {
      const txs = [];
      for (let i = 0; i < mempool.length; i++) {
        const tx: any = mempool[i];
        const functionName = tx['contract_call']['function_name'];
        const senderAddress = tx['sender_address'];
        const txId = tx['tx_id'];
        const time = tx['receipt_time_iso'];
        console.log(tx);
        if (senderAddress === currentUserAddress) {
          txs.push({ functionName, senderAddress, txId, time });
        }
      }
      setPendingTxs(txs);
    }
  }

  async function fetchMempool() {
    const mempool = await checkMempool(auctionContractAddress, auctionContractName, 200);
    if (mempool && mempool.length) {
      const txs = mempoolTxs;
      for (let i = 0; i < mempool.length; i++) {
        const tx: any = mempool[i];
        if (
          tx['contract_call'] &&
          (tx['contract_call']['function_name'] === 'list-auction' ||
            tx['contract_call']['function_name'] === 'unlist-auction' ||
            tx['contract_call']['function_name'] === 'end-auction') &&
          tx['contract_call']['function_args'][0]['repr'].replace("'", '') === props.contractId
        ) {
          const functionName = tx['contract_call']['function_name'];
          const contractId = tx['contract_call']['function_args'][0]['repr'].replace("'", '');
          const tokenId = tx['contract_call']['function_args'][1]['repr'].replace('u', '');
          const obj = { functionName, contractId, tokenId };
          console.log(obj);
          txs.push(obj);
        }
      }
      setMempoolTxs(txs);
    }
  }

  async function fetchDetails(nfts: Array<any>) {
    const details: any = [];

    if (nfts && nfts.length > 0 && contractAddress && contractName) {
      for (let i = 0; i < nfts.length; i++) {
        const tokenId = nfts[i];

        const auction = await getAuction(contractAddress, contractName, tokenId);
        const bid = await getAuctionBid(contractAddress, contractName, tokenId);

        let params = '';
        params += 'address=' + contractAddress;
        params += '&name=' + contractName;
        params += '&id=' + tokenId;
        const res = await fetch('https://legacy.stxnft.com/api/nft?' + params);
        const data = await res.json();

        const detail = {
          contractAddress,
          contractName,
          tokenId,
          auction,
          bid,
          data,
        };
        details.push(detail);
      }
    }
    setAuctionDetails(details);
    setAuctionsFetched(true);
  }

  function handleEmailAddressChange(e: React.ChangeEvent<HTMLInputElement>) {
    setAuctionRequestEmailError('');
    setAuctionRequestEmail(e.target.value);
  }

  function handleEmailAddressBlur() {
    if (auctionRequestEmail) {
      const emailAddressCheck = isValidContactInfo(auctionRequestEmail);
      setAuctionRequestEmailError(emailAddressCheck.error);
    }
  }

  function handleBaseUriTokenIdChange(e: React.ChangeEvent<HTMLInputElement>) {
    setBaseUriTokenId(e.target.value);
  }

  function handleBaseUriNameChange(e: React.ChangeEvent<HTMLInputElement>) {
    setBaseUriAssetName(e.target.value);
  }

  function handleDescriptionChange(e: React.ChangeEvent<HTMLTextAreaElement>) {
    const descriptionCheck = isValidDescription2(e.target.value);
    // setDescriptionError(descriptionCheck.error);
    setBaseUriAssetDescription(e.target.value);
  }

  function handleAssetsDrop(acceptedFiles: any[], rejectedFiles: any[]) {
    setProgress('');
    if (rejectedFiles[0] && rejectedFiles[0].errors[0].code === 'file-invalid-type') {
      const errorMessage =
        'File type must be jpg, gif, png, bmp, svg, webp, apng, mp4, webm, mp3, wav';
      setAssetsError(errorMessage);
      setTokenNames([]);
      setTokenDescriptions([]);
      setAssets([]);
    } else if (rejectedFiles[0]) {
      setAssetsError(rejectedFiles[0].errors[0].message);
      setTokenNames([]);
      setTokenDescriptions([]);
      setAssets([]);
    } else {
      setAssetsError('');
      setAssets(acceptedFiles);
      const names = [];
      const descriptions = [];
      for (let i = 0; i < acceptedFiles.length; i++) {
        names.push('');
        descriptions.push('');
      }
      setTokenNames(names);
      setTokenDescriptions(descriptions);
    }
  }

  function handleTokenNameChange(e: React.ChangeEvent<HTMLInputElement>) {
    const nameCheck = isValidName(e.target.value);
    setCurrentTokenNameError(nameCheck.error);
    setCurrentTokenName(e.target.value);
    const names = tokenNames;
    names[previewStep - 1] = e.target.value;
    setTokenNames(names);
  }

  function handleTokenDescriptionChange(e: React.ChangeEvent<HTMLTextAreaElement>) {
    const descriptionCheck = isValidDescription2(e.target.value);
    setCurrentTokenDescriptionError(descriptionCheck.error);
    setCurrentTokenDescription(e.target.value);
    const descriptions = tokenDescriptions;
    descriptions[previewStep - 1] = e.target.value;
    setTokenDescriptions(descriptions);
    // console.log(tokenDescriptions);
  }

  function handleStepCallback(step: number) {
    setPreviewStep(step);
  }

  function handleFilenamesEnabledChange(checked: boolean) {
    setFilenamesEnabled(checked);
  }

  async function handleSelfMint() {
    const data = {
      collectionName: collectionName || contractName,
      assets,
      filenamesEnabled,
      lastTokenId,
      attributes,
      tokenNames,
      tokenDescriptions,
    };

    const result = await submitContinuousToIpfs(data, handleProgress, handleProgressDone);

    if (!result?.baseUris) {
      console.log('no base uris');
      return;
    }

    selfMint(
      currentUserAddress,
      contractName,
      result?.baseUris,
      handleContractCallFinish,
      handleContractCallCancel,
    );
  }

  async function handleSetBaseUri(functionName: string) {
    const data = {
      collectionName: collectionName || contractName,
      assets: baseUriAsset,
      filenamesEnabled: true,
      lastTokenId,
      tokenDescriptions: [baseUriAssetDescription],
      customName: baseUriAssetName,
      attributes,
    };

    const result = await submitContinuousToIpfs(data, handleProgress, handleProgressDone);

    if (!result?.baseUris) {
      console.log('no base uris');
      return;
    }

    setTokenBaseUri(
      currentUserAddress,
      contractName,
      result?.baseUris[0],
      baseUriTokenId,
      functionName,
      handleContractCallFinish,
      handleContractCallCancel,
    );
  }

  async function handleRevealArtwork(functionName: string) {
    console.log(revealData);
    const hash = revealData['revealMetadataHash'];
    const uri = `ipfs://ipfs/${hash}/json/`;

    if (contractAddress && contractName) {
      revealArtwork(
        contractAddress,
        contractName,
        uri,
        (progress: string) => handleProgress(progress),
        true,
      );
    }
  }

  function handleProgress(text: string) {
    setProgress(text);
  }

  function handleProgressDone() {
    console.log('progress done');
  }

  function handleContractCallFinish(url: string) {
    if (url) {
      console.log('Transactions finished with url ' + url);
      setAssets([]);
      setToastUrl(url);
      setClaimSubmitted(true);
    }
  }

  function handleContractCallCancel() {
    setProgress('Transaction cancelled.');
  }

  function handleFunctionSelected(f: {
    name: string;
    access: string;
    args: [{ name: string; type: any }];
  }) {
    setSelectedFunction(f);
    setSelectedOption('');
    setProgress('');
    setTxUrl('');
    setAssets([]);

    let currentUrlParams = new URLSearchParams(window.location.search);
    currentUrlParams.set('function', f.name);
    history.push(window.location.pathname + '?' + currentUrlParams.toString());
  }

  async function handleAttributesDrop(acceptedFiles: any[], rejectedFiles: any[]) {
    const csv = await handleFileInput(acceptedFiles[0], 'attributes');

    if (!assets.length) {
      setAttributesError('Upload your assets first');
    } else if (csv.attributes.length !== assets.length) {
      setAttributes([]);
      setAttributesError("Attributes don't match number of assets");
    } else if (rejectedFiles[0]) {
      setAttributes([]);
      setAttributesError(rejectedFiles[0].errors[0].message);
    } else {
      setAttributesError('');
      setAttributes(csv.attributes);
    }
  }

  async function handleBaseUriAttributesDrop(acceptedFiles: any[], rejectedFiles: any[]) {
    const csv = await handleFileInput(acceptedFiles[0], 'attributes');

    if (!baseUriAsset.length) {
      setAttributesError('Upload your asset first');
    } else if (csv.attributes.length !== baseUriAsset.length) {
      setAttributes([]);
      setAttributesError("Attributes don't match number of assets");
    } else if (rejectedFiles[0]) {
      setAttributes([]);
      setAttributesError(rejectedFiles[0].errors[0].message);
    } else {
      setAttributesError('');
      setAttributes(csv.attributes);
    }
  }

  function getSelectedFunction(name: string): {
    name: string;
    access: string;
    args: [{ name: string; type: string }];
  } {
    for (let i = 0; i < contractInterface.functions.length; i++) {
      const f = contractInterface.functions[i];
      if (f['name'] === name) {
        return f;
      }
    }

    return {
      name: '',
      access: '',
      args: [{ name: '', type: '' }],
    };
  }

  function renderInterface() {
    if (!contractInterface.functions) {
      return null;
    }

    return contractInterface.functions.map(
      (
        f: {
          name: string;
          access: string;
          args: [{ name: string; type: any }];
        },
        i,
      ) => {
        if (
          (f.access === 'public' && !showReadOnly) ||
          (f.access === 'read_only' && showReadOnly)
        ) {
          return (
            <Row
              css={{
                borderBottom: '1px solid',
                borderColor: '$border',
                padding: '20px 10px',
                cursor: 'pointer',
                backgroundColor: selectedFunction.name === f.name ? '$border' : '$background',
              }}
              onClick={() => handleFunctionSelected(f)}
            >
              <div>{f.name}</div>
            </Row>
          );
        } else {
          return null;
        }
      },
    );
  }

  function handleBaseUriDrop(acceptedFiles: any[], rejectedFiles: any[]) {
    if (rejectedFiles[0]) {
      // setEditionError(rejectedFiles[0].errors[0].message);
      setBaseUriAsset([]);
    } else {
      // setEditionError('');
      setBaseUriAsset(acceptedFiles);
    }
  }

  function renderSelection() {
    if (selectedFunction.name === 'set-royalty-percent') {
      return (
        <Container css={{ paddingTop: 40 }}>
          {/* <H2>Set royalty percent</H2> */}

          {/* <LabelInput
            placeholder="5%"
            id="royalty"
            label="Royalty (optional)"
            type="number"
            value={royalty}
            error={royaltyError}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleRoyaltyChange(e)}
            onBlur={() => handleRoyaltyBlur()}
            note="Enter the royalty percentage you would like to receive on secondary sales (0-10). Will default to 5% if left blank."
          /> */}

          <LabelUpdateRoyalty
            label="Set royalty percent"
            note="Enter the percentage you'd like to receive for royalties on secondary sales (applies to non-custodial sales only)."
            functionName="set-royalty-percent"
            id="royalty"
            type="number"
            placeholder="0-10"
            contractAddress={contractAddress}
            contractName={contractName}
            access="public"
            args={[{ name: 'royalty', type: 'uint128' }]}
          />

          <ContainerItem>
            <EmptyDiv css={{ color: 'gray' }}>
              * Gamma.io sets royalties for secondary sales to 5% by default. To update the royalty
              amount for custodial listing, contact us directly at support@gamma.io.
            </EmptyDiv>
          </ContainerItem>

          {renderProgress()}
        </Container>
      );
    } else if (selectedFunction.name === 'claim' && contractType === 'continuous') {
      return (
        <Container css={{ paddingTop: 40 }}>
          <H2>Add to your collection</H2>

          <AssetsDropzone
            onDrop={(acceptedFiles: [], rejectedFiles: []) =>
              handleAssetsDrop(acceptedFiles, rejectedFiles)
            }
            label=""
            note="Upload the assets that you would like to mint. They will be added to your existing collection."
            error={assetsError}
            showThumbs={true}
            offset={lastTokenId}
          />

          {assets && assets.length ? (
            <ContainerItem>
              <SwitchFilenames
                value={filenamesEnabled}
                onChange={(checked: boolean) => handleFilenamesEnabledChange(checked)}
              />
            </ContainerItem>
          ) : null}

          {assets && assets.length ? (
            <ContainerItem>
              <CsvDropzone
                onDrop={(acceptedFiles: [], rejectedFiles: []) =>
                  handleAttributesDrop(acceptedFiles, rejectedFiles)
                }
                label="Attributes (optional)"
                note="Upload a .csv file of attributes for your assets. Format your file so that each row represents a token id and each column represents an attribute value."
                error={attributesError}
                successText={
                  attributes.length
                    ? `Success! Attributes for ${attributes.length} assets added`
                    : ''
                }
                sample="attributes.csv"
              />
            </ContainerItem>
          ) : null}

          <ContainerItem>
            <ContinueButton
              disabled={!assets.length}
              text="Publish"
              onClick={() => handleSelfMint()}
            />
          </ContainerItem>

          {renderProgress()}
        </Container>
      );
    } else if (
      (selectedFunction.name === 'set-base-uri' && contractType === 'continuous') ||
      (selectedFunction.name === 'set-token-uri' && contractType === 'continuous')
    ) {
      return (
        <Container css={{ paddingTop: 40 }}>
          {/* <SetBaseUri
              contractAddress={contractAddress}
              contractName={contractName}
              onFinish={undefined}
              onCancel={undefined}
              onProgress={undefined}
            /> */}
          <H2>Update token metadata</H2>

          <ContainerItem>
            <LabelInput
              placeholder="1"
              label="Id"
              note="Enter the id of the token you want to update."
              id="tokenid"
              type="number"
              value={baseUriTokenId}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleBaseUriTokenIdChange(e)}
            />
          </ContainerItem>

          <ContainerItem>
            <LabelInput
              placeholder="Token Name"
              label="Name"
              note="Enter the name you want to use for this NFT. If you want the name to stay the same, leave blank."
              id="tokenname"
              type="text"
              value={baseUriAssetName}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleBaseUriNameChange(e)}
            />
          </ContainerItem>

          <BaseUriDropzone
            onDrop={(acceptedFiles: [], rejectedFiles: []) =>
              handleBaseUriDrop(acceptedFiles, rejectedFiles)
            }
            label="Asset"
            note="Upload a single asset to be used as the asset for your collection. The same asset will be minted based on the number of editions you make available for purchase."
            error={''}
            successText={baseUriAsset.length > 0 ? 'Success! Asset added' : ''}
            files={baseUriAsset}
            tokenName={baseUriAssetName}
            tokenDescription={baseUriAssetDescription}
            attributes={attributes}
          />

          {baseUriAsset && baseUriAsset.length ? (
            <ContainerItem>
              <CsvDropzone
                onDrop={(acceptedFiles: [], rejectedFiles: []) =>
                  handleBaseUriAttributesDrop(acceptedFiles, rejectedFiles)
                }
                label="Attributes (optional)"
                note="Upload a .csv file of attributes for your assets. Format your file so that each row represents a token id and each column represents an attribute value."
                error={attributesError}
                successText={
                  attributes.length
                    ? `Success! Attributes for ${attributes.length} assets added`
                    : ''
                }
                sample="attributes.csv"
              />
            </ContainerItem>
          ) : null}

          <ContainerItem>
            <LabelTextArea
              placeholder="This is the new description for the token."
              label="Description (optional)"
              note="Enter a token level description. This will not change the collection level description."
              id="tokendescription"
              value={baseUriAssetDescription}
              onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => handleDescriptionChange(e)}
            />
          </ContainerItem>

          {/* {assets && assets.length ? (
              <ContainerItem>
                <SwitchFilenames
                  value={filenamesEnabled}
                  onChange={(checked: boolean) => handleFilenamesEnabledChange(checked)}
                />
              </ContainerItem>
            ) : null} */}

          <ContainerItem>
            <ContinueButton
              disabled={!baseUriAsset.length || !baseUriTokenId}
              text="Publish"
              onClick={() => handleSetBaseUri(selectedFunction.name)}
            />
          </ContainerItem>

          {renderProgress()}
        </Container>
      );
    } else if (selectedFunction.name === 'reveal-artwork' && revealData) {
      return (
        <Container css={{ paddingTop: 40 }}>
          <H2>Reveal the artwork for your collection</H2>
          <ContainerItem>
            <ContinueButton
              disabled={false}
              text="Reveal"
              onClick={() => handleRevealArtwork(selectedFunction.name)}
            />
          </ContainerItem>

          {renderProgress()}
        </Container>
      );
    } else if (selectedFunction.name === 'add-mintpasses') {
      return (
        <Container css={{ paddingTop: 30 }}>
          <ContainerItem>
            <TextLink
              href={`/manage/${props.contractId}/${search}&viewmode=single-function`}
              target="_blank"
              rel="noopener noreferrer"
            >
              View in single-function mode
            </TextLink>
          </ContainerItem>

          <ContractCall
            contractAddress={contractAddress}
            contractName={contractName}
            access={selectedFunction.access}
            name={selectedFunction.name}
            args={selectedFunction.args}
            onFinish={(tx: string) => handleContractCallFinish(tx)}
            onCancel={() => handleContractCallCancel()}
            onProgress={(progress: string) => setProgress(progress)}
          />
        </Container>
      );
    } else if (selectedFunction.name) {
      return (
        <Container css={{ paddingTop: 30 }}>
          <ContainerItem>
            <TextLink
              href={`/manage/${props.contractId}/${search}&viewmode=single-function`}
              target="_blank"
              rel="noopener noreferrer"
            >
              View in single-function mode
            </TextLink>
          </ContainerItem>

          <ContractCall
            contractAddress={contractAddress}
            contractName={contractName}
            access={selectedFunction.access}
            name={selectedFunction.name}
            args={selectedFunction.args}
            onFinish={(tx: string) => handleContractCallFinish(tx)}
            onCancel={() => handleContractCallCancel()}
            onProgress={(progress: string) => setProgress(progress)}
          />
        </Container>
      );
    } else if (selectedOption === 'Mint Request') {
      return <MintRequest isManaging={true} contractId={props.contractId} />;
    } else if (viewmode === 'single-function') {
      return <Container css={{ paddingTop: 30 }}>Function not found</Container>;
    } else {
      return <Container css={{ paddingTop: 30 }}>Make a selection on the left</Container>;
    }
  }

  function renderProgress() {
    if (txUrl) {
      return (
        <div>
          {progress}{' '}
          <TextLink href={txUrl} target="_blank" rel="noopener noreferrer">
            View your transaction.
          </TextLink>
        </div>
      );
    } else {
      return <div>{progress}</div>;
    }
  }

  function hasFunction(functionName: string) {
    if (contractInterface.functions.some((e) => e['name'] === functionName)) {
      return true;
    } else {
      return false;
    }
  }

  function getTokenFilename(index: number) {
    if (tokenNames && tokenNames[index]) {
      return tokenNames[index];
    } else if (filenamesEnabled) {
      return assets[index].file.name.split('.')[0];
    } else {
      return `${collectionName} #${lastTokenId + 1 + index}`;
    }
  }

  function renderAddNftList() {
    return assets.map((a, index) => {
      return (
        <div>
          {getTokenFilename(index)} ({lastTokenId + 1 + index})
        </div>
      );
    });
  }

  function isAdmin() {
    return currentUserAddress === contractAddress || currentUserAddress === artistAddress;
  }

  function renderRecentAuctions() {
    if (auctionDetails && auctionDetails.length) {
      return auctionDetails.map((detail: any, idx: number) => {
        let isPending = false;
        if (mempoolTxs.some((e) => detail.tokenId === e.tokenId)) {
          isPending = true;
        }

        let auctionText = undefined;
        let sold = false;

        if (isPending) {
          auctionText = 'PENDING';
        } else if (
          detail &&
          detail.auction['end-block-height'].value &&
          blockHeight &&
          detail.auction['end-block-height'].value.value <= blockHeight + 1
        ) {
          sold = true;
        } else if (detail) {
          auctionText = 'LIVE';
        }

        let blocksRemaining = undefined;
        if (
          detail.auction['end-block-height'].value &&
          detail.auction['end-block-height'].value.value
        ) {
          blocksRemaining = detail.auction['end-block-height'].value.value - blockHeight - 1;
        }

        const auctionFlag = !sold;

        let auctionDetailsText = '';
        if (sold && detail.bid.amount.value != 0) {
          auctionDetailsText = `SOLD FOR ${detail.bid.amount.value / 1e6} STX`;
        } else if (detail && detail.bid.amount.value != 0 && blocksRemaining) {
          auctionDetailsText = `${detail.bid.amount.value / 1e6} STX - ${blocksRemaining} blocks`;
        } else if (detail) {
          auctionDetailsText = `RESERVE ${detail.auction['reserve-price'].value / 1e6} STX`;
        } else if (auctionFlag && !auctionsFetched) {
          auctionDetailsText = 'Loading...';
        } else if (auctionFlag && auctionsFetched) {
          auctionDetailsText = 'NOT LISTED';
        }

        return (
          <Card
            key={idx}
            renderer={renderer(detail.data, undefined, 300)}
            collection={detail.data.collection || detail.data.name}
            name={detail.data.name}
            soldFlag={sold || undefined}
            auctionFlag={!sold}
            auctionLoading={!auctionsFetched}
            auctionText={auctionText}
            auctionDetails={detail}
            auctionDetailsText={auctionDetailsText}
            unlistAuctionFunction={
              !blocksRemaining && isAdmin() ? () => handleUnlistAuction(detail.tokenId) : undefined
            }
            viewAuctionPath={`/collection/${contractName}/${detail.tokenId}`}
            endAuctionFunction={
              sold && detail.bid.amount.value != 0 && isAdmin()
                ? () => handleEndAuction(detail.tokenId, detail.bid.amount.value)
                : undefined
            }
            featureAuctionFunction={
              featuredAuctions.collections.includes(contractAddress + '.' + contractName)
                ? () => openFeatureModal(detail.tokenId)
                : undefined
            }
            featureAuctionText={tokenIsFeatured(detail.tokenId) ? 'Remove feature' : 'Add feature'}
          />
        );
      });
    } else if (auctionsFetched && auctionDetails.length === 0) {
      return <div>No auctions found</div>;
    } else {
      return <div>Loading auctions...</div>;
    }
  }

  function handleEndAuction(id: string, bid: number) {
    endAuction(
      currentUserAddress,
      contractAddress,
      contractName,
      Number(id),
      (modal: any) => setModal(modal),
      bid / 1e6,
    );
  }

  function handleUnlistAuction(id: string) {
    unlistAuction(contractAddress, contractName, Number(id), (modal: any) => setModal(modal));
  }

  function handleListAuction() {
    setBlockLengthError('');
    setReservePriceError('');

    let blError = '';
    let rpError = '';
    if (!listingBlockLength || Number(listingBlockLength) <= 1) {
      blError = 'Enter a valid block length (must be an integer > 1)';
    }

    if (!listingReservePrice || (listingReservePrice && Number(listingReservePrice < 0.69))) {
      rpError = 'Enter a valid reserve price (must be a number >= 1 STX)';
    }

    setBlockLengthError(blError);
    setReservePriceError(rpError);

    if (blError || rpError) {
      return;
    }

    closeListModal();

    listAuction(
      currentUserAddress,
      contractAddress,
      contractName,
      Number(tokenId),
      Number(listingBlockLength) + 1,
      Number(listingReservePrice) * 1e6,
      Number(2),
      (modal: any) => setModal(modal),
    );

    setListingBlockLength(undefined);
    setListingReservePrice(undefined);
  }

  function openListModal(id: string) {
    setTokenId(parseInt(id));
    setShowListModal(true);
  }

  function closeListModal() {
    setShowListModal(false);
  }

  function modalClose() {
    setModal(undefined);
  }

  function handleBlockLength(e: any) {
    if (isNaN(e.target.value)) {
      return;
    }

    const blockLength = e.target.value.replaceAll('.', '');
    setListingBlockLength(blockLength);
  }

  function handleReservePrice(e: any) {
    if (isNaN(e.target.value)) {
      return;
    }

    setListingReservePrice(e.target.value);
  }

  function openFeatureModal(id: string) {
    setTokenId(parseInt(id));
    setShowFeatureModal(true);
  }

  async function handleFeatureAuction(tokenId: number) {
    setShowFeatureModal(false);

    const nft: string = contractAddress + '.' + contractName + '::' + tokenId;
    let _featuredAuctions = await getFeaturedAuctions();
    let tokens = _featuredAuctions.tokens.filter(
      (e: string) => e.split('::')[0] !== contractAddress + '.' + contractName,
    );
    if (!tokenIsFeatured(tokenId)) {
      tokens.unshift(nft);
    }
    _featuredAuctions.tokens = tokens;
    // const res = await postFeaturedAuctions(_featuredAuctions);
    // if (res === 'success') {
    //   setFeaturedAuctions(_featuredAuctions);
    //   setModal({ success: true });
    // }
  }

  function closeFeatureModal() {
    setShowFeatureModal(false);
  }

  function renderOwnedTokens() {
    if (ownedTokens && ownedTokens.length) {
      return ownedTokens.map((token: any, idx: number) => {
        let isPending = false;
        if (mempoolTxs.some((e) => token.tokenId === e.tokenId)) {
          isPending = true;
        }

        if (!nftIdsForAuction.includes(token.tokenId)) {
          return (
            <EmptyDiv>
              <Card
                key={idx}
                renderer={renderer(token.data, undefined, 300)}
                collection={token.data.collection || token.data.name}
                name={token.data.name}
                listAuctionFunction={() => openListModal(token.tokenId)}
                viewAuctionPath={`/collection/${contractName}/${token.tokenId}`}
                auctionText={isPending ? 'PENDING' : undefined}
              />
              {/* {contractAddress === currentUserAddress || artistAddress === currentUserAddress ? (
                <button>Set as featured</button>
              ) : null} */}
            </EmptyDiv>
          );
        }
      });
    }
  }

  function handleReadOnly(checked: boolean) {
    setShowReadOnly(checked);
  }

  function hasQuickAction() {
    return (
      hasFunction('admin-airdrop') ||
      hasFunction('enable-premint') ||
      hasFunction('toggle-sale-state') ||
      hasFunction('toggle-pause') ||
      (mints && mints.length) ||
      (owners && owners.length)
    );
  }

  function hasCommonFunction() {
    return (
      hasFunction('set-price') ||
      hasFunction('set-royalty-percent') ||
      hasFunction('set-artist-address')
    );
  }

  function hasDangerZoneFunction() {
    return hasFunction('freeze-metadata') || hasFunction('set-mint-limit') || hasFunction('burn');
  }

  function handleSetCurrentTab(tab: string) {
    if (tab === 'Auctions') {
      getNftsForAuction(auctionContractPrincipal, contractAddress, contractName, 1000)
        .then((res) => {
          setAuctionsFetched(false);
          if (res.length) {
            setNftIdsForAuction(res);
            fetchDetails(res);
          } else {
            setAuctionsFetched(true);
          }
        })
        .catch((err) => console.error(err));
    }

    let currentUrlParams = new URLSearchParams(search);
    currentUrlParams.set('tab', tab);
    if (tab !== 'AdvancedFunctions') {
      currentUrlParams.delete('function');
      currentUrlParams.delete('viewmode');
    }

    setCurrentTab(tab);
    history.push(window.location.pathname + '?' + currentUrlParams.toString());
  }

  async function handleAuctionRequest() {
    if (!auctionRequestEmail || auctionRequestEmailError) {
      setAuctionRequestEmailError('Please enter a valid email address');
      return;
    }

    const zapierData: any = {
      emailAddress: auctionRequestEmail,
      contractAddress,
      contractName,
      contractId: props.contractId,
      transaction: `https://explorer.stacks.co/txid/${props.contractId}?chain=mainnet`,
      collectionPage: 'https://create.gamma.io/collection/' + contractName,
    };

    // send as webhook
    if (isMainnet) {
      const webhookUrl = 'https://hooks.zapier.com/hooks/catch/11337148/bq6w8wq/';
      axios
        .create({
          transformRequest: [(data: any, _headers: any) => JSON.stringify(zapierData)],
        })
        .post(webhookUrl, zapierData)
        .then((res: any) => {
          console.log(res);
          setAuctionRequestSent(true);
        })
        .catch((err: any) => {
          console.error(err);
        });
    }
  }

  function handleCancelAddNft() {
    setAssetsError('');
    setAssets([]);
    setProgress('');
  }

  function handleToastClose() {
    setToastUrl('');
  }

  function downloadMints() {
    const csv = mints
      .map((it) => {
        return Object.values(it).toString();
      })
      .join('\n');
    const content = 'data:text/csv;charset=utf-8,' + csv;
    let encodedUri = encodeURI(content);
    let link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', 'mints.csv');
    document.body.appendChild(link);
    link.click();
  }

  function downloadOwners() {
    const csv = owners
      .map((it) => {
        return Object.values(it).toString();
      })
      .join('\n');
    const content = 'data:text/csv;charset=utf-8,' + csv;
    let encodedUri = encodeURI(content);
    let link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', 'owners.csv');
    document.body.appendChild(link);
    link.click();
  }

  function tokenIsFeatured(tokenId: number) {
    return featuredAuctions.tokens.includes(contractAddress + '.' + contractName + '::' + tokenId);
  }

  function renderTab() {
    if (viewmode === 'single-function') {
      return (
        <ContainerSection>
          <Row
            css={{
              backgroundColor: '$border',
              justifyContent: 'space-between',
              padding: 10,
            }}
          >
            <Row css={{ alignItems: 'center', gap: 10 }}>
              <InfoIcon />
              Some contract calls result in irreversible changes to your contract; proceed with
              caution.{' '}
              <TextLink
                href="https://support.gamma.io/hc/en-us/articles/6011061378963-How-can-I-manage-my-collection-deployed-using-the-Creators-Self-Service-Portal-"
                target="_blank"
                rel="noopener noreferrer"
              >
                Learn more.
              </TextLink>
            </Row>
          </Row>
          <ContainerItem>{renderSelection()}</ContainerItem>
        </ContainerSection>
      );
    } else if (currentTab === 'MintRequest') {
      return (
        <ContainerSection>
          <MintRequest isManaging={true} contractId={props.contractId} />
        </ContainerSection>
      );
    } else if (currentTab === 'Overview') {
      return (
        <>
          <EmptyDiv css={{ color: 'gray' }}>
            * Gamma.io sets royalties for secondary sales to 5% by default. Contact us directly at
            support@gamma.io to update the royalty amount.
          </EmptyDiv>
          <ContainerSection>
            <h2>Quick actions</h2>
            {hasQuickAction() ? (
              <QuickTipsGrid>
                {mints && mints.length ? (
                  <LabelQuickDownload
                    label="Download mint data"
                    buttonText="Save as mints.csv"
                    note="Click the button below to download the mint data (mint recipient address and token id) for this collection."
                    onClick={() => downloadMints()}
                  />
                ) : null}

                {owners && owners.length ? (
                  <LabelQuickDownload
                    label="Download owner data"
                    buttonText="Save as owners.csv"
                    note="Click the button below to download the owner data (current owner address and token id) for this collection."
                    onClick={() => downloadOwners()}
                  />
                ) : null}

                {hasFunction('admin-airdrop') ? (
                  <LabelQuickAction
                    label="Airdrop tokens"
                    note="Airdrop tokens to all the addresses included on your airdrop list."
                    functionName="admin-airdrop"
                    contractAddress={contractAddress}
                    contractName={contractName}
                    access="public"
                    args={[]}
                  />
                ) : null}

                {hasFunction('enable-premint') ? (
                  <LabelQuickAction
                    label="Start mintpass sale"
                    note="Initialize the mintpass sale for your collection. To be executed before you initiate public sale."
                    functionName="enable-premint"
                    contractAddress={contractAddress}
                    contractName={contractName}
                    access="public"
                    args={[]}
                  />
                ) : null}

                {hasFunction('toggle-sale-state') ? (
                  <LabelQuickAction
                    label="Start public sale"
                    note="Initialize the public sale for your collection. Execute after your mintpass sale has
                completed."
                    functionName="toggle-sale-state"
                    contractAddress={contractAddress}
                    contractName={contractName}
                    access="public"
                    args={[]}
                  />
                ) : null}

                {hasFunction('toggle-pause') ? (
                  <LabelQuickAction
                    label="Toggle pause"
                    note="Pause minting on your collection, temporarily."
                    functionName="toggle-pause"
                    contractAddress={contractAddress}
                    contractName={contractName}
                    access="public"
                    args={[]}
                  />
                ) : null}
              </QuickTipsGrid>
            ) : (
              <div>No quick actions found for this contract</div>
            )}
          </ContainerSection>

          <ContainerSection>
            <h2>Other common functions</h2>
            {hasCommonFunction() ? (
              <QuickTipsGrid>
                {hasFunction('set-price') ? (
                  <LabelQuickAction
                    label="Set price"
                    note="Change the mint price for your collection (STX)"
                    functionName="set-price"
                    id="price"
                    type="number"
                    placeholder="uint"
                    contractAddress={contractAddress}
                    contractName={contractName}
                    access="public"
                    args={[{ name: 'price', type: 'uint128' }]}
                  />
                ) : null}

                {/* {hasFunction('set-royalty-percent') ? (
                  <LabelQuickAction
                    label="Update royalty"
                    note="Update the royalty percentage you receive on secondary sales (non-custodial marketplaces only)."
                    functionName="set-royalty-percent"
                    id="royalty"
                    type="number"
                    placeholder="uint"
                    contractAddress={contractAddress}
                    contractName={contractName}
                    access="public"
                    args={[{ name: 'royalty', type: 'uint128' }]}
                  />
                ) : null} */}

                {hasFunction('set-artist-address') ? (
                  <LabelQuickAction
                    label="Update artist address"
                    note="Update the artist address for your contract. The artist address for each contract is assigned special admin privileges and receives royalties on secondary sales."
                    functionName="set-artist-address"
                    id="royalty"
                    type="text"
                    placeholder="standard principal"
                    contractAddress={contractAddress}
                    contractName={contractName}
                    access="public"
                    args={[{ name: 'address', type: 'principal' }]}
                  />
                ) : null}
              </QuickTipsGrid>
            ) : (
              <div>No common functions found for this contract</div>
            )}
          </ContainerSection>

          <ContainerSection>
            <h2>Danger zone</h2>
            <ContainerItem css={{ color: 'red' }}>
              These actions permanently alter your smart contract.
            </ContainerItem>

            {hasDangerZoneFunction() ? (
              <QuickTipsGrid>
                {hasFunction('freeze-metadata') ? (
                  <LabelQuickAction
                    label="Freeze metadata"
                    note="Freeze the metadata for your contract so that tokens permanently point to the assigned ipfs root."
                    functionName="freeze-metadata"
                    contractAddress={contractAddress}
                    contractName={contractName}
                    access="public"
                    args={[]}
                  />
                ) : null}

                {hasFunction('set-mint-limit') ? (
                  <LabelQuickAction
                    label="Set mint limit"
                    note="Set the maximum number of mints for your contract. You are only able to decrease the collection size."
                    functionName="set-mint-limit"
                    id="mint-limit"
                    type="number"
                    placeholder="uint"
                    contractAddress={contractAddress}
                    contractName={contractName}
                    access="public"
                    args={[{ name: 'limit', type: 'uint128' }]}
                  />
                ) : null}

                {hasFunction('burn') ? (
                  <LabelQuickAction
                    label="Burn token"
                    note="Enter the token id of the NFT you want to remove from the collection. Burned tokens are no longer able to be transferred."
                    functionName="burn"
                    id="burn"
                    type="number"
                    placeholder="uint"
                    contractAddress={contractAddress}
                    contractName={contractName}
                    access="public"
                    args={[{ name: 'burn', type: 'uint128' }]}
                  />
                ) : null}
              </QuickTipsGrid>
            ) : (
              <div>No danger zone function found for this contract</div>
            )}
          </ContainerSection>
        </>
      );
    } else if (
      currentTab === 'Auctions' &&
      currentUserAddress !== contractAddress &&
      currentUserAddress !== artistAddress
    ) {
      return (
        <Container>
          <ContainerItem>
            You're not authorized to manage auctions for this collection
          </ContainerItem>
        </Container>
      );
    } else if (currentTab === 'Auctions' && !royaltyPercent && !auctionRequestSent) {
      return (
        <Container>
          <h2>Start an auction</h2>
          <ContainerItem>
            This smart contract is not yet allowlisted for auctions. You may request approval by
            submitting the form below. Requests usually take 24-48 hours.
          </ContainerItem>
          <LabelInput
            placeholder=""
            label="Contract ID (contract principal)"
            id="start-auction-id"
            type="text"
            value={props.contractId}
            disabled={true}
          />
          <LabelInput
            placeholder="hello@gamma.io"
            label="Email"
            id="start-auction-email"
            type="text"
            value={auctionRequestEmail}
            error={auctionRequestEmailError}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleEmailAddressChange(e)}
            onBlur={() => handleEmailAddressBlur()}
          />
          <ContainerItem>
            <CreateSmallButton text="Submit request" onClick={() => handleAuctionRequest()} />
          </ContainerItem>
        </Container>
      );
    } else if (currentTab === 'Auctions' && !royaltyPercent && auctionRequestSent) {
      return (
        <Container>
          <h2>Thanks for your request!</h2>
          <ContainerItem>Hold tight. Requests usually take 24-48 hours.</ContainerItem>
        </Container>
      );
    } else if (currentTab === 'Auctions' && royaltyPercent) {
      return (
        <ContainerSection>
          <h2>Live auctions</h2>
          <CardGrid>{renderRecentAuctions()}</CardGrid>

          <h2>Start an auction</h2>
          {ownedTokens && ownedTokens.length ? <CardGrid>{renderOwnedTokens()}</CardGrid> : null}

          {!ownedTokens && ownedTokensFetched ? (
            <Row>You don't appear to own any NFTs from this collection</Row>
          ) : null}

          {!ownedTokensFetched ? <Row>Fetching tokens...</Row> : null}
        </ContainerSection>
      );
    } else if (currentTab === 'AdvancedFunctions') {
      return (
        <ContainerSection>
          <h2>Contract functions</h2>
          <ContainerItem>
            All functions will be displayed regardless of your permission to call them, so not all
            functions may apply to you.
          </ContainerItem>
          <Row
            css={{
              backgroundColor: '$border',
              justifyContent: 'space-between',
              padding: 10,
            }}
          >
            <Row css={{ alignItems: 'center', gap: 10 }}>
              <InfoIcon />
              Some contract calls result in irreversible changes to your contract; proceed with
              caution.{' '}
              <TextLink
                href="https://support.gamma.io/hc/en-us/articles/6011061378963-How-can-I-manage-my-collection-deployed-using-the-Creators-Self-Service-Portal-"
                target="_blank"
                rel="noopener noreferrer"
              >
                Learn more.
              </TextLink>
            </Row>
            <Row css={{ alignItems: 'center', gap: 10 }}>
              <SwitchReadOnly
                value={showReadOnly}
                label=""
                onChange={(checked: boolean) => handleReadOnly(checked)}
              />
              Show read-only functions
            </Row>
          </Row>
          <CollectionFlex>
            <Rows css={{ borderLeft: '1px solid', borderColor: '$border', gap: 0 }}>
              {renderInterface()}
            </Rows>
            {renderSelection()}
          </CollectionFlex>
        </ContainerSection>
      );
    } else if (currentTab === 'AddNfts' && (claimSubmitted || (pendingTxs && pendingTxs.length))) {
      return (
        <Container>
          {claimSubmitted ? (
            <div>
              Transaction submitted! Check your pending transactions before minting another token.
            </div>
          ) : (
            <div>
              You have pending transactions! Check your pending transactions before minting another
              token.
            </div>
          )}
        </Container>
      );
    } else if (currentTab === 'AddNfts') {
      return (
        <Container>
          <h2>Add to your collection</h2>
          <ContainerItem>
            Continuous collections allow you to add new NFTs over time. Upload a file below to add
            NFTs. If selected, the name of your file will become the display name of your NFT.
          </ContainerItem>

          <ContainerSection>
            <AssetsDropzone
              onDrop={(acceptedFiles: [], rejectedFiles: []) =>
                handleAssetsDrop(acceptedFiles, rejectedFiles)
              }
              label="File upload"
              note=""
              error={assetsError}
              showThumbs={true}
              offset={lastTokenId}
              stepCallback={(step: number) => handleStepCallback(step)}
            />
            {assets && assets.length ? (
              <ContainerItem>
                <SwitchFilenames
                  value={filenamesEnabled}
                  onChange={(checked: boolean) => handleFilenamesEnabledChange(checked)}
                />
                <LabelInput
                  type="text"
                  placeholder={`Enter a custom name for token #${previewStep + lastTokenId}`}
                  label="Custom token name (optional)"
                  id="custom-names"
                  note="You can enter a custom name below for each of your assets. This will override the 'Use filenames...' option for the specified token."
                  value={tokenNames[previewStep - 1]}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleTokenNameChange(e)}
                  error={''}
                />
                <LabelTextArea
                  placeholder={`Enter a custom description for token #${previewStep + lastTokenId}`}
                  label="Custom token description (optional)"
                  id="custom-description"
                  note="You can enter a custom description below for each of your assets. This is will change the metadata description for the previewed token, which is particularly useful for auctions and 1/1 artworks."
                  value={tokenDescriptions[previewStep - 1]}
                  onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                    handleTokenDescriptionChange(e)
                  }
                  error={currentTokenDescriptionError}
                />
              </ContainerItem>
            ) : null}
            {assets && assets.length ? (
              <ContainerItem>
                <CsvDropzone
                  onDrop={(acceptedFiles: [], rejectedFiles: []) =>
                    handleAttributesDrop(acceptedFiles, rejectedFiles)
                  }
                  label="Attributes (optional)"
                  note="Upload a .csv file of attributes for your assets. Format your file so that each row represents a token id and each column represents an attribute value."
                  error={attributesError}
                  successText={
                    attributes.length
                      ? `Success! Attributes for ${attributes.length} assets added`
                      : ''
                  }
                  sample="attributes.csv"
                />
              </ContainerItem>
            ) : null}{' '}
          </ContainerSection>

          {assets && assets.length ? (
            <ContainerSection>
              <h2>Summary</h2>
              <ContainerItem>
                <strong>{assets.length}</strong> NFT(s) will be added to your collection. You cannot
                remove NFTs from your collection later.
              </ContainerItem>
              {/* <ol start={lastTokenId + 1}>{renderAddNftList()}</ol> */}
              {renderAddNftList()}
              <ContainerItem>
                NFTs will be minted directly to your wallet address, where you can display, list, or
                auction them: <strong>{currentUserAddress}</strong>
              </ContainerItem>
            </ContainerSection>
          ) : null}

          {assets && assets.length ? (
            <ContainerSection>
              <ContainerItem>
                <CollectionFlex css={{ gap: 20 }}>
                  <ContinueButton
                    disabled={!assets.length}
                    text="Add NFT(s)"
                    onClick={() => handleSelfMint()}
                  />
                  <ContinueButtonOutlineFit text="Cancel" onClick={() => handleCancelAddNft()} />
                </CollectionFlex>
              </ContainerItem>

              {renderProgress()}
            </ContainerSection>
          ) : null}
        </Container>
      );
    }
  }

  function renderPendingTransactions() {
    return pendingTxs.map((tx) => {
      return (
        <li>
          <a
            href={`https://explorer.stacks.co/txid/${tx.txId}?chain=mainnet`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {tx.functionName}
          </a>{' '}
          was called ({tx.time.split('T')[0]}, {tx.time.split('.')[0].split('T')[1]})
        </li>
      );
    });
  }

  if (contractAddress && contractName && viewmode === 'single-function') {
    return (
      <ContainerFull>
        <Link to={`/manage/${contractAddress}.${contractName}`}>
          <Underline>Manage collection</Underline>
        </Link>
        <H1>{collectionName}</H1>
        <HeaderRow>
          <div>
            <TextLink
              href={`https://explorer.stacks.co/txid/${contractAddress}.${contractName}?chain=mainnet`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {contractAddress + '.' + contractName}
            </TextLink>{' '}
            <ToastCopyAddress address={contractAddress + '.' + contractName} />
          </div>
          <Bullet>
            <a
              href={`https://stacks.gamma.io/collections/${contractAddress}.${contractName}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <CreateSmallButtonOutline text="Marketplace" />
            </a>
            <Link to={`/collection/${contractName}`}>
              <CreateSmallButtonOutline text="Mint page" />
            </Link>
          </Bullet>
        </HeaderRow>

        {renderTab()}

        {showListModal && (
          <Modal onClose={() => closeListModal()}>
            <ListAuctionModal
              tokenId={Number(tokenId)}
              onClose={() => handleListAuction()}
              title={`Auction Details for Token #${tokenId}`}
            >
              <div>
                <div>Enter block length (10 minutes per block)</div>
                <TextInput
                  placeholder={'Block length 144 = 24 hrs'}
                  error={blockLengthError}
                  value={listingBlockLength || ''}
                  onChange={(e) => handleBlockLength(e)}
                />
              </div>
              <div>
                <div>Enter reserve price in STX</div>
                <TextInput
                  placeholder={'Minimum bid amount'}
                  error={reservePriceError}
                  value={listingReservePrice || ''}
                  onChange={(e) => handleReservePrice(e)}
                />
              </div>
            </ListAuctionModal>
          </Modal>
        )}

        {modal && (
          <Modal onClose={() => modalClose()}>
            <Confirm
              success={modal['success']}
              transactionId={modal['successTransaction']}
              errorMessage={modal['errorMessage']}
              onClose={() => modalClose()}
            />
          </Modal>
        )}
      </ContainerFull>
    );
  } else if (contractAddress && contractName) {
    return (
      <ContainerFull>
        <Underline>
          <Link to="/manage">Back to all collections</Link>
        </Underline>

        <H1>{collectionName}</H1>
        <HeaderRow>
          <div>
            <TextLink
              href={`https://explorer.stacks.co/txid/${contractAddress}.${contractName}?chain=mainnet`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {contractAddress + '.' + contractName}
            </TextLink>{' '}
            <ToastCopyAddress address={contractAddress + '.' + contractName} />
          </div>
          <Bullet>
            {!mintRequested &&
            (contractAddress === currentUserAddress || artistAddress === currentUserAddress) ? (
              <CreateSmallButton
                text="Complete submission"
                onClick={() => handleSetCurrentTab('MintRequest')}
              />
            ) : null}

            <a
              href={`https://stacks.gamma.io/collections/${contractAddress}.${contractName}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <CreateSmallButtonOutline text="Marketplace" />
            </a>
            <Link to={`/collection/${contractName}`}>
              <CreateSmallButtonOutline text="Mint page" />
            </Link>
          </Bullet>
        </HeaderRow>

        {pendingTxs && pendingTxs.length ? (
          <ContainerItem>
            <h4>You have pending transactions!</h4>
            <ul>{renderPendingTransactions()}</ul>
          </ContainerItem>
        ) : null}

        <CollectionFlex css={{ marginBottom: '30px' }}>
          <Tab
            onClick={() => handleSetCurrentTab('Overview')}
            css={
              currentTab === 'Overview'
                ? { textDecoration: 'underline' }
                : { textDecoration: 'none' }
            }
          >
            Overview
          </Tab>
          {contractType === 'continuous' ? (
            <Tab
              onClick={() => handleSetCurrentTab('AddNfts')}
              css={
                currentTab === 'AddNfts'
                  ? { textDecoration: 'underline' }
                  : { textDecoration: 'none' }
              }
            >
              Add NFTs
            </Tab>
          ) : null}

          <Tab
            onClick={() => handleSetCurrentTab('Auctions')}
            css={
              currentTab === 'Auctions'
                ? { textDecoration: 'underline' }
                : { textDecoration: 'none' }
            }
          >
            Auctions
          </Tab>
          <Tab
            onClick={() => handleSetCurrentTab('AdvancedFunctions')}
            css={
              currentTab === 'AdvancedFunctions'
                ? { textDecoration: 'underline' }
                : { textDecoration: 'none' }
            }
          >
            Advanced functions
          </Tab>
          <a
            href={`http://stacks.gamma.io/manage/collections/${contractAddress}.${contractName}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Tab>
              <div>
                Manage collection <ExternalLinkIcon />
              </div>
            </Tab>
          </a>
        </CollectionFlex>

        {renderTab()}

        {toastUrl ? <ToastTransaction onClose={() => handleToastClose()} url={toastUrl} /> : null}

        {showListModal && (
          <Modal onClose={() => closeListModal()}>
            <ListAuctionModal
              tokenId={Number(tokenId)}
              onClose={() => handleListAuction()}
              title={`Auction Details for Token #${tokenId}`}
            >
              <div>
                <div>Enter block length (10 minutes per block)</div>
                <TextInput
                  placeholder={'Block length 144 = 24 hrs'}
                  error={blockLengthError}
                  value={listingBlockLength || ''}
                  onChange={(e) => handleBlockLength(e)}
                />
              </div>
              <div>
                <div>Enter reserve price in STX</div>
                <TextInput
                  placeholder={'Minimum bid amount'}
                  error={reservePriceError}
                  value={listingReservePrice || ''}
                  onChange={(e) => handleReservePrice(e)}
                />
              </div>
            </ListAuctionModal>
          </Modal>
        )}

        {/* {showFeatureModal && (
          <Modal onClose={() => closeFeatureModal()}>
            <FeatureAuctionModal
              tokenId={Number(tokenId)}
              onClose={() => handleFeatureAuction(tokenId)}
              title={
                tokenIsFeatured(tokenId)
                  ? `Token #${tokenId} is currently featured`
                  : `Token #${tokenId} is NOT featured`
              }
              onCancel={() => closeFeatureModal()}
              buttonText={tokenIsFeatured(tokenId) ? 'REMOVE FEATURE' : 'ADD FEATURE'}
            >
              <EmptyDiv css={{ marginBottom: 20, padding: 5 }}>
                {tokenIsFeatured(tokenId)
                  ? `Click the button below to REMOVE token #${tokenId} from the list of featured auctions.`
                  : `Click the button below to ADD token #${tokenId} to the list of featured auctions. You can only feature one auction per colleciton. This will remove any of your currently featured auctions.`}
              </EmptyDiv>
            </FeatureAuctionModal>
          </Modal>
        )} */}

        {modal && (
          <Modal onClose={() => modalClose()}>
            <Confirm
              success={modal['success']}
              transactionId={modal['successTransaction']}
              errorMessage={modal['errorMessage']}
              onClose={() => modalClose()}
            />
          </Modal>
        )}
      </ContainerFull>
    );
  } else {
    return <ContainerFull>Contract not found.</ContainerFull>;
  }
}

export default StudioCollection;
