import { useAtom } from 'jotai';
import { useEffect, useState } from 'react';
import {
  isValidAddress,
  isValidContactInfo,
  isValidDescription2,
  isValidDiscordInvite,
  isValidDomain,
  isValidName,
  isValidTwitter,
} from '../../../../utils/SelfServe/validation';
import { ContinueButton } from '../../../../components/buttons';
import { LabelInput, LabelLicenses, LabelTextArea } from '../../../../components/label';
import {
  artistAddressAtom,
  assetsAtom,
  attributesAtom,
  categoryAtom,
  collectionNameAtom,
  descriptionAtom,
  discordAtom,
  emailAddressAtom,
  filenamesEnabledAtom,
  logoAtom,
  logoEnlargedAtom,
  tokenDescriptionsAtom,
  tokenNamesAtom,
  twitterAtom,
  websiteAtom,
} from '../../../../components/atoms';
import { AssetsDropzone, CsvDropzone, LogoDropzone } from '../../../../components/dropzone';
import {
  Container,
  ContainerItem,
  ContainerItemTitle,
  EmptyDiv,
} from '../../../../components/common';
import { ToggleGroupCategory, ToggleTokenNaming } from '../../../../components/toggle-group';
import { SwitchEnlargedImage, SwitchFilenames } from '../../../../components/switch';
import { Link } from 'react-router-dom';
import { handleFileInput } from '../../../../utils';

function Details(props: { onNextStep: any }) {
  const [collectionName, setCollectionName] = useAtom(collectionNameAtom);
  const [description, setDescription] = useAtom(descriptionAtom);
  const [emailAddress, setEmailAddress] = useAtom(emailAddressAtom);
  const [website, setWebsite] = useAtom(websiteAtom);
  const [twitter, setTwitter] = useAtom(twitterAtom);
  const [logo, setLogo] = useAtom(logoAtom);
  const [discord, setDiscord] = useAtom(discordAtom);
  const [category, setCategory] = useAtom(categoryAtom);
  const [logoEnlarged, setLogoEnlarged] = useAtom(logoEnlargedAtom);
  const [assets, setAssets] = useAtom(assetsAtom);
  const [filenamesEnabled, setFilenamesEnabled] = useAtom(filenamesEnabledAtom);
  const [attributes, setAttributes] = useAtom(attributesAtom);
  const [tokenNames, setTokenNames] = useAtom(tokenNamesAtom);
  const [tokenDescriptions, setTokenDescriptions] = useAtom(tokenDescriptionsAtom);
  const [artistAddress, setArtistAddress] = useAtom(artistAddressAtom);

  const [collectionNameError, setCollectionNameError] = useState('');
  const [descriptionError, setDescriptionError] = useState('');
  const [emailAddressError, setEmailAddressError] = useState('');
  const [websiteError, setWebsiteError] = useState('');
  const [twitterError, setTwitterError] = useState('');
  const [logoError, setLogoError] = useState('');
  const [assetsError, setAssetsError] = useState('');
  const [discordError, setDiscordError] = useState('');
  const [categories, setCategories] = useState([]);
  const [attributesError, setAttributesError] = useState('');
  const [previewStep, setPreviewStep] = useState(1);
  const [currentTokenDescription, setCurrentTokenDescription] = useState('');
  const [currentTokenDescriptionError, setCurrentTokenDescriptionError] = useState('');
  const [currentTokenName, setCurrentTokenName] = useState('');
  const [currentTokenNameError, setCurrentTokenNameError] = useState('');
  const [artistAddressError, setArtistAddressError] = useState('');

  useEffect(() => {
    window.scrollTo(0, 0);

    fetch('https://create.gamma.io/api/categories')
      .then((res) => {
        return res.json();
      })
      .then((json) => {
        const cats = json.categories;
        cats.sort((a: any, b: any) => a.id - b.id);
        setCategories(cats);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleCollectionNameChange(e: React.ChangeEvent<HTMLInputElement>) {
    const nameCheck = isValidName(e.target.value);
    setCollectionNameError(nameCheck.error);
    setCollectionName(e.target.value);

    // still need a good way to check if collection name is already taken (currently use blacklist.json)
  }

  function handleDescriptionChange(e: React.ChangeEvent<HTMLTextAreaElement>) {
    const descriptionCheck = isValidDescription2(e.target.value);
    setDescriptionError(descriptionCheck.error);
    setDescription(e.target.value);
  }

  function handleTokenNameChange(e: React.ChangeEvent<HTMLInputElement>) {
    const nameCheck = isValidName(e.target.value);
    setCurrentTokenNameError(nameCheck.error);
    setCurrentTokenName(e.target.value);
    const names = tokenNames;
    names[previewStep - 1] = e.target.value;
    setTokenNames(names);
  }

  function handleTokenDescriptionChange(e: React.ChangeEvent<HTMLTextAreaElement>) {
    const descriptionCheck = isValidDescription2(e.target.value);
    setCurrentTokenDescriptionError(descriptionCheck.error);
    setCurrentTokenDescription(e.target.value);
    const descriptions = tokenDescriptions;
    descriptions[previewStep - 1] = e.target.value;
    setTokenDescriptions(descriptions);
  }

  function handleStepCallback(step: number) {
    setPreviewStep(step);
  }

  function handleEmailAddressChange(e: React.ChangeEvent<HTMLInputElement>) {
    setEmailAddressError('');
    setEmailAddress(e.target.value);
  }

  function handleEmailAddressBlur() {
    if (emailAddress) {
      const emailAddressCheck = isValidContactInfo(emailAddress);
      setEmailAddressError(emailAddressCheck.error);
    }
  }

  function handleCategoryChange(category: string) {
    let categoryArray: number[] = [];
    if (category) {
      categoryArray = [parseInt(category)];
    }
    setCategory(categoryArray);
  }

  function handleWebsiteChange(e: React.ChangeEvent<HTMLInputElement>) {
    setWebsiteError('');
    setWebsite(e.target.value);
  }

  function handleWebsiteBlur() {
    if (website) {
      const websiteCheck = isValidDomain(website);
      setWebsiteError(websiteCheck.error);
    }
  }

  function handleTwitterChange(e: React.ChangeEvent<HTMLInputElement>) {
    setTwitterError('');
    setTwitter(e.target.value);
  }

  function handleTwitterBlur() {
    if (twitter) {
      const twitterCheck = isValidTwitter(twitter);
      setTwitterError(twitterCheck.error);
    }
  }

  function handleDiscordChange(e: React.ChangeEvent<HTMLInputElement>) {
    setDiscordError('');
    setDiscord(e.target.value);
  }

  function handleDiscordBlur() {
    if (discord) {
      const discordCheck = isValidDiscordInvite(discord);
      setDiscordError(discordCheck.error);
    }
  }

  function handleLogoDrop(acceptedFiles: any[], rejectedFiles: any[]) {
    if (rejectedFiles[0]) {
      setLogoError(rejectedFiles[0].errors[0].message);
      setLogo([]);
    } else {
      setLogoError('');
      setLogo(acceptedFiles);
    }
  }

  function handleLogoEnlargedChange(checked: boolean) {
    setLogoEnlarged(checked);
  }

  function handleFilenamesEnabledChange(checked: boolean) {
    setFilenamesEnabled(checked);
  }

  function handleAssetsDrop(acceptedFiles: any[], rejectedFiles: any[]) {
    if (rejectedFiles[0]) {
      setAssetsError(rejectedFiles[0].errors[0].message);
      setAssets([]);
      setTokenNames([]);
      setTokenDescriptions([]);
    } else {
      setAssetsError('');
      setAssets(acceptedFiles);
      const names = [];
      const descriptions = [];
      for (let i = 0; i < acceptedFiles.length; i++) {
        names.push('');
        descriptions.push('');
      }
      setTokenNames(names);
      setTokenDescriptions(descriptions);
    }
  }

  async function handleAttributesDrop(acceptedFiles: any[], rejectedFiles: any[]) {
    const csv = await handleFileInput(acceptedFiles[0], 'attributes');

    if (!assets.length) {
      setAttributesError('Upload your asset first');
    } else if (csv.attributes.length !== assets.length) {
      setAttributes([]);
      setAttributesError("Attributes don't match number of assets");
    } else if (rejectedFiles[0]) {
      setAttributes([]);
      setAttributesError(rejectedFiles[0].errors[0].message);
    } else {
      setAttributesError('');
      setAttributes(csv.attributes);
    }
  }

  function handleContinue() {
    props.onNextStep();
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  }

  function handleArtistAddressChange(e: React.ChangeEvent<HTMLInputElement>) {
    setArtistAddressError('');
    setArtistAddress(e.target.value);
    if (e.target.value !== '') {
      const addressCheck = isValidAddress(e.target.value);
      setArtistAddressError(addressCheck.error);
    }
  }

  function handleArtistAddressBlur() {
    if (artistAddress) {
      const addressCheck = isValidAddress(artistAddress);
      setArtistAddressError(addressCheck.error);
    }
  }

  return (
    <Container>
      <h2>Add your collection details</h2>
      <div>
        Bring your NFTs together in a collection. You'll publish your collection as a smart contract
        — when it's deployed you'll be ready to start minting NFTs.
      </div>

      <ContainerItem>
        <LabelInput
          placeholder="e.g. Hello Potato"
          label="Collection name"
          id="collectionName"
          type="text"
          value={collectionName}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleCollectionNameChange(e)}
          error={collectionNameError}
        />
      </ContainerItem>

      <ContainerItem>
        <LabelTextArea
          placeholder="Potato is good"
          label="Collection description"
          id="description"
          value={description}
          onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => handleDescriptionChange(e)}
          error={descriptionError}
        />
      </ContainerItem>

      <ContainerItem>
        <LabelInput
          placeholder="name@gmail.com"
          label="Email address (will remain private)"
          id="email"
          type="text"
          value={emailAddress}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleEmailAddressChange(e)}
          onBlur={() => handleEmailAddressBlur()}
          error={emailAddressError}
        />
      </ContainerItem>

      <ContainerItem>
        <LogoDropzone
          onDrop={(acceptedFiles: [], rejectedFiles: []) =>
            handleLogoDrop(acceptedFiles, rejectedFiles)
          }
          label="Logo"
          note="Upload an image to be displayed as the logo for your collection.
          Recommended image size is 400x400px."
          error={logoError}
          successText={logo.length > 0 ? 'Success! Logo added' : ''}
          files={logo}
        />
      </ContainerItem>

      {/* <ContainerItem>
        <SwitchEnlargedImage
          value={logoEnlarged}
          onChange={(checked: boolean) => handleLogoEnlargedChange(checked)}
        />
      </ContainerItem> */}

      <ContainerItem>
        <AssetsDropzone
          onDrop={(acceptedFiles: [], rejectedFiles: []) =>
            handleAssetsDrop(acceptedFiles, rejectedFiles)
          }
          label="Assets (optional)"
          note="Upload a folder of your assets to be minted when you deploy the contract. 
          This is optional since you are able to add tokens to personal collections over time."
          error={currentTokenDescriptionError}
          successText={assets.length ? `Success! ${assets.length} assets added` : ''}
          showThumbs={true}
          stepCallback={(step: number) => handleStepCallback(step)}
        />
      </ContainerItem>

      {assets && assets.length ? (
        <ContainerItem>
          <SwitchFilenames
            value={filenamesEnabled}
            onChange={(checked: boolean) => handleFilenamesEnabledChange(checked)}
          />
          <LabelInput
            type="text"
            placeholder={`Enter a custom name for token #${previewStep}`}
            label="Custom token name (optional)"
            id="custom-names"
            note="You can enter a custom name below for each of your assets. This will override the 'Use filenames...' option for the specified token."
            value={tokenNames[previewStep - 1]}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleTokenNameChange(e)}
            error={''}
          />
          <LabelTextArea
            placeholder={`Enter a custom description for token #${previewStep}`}
            label="Custom token description (optional)"
            id="custom-description"
            note="You can enter a custom description below for each of your assets. This is will change the metadata description for the previewed token, which is particularly useful for auctions and 1/1 artworks."
            value={tokenDescriptions[previewStep - 1]}
            onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
              handleTokenDescriptionChange(e)
            }
            error={''}
          />
        </ContainerItem>
      ) : null}

      {assets && assets.length ? (
        <ContainerItem>
          <CsvDropzone
            onDrop={(acceptedFiles: [], rejectedFiles: []) =>
              handleAttributesDrop(acceptedFiles, rejectedFiles)
            }
            label="Attributes (optional)"
            note="Upload a .csv file of attributes for your assets. Format your file so that each row represents a token id and each column represents an attribute value."
            error={attributesError}
            successText={
              attributes.length ? `Success! Attributes for ${attributes.length} assets added` : ''
            }
            sample="attributes.csv"
          />
        </ContainerItem>
      ) : null}

      {categories ? (
        <ContainerItem>
          <ContainerItemTitle>Category (optional)</ContainerItemTitle>
          <ToggleGroupCategory
            value={category[0] || []}
            onChange={(value: string) => handleCategoryChange(value)}
            categories={categories}
          />
        </ContainerItem>
      ) : null}

      <ContainerItem>
        <LabelInput
          placeholder="https://gamma.io"
          label="Website (optional)"
          id="website"
          type="text"
          value={website}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleWebsiteChange(e)}
          onBlur={() => handleWebsiteBlur()}
          error={websiteError}
        />
      </ContainerItem>

      <ContainerItem>
        <LabelInput
          placeholder="https://twitter.com/trygamma"
          label="Twitter (optional)"
          id="twitter"
          type="text"
          value={twitter}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleTwitterChange(e)}
          onBlur={() => handleTwitterBlur()}
          error={twitterError}
        />
      </ContainerItem>

      <ContainerItem>
        <LabelInput
          placeholder="https://discord.gg/tmpSQc2qft"
          label="Discord (optional)"
          id="discord"
          type="text"
          value={discord}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleDiscordChange(e)}
          onBlur={() => handleDiscordBlur()}
          error={discordError}
        />
      </ContainerItem>

      <ContainerItem>
        <LabelInput
          placeholder="SP1CSHTKVHMMQJ7PRQRFYW6SB4QAW6SR3XY2F81PA"
          label="Artist Address (optional)"
          id="artist"
          type="text"
          value={artistAddress}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleArtistAddressChange(e)}
          onBlur={() => handleArtistAddressBlur()}
          error={artistAddressError}
          note="Enter an artist address if you want mint sales to go to a different wallet than the one you're using to deploy the contract. The artist address wallet will also have admin privileges on certain public functions."
        />
      </ContainerItem>

      <ContainerItem>
        <LabelLicenses
          label="Select a license (optional)"
          id="license"
          note={`You can select a "can't be evil" license for your collection below. Can't Be Evil NFT licenses are a set of free, public licenses designed for NFTs and inspired by the work of Creative Commons, released by a16z.`}
        />
      </ContainerItem>

      <ContainerItem css={{ marginTop: 0 }}>
        <ContinueButton
          disabled={
            !collectionName ||
            !emailAddress ||
            !logo.length ||
            !description ||
            artistAddressError !== ''
          }
          text="Continue"
          onClick={() => handleContinue()}
        />

        <ContainerItem>
          <EmptyDiv css={{ color: 'gray' }}>
            * Gamma.io sets royalties for secondary sales to 5% by default. Contact us directly at
            support@gamma.io after deploying your collection to update the royalty amount.
          </EmptyDiv>
        </ContainerItem>

        {/* <ContinueButton disabled={false} text="Continue" onClick={() => handleContinue()} /> */}
      </ContainerItem>
    </Container>
  );
}

export default Details;
