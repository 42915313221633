import React from 'react';
import { Link } from 'react-router-dom';
import { ContainerFull, ContainerItem, H2 } from '../../../components/common';

export default function TermsCreator() {
  return (
    <ContainerFull>
      <H2>Gamma Additional Terms of Use for Create Portal</H2>
      <ol>
        <li style={{ marginBottom: '10px' }}>
          <span style={{ textDecoration: 'underline' }}>Terms of Use</span>. These Additional Terms
          of Use of [STXNFT, Inc., a Delaware corporation doing business as Gamma] (“Gamma,” “we,”
          “us”, or “our”) for Create Portal (“Creator Terms”) are in addition to our{' '}
          <Link to="/terms">Terms of Use</Link>. Pursuant to Section 2 of the Terms of Use, the use
          of this Create Portal (the “Portal”), including creating smart contracts and deploying
          your NFT collection, are considered part of the Service and are therefore subject to the
          Terms of Use. Capitalized terms used in these Creator Terms have the meanings set forth in
          the Terms of Use unless otherwise defined in these Creator Terms.
        </li>
        <li style={{ marginBottom: '10px' }}>
          <span style={{ textDecoration: 'underline' }}>Controlling Terms</span>. Wherever possible,
          these Creator Terms shall be considered in addition to the Terms of Use, and not in lieu
          of the same unless there is a direct contradiction, in which case these Creator Terms
          shall control.
        </li>
        <li style={{ marginBottom: '10px' }}>
          <span style={{ textDecoration: 'underline' }}>Smart Contracts</span>. Smart contracts are
          a mechanism by which NFT collections are managed, minted, and traded on Stacks. This
          Portal helps you deploy a custom smart contract for your collection. That said, because
          smart contracts are deployed to a blockchain, there are many ways in which they may never
          be changed. They also may never be deleted. The creation of a smart contract by you
          through the Portal is considered a User Contribution.
        </li>
        <li style={{ marginBottom: '10px' }}>
          <span style={{ textDecoration: 'underline' }}>Minting Commission</span>. Gamma provides
          you with assistance in creating your own smart contract. In return, the smart contract
          will include a 10% commission that is paid to us each time one of your NFTs is minted.
          This commission is paid only the first time an NFT is minted, and is not charged on
          secondary sales. You, as the creator of the NFT, do not pay anything directly to us, as it
          is your NFT buyers who pay the mint price that is then payable as follows: 10% to us and
          90% to you, the creator or artist wallet address. Due to the nature of the smart contract
          you will deploy in connection with your NFT, this commission trait cannot be edited or
          removed after the smart contract is created.
        </li>
        <li style={{ marginBottom: '10px' }}>
          <span style={{ textDecoration: 'underline' }}>Network Fee</span>. We do not charge any
          flat fee for using our Portal, but the network requires a network fee in order to process
          and confirm your transaction. This requires a payment to the network when submitting your
          transaction. Gamma does not take any portion of this fee and it is possible that you will
          lose this fee if your transaction fails for any reason. Neither Gamma nor the network will
          refund any portion of such fees.
        </li>
        <li style={{ marginBottom: '10px' }}>
          <span style={{ textDecoration: 'underline' }}>Help Center</span>. There are some specific
          formats and requirements required when defining and deploying your smart contract. We’ve
          created a Help Center specifically for creators deploying a smart contract for the first
          time. If you do not follow these instructions as required, your smart contract may be
          deployed in a manner that is unintended, and it is highly likely that the contract will be
          uneditable and therefore can never be used. The Help Center is a guide; it is not a
          guaranty that the smart contract will be defined and deployed by you correctly.
          Ultimately, the terms of the smart contract are your responsibility, and we will have no
          responsibility for the terms of your smart contract.
        </li>
        <li style={{ marginBottom: '10px' }}>
          <span style={{ textDecoration: 'underline' }}>You Own Your Smart Contract</span>. You are
          the sole owner of your smart contract and solely responsible for the legitimacy,
          authenticity, display, deployment, promotion, support, and any liability associated with
          your smart contract and its NFTs. You attest that you are the sole owner of any artwork or
          intellectual property used in connection with your smart contract and attest that your
          smart contract does not violate any other entity’s or persons’ copyright or any other
          rights.
        </li>
        <li style={{ marginBottom: '10px' }}>
          <span style={{ textDecoration: 'underline' }}>Ownership and License</span>. Any User
          Contribution you post to the Site or through the Service will be considered
          non-confidential and non-proprietary. By providing any User Contribution on the Site or
          through the Service, you grant Gamma and its licensee, successors, and assigns a
          worldwide, non-exclusive, sublicensable, royalty-free limited license to use, display,
          distribute, and otherwise disclose to any third party any such User Contribution you
          submit or post on or through the Service for the limited purposes of providing, promoting,
          and improving the Service and as authorized in your user account settings, if any. You
          still retain the ownership and remain the copyright holder of such User Contributions.
        </li>
        <li style={{ marginBottom: '10px' }}>
          <div style={{ textDecoration: 'underline', marginBottom: '10px' }}>Other Notices</div>
          <ol style={{ listStyleType: 'lower-alpha' }}>
            <li style={{ marginBottom: '10px' }}>
              <span style={{ textDecoration: 'underline' }}>No Endorsement</span>. Collections
              deployed using the Portal are not owned by, nor affiliated in any way, with Gamma.
              While we automatically collect 10% of mint revenues via the smart contracts deployed
              using our Portal, this in no way serves to acknowledge or endorse any given
              collection. This is an unmonitored, unmodifiable smart contract template that we do
              not have control over following deployment.
            </li>
            <li style={{ marginBottom: '10px' }}>
              <span style={{ textDecoration: 'underline' }}>No Guaranty</span>. Using our Portal
              does not guarantee your collection will be listed for mint or secondary sales on our
              marketplace. We simply offer this tool to help you with the creation of a tested and
              secure smart contract for your collection. After submission, we will review your
              details once again, and assuming it continues to meet all requirements, we will then
              follow-up with your mint page details.
            </li>
            <li style={{ marginBottom: '10px' }}>
              <span style={{ textDecoration: 'underline' }}>Removal and Control</span>. We reserve
              the right to remove collection mint pages, or prevent collections from appearing on
              our main marketplace for any or no reason if they are found to violate our standards
              or infringe upon any other person or entity's copyright or any law.
            </li>
            <li style={{ marginBottom: '10px' }}>
              <span style={{ textDecoration: 'underline' }}>Other Marketplaces</span>. Gamma will
              not, and has no ability to, prevent your continued use of your smart contract on other
              websites, such as your own or any other marketplace.
            </li>
          </ol>
        </li>
      </ol>
    </ContainerFull>
  );
}
