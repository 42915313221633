import React from 'react';
import { styled } from '@stitches/react';
import { violet, mauve, blackA, whiteA } from '@radix-ui/colors';
import * as SwitchPrimitive from '@radix-ui/react-switch';

const StyledSwitch = styled(SwitchPrimitive.Root, {
  all: 'unset',
  width: 42,
  height: 25,
  backgroundColor: blackA.blackA9,
  borderRadius: '9999px',
  position: 'relative',
  // boxShadow: `0 2px 10px ${blackA.blackA7}`,
  WebkitTapHighlightColor: 'rgba(0, 0, 0, 0)',
  '&:focus': { boxShadow: `0 0 0 2px black` },
  '&[data-state="checked"]': { backgroundColor: 'black' },
});

const StyledThumb = styled(SwitchPrimitive.Thumb, {
  display: 'block',
  width: 21,
  height: 21,
  backgroundColor: 'white',
  borderRadius: '9999px',
  // boxShadow: `0 2px 2px ${blackA.blackA7}`,
  transition: 'transform 100ms',
  transform: 'translateX(2px)',
  willChange: 'transform',
  '&[data-state="checked"]': { transform: 'translateX(19px)' },
});

// Exports
export const Switch = StyledSwitch;
export const SwitchThumb = StyledThumb;

// Your app...
const Flex = styled('div', { display: 'flex' });
const Label = styled('label', {
  // color: 'white',
  fontSize: 15,
  lineHeight: 1,
  userSelect: 'none',
});
const Value = styled('label', {
  fontSize: 15,
  lineHeight: 1,
  userSelect: 'none',
  margin: '0 0 0 10px',
  color: 'Gray',
});

export const SwitchEnlargedImage = (props: { onChange: any; value?: any }) => (
  <form>
    <Flex css={{ alignItems: 'center' }}>
      <Label htmlFor="s1" css={{ paddingRight: 15 }}>
        Enlarge logo on mint page (portal only)
      </Label>
      <Switch
        id="s1"
        defaultChecked={false}
        value={props.value}
        onCheckedChange={(checked: boolean) => props.onChange(checked)}
      >
        <SwitchThumb />
      </Switch>
    </Flex>
  </form>
);

export const SwitchFilenames = (props: { onChange: any; value?: any }) => (
  <form>
    <Flex css={{ alignItems: 'center' }}>
      <Label htmlFor="s2" css={{ paddingRight: 15 }}>
        Use filenames as NFT names (defaults to collection name + token id)
      </Label>
      <Switch
        id="s2"
        defaultChecked={false}
        value={props.value}
        onCheckedChange={(checked: boolean) => props.onChange(checked)}
      >
        <SwitchThumb />
      </Switch>
    </Flex>
  </form>
);

export const SwitchGeneric = (props: {
  onChange: any;
  label: string;
  value: any;
  checked?: boolean;
}) => (
  <form style={{ borderLeft: '1px solid #dcdde2', paddingLeft: '20px' }}>
    <Label htmlFor="s3" css={{ paddingRight: 15 }}>
      {props.label}
    </Label>
    <Flex css={{ alignItems: 'center', marginTop: 6 }}>
      <Switch
        id="s3"
        // defaultChecked={props.defaultChecked || false}
        checked={props.checked}
        value={props.value}
        onCheckedChange={(checked: boolean) => props.onChange(checked)}
      >
        <SwitchThumb />
      </Switch>
      <Value>{props.value ? 'yes' : 'no'}</Value>
    </Flex>
  </form>
);

export const SwitchReadOnly = (props: {
  onChange: any;
  label: string;
  value: any;
  checked?: boolean;
}) => (
  <form style={{ borderLeft: '1px solid #dcdde2', paddingLeft: '20px', alignItems: 'center' }}>
    <Flex css={{ alignItems: 'center' }}>
      <Switch
        id="s3"
        // defaultChecked={props.defaultChecked || false}
        checked={props.checked}
        value={props.value}
        onCheckedChange={(checked: boolean) => props.onChange(checked)}
      >
        <SwitchThumb />
      </Switch>
    </Flex>
  </form>
);
