import React, { useEffect, useState } from 'react';
import {
  CardGrid,
  CollectionFlex,
  Container,
  ContainerFull,
  ContainerItem,
  EmptyDiv,
  H1,
  MintHeader,
  TextLink,
  Underline,
} from '../../components/common';
import {
  useNetwork,
  useContractWrite,
  usePrepareContractWrite,
  useAccount,
  useContractReads,
  useBalance,
  useSigner,
  useContractRead,
} from 'wagmi';
import { BigNumber, ethers, utils } from 'ethers';
import { formatImageUrl } from '../../utils/format-image-url';
import { EthCard, Image } from '../../components/cards';
import { bigNumToNum, convertIPFS, dealWithEther } from '../../utils';
import { MintButton, MintManyButton } from '../../components/buttons';
import { ToggleGroupMintAmounts } from '../../components/toggle-group';
import { ContractCallEth } from '../../components/contract-call';
import { getMerkle, makeTree } from '../../utils/merkle';
import { feeManagerAbi } from '../../utils/SelfServe/contracts';

const feeManagerAddress = '0xd21b360DeD9f51FA501823bAF8Ff50f9120e6CD3';

export default function EthFeeManager() {
  useEffect(() => {
    const functions = feeManagerAbi.filter((item: any) => {
      return item && item.type === 'function' && item.name !== 'initialize';
    });
    setFunctions(functions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const [contractAddress, setContractAddress] = useState('');
  const [price, setPrice] = useState('');
  const [maxSupply, setMaxSupply] = useState(0);
  const [totalMinted, setTotalMinted] = useState(0);
  const [tokens, setTokens] = useState<any[]>([]);
  const [collectionName, setCollectionName] = useState('');
  const [collectionDescription, setCollectionDescription] = useState('');
  const [collectionImage, setCollectionImage] = useState('');
  const [mintAmount, setMintAmount] = useState('1');
  const [maxPerAddress, setMaxPerAddress] = useState(0);
  const [presaleEnd, setPresaleEnd] = useState(0);
  const [presaleStart, setPresaleStart] = useState(0);
  const [publicSaleStart, setPublicSaleStart] = useState(0);
  const [publicSaleEnd, setPublicSaleEnd] = useState(0);
  const [presaleMerkleRoot, setPresaleMerkleRoot] = useState('');
  const [treasury, setTreasury] = useState('');
  const [functions, setFunctions] = useState<any[]>([]);
  const [selectedFunction, setSelectedFunction] = useState<any>({});
  const [owner, setOwner] = useState('');
  const [salesConfig, setSalesConfig] = useState<any[]>();
  const [verified, setVerified] = useState(false);
  const [presaleMints, setPresaleMints] = useState(0);
  const [presaleMaxCount, setPresaleMaxCount] = useState(0);
  const [presalePrice, setPresalePrice] = useState(0);
  const [publicActive, setPublicActive] = useState(false);
  const [presaleActive, setPresaleActive] = useState(false);
  const [merkleProof, setMerkleProof] = useState<string[]>([]);
  const [mintFunctionName, setMintFunctionName] = useState('');
  const [mintArgs, setMintArgs] = useState<any[]>([]);

  const feeManagerInterface = {
    contractInterface: feeManagerAbi,
  };
  const { address, isConnected } = useAccount();

  const { data: signer, isError: isSignerError, isLoading: isSignerLoading } = useSigner();

  const {
    data: contractWriteData,
    isLoading: isContractWriteLoading,
    isSuccess: isContractWriteSuccess,
    isError: isContractWriteError,
    write: mint,
  } = useContractWrite({
    mode: 'recklesslyUnprepared',
    addressOrName: feeManagerAddress,
    contractInterface: feeManagerAbi,
    functionName: mintFunctionName,
    args: mintArgs,
    overrides: {
      value: presaleActive
        ? (presalePrice * parseInt(mintAmount)).toString()
        : (parseInt(dealWithEther(price).toString()) * parseInt(mintAmount)).toString(),
    },
    onError: (e) => console.log(e),
  });

  function renderAdminOptions() {
    if (!functions) {
      return null;
    }

    return functions.map((f: any) => {
      return <option value={f.name}>{f.name}</option>;
    });
  }

  function handleAdminOptionChange() {
    const sel: any = document.getElementById('functions');
    const functionName = sel.options[sel.selectedIndex].text;
    const res = functions.filter((obj) => {
      return obj.name === functionName;
    });
    setSelectedFunction(res[0]);
  }

  function renderAdminFunction() {
    if (!selectedFunction || !selectedFunction.name) {
      return null;
    }
    let args = [];
    if (selectedFunction.inputs && selectedFunction.inputs.length) {
      for (let i = 0; i < selectedFunction.inputs.length; i++) {
        const input = selectedFunction.inputs[i];
        const arg = { name: input.name, type: input.type };
        args.push(arg);
      }
    }

    return (
      <ContractCallEth
        contractAddress={feeManagerAddress}
        method={selectedFunction.name}
        args={args}
        selectedFunction={selectedFunction}
        signer={signer}
        abi={feeManagerAbi}
      />
    );
  }

  return (
    <ContainerFull>
      <Container>
        <select name="functions" id="functions" onChange={() => handleAdminOptionChange()}>
          <option disabled selected={!selectedFunction || !selectedFunction.name}>
            {' '}
            -- select a function --{' '}
          </option>
          {renderAdminOptions()}
        </select>{' '}
        <button onClick={() => setSelectedFunction([])}>reset</button>
        <ContainerItem>{renderAdminFunction()}</ContainerItem>
      </Container>
    </ContainerFull>
  );
}
