import React, { Component } from "react";
import styles from "./index.module.scss";

import Button from "../Button";

import { darkmodeListener } from "../../theme";

type UserTipProps = {
  onClose: () => void;
  buttonText?: string;
};

type UserTipState = {};

class UserTip extends Component<UserTipProps, UserTipState> {
  componentDidMount() {
    darkmodeListener(styles["theme-light"], styles["theme-dark"]);
  }

  render() {
    return (
      <div className={styles.Confirm}>
        <div className={styles.ConfirmTitle}>User Safety Tips</div>
        {this.props.children}
        <div className={styles.ConfirmContainerButton}>
          <Button
            text={
              this.props.buttonText
                ? this.props.buttonText
                : "Proceed with Mint"
            }
            onClick={this.props.onClose.bind(this, true)}
          />
        </div>
      </div>
    );
  }
}

export default UserTip;
