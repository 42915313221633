import { ArrowLeftIcon, ArrowRightIcon } from '@radix-ui/react-icons';
import { keyframes } from '@stitches/react';
import { styled } from '../stitches.config';
import { useAtom } from 'jotai';
import { useEffect, useRef, useState } from 'react';
import * as HoverCardPrimitive from '@radix-ui/react-hover-card';
import { mauve } from '@radix-ui/colors';
import {
  assetsAtom,
  attributesAtom,
  collectionNameAtom,
  editionAtom,
  editionNameAtom,
  filenamesEnabledAtom,
  priceAtom,
  tokenDescriptionsAtom,
  tokenNamesAtom,
} from './atoms';
import { InfoIcon } from '../utils/svgs';
import {
  useGetAuction,
  useGetBid,
} from '../hooks/passthrough';
import { formatImageUrl, _formatImageUrl } from '../utils/format-image-url';
import { convertIPFS, renderer } from '../utils';
import CardRenderer from '../components/Card';
import { useContractRead } from 'wagmi';

const slideUpAndFade = keyframes({
  '0%': { opacity: 0, transform: 'translateY(2px)' },
  '100%': { opacity: 1, transform: 'translateY(0)' },
});

const slideRightAndFade = keyframes({
  '0%': { opacity: 0, transform: 'translateX(-2px)' },
  '100%': { opacity: 1, transform: 'translateX(0)' },
});

const slideDownAndFade = keyframes({
  '0%': { opacity: 0, transform: 'translateY(-2px)' },
  '100%': { opacity: 1, transform: 'translateY(0)' },
});

const slideLeftAndFade = keyframes({
  '0%': { opacity: 0, transform: 'translateX(2px)' },
  '100%': { opacity: 1, transform: 'translateX(0)' },
});

const StyledContent = styled(HoverCardPrimitive.Content, {
  borderRadius: 6,
  padding: 20,
  width: 300,
  backgroundColor: 'white',
  boxShadow: 'hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px',
  '@media (prefers-reduced-motion: no-preference)': {
    animationDuration: '400ms',
    animationTimingFunction: 'cubic-bezier(0.16, 1, 0.3, 1)',
    animationFillMode: 'forwards',
    willChange: 'transform, opacity',
    '&[data-state="open"]': {
      '&[data-side="top"]': { animationName: slideDownAndFade },
      '&[data-side="right"]': { animationName: slideLeftAndFade },
      '&[data-side="bottom"]': { animationName: slideUpAndFade },
      '&[data-side="left"]': { animationName: slideRightAndFade },
    },
  },
});

const StyledArrow = styled(HoverCardPrimitive.Arrow, {
  fill: 'white',
});

// Exports
export const HoverCard = HoverCardPrimitive.Root;
export const HoverCardTrigger = HoverCardPrimitive.Trigger;
export const HoverCardContent = StyledContent;
export const HoverCardArrow = StyledArrow;

// Your app...
const Flex = styled('div', { display: 'flex' });

const Text = styled('div', {
  margin: 0,
  color: mauve.mauve12,
  fontSize: 15,
  lineHeight: 1.5,
  variants: {
    faded: {
      true: { color: mauve.mauve10 },
    },
    bold: {
      true: { fontWeight: 500 },
    },
  },
});

const Card = styled('div', {
  maxWidth: '300px',
  height: 'fit-content',
  borderRadius: '12px',
  border: '1px solid',
  borderColor: '$border',
  display: 'flex',
  flexDirection: 'column',
  padding: '12px',
});

const Empty = styled('div', {
  maxWidth: '300px',
  height: '250px',
  // height: "fit-content",
  borderRadius: '12px',
  border: '1px solid',
  borderColor: '$border',
  display: 'flex',
  flexDirection: 'column',
  padding: '22px',
  // textAlign: "center",
  // justifyContent: "center",
  position: 'relative',
  top: 0,
  transition: 'top ease 0.2s',
  '&:hover': {
    top: '-5px',
    // boxShadow: '2px 2px 5px #dcdde2, -2px 2px 5px #dcdde2',
  },
});

const Drop = styled('div', {
  maxWidth: '300px',
  height: '350px',
  // height: "fit-content",
  borderRadius: '12px',
  border: '1px solid',
  borderColor: '$border',
  display: 'flex',
  flexDirection: 'column',
  // padding: '22px',
  // textAlign: "center",
  // justifyContent: "center",
  position: 'relative',
  top: 0,
  transition: 'top ease 0.2s',
  '&:hover': {
    top: '-5px',
    // boxShadow: '2px 2px 5px #dcdde2, -2px 2px 5px #dcdde2',
  },
});

const Create = styled('div', {
  cursor: 'pointer',
  fontSize: '20px',
  minHeight: '180px',
  height: 'fit-content',
  borderRadius: '12px',
  border: '1px solid',
  borderColor: '$border',
  display: 'flex',
  flexDirection: 'column',
  padding: '30px',
  position: 'relative',
  top: 0,
  transition: 'top ease 0.2s',
  '&:hover': {
    top: '-5px',
    // boxShadow: '2px 2px 5px #dcdde2, -2px 2px 5px #dcdde2',
  },
});

export const Image = styled('img', {
  borderRadius: '12px',
  backgroundColor: '#dcdde2',
});

const Details = styled('div', {
  width: '100%',
  textAlign: 'left',
  padding: '20px 0 0 0',
  lineHeight: '24px',
});

const Actions = styled('div', {
  width: '100%',
  textAlign: 'left',
  padding: '8px 0 8px 0',
});

const Attributes = styled('div', {
  width: '100%',
  textAlign: 'left',
  padding: '8px 0 8px 0',
  textDecoration: 'underline',
  cursor: 'pointer',
});

const PreviewDetails = styled('div', {
  marginTop: '0.67em',
  padding: '12px 0',
  width: '100%',
  display: 'flex',
  userSelect: 'none',
});

const PreviewProgress = styled('div', { textAlign: 'right', width: '100%' });

const PreviewArrows = styled('span', {
  marginLeft: '10px',
  verticalAlign: 'middle',
});

const Front = styled('div', {
  // height: 400,
  // box-shadow: 0 0 6px 3px;
  // border: 1 solid red;
  border: '1px solid',
  borderColor: '$border',
  borderRadius: '8px',
  // width: 295px;
  // min-height: 380px;
  // display: flex;
  // flex-direction: column;
  alignItems: 'center',
  boxSizing: 'border-box',
  padding: '12px',
  position: 'relative',
  // @include theme-aware("color", "color-nine");
  // @include theme-aware('background', 'color-one');
  // @include theme-aware("border-color", "color-nine");
});

const Auction = styled('div', {
  height: 400,
  // box-shadow: 0 0 6px 3px;
  // border: 1 solid red;
  border: '1px solid',
  borderColor: '$border',
  borderRadius: '8px',
  // width: 295px;
  // min-height: 380px;
  // display: flex;
  // flex-direction: column;
  alignItems: 'center',
  boxSizing: 'border-box',
  padding: '12px',
  position: 'relative',
  // @include theme-aware("color", "color-nine");
  // @include theme-aware('background', 'color-one');
  // @include theme-aware("border-color", "color-nine");
});

const AuctionImage = styled('img', {
  borderRadius: '12px',
  // backgroundColor: '#dcdde2',
  height: '60%',
  width: '100%',
});

const Hover = styled('span', {
  color: 'black',
});

const Description = styled('div', { color: 'Gray' });

export const FrontCard = (props: { children: any }) => {
  return <Front>{props.children}</Front>;
};

export const EmptyCard = (props: { children: any }) => {
  return <Empty>{props.children}</Empty>;
};

export const CreateCard = (props: { children: any; onClick: any; selected: boolean }) => {
  return (
    <Create
      css={props.selected ? { backgroundColor: '$stepBackground', color: '$text' } : {}}
      onClick={props.onClick}
    >
      {props.children}
    </Create>
  );
};

export const DropCard = (props: { children: any }) => {
  return <Drop>{props.children}</Drop>;
};

export const EditionsReviewCard = (props: { isEth?: boolean }) => {
  const [edition, setEdition] = useAtom(editionAtom);
  const [collectionName, setCollectionName] = useAtom(collectionNameAtom);
  const [price, setPrice] = useAtom(priceAtom);
  const [editionName, setEditionName] = useAtom(editionNameAtom);

  function renderPreview() {
    if (edition[0].file.type.includes('video')) {
      return (
        <video autoPlay controls muted>
          <source src={edition[0].preview} type={edition[0].file.type} />
        </video>
      );
    } else {
      return <Image alt={edition[0].file.name} src={edition[0].preview} />;
    }
  }

  return (
    <div>
      <Card>
        {renderPreview()}
        <Details>
          <div>{collectionName}</div>
          <div>{editionName || edition[0].file.name.split('.')[0]}</div>
        </Details>
        {price ? (
          <Actions>
            {price} {props.isEth ? 'ETH' : 'STX'}
          </Actions>
        ) : null}
      </Card>
    </div>
  );
};

export const ReviewCard = (props: { tokenId: number }) => {
  const [assets, setAssets] = useAtom(assetsAtom);
  const [collectionName, setCollectionName] = useAtom(collectionNameAtom);
  const [filenamesEnabled, setFilenamesEnabled] = useAtom(filenamesEnabledAtom);
  const [price, setPrice] = useAtom(priceAtom);
  const [attributes, setAttributes] = useAtom(attributesAtom);

  function renderAttributes() {
    return attributes[props.tokenId - 1].map((attribute: any, idx: number) => {
      if (attribute.value) {
        return (
          <Text>
            <Text bold>{attribute.trait}</Text>
            <Text faded>{attribute.value}</Text>
          </Text>
        );
      }
    });
  }

  function renderPreview() {
    if (assets[props.tokenId - 1].file.type.includes('video')) {
      return (
        <video autoPlay controls muted>
          <source
            src={assets[props.tokenId - 1].preview}
            type={assets[props.tokenId - 1].file.type}
          />
        </video>
      );
    } else {
      return (
        <Image alt={assets[props.tokenId - 1].file.name} src={assets[props.tokenId - 1].preview} />
      );
    }
  }

  return (
    <div>
      <Card>
        {renderPreview()}
        <Details>
          <div>{collectionName}</div>
          <div>
            {!filenamesEnabled
              ? `${collectionName} #${props.tokenId}`
              : assets[props.tokenId - 1].file.name.split('.')[0]}
          </div>
        </Details>
        {price ? <Actions>{price} STX</Actions> : null}
        {attributes.length ? (
          <HoverCard>
            <HoverCardTrigger asChild>
              <Attributes>View attributes</Attributes>
            </HoverCardTrigger>
            <HoverCardContent sideOffset={5}>
              <Flex css={{ flexDirection: 'column', gap: 7 }}>
                <Flex css={{ flexDirection: 'column', gap: 15 }}>{renderAttributes()}</Flex>
              </Flex>
            </HoverCardContent>
          </HoverCard>
        ) : null}
      </Card>
    </div>
  );
};

export const PreviewCard = (props: { offset?: number; stepCallback?: any }) => {
  const [collectionName, setCollectionName] = useAtom(collectionNameAtom);
  const [assets, setAssets] = useAtom(assetsAtom);
  const [filenamesEnabled, setFilenamesEnabled] = useAtom(filenamesEnabledAtom);
  const [price, setPrice] = useAtom(priceAtom);
  const [attributes, setAttributes] = useAtom(attributesAtom);
  const [tokenNames, setTokenNames] = useAtom(tokenNamesAtom);
  const [tokenDescriptions, setTokenDescriptions] = useAtom(tokenDescriptionsAtom);

  const [previewStep, setPreviewStep] = useState(1);

  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    videoRef.current?.load();
  }, [previewStep]);

  function previewPrev() {
    if (previewStep > 1) {
      setPreviewStep(previewStep - 1);

      if (props.stepCallback) {
        props.stepCallback(previewStep - 1);
      }
    }
  }

  function previewNext() {
    if (previewStep < assets.length) {
      setPreviewStep(previewStep + 1);

      if (props.stepCallback) {
        props.stepCallback(previewStep + 1);
      }
    }
  }

  function renderAttributes() {
    return attributes[previewStep - 1].map((attribute: any, idx: number) => {
      if (attribute.value) {
        return (
          <Text>
            <Text bold>{attribute.trait}</Text>
            <Text faded>{attribute.value}</Text>
          </Text>
        );
      }
    });
  }

  function renderFilename() {
    if (tokenNames && tokenNames.length && tokenNames[previewStep - 1]) {
      return tokenNames[previewStep - 1];
    } else if (filenamesEnabled) {
      return assets[previewStep - 1].file.name.split('.')[0];
    } else {
      return `${collectionName} #${previewStep + offset}`;
    }
  }

  function renderPreview() {
    if (assets[previewStep - 1].file.type.includes('video')) {
      return (
        <video ref={videoRef} autoPlay controls muted>
          <source src={assets[previewStep - 1].preview} type={assets[previewStep - 1].file.type} />
        </video>
      );
    } else {
      return (
        <Image alt={assets[previewStep - 1].file.name} src={assets[previewStep - 1].preview} />
      );
    }
  }

  let offset = 0;
  if (props.offset) {
    offset = props.offset;
  }

  if (assets.length) {
    return (
      <div>
        <PreviewDetails>
          Preview
          <PreviewProgress>
            {previewStep} of {assets.length}
            <PreviewArrows>
              <ArrowLeftIcon cursor="pointer" onClick={() => previewPrev()} />{' '}
              <ArrowRightIcon cursor="pointer" onClick={() => previewNext()} />
            </PreviewArrows>
          </PreviewProgress>
        </PreviewDetails>

        <Card>
          {renderPreview()}
          <Details>
            <div>{collectionName}</div>
            <div>{renderFilename()}</div>
          </Details>
          {price ? <Actions>{price} STX</Actions> : null}
          {attributes.length ? (
            <HoverCard>
              <HoverCardTrigger asChild>
                <Attributes>View attributes</Attributes>
              </HoverCardTrigger>
              <HoverCardContent sideOffset={5}>
                <Flex css={{ flexDirection: 'column', gap: 7 }}>
                  <Flex css={{ flexDirection: 'column', gap: 15 }}>{renderAttributes()}</Flex>
                </Flex>
              </HoverCardContent>
            </HoverCard>
          ) : null}
          {/* {tokenNames && tokenNames.length && tokenNames[previewStep - 1] ? (
            <Description>{tokenNames[previewStep - 1]}</Description>
          ) : null} */}
          {tokenDescriptions && tokenDescriptions.length && tokenDescriptions[previewStep - 1] ? (
            <Description>{tokenDescriptions[previewStep - 1]}</Description>
          ) : null}
        </Card>
      </div>
    );
  } else {
    return null;
  }
};

export const EditionsPreviewCard = (props: { asset: any }) => {
  const [collectionName, setCollectionName] = useAtom(collectionNameAtom);
  const [filenamesEnabled, setFilenamesEnabled] = useAtom(filenamesEnabledAtom);
  const [price, setPrice] = useAtom(priceAtom);
  const [attributes, setAttributes] = useAtom(attributesAtom);
  const [tokenDescriptions, setTokenDescriptions] = useAtom(tokenDescriptionsAtom);
  const [editionName, setEditionName] = useAtom(editionNameAtom);

  function renderPreview() {
    if (props.asset.file.type.includes('video')) {
      return (
        <video autoPlay controls muted>
          <source src={props.asset.preview} type={props.asset.file.type} />
        </video>
      );
    } else {
      return <Image alt={props.asset.file.name} src={props.asset.preview} />;
    }
  }

  function renderAttributes() {
    return attributes[0].map((attribute: any, idx: number) => {
      if (attribute.value) {
        return (
          <Text>
            <Text bold>{attribute.trait}</Text>
            <Text faded>{attribute.value}</Text>
          </Text>
        );
      }
    });
  }

  if (props.asset) {
    return (
      <div>
        <Card>
          {renderPreview()}
          <Details>
            <div>{collectionName}</div>
            <div>{editionName || props.asset.file.name.split('.')[0]}</div>
          </Details>
          {price ? <Actions>{price} STX</Actions> : null}
          {attributes.length ? (
            <HoverCard>
              <HoverCardTrigger asChild>
                <Attributes>View attributes</Attributes>
              </HoverCardTrigger>
              <HoverCardContent sideOffset={5}>
                <Flex css={{ flexDirection: 'column', gap: 7 }}>
                  <Flex css={{ flexDirection: 'column', gap: 15 }}>{renderAttributes()}</Flex>
                </Flex>
              </HoverCardContent>
            </HoverCard>
          ) : null}
          {tokenDescriptions && tokenDescriptions.length && tokenDescriptions[0] ? (
            <Description>{tokenDescriptions[0]}</Description>
          ) : null}
        </Card>
      </div>
    );
  } else {
    return null;
  }
};

export const BaseUriPreviewCard = (props: {
  asset: any;
  name: string;
  description?: string;
  attributes?: any[];
}) => {
  const [collectionName, setCollectionName] = useAtom(collectionNameAtom);
  // const [filenamesEnabled, setFilenamesEnabled] = useAtom(filenamesEnabledAtom);
  // const [price, setPrice] = useAtom(priceAtom);
  // const [attributes, setAttributes] = useAtom(attributesAtom);
  // const [tokenDescriptions, setTokenDescriptions] = useAtom(tokenDescriptionsAtom);
  // const [editionName, setEditionName] = useAtom(editionNameAtom);

  function renderPreview() {
    if (props.asset.file.type.includes('video')) {
      return (
        <video autoPlay controls muted>
          <source src={props.asset.preview} type={props.asset.file.type} />
        </video>
      );
    } else {
      return <Image alt={props.name} src={props.asset.preview} />;
    }
  }

  function renderAttributes() {
    if (!props.attributes?.length) {
      return null;
    }
    return props.attributes[0].map((attribute: any, idx: number) => {
      if (attribute.value) {
        return (
          <Text>
            <Text bold>{attribute.trait}</Text>
            <Text faded>{attribute.value}</Text>
          </Text>
        );
      }
    });
  }

  if (props.asset) {
    return (
      <div>
        <Card>
          {renderPreview()}
          <Details>
            <div>{collectionName}</div>
            <div>{props.name}</div>
          </Details>
          {props.description ? <Description>{props.description}</Description> : null}
          {props.attributes?.length ? (
            <HoverCard>
              <HoverCardTrigger asChild>
                <Attributes>View attributes</Attributes>
              </HoverCardTrigger>
              <HoverCardContent sideOffset={5}>
                <Flex css={{ flexDirection: 'column', gap: 7 }}>
                  <Flex css={{ flexDirection: 'column', gap: 15 }}>{renderAttributes()}</Flex>
                </Flex>
              </HoverCardContent>
            </HoverCard>
          ) : null}
        </Card>
      </div>
    );
  } else {
    return null;
  }
};

export const HoverIcon = (props: { text: string }) => (
  <HoverCard>
    <HoverCardTrigger>
      <InfoIcon />
    </HoverCardTrigger>
    <HoverCardContent sideOffset={5}>
      {/* <Flex css={{ flexDirection: 'column', gap: 7 }}>
        <Flex css={{ flexDirection: 'column', gap: 15 }}>{renderAttributes()}</Flex>
      </Flex> */}
      <Hover>{props.text}</Hover>
    </HoverCardContent>
  </HoverCard>
);

export const AuctionsCard = (props: {
  children: any;
  contractAddress: string;
  contractName: string;
  tokenId: string;
  // data: any;
  blockHeight: number;
  onLoad: any;
  disableRender?: boolean;
  isFeatured?: boolean;
}) => {
  const [data, setData] = useState<any>(undefined);
  const [status, setStatus] = useState('');
  const [bid, setBid] = useState('');
  const [isLoaded, setIsLoaded] = useState(false);
  const [isDataFetched, setIsDataFetched] = useState(false);
  const [auctionDetailsText, setAuctionDetailsText] = useState('');
  const [auctionText, setAuctionText] = useState('');
  const auctionQuery = useGetAuction(props.contractAddress, props.contractName, props.tokenId);
  const bidQuery = useGetBid(props.contractAddress, props.contractName, props.tokenId);
  const [notAnAuction, setNotAnAuction] = useState(false);

  useEffect(() => {
    if (!isLoaded && auctionQuery.data && bidQuery.data && data) {
      setIsLoaded(true);

      if (auctionQuery.data === '107' && props.isFeatured) {
        setNotAnAuction(true);
        props.onLoad([
          'error',
          props.contractAddress + '.' + props.contractName + '::' + props.tokenId,
        ]);
        return;
      }

      let endBlockHeight =
        auctionQuery.data['end-block-height'] && auctionQuery.data['end-block-height'].value
          ? auctionQuery.data['end-block-height'].value.value
          : '';
      let bid = bidQuery.data['auction-id'] ? (bidQuery.data.amount.value / 1e6).toString() : '';
      setBid(bid);
      let blocksRemaining =
        endBlockHeight && props.blockHeight ? parseInt(endBlockHeight) - props.blockHeight : '';
      let status = '';
      if (bid && blocksRemaining < 0) {
        status = 'sold';
        setAuctionDetailsText(`SOLD FOR ${bidQuery.data.amount.value / 1e6} STX`);
        setAuctionText('SOLD');
      } else if (bid) {
        status = 'live';
        setAuctionDetailsText(
          `${bidQuery.data.amount.value / 1e6} STX - ${blocksRemaining} blocks`,
        );
        setAuctionText('LIVE');
      } else if (auctionQuery.data !== '107') {
        setAuctionDetailsText(`RESERVE ${auctionQuery.data['reserve-price'].value / 1e6} STX`);
        setAuctionText('RESERVE NOT MET');
      }
      setStatus(status);
      blocksRemaining = blocksRemaining.toString();
      props.onLoad({
        auction: auctionQuery.data,
        bid: bidQuery.data,
        status,
        blocksRemaining,
        contractAddress: props.contractAddress,
        contractName: props.contractName,
        tokenId: props.tokenId,
        nftId: `${props.contractAddress}.${props.contractName}::${props.tokenId}`,
        data,
      });
    }

    // props.onLoad(auctionQuery.data);
  }, [props, auctionQuery.data, bidQuery.data, isLoaded, data]);

  useEffect(() => {
    if (!isDataFetched) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function fetchData() {
    setIsDataFetched(true);
    var params = '';
    params += 'address=' + props.contractAddress;
    params += '&name=' + props.contractName;
    params += '&id=' + props.tokenId;

    try {
      const res = await fetch('https://legacy.stxnft.com/api/nft?' + params);
      const data = await res.json();
      setData(data);
    } catch (error) {
      // console.log(error);
    }
  }
  // return null;
  // return <Auction>Loading...</Auction>;

  if (props.disableRender || notAnAuction) {
    return null;
  } else if (isLoaded && data) {
    return (
      <CardRenderer
        key={data.name + props.tokenId}
        renderer={renderer(data, undefined, 600)}
        collection={data.collection}
        name={data.name}
        soldFlag={auctionText === 'SOLD' || undefined}
        auctionText={auctionText}
        auctionDetails={auctionQuery.data}
        auctionDetailsText={auctionDetailsText}
        collectionPath={`/collection/${props.contractName}`}
        tokenPath={`/collection/${props.contractName}/${props.tokenId}`}
      />
    );
  } else {
    return <Auction>Loading...</Auction>;
  }
};

export const EthCard = (props: { address: string; interface: any; tokenId: number }) => {
  const [token, setToken] = useState<any>(undefined);

  const { data, isError, isLoading } = useContractRead({
    addressOrName: props.address,
    contractInterface: props.interface,
    functionName: 'tokenURI',
    args: [props.tokenId],
    onSuccess(data) {
      fetchTokenData(data.toString());
    },
  });

  // const {
  //   data: saleDetails,
  //   isError: isSaleDetailsError,
  //   isLoading: isSaleDetailsLoading,
  // } = useContractRead({
  //   addressOrName: props.address,
  //   contractInterface: props.interface,
  //   functionName: 'tokenURI',
  //   args: [props.tokenId],
  //   onSuccess(data) {
  //     // fetchTokenData(data.toString());
  //     console.log(data);
  //   },
  // });

  async function fetchTokenData(uri: string) {
    // console.log(uri);
    try {
      const ipfs = convertIPFS(uri);
      const res = await fetch(ipfs);
      const data = await res.json();
      const image = formatImageUrl({ url: data.image, size: 200 });
      const token: any = { uri, image, data };
      const baseImage = _formatImageUrl(data.image);
      var req = await fetch(baseImage, { method: 'HEAD' });
      const type = req.headers.get('content-type');
      if (type?.includes('video')) {
        token.data.video = baseImage;
      }
      setToken(token);
    } catch (e) {
      // console.log(e);
    }
  }

  function parseTokenName(name: string) {
    let parsedName = name;
    if (name.includes('4294967295')) {
      parsedName = name.replace('4294967295', '∞');
    }
    if (parsedName.length > 25) {
      parsedName = parsedName.substring(0, 22) + '...';
    }
    return parsedName;
  }

  return (
    <div>
      {token && token.data ? (
        <CardRenderer
          key={token.data.name + props.tokenId}
          renderer={renderer(token.data, undefined, 600)}
          collection={token.data.properties.name}
          name={parseTokenName(token.data.name)}
          // soldFlag={sold || undefined}
          // auctionFlag={!sold}
          // auctionLoading={!this.state.auctionsFetched}
          // auctionText={auctionText}
          // auctionDetails={auctionQuery.data}
          // auctionDetailsText={auctionDetailsText}
          // collectionPath={`/collection/${props.contractName}`}
          // tokenPath={`/collection/${props.contractName}/${props.tokenId}`}
        />
      ) : null}
    </div>
  );
};
