import React, { Component, useEffect } from 'react';
import { useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import {
  ContinueButton,
  CreateBlackButton,
  CreateButton,
  CreateWhiteButton,
} from '../../../components/buttons';
import { ContainerItem, H1, H2, HowItWorks } from '../../../components/common';
import { LabelInput, LabelTextArea } from '../../../components/label';
import Footer from '../../../components/Studio/footer';
import {
  isValidContactInfo,
  isValidFullName,
  isValidMessage,
} from '../../../utils/SelfServe/validation';
import { ToolIcon, UserCheckIcon, UsersIcon } from '../../../utils/svgs';
import styles from './index.module.scss';

const axios = require('axios');

export default function StudioHome() {
  const [fullName, setFullName] = useState('');
  const [emailAddress, setEmailAddress] = useState('');
  const [message, setMessage] = useState('');
  const [submitted, setSubmitted] = useState(false);

  const [fullNameError, setFullNameError] = useState('');
  const [emailAddressError, setEmailAddressError] = useState('');
  const [messageError, setMessageError] = useState('');

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  function handleFullNameChange(e: React.ChangeEvent<HTMLInputElement>) {
    setFullNameError('');
    setFullName(e.target.value);
  }

  function handleFullNameBlur() {
    if (fullName) {
      const fullNameCheck = isValidFullName(fullName);
      setFullNameError(fullNameCheck.error);
    }
  }

  function handleEmailAddressChange(e: React.ChangeEvent<HTMLInputElement>) {
    setEmailAddressError('');
    setEmailAddress(e.target.value);
  }

  function handleEmailAddressBlur() {
    if (emailAddress) {
      const emailAddressCheck = isValidContactInfo(emailAddress);
      setEmailAddressError(emailAddressCheck.error);
    }
  }

  function handleMessageChange(e: React.ChangeEvent<HTMLTextAreaElement>) {
    const messageCheck = isValidMessage(e.target.value);
    setMessageError(messageCheck.error);
    setMessage(e.target.value);
  }

  async function handleGetInTouch() {
    const subject = `Get In Touch: ${fullName}`;
    // const body = `Requester: ${emailAddress}; Subject: Collection Deployed: ${collectionName}; Ticket Form: Create Portal`;
    const body = `Email Address: ${emailAddress}
Full Name: ${fullName}
Message: ${message}`;

    // # Package the data in a dictionary matching the expected JSON
    const ticketData = {
      request: {
        subject: subject,
        comment: { body: body },
      },
    };

    // # Encode the data to create a JSON payload
    const payload = JSON.stringify(ticketData);

    // # Set the request parameters
    const url = 'https://stxnft.zendesk.com/api/v2/requests.json';
    const pwd = process.env.REACT_APP_ZENDESK_TOKEN;
    const user = 'nick@gamma.io/token:' + pwd;
    const encoded = btoa(user);

    const headers = {
      'Content-Type': 'application/json',
      Authorization: 'Basic ' + encoded,
    };

    try {
      const res = await axios.post(url, payload, {
        headers: headers,
      });
      // console.log(res);
      setSubmitted(true);
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <div className={styles.Container}>
      <div className={styles.BannerContainer}>
        <div className={styles.BannerOverlay}>
          <div className={styles.Banner}>
            <div className={styles.BannerTitle}>
              Create your own Bitcoin NFT collection in minutes
            </div>
            <div className={styles.BannerSubtitle}>
              Design, build, and manage a fully featured NFT smart contract where you have 100%
              ownership and control.
            </div>
            {/* <div className={styles.InlineBlock}>
            <Link to="/getting-started">
              <CreateButton text="Create a Collection" />
            </Link>
          </div> */}
            <div className={styles.InlineBlock}>
              <Link to="/getting-started">
                <CreateWhiteButton text="Start creating" />
              </Link>
              <Link to="/minting-now">
                <CreateBlackButton text="See what's minting" />
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.Middle}>
        <div className={styles.Grid}>
          <div className={styles.GridItem}>
            <div className={styles.Centered}>
              <UserCheckIcon />
            </div>
            <h2>You own your art</h2>
            <div className={styles.GridText}>
              Let's keep it that way. Gamma takes a 10% commission on your first sale, but does not
              apply ongoing fees or restrictions on your work.
            </div>
          </div>
          <div className={styles.GridItem}>
            <div className={styles.Centered}>
              <ToolIcon />
            </div>

            <h2>You hold the keys</h2>
            <div className={styles.GridText}>
              Gamma cannot censor or block your NFTs from other platforms. We provide you with the
              tools to be successful without locking you in.
            </div>
          </div>
          <div className={styles.GridItem}>
            <div className={styles.Centered}>
              <UsersIcon />
            </div>

            <h2>You have our help</h2>
            <div className={styles.GridText}>
              We're here to guide you through the entire process — from files on your computer to
              NFTs on the blockchain — and for whatever's next.
            </div>
          </div>
        </div>
        {/* <div className={styles.Centered}>
          <div className={styles.InlineBlock}>
            <Link to="/getting-started">
              <CreateButton text="Start creating" />
            </Link>
            <a href="https://gamma.io/categories" target="_blank" rel="noopener noreferrer">
              <CreateContrastButton text="See top collections" />
            </a>
          </div>
        </div> */}
      </div>
      <HowItWorks>
        <div className={styles.Centered}>
          <div className={styles.HowItWorksTitle}>How it works</div>
        </div>

        <div className={styles.Grid}>
          <div className={styles.GridItem}>
            <H2>Step 1</H2>
            <div className={styles.GridText}>
              First, you'll provide the media and other information that will be used to create your
              NFTs.
            </div>
          </div>
          <div className={styles.GridItem}>
            <H2>Step 2</H2>
            <div className={styles.GridText}>
              Next, you'll deploy your smart contract to the network. This will allow your NFTs to
              be minted or sold on the blockchain.
            </div>
          </div>
          <div className={styles.GridItem}>
            <H2>Step 3</H2>
            <div className={styles.GridText}>
              Last, you'll provide details that can help collectors discover and mint your
              collection on Gamma.
            </div>
          </div>
        </div>
        <div className={styles.Centered}>
          <div className={styles.InlineBlock}>
            <Link to="/getting-started">
              <CreateWhiteButton text="Start creating" />
            </Link>
            <a href="https://stacks.gamma.io/categories" target="_blank" rel="noopener noreferrer">
              <CreateBlackButton text="See top collections" />
            </a>
          </div>
        </div>
      </HowItWorks>
      <div className={styles.Bottom}>
        <div className={styles.BottomGrid}>
          <div>
            <H1>Need help? Get In Touch</H1>
            <div className={styles.BottomItem}>
              Gamma can manage or assist with the creation of completely new kinds of NFT smart
              contracts for cutting-edge projects. Fill out the form to discuss your custom needs
              and someone will be in touch.
            </div>
          </div>
          {submitted ? (
            <h3>Thanks for your enquiry! We'll get back to you soon.</h3>
          ) : (
            <div className={styles.BottomGridItem}>
              <LabelInput
                placeholder="Email Address"
                type="text"
                id="email"
                label="Email Address"
                value={emailAddress}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleEmailAddressChange(e)}
                onBlur={() => handleEmailAddressBlur()}
                error={emailAddressError}
              />
              <LabelInput
                placeholder="Full Name"
                type="text"
                id="email"
                label="Full Name"
                value={fullName}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleFullNameChange(e)}
                onBlur={() => handleFullNameBlur()}
                error={fullNameError}
              />
              <LabelTextArea
                placeholder="Message"
                label="Message"
                id="message"
                value={message}
                onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => handleMessageChange(e)}
                error={messageError}
              />
              <ContainerItem>
                <ContinueButton
                  text="Send"
                  onClick={() => handleGetInTouch()}
                  disabled={
                    !emailAddress ||
                    !fullName ||
                    !message ||
                    emailAddressError !== '' ||
                    fullNameError !== '' ||
                    messageError !== ''
                  }
                />
              </ContainerItem>
            </div>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
}
