import { useAtom } from 'jotai';
import { useEffect, useState } from 'react';
import {
  isValidAddress,
  isValidContactInfo,
  isValidDescription2,
  isValidDiscordInvite,
  isValidDomain,
  isValidName,
  isValidTwitter,
} from '../../../../utils/SelfServe/validation';
import { ContinueButton } from '../../../../components/buttons';
import { Label, LabelInput, LabelLicenses, LabelTextArea } from '../../../../components/label';
import {
  artistAddressAtom,
  categoryAtom,
  collectionNameAtom,
  descriptionAtom,
  discordAtom,
  emailAddressAtom,
  fungibleOptionsAtom,
  stxusdAtom,
  twitterAtom,
  websiteAtom,
} from '../../../../components/atoms';
import { Container, ContainerItem, ContainerItemTitle, H1 } from '../../../../components/common';
import makeEndpoint from '../../../../endpoints';
import { ToggleGroupCategory } from '../../../../components/toggle-group';

function Details(props: { onNextStep: any }) {
  const [collectionName, setCollectionName] = useAtom(collectionNameAtom);
  const [description, setDescription] = useAtom(descriptionAtom);
  const [emailAddress, setEmailAddress] = useAtom(emailAddressAtom);
  const [website, setWebsite] = useAtom(websiteAtom);
  const [twitter, setTwitter] = useAtom(twitterAtom);
  const [discord, setDiscord] = useAtom(discordAtom);
  const [artistAddress, setArtistAddress] = useAtom(artistAddressAtom);
  const [fungibleOptions, setFungibleOptions] = useAtom(fungibleOptionsAtom);
  const [stxusd, setStxusd] = useAtom(stxusdAtom);
  const [category, setCategory] = useAtom(categoryAtom);

  const [collectionNameError, setCollectionNameError] = useState('');
  const [descriptionError, setDescriptionError] = useState('');
  const [emailAddressError, setEmailAddressError] = useState('');
  const [websiteError, setWebsiteError] = useState('');
  const [twitterError, setTwitterError] = useState('');
  const [discordError, setDiscordError] = useState('');
  const [artistAddressError, setArtistAddressError] = useState('');
  const [categories, setCategories] = useState([]);

  function handleCollectionNameChange(e: React.ChangeEvent<HTMLInputElement>) {
    const nameCheck = isValidName(e.target.value);
    setCollectionNameError(nameCheck.error);
    setCollectionName(e.target.value);

    // still need a good way to check if collection name is already taken (currently use blacklist.json)
  }

  function handleDescriptionChange(e: React.ChangeEvent<HTMLTextAreaElement>) {
    const descriptionCheck = isValidDescription2(e.target.value);
    setDescriptionError(descriptionCheck.error);
    setDescription(e.target.value);
  }

  function handleEmailAddressChange(e: React.ChangeEvent<HTMLInputElement>) {
    setEmailAddressError('');
    setEmailAddress(e.target.value);
  }

  function handleEmailAddressBlur() {
    if (emailAddress) {
      const emailAddressCheck = isValidContactInfo(emailAddress);
      setEmailAddressError(emailAddressCheck.error);
    }
  }

  function handleWebsiteChange(e: React.ChangeEvent<HTMLInputElement>) {
    setWebsiteError('');
    setWebsite(e.target.value);
  }

  function handleWebsiteBlur() {
    if (website) {
      const websiteCheck = isValidDomain(website);
      setWebsiteError(websiteCheck.error);
    }
  }

  function handleTwitterChange(e: React.ChangeEvent<HTMLInputElement>) {
    setTwitterError('');
    setTwitter(e.target.value);
  }

  function handleTwitterBlur() {
    if (twitter) {
      const twitterCheck = isValidTwitter(twitter);
      setTwitterError(twitterCheck.error);
    }
  }

  function handleCategoryChange(category: string) {
    let categoryArray: number[] = [];
    if (category) {
      categoryArray = [parseInt(category)];
    }
    setCategory(categoryArray);
  }

  function handleDiscordChange(e: React.ChangeEvent<HTMLInputElement>) {
    setDiscordError('');
    setDiscord(e.target.value);
  }

  function handleDiscordBlur() {
    if (discord) {
      const discordCheck = isValidDiscordInvite(discord);
      setDiscordError(discordCheck.error);
    }
  }

  function handleArtistAddressChange(e: React.ChangeEvent<HTMLInputElement>) {
    setArtistAddressError('');
    setArtistAddress(e.target.value);
    if (e.target.value !== '') {
      const addressCheck = isValidAddress(e.target.value);
      setArtistAddressError(addressCheck.error);
    }
  }

  function handleArtistAddressBlur() {
    if (artistAddress) {
      const addressCheck = isValidAddress(artistAddress);
      setArtistAddressError(addressCheck.error);
    }
  }

  function handleContinue() {
    if (!collectionName || !description || !emailAddress) {
      console.log('empty fields');
      return;
    }
    props.onNextStep();
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  }

  useEffect(() => {
    async function fetchPaymentOptions() {
      try {
        const res = await fetch(makeEndpoint('/api/fts'));
        const json = await res.json();
        console.log(json);
        setFungibleOptions(json.tokens);
        setStxusd(json.stacks['price']);
      } catch (error) {
        console.error(error);
      }
    }

    window.scrollTo(0, 0);

    if (!fungibleOptions.length) {
      fetchPaymentOptions();
    }

    fetch('https://create.gamma.io/api/categories')
      .then((res) => {
        return res.json();
      })
      .then((json) => {
        const cats = json.categories;
        cats.sort((a: any, b: any) => a.id - b.id);
        setCategories(cats);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container>
      <h2>Add your collection details</h2>
      <div>
        Already have all your assets and attributes prepared? Enable anybody to mint NFTs from your
        collection by launching a public mint on Gamma.
      </div>
      <ContainerItem>
        <LabelInput
          placeholder="e.g. Hello Potato"
          label="Collection name"
          id="collectionName"
          type="text"
          value={collectionName}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleCollectionNameChange(e)}
          error={collectionNameError}
        />
      </ContainerItem>

      <ContainerItem>
        <LabelTextArea
          placeholder="Potato is good"
          label="Collection description"
          id="description"
          value={description}
          onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => handleDescriptionChange(e)}
          error={descriptionError}
        />
      </ContainerItem>

      <ContainerItem>
        <LabelInput
          placeholder="name@gmail.com"
          label="Email address (will remain private)"
          id="email"
          type="text"
          value={emailAddress}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleEmailAddressChange(e)}
          onBlur={() => handleEmailAddressBlur()}
          error={emailAddressError}
        />
      </ContainerItem>

      {categories ? (
        <ContainerItem>
          <ContainerItemTitle>Category (optional)</ContainerItemTitle>
          <ToggleGroupCategory
            value={category[0] || []}
            onChange={(value: string) => handleCategoryChange(value)}
            categories={categories}
          />
        </ContainerItem>
      ) : null}

      <ContainerItem>
        <LabelInput
          placeholder="https://gamma.io"
          label="Website (optional)"
          id="website"
          type="text"
          value={website}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleWebsiteChange(e)}
          onBlur={() => handleWebsiteBlur()}
          error={websiteError}
          note="Include a link to your website which will appear on your collection page."
        />
      </ContainerItem>

      <ContainerItem>
        <LabelInput
          placeholder="https://twitter.com/trygamma"
          label="Twitter (optional)"
          id="twitter"
          type="text"
          value={twitter}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleTwitterChange(e)}
          onBlur={() => handleTwitterBlur()}
          error={twitterError}
        />
      </ContainerItem>

      <ContainerItem>
        <LabelInput
          placeholder="https://discord.gg/tmpSQc2qft"
          label="Discord (optional)"
          id="discord"
          type="text"
          value={discord}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleDiscordChange(e)}
          onBlur={() => handleDiscordBlur()}
          error={discordError}
        />
      </ContainerItem>

      <ContainerItem>
        <LabelInput
          placeholder="SP1CSHTKVHMMQJ7PRQRFYW6SB4QAW6SR3XY2F81PA"
          label="Artist Address (optional)"
          id="artist"
          type="text"
          value={artistAddress}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleArtistAddressChange(e)}
          onBlur={() => handleArtistAddressBlur()}
          error={artistAddressError}
          note="Enter an artist address if you want mint sales to go to a different wallet than the one you're using to deploy the contract. The artist address wallet will also have admin privileges on certain public functions."
        />
      </ContainerItem>

      <ContainerItem>
        <LabelLicenses
          label="Select a license (optional)"
          id="license"
          note={`You can select a "can't be evil" license for your collection below. Can't Be Evil NFT licenses are a set of free, public licenses designed for NFTs and inspired by the work of Creative Commons, released by a16z.`}
        />
      </ContainerItem>

      <ContainerItem css={{ marginTop: 0 }}>
        <ContinueButton
          disabled={
            !collectionName ||
            collectionNameError !== '' ||
            !description ||
            descriptionError !== '' ||
            !emailAddress ||
            emailAddressError !== '' ||
            artistAddressError !== ''
          }
          text="Continue"
          onClick={() => handleContinue()}
        />
      </ContainerItem>
    </Container>
  );
}

export default Details;
