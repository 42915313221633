import React, { useEffect, useState } from 'react';
import styles from './index.module.scss';
import { Link } from 'react-router-dom';
import { getAllContractEvents, getAllNftsForAuction, getBlockHeight } from '../../utils/api';
import Card from '../../components/Card';
import { renderer } from '../../utils';

import {
  ContainerFull,
  ContainerItem,
  ContainerSection,
  EmptyDiv,
  H1,
  H2,
} from '../../components/common';
import {
  ToggleAuctionTypes,
  ToggleLiveAuctions,
  ToggleReserveNotMetAuctions,
} from '../../components/toggle-group';
import { AuctionsCard } from '../../components/cards';
import { ContinueButton } from '../../components/buttons';
import {
  auctionAddresses,
  auctionContractAddress,
  auctionContractName,
} from '../../utils/SelfServe/contracts';
import { adminAddresses, auctionAdminAddresses } from '../../components/Common/AdminRoute';
import { currentUserAddressAtom } from '../../components/atoms';
import { useAtom } from 'jotai';
import { getFeaturedAuctions, postFeaturedAuctions } from '../../utils/Studio/serverCalls';

const omittedContracts = [
  'SP1MGZHFXGHB6MNJQ1771TS4NMJST2CWAP8M8NCMX.x-skull',
  'SP2XD80BWCFN1W4ECVTE9EDC6TZV805MYF761YRK.chicco-universe',
  'SP312VR2K9RKB9ZNJ58JT7S4AA3Q6DY5GADRA7TVT.wasaka',
  'SPN79K1P1ZVGDFBQ28B42WKT87GH0M308A6RTW80.crezy-rex-flock',
  'SP2KJB0E1X52J887QSVNSDF1TAWM4Y02TFW8XMVCC.pecco-land',
  'SPHSVD0ET359PPZX0ZA51B5HSKERJSQGGX7NDK4E.fearless-monkey',
  'SP2ZZ0C5J5JABYY2AM103M9AWE8R02ACJ2CT9WHKM.the-rabbit-club',
  'SP2QQH09E2VQVVXWPKWSCCRP1ARY7X2AYHXQ154CP.fried-bros',
  'SPV11W2NB3N9V44T26CFKQJBB3S9K0TSRVMKG9XG.dicky-duck',
  'SP1CMJTRXB231479RQB99WBVV9Q2KW3XAH5XDZZA1.gen-frog',
  'SP2NGCRYM40BJ6XDC8MS7DGV1AF9NHBNHRRKMBR9P.block-headnft',
  'SP29267MG581SC8NCW8C59SMABNPHNE2BSFPXADYY.gamma-mimi',
  'SP3XHTK3DXASP71G5XDCGKNNR2BAW1P17C29MSYAW.metarealism-citizen',
  'SP1D1SV5H6HQ717EZ5PGZRT5QC7H8SW7A6ERXYS3S.machina-realm',
  'SP15GZEM23JBZ9D5BWXDKPT73CYR3QDH15KT81GC7.klobb-sapiens',
  'SP7QA77Z81S81S85Q7B555S80G1S23Y2YPHT10BA.neko-gamma-specials',
  'SP1XJKJ5MY07M5YG587J08ZC9EQQGP4KJJ2MVDMJ6.pelikanoworld',
  'SP3JFBTN2MQZF3QSZMA97Q1RMRRMD8W686QZDV8SY.the-masked-heros',
  'SPK75AE4F1N16SDBG8RHHRGXZJGF96F6SFRXBZ6X.crypto-pet',
];

function Auctions() {
  const [currentUserAddress, setCurrentUserAddress] = useAtom(currentUserAddressAtom);
  const [blockHeight, setBlockHeight] = useState<any>(undefined);
  const [nftsForAuction, setNftsForAuction] = useState<object[]>([]);
  const [loadingText, setLoadingText] = useState('Loading...');
  const [liveAuctions, setLiveAuctions] = useState<any[]>([]);
  const [liveAuctionsValue, setLiveAuctionsValue] = useState('');
  const [reserveNotMetAuctions, setReserveNotMetAuctions] = useState<any[]>([]);
  const [reserveNotMetAuctionsValue, setReserveNotMetAuctionsValue] = useState('');
  const [soldAuctions, setSoldAuctions] = useState<any[]>([]);
  const [fetchedAuctions, setFetchedAuctions] = useState<any>({});
  const [isLoaded, setIsLoaded] = useState(false);
  const [isFetched, setIsFetched] = useState(false);
  const [dupes, setDupes] = useState<any>({});
  const [viewAuctionType, setViewAuctionType] = useState('');
  const [updatedAuction, setUpdatedAuction] = useState('');
  const [updatedAuctionText, setUpdatedAuctionText] = useState('');
  const [featuredAuctions, setFeaturedAuctions] = useState<string[]>([]);
  const [featuredAuctionsToRemove, setFeaturedAuctionsToRemove] = useState<string[]>([]);
  const [loadMore, setLoadMore] = useState(false);

  useEffect(() => {
    fetchNftsForAuction();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function fetchNftsForAuction() {
    if (!blockHeight) {
      getBlockHeight().then((_blockHeight) => {
        setBlockHeight(_blockHeight);
      });
    }

    const featuredAuctions = await getFeaturedAuctions();
    setFeaturedAuctions(featuredAuctions.tokens);

    let allAuctions = await getAllNftsForAuction();
    let dupes: any = {};
    const nftsForAuction: any = [];
    for (let i = 0; i < allAuctions.length; i++) {
      const nft: any = allAuctions[i];
      if (omittedContracts.includes(nft.contractId)) {
        continue;
      }
      const dupe = nftsForAuction.findIndex((x: any) => `${x.contractId}` === nft.contractId);
      if (dupe === -1) {
        nftsForAuction.push(nft);
      }
      if (dupes[nft.contractId]) {
        const totalAuctions = dupes[nft.contractId] + 1;
        dupes[nft.contractId] = totalAuctions;
      } else {
        dupes[nft.contractId] = 1;
      }
    }
    setDupes(dupes);

    if (nftsForAuction) {
      nftsForAuction.sort((a: any, b: any) => b.blockHeight - a.blockHeight);
      setNftsForAuction(nftsForAuction);
    }
  }

  function sortByBlocksRemaining(a: any, b: any) {
    if (typeof a.blocksRemaining === 'undefined' && typeof b.blocksRemaining === 'undefined')
      return 0;
    // if a is undefined and b isn't a should have a lower index in the array
    else if (typeof a.blocksRemaining === 'undefined') return 1;
    // if b is undefined and a isn't a should have a higher index in the array
    else if (typeof b.blocksRemaining === 'undefined') return -1;
    // if both numbers are defined compare as normal
    else return a.blocksRemaining - b.blocksRemaining;
  }

  function sortAssets(sortBy?: string) {
    let assets: any[] = [];
    switch (sortBy) {
      case 'blocksRemaining':
        assets = liveAuctions;
        assets.sort(sortByBlocksRemaining);
        setLiveAuctions(assets);
        setLiveAuctionsValue(sortBy);
        break;

      case 'lowestBid':
        assets = liveAuctions;
        assets.sort(
          (a: any, b: any) => parseInt(a.bid.amount.value) - parseInt(b.bid.amount.value),
        );
        setLiveAuctions(assets);
        setLiveAuctionsValue(sortBy);
        break;

      case 'highestBid':
        assets = liveAuctions;
        assets.sort(
          (a: any, b: any) => parseInt(b.bid.amount.value) - parseInt(a.bid.amount.value),
        );
        setLiveAuctions(assets);
        setLiveAuctionsValue(sortBy);
        break;

      case 'lowestReservePrice':
        assets = reserveNotMetAuctions;
        assets.sort(
          (a: any, b: any) =>
            parseInt(a.auction['reserve-price'].value) - parseInt(b.auction['reserve-price'].value),
        );
        setReserveNotMetAuctions(assets);
        setReserveNotMetAuctionsValue(sortBy);
        break;

      case 'highestReservePrice':
        assets = reserveNotMetAuctions;
        assets.sort(
          (a: any, b: any) =>
            parseInt(b.auction['reserve-price'].value) - parseInt(a.auction['reserve-price'].value),
        );
        setReserveNotMetAuctions(assets);
        setReserveNotMetAuctionsValue(sortBy);
        break;

      case 'mostRecentlyListed':
        assets = reserveNotMetAuctions;
        assets.sort(
          (a: any, b: any) =>
            parseInt(b.auction['auction-id'].value) - parseInt(a.auction['auction-id'].value),
        );
        setReserveNotMetAuctions(assets);
        setReserveNotMetAuctionsValue(sortBy);
        break;

      default:
        break;
    }
  }

  function renderLiveAuctions() {
    if (liveAuctions && liveAuctions.length > 0) {
      return liveAuctions.map((asset: any, idx: number) => {
        const bid: any = asset.bid;
        const auction: any = asset.auction;
        let auctionDetailsText = `${bid.amount.value / 1e6} STX - ${asset.blocksRemaining} blocks`;
        const contractId = asset.contractAddress + '.' + asset.contractName;
        return (
          <EmptyDiv>
            <Card
              key={idx}
              renderer={renderer(asset.data, undefined, 600)}
              collection={asset.data.collection || asset.data.name}
              name={asset.data.name}
              auctionText={'LIVE'}
              auctionDetails={auction}
              auctionDetailsText={auctionDetailsText}
              tokenPath={`/collection/${asset.contractName}/${asset.tokenId}`}
              collectionPath={`/collection/${asset.contractName}`}
            />
            {adminAddresses.includes(currentUserAddress) ? (
              <EmptyDiv css={{ marginTop: 5 }}>
                <button onClick={() => handleFeature(contractId, asset.tokenId, asset.fqn)}>
                  feature
                </button>
                {contractId + '::' + asset.tokenId === updatedAuction ? (
                  <span>{updatedAuctionText}</span>
                ) : null}
              </EmptyDiv>
            ) : null}
          </EmptyDiv>
        );
      });
    } else {
      return null;
    }
  }

  function renderReserveNotMetAuctions() {
    if (reserveNotMetAuctions && reserveNotMetAuctions.length > 0) {
      return reserveNotMetAuctions.map((asset: any, idx: number) => {
        const auction: any = asset.auction;
        let auctionDetailsText = `RESERVE ${auction['reserve-price'].value / 1e6} STX`;
        const auctionCount = dupes[`${asset.contractAddress}.${asset.contractName}`];
        const contractId = asset.contractAddress + '.' + asset.contractName;
        return (
          <EmptyDiv>
            <Card
              key={idx}
              renderer={renderer(asset.data, undefined, 600)}
              collection={asset.data.collection || asset.data.name}
              name={asset.data.name}
              auctionText={'RESERVE NOT MET'}
              auctionDetails={auction}
              auctionDetailsText={auctionDetailsText}
              auctionCount={auctionCount && auctionCount > 1 ? auctionCount : 1}
              tokenPath={`/collection/${asset.contractName}/${asset.tokenId}`}
              collectionPath={`/collection/${asset.contractName}`}
            />
            {adminAddresses.includes(currentUserAddress) ? (
              <EmptyDiv css={{ marginTop: 5 }}>
                <button onClick={() => handleFeature(contractId, asset.tokenId, asset.fqn)}>
                  feature
                </button>
                {contractId + '::' + asset.tokenId === updatedAuction ? (
                  <span>{updatedAuctionText}</span>
                ) : null}
              </EmptyDiv>
            ) : null}
          </EmptyDiv>
        );
      });
    } else {
      return null;
    }
  }

  function renderSoldAuctions() {
    if (soldAuctions && soldAuctions.length > 0) {
      return soldAuctions.map((asset: any, idx: number) => {
        const bid: any = asset.bid;
        const auction: any = asset.auction;
        let auctionDetailsText = `SOLD FOR ${bid.amount.value / 1e6} STX`;
        const contractId = asset.contractAddress + '.' + asset.contractName;
        return (
          <EmptyDiv>
            <Card
              key={idx}
              renderer={renderer(asset.data, undefined, 600)}
              collection={asset.data.collection || asset.data.name}
              name={asset.data.name}
              auctionText={'SOLD'}
              auctionDetails={auction}
              auctionDetailsText={auctionDetailsText}
              soldFlag={true}
              auctionFlag={false}
              tokenPath={`/collection/${asset.contractName}/${asset.tokenId}`}
              collectionPath={`/collection/${asset.contractName}`}
            />
            {adminAddresses.includes(currentUserAddress) ? (
              <EmptyDiv css={{ marginTop: 5 }}>
                <button onClick={() => handleFeature(contractId, asset.tokenId, asset.fqn)}>
                  feature
                </button>
                {contractId + '::' + asset.tokenId === updatedAuction ? (
                  <span>{updatedAuctionText}</span>
                ) : null}
              </EmptyDiv>
            ) : null}
          </EmptyDiv>
        );
      });
    } else {
      return null;
    }
  }

  function handleAuctionCardsData(data: any) {
    let fetchedAuctionsRecord: any = fetchedAuctions;
    if (fetchedAuctionsRecord[data.nftId]) {
      return;
    }
    let auctionData = fetchedAuctionsRecord;
    auctionData[data.nftId] = data;
    setFetchedAuctions(auctionData);

    const percentLoaded = Math.trunc(
      (Object.keys(auctionData).length / nftsForAuction.length) * 100,
    );
    setLoadingText(`Fetching auction data (${percentLoaded}%)...`);

    if (Object.keys(auctionData).length === nftsForAuction.length) {
      setIsFetched(true);
      setLoadingText('');
      handleLoaded();
    }
  }

  function handleFeaturedCardsData(data: any) {
    if (data[0] === 'error') {
      setFeaturedAuctionsToRemove([...featuredAuctionsToRemove, data[1]]);
    }
  }

  function renderFeaturedAuctions() {
    return featuredAuctions.map((fqn: string) => {
      const contractAddress = fqn.split('.')[0];
      const contractName = fqn.split('.')[1].split('::')[0];
      const tokenId = fqn.split('::')[1].split(':')[1];
      return (
        <AuctionsCard
          contractAddress={contractAddress}
          contractName={contractName}
          tokenId={tokenId}
          blockHeight={blockHeight}
          onLoad={(data: any) => handleFeaturedCardsData(data)}
          isFeatured={true}
        >
          <div></div>
        </AuctionsCard>
      );
    });
  }

  function renderCards() {
    return nftsForAuction.map((nft: any) => {
      return (
        <EmptyDiv>
          <AuctionsCard
            contractAddress={nft.contractId.split('.')[0]}
            contractName={nft.contractId.split('.')[1]}
            tokenId={nft.tokenId}
            blockHeight={blockHeight}
            onLoad={(data: any) => handleAuctionCardsData(data)}
            disableRender={true}
          >
            <div></div>
          </AuctionsCard>
          {/* {adminAddresses.includes(currentUserAddress) ? (
            <EmptyDiv css={{ marginTop: 5 }}>
              <button onClick={() => handleFeature(nft.contractId, nft.tokenId)}>feature</button>
              {nft.contractId + '::' + nft.tokenId === updatedAuction ? (
                <span>{updatedAuctionText}</span>
              ) : null}
            </EmptyDiv>
          ) : null} */}
        </EmptyDiv>
      );
    });
  }

  async function handleFeature(contractId: string, tokenId: string, fqn: string) {
    // const nft: string = contractId + '::' + tokenId;
    const _featuredAuctions = await getFeaturedAuctions();
    let tokens: string[] = _featuredAuctions.tokens;
    let isFeatured: boolean = false;
    if (tokens.includes(fqn)) {
      tokens = tokens.filter((e) => e !== fqn);
    } else {
      tokens.unshift(fqn);
      isFeatured = true;
    }
    _featuredAuctions.tokens = tokens;
    const res = await postFeaturedAuctions(_featuredAuctions);
    if (res === 'success' && isFeatured) {
      setUpdatedAuctionText('added');
    } else if (res === 'success' && !isFeatured) {
      setUpdatedAuctionText('removed');
    } else {
      setUpdatedAuctionText('error');
    }
    setUpdatedAuction(contractId + '::' + tokenId);
  }

  function handleLoaded() {
    setIsLoaded(true);
    const reserveNotMet: any[] = [];
    const sold = [];
    const live = [];
    for (let i = 0; i < nftsForAuction.length; i++) {
      const nft: any = nftsForAuction[i];
      const nftId: any = nft.contractId + '::' + nft.tokenId;
      const fetchedNft = fetchedAuctions[nftId];
      const fqn = nft.id;
      fetchedNft['fqn'] = fqn;

      if (fetchedNft.status && fetchedNft.status === 'sold') {
        sold.push(fetchedNft);
        continue;
      }

      if (fetchedNft.status && fetchedNft.status === 'live') {
        live.push(fetchedNft);
        continue;
      }

      if (fetchedNft.auction !== '107') {
        reserveNotMet.push(fetchedNft);
      }
    }
    setSoldAuctions(sold);
    setLiveAuctions(live);
    setReserveNotMetAuctions(reserveNotMet);
    setViewAuctionType('inProgress');
  }

  function handleAuctionType(value: string) {
    if (!value) {
      return;
    }
    setViewAuctionType(value);
  }

  async function handleLoadMore() {
    setLoadMore(true);
    if (featuredAuctionsToRemove.length) {
      let _featuredAuctions = featuredAuctions;
      _featuredAuctions = featuredAuctions.filter((e) => !featuredAuctionsToRemove.includes(e));
      try {
        const json = await getFeaturedAuctions();
        json.tokens = _featuredAuctions;
        const res = await postFeaturedAuctions(json);
      } catch (e) {}
    }
  }

  return (
    <ContainerFull css={{ marginBottom: 50 }}>
      <H1>Featured Auctions</H1>

      {featuredAuctions && featuredAuctions.length ? (
        <div className={styles.Grid}>
          {renderFeaturedAuctions()}
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      ) : null}

      {isLoaded && isFetched ? (
        <ContainerItem>
          <H1>More Auctions</H1>

          <ToggleAuctionTypes
            onChange={(value: string) => handleAuctionType(value)}
            value={viewAuctionType}
          />
        </ContainerItem>
      ) : null}

      {loadingText && loadMore ? <h3>{loadingText}</h3> : null}

      {!loadMore ? (
        <ContinueButton
          text="Load more"
          onClick={() => handleLoadMore()}
          disabled={false}
        ></ContinueButton>
      ) : null}

      {soldAuctions && soldAuctions.length && viewAuctionType === 'recentlySold' ? (
        <>
          {/* <H2>Recently Sold</H2> */}
          <div className={styles.Grid}>
            {renderSoldAuctions()}
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </>
      ) : null}

      {liveAuctions && liveAuctions.length && viewAuctionType === 'inProgress' ? (
        <>
          {/* <H2>In Progress</H2> */}
          {/* <ToggleLiveAuctions
            onChange={(value: string) => sortAssets(value)}
            value={liveAuctionsValue}
          /> */}
          <div className={styles.Grid}>
            {renderLiveAuctions()}
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </>
      ) : null}

      {reserveNotMetAuctions &&
      reserveNotMetAuctions.length &&
      viewAuctionType === 'reserveNotMet' ? (
        <>
          {/* <H2>Reserve Not Met</H2> */}
          {/* <ToggleReserveNotMetAuctions
            onChange={(value: string) => sortAssets(value)}
            value={reserveNotMetAuctionsValue}
          /> */}
          <div className={styles.Grid}>
            {renderReserveNotMetAuctions()}
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </>
      ) : null}

      {loadMore && nftsForAuction && nftsForAuction.length && viewAuctionType === '' ? (
        <div className={styles.Grid}>
          {renderCards()}
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      ) : null}
    </ContainerFull>
  );
}

export default Auctions;
