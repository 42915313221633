import React, { Component } from 'react';
import styles from './index.module.scss';

import { darkmodeListener } from '../../theme';

import { convertIPFS } from '../../utils';
import { formatImageUrl } from '../../utils/format-image-url';

type RendererProps = {
  video?: string;
  src?: string;
  type?: string;
  imageClasses: string;
  text?: string;
  audio?: string;
  poster?: string;
  size?: number;
};
type RendererState = {};

class Renderer extends Component<RendererProps, RendererState> {
  static defaultProps = {
    imageClasses: [],
  };

  componentDidMount() {
    darkmodeListener(styles['theme-light'], styles['theme-dark']);
  }

  render() {
    return (
      <div className={styles.Renderer}>
        {this.props.audio && this.props.poster && (
          <video
            controls
            className={styles.Video}
            poster={formatImageUrl({ url: this.props.poster })}
          >
            <source src={convertIPFS(this.props.audio)} type={this.props.type} />
          </video>
        )}
        {this.props.video && (
          <video
            controls
            autoPlay
            muted
            loop
            className={styles.Video}
            poster={formatImageUrl({ url: this.props.video, size: this.props.size, isVideo: true })}
            src={formatImageUrl({ url: this.props.video, size: this.props.size, isVideo: true })}
          >
            {/* <source
              src={formatImageUrl({ url: this.props.video, size: this.props.size, isVideo: true })}
              type={this.props.type}
            /> */}
          </video>
        )}
        {this.props.src && this.props.video == undefined && (
          <img
            className={`${styles.Image} ${this.props.imageClasses ?? ''}`}
            // src={convertIPFS(this.props.src)}
            src={formatImageUrl({ url: this.props.src, size: this.props.size })}
            alt={this.props.text}
          />
        )}
        {this.props.text && this.props.src == undefined && (
          <div className={styles.Text}>
            <div>{this.props.text}</div>
          </div>
        )}
        {/* {this.props.text == undefined && this.props.src == undefined && (
          <div className={styles.UnknownText}>?</div>
        )} */}
      </div>
    );
  }
}

export default Renderer;
