import { ArrowLeftIcon } from '@radix-ui/react-icons';
import { useAtom } from 'jotai';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { assetsAtom, useResetAtoms } from '../../../../components/atoms';
import { H1, Wrapper } from '../../../../components/common';
import ProgressBar from '../../../../components/Studio/ProgressBar';
import MintRequest from '../../MintRequest';
import CreatorTerms from '../../../../components/creator-terms';

import Details from './public-details';
import Media from './public-media';
import Minting from './public-minting';
import Publish from './public-publish';
import Review from './public-review';

function Public() {
  const [assets, setAssets] = useAtom(assetsAtom);

  const [state, setState] = useState({ step: 1 });
  const [txUrl, setTxUrl] = useState('');
  const history = useHistory();
  const { resetDeployAtoms } = useResetAtoms();

  useEffect(() => {
    // Revoke data uris to avoid memory leaks, will run on unmount
    return () => {
      if (assets && assets.length) {
        assets.forEach((files: any) => URL.revokeObjectURL(files.preview));
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function prevStep() {
    const step = state.step;
    if (step === 1) {
      resetDeployAtoms();
      history.push('/create');
    } else {
      setState({ ...state, step: step - 1 });
    }
  }

  function nextStep(txUrl?: string) {
    const step = state.step;
    setState({ ...state, step: step + 1 });

    if (txUrl) {
      setTxUrl(txUrl);
    }
  }

  function handleStepSelected(stepSelected: number) {
    setState({ ...state, step: stepSelected });
  }

  function renderStep() {
    switch (state.step) {
      case 1:
        return <Details onNextStep={() => nextStep()} />;

      case 2:
        return <Media onNextStep={() => nextStep()} />;

      case 3:
        return <Minting onNextStep={() => nextStep()} />;

      case 4:
        return <Review onNextStep={() => nextStep()} />;

      case 5:
        return <CreatorTerms onNextStep={() => nextStep()} />;

      case 6:
        return <Publish onNextStep={() => nextStep()} />;

      case 7:
        return <MintRequest txUrl={txUrl} />;

      default:
        prevStep();
        return null;
    }
  }

  return (
    <Wrapper>
      {state.step < 7 ? (
        <>
          <H1>Create your public collection</H1>

          <ProgressBar
            steps={['1. Details', '2. Media', '3. Minting', '4. Review', '5. Terms', '6. Publish']}
            step={state.step}
            onStepSelected={(stepSelected: number) => handleStepSelected(stepSelected)}
          />

          <ArrowLeftIcon style={{ cursor: 'pointer' }} onClick={() => prevStep()} />
        </>
      ) : null}
      {renderStep()}
    </Wrapper>
  );
}

export default Public;
