import React, { Component } from 'react';
import styles from './index.module.scss';

import { darkmodeListener } from '../../theme';
import { Link } from 'react-router-dom';
import { DropCard, EmptyCard } from '../cards';

type DropProps = {
  new?: boolean;
  image: string;
  name: string;
  description: string;
  to: string;
};
type DropState = {};

class Drop extends Component<DropProps, DropState> {
  renderNew() {
    if (this.props.new) {
      return <div className={styles.DropsNew}>NEW</div>;
    }
  }

  render() {
    return (
      <a href={`https://stacks.gamma.io/${this.props.to}`} target="_blank" rel="noopener noreferrer">
        <DropCard>
          <div className={styles.Drops}>
            <img className={styles.DropsImage} src={this.props.image} />
            <div className={styles.DropsData}>
              <div className={styles.DropsDataName}>{this.props.name}</div>
              <div className={styles.DropsDataStats}>{this.props.name}</div>
              <div className={styles.DropsDataBackground}>{this.props.description}</div>
              {this.renderNew()}
            </div>
          </div>
        </DropCard>
      </a>
    );
  }
}

export default Drop;
